import { StyleSheet } from 'react-native';
import { getColors } from './colors';

const COLORS = getColors();

enum FONT_TYPES {
    MuliBase = 'Muli',
    Muli_200ExtraLight = 'Muli_200',
    Muli_300Light = 'Muli_300',
    Muli_400Regular = 'Muli_400',
    Muli_500Medium = 'Muli_500',
    Muli_600SemiBold = 'Muli_600',
    Muli_700Bold = 'Muli_700',
    Muli_800ExtraBold = 'Muli_800',
    Muli_900Black = 'Muli_900Black',
    Muli_200ExtraLight_Italic = 'Muli_200_italic',
    Muli_300Light_Italic = 'Muli_300_italic',
    Muli_400Regular_Italic = 'Muli_400_italic',
    Muli_500Medium_Italic = 'Muli_500_italic',
    Muli_600SemiBold_Italic = 'Muli_600_italic',
    Muli_700Bold_Italic = 'Muli_700_italic',
    Muli_800ExtraBold_Italic = 'Muli_800_italic',
    Muli_900Black_Italic = 'Muli_900_italic',
    Inter100Thin = 'Inter_100',
    Inter200ExtraLight = 'Inter_200',
    Inter300Light = 'Inter_300',
    Inter400Regular = 'Inter_400',
    Inter500Medium = 'Inter_500',
    Inter600SemiBold = 'Inter_600',
    Inter700Bold = 'Inter_700',
    Inter800ExtraBold = 'Inter_800',
    Inter900Black = 'Inter_900',
}

const textStyles = StyleSheet.create({
    base: {
        fontFamily: 'Inter',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Inter_16_500: {
        fontFamily: FONT_TYPES.Inter500Medium,
        fontWeight: '500',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Inter_16_600: {
        fontFamily: FONT_TYPES.Inter600SemiBold,
        fontWeight: '600',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Inter_16_700: {
        fontFamily: FONT_TYPES.Inter700Bold,
        fontWeight: '700',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_200: {
        fontFamily: FONT_TYPES.Muli_200ExtraLight,
        fontWeight: '200',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_300: {
        fontFamily: FONT_TYPES.Muli_300Light,
        fontWeight: '300',
        fontSize: 14,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_400: {
        fontFamily: FONT_TYPES.Muli_400Regular,
        fontWeight: '400',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_500: {
        fontFamily: FONT_TYPES.Muli_500Medium,
        fontWeight: '500',
        fontSize: 16,
        textAlign: 'center',
    },
    Muli_16_600: {
        fontFamily: FONT_TYPES.Muli_600SemiBold,
        fontWeight: '600',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_700: {
        fontFamily: FONT_TYPES.Muli_700Bold,
        fontWeight: '700',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_800: {
        fontFamily: FONT_TYPES.Muli_800ExtraBold,
        fontWeight: '800',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    Muli_16_900: {
        fontFamily: FONT_TYPES.Muli_900Black,
        fontWeight: '900',
        fontSize: 16,
        textAlign: 'center',
        color: COLORS.textPrimary,
    },
    popUpErrorMessageText: {
        fontFamily: FONT_TYPES.Muli_400Regular,
        fontWeight: '400',
        fontSize: 14,
        textAlign: 'center',
        color: COLORS.errorTextColor,
    },
});

export { textStyles, FONT_TYPES };
