export enum UserRole {
    /**
     * Super admin.
     */
    admin = 'admin',

    companyAdmin = 'companyAdmin',

    regionAdmin = 'regionAdmin',

    recruiter = 'recruiter',

    client = 'client',

    employee = 'employee',
}

/**
 * Roles managed through the user management page.
 */
export const CompanyUserRoles = [
    UserRole.companyAdmin,
    UserRole.regionAdmin,
    UserRole.recruiter,
] as const;

export const UserRolesWithBranches = [
    UserRole.recruiter,
    UserRole.client,
    UserRole.employee,
] as const;

export const UserRolesWithRegions = [
    ...UserRolesWithBranches,
    UserRole.regionAdmin,
] as const;

export enum UserStatus {
    active = 'active',
    inactive = 'inactive',
}
