import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text} from 'react-native';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import {  EmployeeJobsTableProps} from './types';
import { EmployeePosition, toEmployeePositionLabel, toEmployeePositionTypeLabel} from '@temptrack/business';
import {Actions} from './EmployeeJobActionsUI';
import { getEmptyStrings } from './utils';
import { listViewHeaderHeighNoFilters, listViewHeaderHeight } from '../../../../constants/elementSizes';


const header = [
    {content: 'Client name', conatinerStyle: { minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'address', conatinerStyle: { minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'start date', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'end date', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'position', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'position type', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1, minWidth: 120}, textStyle: tableStyles.headerTextStyle },

];

const JobsTable = ({data, isLoading, openRowMenu, viewType}:  EmployeeJobsTableProps) => {
    const tableHeight = utilHooks.useTableHeight() + listViewHeaderHeight - listViewHeaderHeighNoFilters;

    const keyExtractor = useCallback((index) =>data?.[index]?.workOrderId + viewType ?? index, [data, viewType]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {

            const columns = {
                clientName: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.clientName}</Text>,
                address: <Text style={tableStyles.tableRowText} numberOfLines={2}>
                    {utils.getAddressToDisplay(item?.jobsiteAddress)}
                </Text>,
                startDate: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.formatDateObjectToDisplay(item?.startDate)}</Text>,
                endDate: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.formatDateObjectToDisplay(item?.endDate)}</Text>,
                position: <Text style={tableStyles.tableRowText} numberOfLines={1}>{toEmployeePositionLabel(item.position as EmployeePosition)}</Text>,
                positionType: <Text style={tableStyles.tableRowText} numberOfLines={1}>{toEmployeePositionTypeLabel(item.positionType)}</Text>,
            };

            const actions = <Actions onEditPress={()=>{}}
                                     hideEdit={true}
                                     threeDotAction={(e:GestureResponderEvent)=>openRowMenu(e, item.workOrderId)}/>;

            return [
                columns.clientName,
                columns.address,
                columns.startDate,
                columns.endDate,
                columns.position,
                columns.positionType,
                actions,
            ];


        });
    }, [data, openRowMenu]);

    const emptyListStrings = useMemo(()=> {
        return getEmptyStrings(viewType);

    },[viewType]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={header}
                          headerStyle={tableStyles.tableHeader}
                          data={dataToDisplay}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={  <EmptyTableView onAddPress={()=>{}}
                                                            title={emptyListStrings.title}
                                                            subtitle={emptyListStrings.subtitle}
                                                            buttonText={''}
                                                            hideAddButton={true}
                                                            hasFilters={false}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { JobsTable };
