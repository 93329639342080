import {
    WorkOrderJobsiteEnvironment,
    WorkOrderLanguage,
    WorkOrderPPE,
    WorkOrderPayFrequency,
    WorkOrderShift,
    WorkOrderStatus,
} from '.';
export {
    toEmployeePositionTypeLabel as toWorkOrderPositionTypeLabel,
    toEmployeePositionLabel as toWorkOrderPositionLabel,
} from '../employee';

export function toWorkOrderStatusLabel(
    workOrderStatus: WorkOrderStatus,
): string {
    return {
        [WorkOrderStatus.open]: 'Open',
        [WorkOrderStatus.inProgress]: 'In Progress',
        [WorkOrderStatus.completed]: 'Completed',
    }[workOrderStatus];
}

export function toWorkOrderPayFrequencyLabel(
    workOrderPayFrequency: WorkOrderPayFrequency,
): string {
    return {
        [WorkOrderPayFrequency.weekly]: 'Weekly',
        [WorkOrderPayFrequency.biweekly]: 'Bi-weekly',
        [WorkOrderPayFrequency.monthly]: 'Monthly',
    }[workOrderPayFrequency];
}

export function toWorkOrderShiftLabel(workOrderShift: WorkOrderShift): string {
    return {
        [WorkOrderShift.morning]: 'Morning (1st)',
        [WorkOrderShift.afternoon]: 'Afternoon (2nd)',
        [WorkOrderShift.night]: 'Night (3rd)',
    }[workOrderShift];
}

export function toWorkOrderPPELabel(workOrderPPE: WorkOrderPPE): string {
    return {
        [WorkOrderPPE.steelToeBoots]: 'Steel Toe Boots',
        [WorkOrderPPE.slipResistantShoes]: 'Slip Resistant Shoes',
        [WorkOrderPPE.safetyVest]: 'Safety Vest',
        [WorkOrderPPE.gloves]: 'Gloves',
        [WorkOrderPPE.other]: 'Other',
    }[workOrderPPE];
}

export function toWorkOrderJobsiteEnvironmentLabel(
    workOrderJobsiteEnvironment: WorkOrderJobsiteEnvironment,
): string {
    return {
        [WorkOrderJobsiteEnvironment.food]: 'Food',
        [WorkOrderJobsiteEnvironment.industrial]: 'Industrial',
        [WorkOrderJobsiteEnvironment.produce]: 'Produce',
        [WorkOrderJobsiteEnvironment.machinery]: 'Machinery',
        [WorkOrderJobsiteEnvironment.apparel]: 'Apparel',
        [WorkOrderJobsiteEnvironment.freezer]: 'Freezer',
        [WorkOrderJobsiteEnvironment.other]: 'Other',
    }[workOrderJobsiteEnvironment];
}

export function toWorkOrderLanguageLabel(
    workOrderLanguage: WorkOrderLanguage,
): string {
    return {
        [WorkOrderLanguage.bilingual]: 'Bilingual',
        [WorkOrderLanguage.english]: 'English',
        [WorkOrderLanguage.spanish]: 'Spanish',
        [WorkOrderLanguage.other]: 'Other',
    }[workOrderLanguage];
}
