import React, { useMemo } from 'react';
import { ScrollView, StyleSheet, View } from 'react-native';
import LoginInput from './LoginInput';
import { ILoginUI, LOGIN_UI_BUTTON_PRESSED_TYPES } from './LoginUtils';
import { ComponentWrapper } from '../../helperComponents/componentWrapper/ComponentWrapper';
import { applyTTStyles, ColorSchema, getColors } from '../../../constants/colors';
import { DisplayLogo } from '../../helperComponents/misc/DisplayLogo';
import { SelectAnotherSubdomainUI } from '../EnterUrl/SelectAnotherSubdomainUI';

const LoginUI = ({ onButtonPressed,
                     email, setEmail,
                     password, setPassword,
                     formErrorMessages, errorMessage,
                     isTTEmployee, companyData,
            phoneNumberField,
}: ILoginUI) => {
    const COLORS = getColors();

    const styles = useMemo(()=> getStyles(COLORS), [COLORS]);

    return (
        <ComponentWrapper hideWebHeader>
            <ScrollView contentContainerStyle={styles.container}>


            <View style={styles.logoContainer}>
                <DisplayLogo isTempTrack={isTTEmployee}
                             svgSize={146}
                             width={200}
                    logoUrl={companyData?.logoUrl} companyName={companyData?.name} ignoreName={isTTEmployee}/>
            </View>
                <LoginInput
                    onButtonPressed={onButtonPressed}
                    email={email}
                    setEmail={setEmail}
                    password={password}
                    setPassword={setPassword}
                    formErrorMessages={formErrorMessages}
                    errorMessage={errorMessage}
                   isTTEmployee={isTTEmployee}
                    phoneNumberField={phoneNumberField}
                />
            <SelectAnotherSubdomainUI
                action={()=>onButtonPressed(isTTEmployee ? LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_TO_ENTER_URL : LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_TO_TT_LOGIN)}
                title={isTTEmployee ? 'Not a TempTrack admin?' : 'TempTrack admin?'}
                buttonColor={applyTTStyles(!isTTEmployee).textButtonColor}/>
            </ScrollView>
        </ComponentWrapper>
    );
};

export default LoginUI;

const getStyles = (theme: ColorSchema)=>StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        flex:1,
        backgroundColor: theme.backgroundSecondary,
    },
    logoContainer: {
        width: '100%',
        marginBottom: 16,
        alignItems: 'center',
    },
});
