import React from 'react';
import { utils } from '../utils/utils';
import { SignedInStatusContextProvider } from './SignedInContext';
import { QueryContextProvider } from './QueryContextProvider';
import { MB_PortalProvider } from './MB_Portal';
import {CurrentCompanyContextProvider} from './CurrentCompanyContext';
import {DrawerContextProvider} from './DrawerContext';

const providers: React.FC[] = [
    QueryContextProvider,
    SignedInStatusContextProvider,
    MB_PortalProvider,
    CurrentCompanyContextProvider,
    DrawerContextProvider,
];

const AppContextProvider = utils.combineComponents(providers);

export { AppContextProvider };
