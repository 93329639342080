import React, {useMemo} from 'react';
import { Text, View, StyleSheet, TextStyle, StyleProp, ViewStyle } from 'react-native';
import { getColors } from '../../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';


const COLORS = getColors();

enum DisplayOptions {
    primary = 'primary',
    secondary = 'secondary',
}

const ValueRow = ({ label, value, valueComponent, displayOption, customLabelStyle, customTextStyle, containerStyles, fontSize }: {
    label?: string,
    value?: string,
    valueComponent?: JSX.Element,
    displayOption?: DisplayOptions,
    customLabelStyle?: StyleProp<TextStyle>
    customTextStyle?: StyleProp<TextStyle>
    containerStyles?: StyleProp<ViewStyle>
    fontSize?: number
}) => {
    const labelStyle = useMemo(() => {
        return [styles.label, displayOption === DisplayOptions.secondary ? styles.labelSecondary : styles.labelPrimary];
    }, [displayOption]);

    const textStyle = useMemo(() => {
        return [styles.text, styles.textPrimary, customTextStyle ?? {}];
    }, [customTextStyle]);

    return (
        <View style={[styles.container, containerStyles]}>
            {label ? <Text style={[labelStyle, customLabelStyle, fontSize ? {fontSize: fontSize} : null]}>{`${label}:`}</Text> : null}
            {valueComponent ?? <Text style={[textStyle, fontSize ? {fontSize: fontSize} : null]}>{value ?? ''}</Text>}
        </View>
    );
};


export { ValueRow, DisplayOptions };
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    text: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'left',
            flex: 1,
            flexWrap: 'wrap',
        },
    ]),
    label: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '400',
            marginRight: 8,
        },
    ]),
    labelSecondary: {
        color: COLORS.textSecondary,
    },
    labelPrimary: {
        color: COLORS.textPrimary,
    },
    textPrimary: {
        color: COLORS.textPrimary,
    },
});


