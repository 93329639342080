import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Rect } from 'react-native-svg';

const UploadLogo = React.memo((props: { size?: number, containerStyle?: StyleProp<ViewStyle> }) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 208}
                height={props.size ? props.size * (81 / 208) : 81}
                viewBox="0 0 208 81"
                fill="none"
            >
                <Rect x={0.5} y={1} width={207} height={79} rx={23.5} fill="#F9FAFB" />
                <Rect
                    x={0.5}
                    y={1}
                    width={207}
                    height={79}
                    rx={23.5}
                    stroke="#D1D5DB"
                    strokeDasharray="10 10"
                />
            </Svg>
        </View>
    );
});

export { UploadLogo };
