import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import { UserStatus } from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
    MB_TextInputRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import {recordModalContentStyles} from '../../../../constants/recordModalContentStyles';
import { DefaultSwitch } from '../../../helperComponents/misc/DefaultSwitch';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import {AdminCreateUserInput, AdminEditUserInput} from '@temptrack/business';
import { trpc } from '../../../../apiCalls/trpcClient';
import {LoadingPage} from '../../../helperComponents/LoadingPage';
import { useEmail } from '../../../../hooks/useEmail';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    firstName = 'firstName',
    lastName = 'lastName',
}


const AdminUserForm = (
    {
        isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        userId,
        resetSubmitError,
        showTimeline,
    }: {
    userId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data:any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {

    const [initialIsDone, setInitialIsDone] = useState(false);
    const phoneNumber = usePhoneNumber({isRequired: true});
    const email = useEmail({isRequired: true, nextToFocus: phoneNumber.ref});

    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');
    const [status, setStatus] = React.useState<UserStatus>(UserStatus.active);

    const lastNameRef = useRef<MB_TextInputRef>(null);

    const {data: recordData, isLoading} = trpc.admin.user.getById.useQuery({userId: userId ?? '' }, {
        enabled: isEditing && !!userId,
    });

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.userData) {
            const initialData = recordData.userData;
            setFirstName(initialData.firstName);
            setLastName(initialData.lastName);
            setStatus(initialData.status);

            email.setInitialEmail(initialData.email);
            phoneNumber.setInitialValue(initialData.phoneNumber);
            setInitialIsDone(true);
        }

    }, [recordData, email, phoneNumber, initialIsDone, setInitialIsDone]);

    const {data: timeline, isLoading: gettingTimeline} = trpc.admin.user.getTimeline.useQuery({userId: userId ?? ''}, {
        enabled: showTimeline && !!userId,
    });

    const fieldsMap = useMemo(()=> {
        const base = {
            [InputTypes.firstName]: {value: firstName, action: setFirstName},
            [InputTypes.lastName]: {value: lastName, action: setLastName},
        };
        return base;
    }, [firstName, lastName]);

    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
        [InputTypes.firstName]: '',
        [InputTypes.lastName]: '',
    });

    const isEmpty = (value: string) => {
        if (!value) {
            return true;
        }
        return value?.trim().length === 0;
    };

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;
        const requiredFields = [InputTypes.firstName, InputTypes.lastName];

        //check required fields
        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });

        setFormErrorMessages(errors);
        const emailIsValid = email.validateEmail();
        const phoneNumberIsValid = phoneNumber.validate();
        return isValid && emailIsValid && phoneNumberIsValid;
    },[fieldsMap, formErrorMessages, setFormErrorMessages, email, phoneNumber]);

    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);

    const setFormValue = useCallback((field: InputTypes, value: string) => {
        fieldsMap[field].action(value);
    }, [fieldsMap]);

    const createRecord = () => {
        const data: AdminCreateUserInput = {
            firstName,
            lastName,
            email: email.value,
            phoneNumber: phoneNumber.value,
            status,
        };
        action(data);
    };

    const editRecord = () => {
        const data: AdminEditUserInput = {
            id: userId as string,
            firstName,
            lastName,
            email: email.value,
            phoneNumber: phoneNumber.value,
            status,
        };
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {
            createRecord();

        }
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.firstName]: {title: 'First name', placeholder: 'Enter first name',
                textContentType: 'name',  showRequiredAsteriks: true,
                nextToFocus: lastNameRef,
            },
            [InputTypes.lastName]: {title: 'Last name', placeholder: 'Enter last name',
                textContentType: 'name', showRequiredAsteriks: true,
                nextToFocus: email.ref,
            },
        };

        const onChange = (newText: string) => {
            setFormErrorMessages((prev) => ({...prev, [field]: ''}));
            setFormValue(field, newText);
            resetSubmitError();
        };

        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            onChangeText: onChange,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
        };
    }, [fieldsMap, setFormValue, resetSubmitError, email.ref]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.primary500}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1}}>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.firstName)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.firstName)}
                                          nextToFocus={lastNameRef}
                                      />}
                        />
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.lastName)}
                                      inputComponent={<MB_TextInput {...getInputProps(InputTypes.lastName)}
                                          MB_Ref={lastNameRef}
                                      />}
                        />
                    </View>
                </View>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>
                <View style={[{justifyContent: 'flex-start'}]}>
                    <Text style={recordModalContentStyles.inputLabel}>Status</Text>

                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <DefaultSwitch isToggled={status === UserStatus.active}
                                       onToggle={(newValue)=> {
                                           setStatus(newValue ? UserStatus.active : UserStatus.inactive);}}
                                       shouldUseTTStyles={true}
                                       label={status}

                        />
                    </View>

                </View>
            </View>


            <View>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add user'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(true).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                            // preventDisabledStyleChange={true}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>


        </ScrollView>
    );
};

export { AdminUserForm };
