import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GestureResponderEvent, View } from 'react-native';
import { trpc } from '../../../../apiCalls/trpcClient';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { AdminCompanyForm } from './AdminCompanyContent';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { UIDrawerRightContentWrapper } from '../../../helperComponents/DrawerRightContentWrapper';
import { CompaniesTable } from './TableView';
import { AdminCompaniesProps } from '../../../../typesAndInterfaces/componentProps';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { routeParams } from '../../../../constants/urlConstants';
import { AdminViewRoutes } from '../../../../navigations/types';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { navigatorTypes } from '../../../../navigations/CompanyDashboardNavigator/CompanyDashboardNavigator';
import { utils } from '../../../../utils/utils';
import { useGetCompanies } from './useGetCompanies';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterFieldType } from '@temptrack/business';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';


const COLORS = getColors();

const Companies = ({navigation, route}:AdminCompaniesProps) => {
    const trpcContext = trpc.useUtils();

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.companyId);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);

    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);

    const [errorMessage, setErrorMessage] = useState('');

    const {goToCompanyDashboard} = useCurrentCompanyContext();

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const { PaginationComponent, recordData, isLoading,
              hasNextPage, fetchNextPage, isPreviousData, setFilters} = useGetCompanies({navigation, route});

    const applyFiltersCallback = useCallback(({ filters}: FilterQueryAndOtherParams)  => {
        setFilters?.(filters);
    }, [setFilters]);

    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'Company Name',
                apiName: 'name',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
        ];
    }, []);

    const filters = useFilter({
        isTempTrack: true,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
    });

    const onRecordAddPress = useCallback(() => {
        navigation.navigate(AdminViewRoutes.companies, {...route.params, ...{companyId: routeParams.newRecord}});
        setShowRecordModal(true)
        ;} , [navigation, route.params]);

    const addButtonText = 'Add new company';

    const closeModal = useCallback(()=> {
        navigation.navigate(AdminViewRoutes.companies,{...route.params, ...{companyId: '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, route.params]);

    const onEditRecordPressed = useCallback((id: string|undefined, timeline?: boolean) => {
        if (id) {
            navigation.navigate(AdminViewRoutes.companies, {...route.params, ...{companyId: id, timeline: timeline ?? undefined }});
            setShowRecordModal(true);
        } else {
           closeModal();
        }

    }, [navigation, closeModal, route.params]);

    const modalCompanyId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeCompanyId = route.params?.companyId;
        return routeCompanyId === routeParams.newRecord ? undefined : routeCompanyId;
    } , [route.params?.companyId, showRecordModal]);

    const refreshList = useCallback((id?:string)=> {
        if (id) {
            trpcContext.admin.company.getById.invalidate({id: id});

        }
        if (isMobileApp) {
            trpcContext.admin.company.list.invalidate();
        } else {
            trpcContext.admin.company.getPage.invalidate();
        }
    }, [trpcContext.admin.company.getPage,trpcContext.admin.company.list, trpcContext.admin.company.getById]);


    const {mutate: createRecord, isLoading: isLoadingRecordCreate} = trpc.admin.company.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Inviting Company',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editRecord, isLoading: isLoadingEdit } = trpc.admin.company.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.companyData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing company',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: deleteCompany, isLoading: isLoadingCompanyDelete} = trpc.admin.company.delete.useMutation({
        onSuccess: ()=> {
            refreshList();
            hideDeleteCompany();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting company',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });


    const {hide: hideDeleteCompany, show: showDeleteCompany, component:DeleteCompany, setDeleteError} = useDeleteRecord({
        recordType: 'Company',
        deleteAction: (id)=>{deleteCompany({id: id},{
            onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: isLoadingCompanyDelete,
    });

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);


    const menuItems = useMemo(()=> {
        const base = [
            {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
            }},
            {title: 'View dashboard', onPress: ()=>{
                    setShowMenuForRowId(undefined);
                    goToCompanyDashboard(showMenuForRowId!, navigatorTypes.CompanyDashboardNavigator);

                }},
            {title: 'Delete', onPress: ()=>{
                    setShowMenuForRowId(undefined);
                    showDeleteCompany({
                        recordId: showMenuForRowId ?? '',
                        recordName: recordData?.items.find((item)=>item.id === showMenuForRowId!)?.name,
                    });
                }, textStyle: {color: COLORS.dangerTextColor}},
        ];
        if (isMobileApp) {
            base.unshift({title: 'Edit', onPress: ()=>{
                    setShowMenuForRowId(undefined);
                    onEditRecordPressed(showMenuForRowId);
            }});
        }
        return base;
    }, [showMenuForRowId, goToCompanyDashboard, showDeleteCompany, recordData?.items, onEditRecordPressed]);

    const isLoadingData = useMemo(()=> {
        return recordData ? (isLoading && !isPreviousData) : isLoading;
    } , [recordData, isLoading, isPreviousData]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const headerComponent = useMemo(()=> {
        return <ListViewHeader title={'List of Companies'}
                               subtitle={'View and manage our companies.'}
                               buttonText={addButtonText}
                               onAddPress={onRecordAddPress}
                               buttonColor={applyTTStyles(true).primaryButtonBg}
                               filterComponent={filters.Button}

        />;
    }, [onRecordAddPress, filters.Button, addButtonText]);

    const recordModalTitleProps = useMemo(()=> {
        if (!modalCompanyId) {
            return {title: 'Add new company'};
        }
        const company = recordData?.items.find((item)=>item.id === modalCompanyId);
        if (showTimeline) {
            return {
                title: company ? `Timeline for ${company.name}` : 'Timeline',
            };
        } else {
            return {
                title: company ? `Edit ${company.name}` : 'Edit company',
            };
        }

    }, [showTimeline, modalCompanyId, recordData?.items]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            <>
                {DeleteCompany}
                <ModalWrapper show={ showRecordModal}
                              closeAction={()=>onEditRecordPressed(undefined)}
                              {...recordModalTitleProps}
                              maxHeight={undefined}
                >
                    {showRecordModal ? <AdminCompanyForm isEditing={!!modalCompanyId}
                                                         closeAction={()=>onEditRecordPressed(undefined)}
                                                         companyId={modalCompanyId}
                                                         action={modalCompanyId ? editRecord : createRecord}
                                                         submitInProgress={modalCompanyId ? isLoadingEdit : isLoadingRecordCreate}
                                                         submitErrorMessage={errorMessage}
                                                         resetSubmitError={()=>setErrorMessage('')}
                                                         showTimeline={showTimeline}
                                                         toggleShowTimeline={toggleShowTimeline}

                    /> : null}
                </ModalWrapper>
                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                callerX={position.x}
                                callerY={position.y}
                                visible={!!showMenuForRowId}
                                menuItems={menuItems}
                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}

                {isMobileApp ? null : headerComponent}
                {filters.FilterPanel}
                <CompaniesTable data={recordData?.items}
                                refreshList={refreshList}
                                isLoading={isLoadingData}
                                openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                                onEditRecordPressed={onEditRecordPressed}
                                addButtonText={addButtonText}
                                onAddPress={onRecordAddPress}
                                headerComponent={headerComponent}
                                fetchMore={hasNextPage ? fetchNextPage : undefined}
                                hasFilters={filters.filtersApplied}
                />
                {isMobileApp ? null : <View style={{minHeight:60}}>
                {PaginationComponent ? PaginationComponent({totalItems: recordData?.totalItems, currentItemsLength: recordData?.items?.length ?? 0}) : null}
                </View>}
                </>
        </UIDrawerRightContentWrapper>
    );
};

export { Companies };

