import React from 'react';
import { NavMenuProps } from './types';
import { View } from 'react-native';
import { NavMenuItem } from './NavMenuItem';
import {ModalDropdown} from '@mightybyte/rnw.components.modal-dropdown';
import {MenuItem} from './types';
import {appColors} from '../../../constants/colors';
import { headerStyles } from './styles';


const DesktopMenu = ({
    items,
    currentActiveTab,
    tabIsActive,
    tabOnClick,
}:NavMenuProps) => {
    return (
        <View style={{flexDirection: 'row', flex: 1, marginLeft: 36}} key={currentActiveTab}>
            {items.map((item, index) => {

                const isActive = tabIsActive(item);
                if (item.subTabs) {
                    const dropdownHeight = item.subTabs.length * 40;
                    return (
                        <View key={index.toString() + item.label} style={{flexDirection: 'row'}}>
                        <ModalDropdown options={item.subTabs}
                                       ref={item?.tabRef?.modal}
                                       dropdownStyle={[headerStyles.modalContentContainer, {
                                           padding: 0,
                                           height: dropdownHeight,
                                       }]}
                                       renderRow={(subItem:MenuItem, subIndex:number) => {
                                           return <NavMenuItem key={subIndex.toString() + subItem.label}
                                                               item={subItem}
                                                               index={subIndex}
                                                               isActive={tabIsActive(subItem)}
                                                               action={() => {
                                                                   item?.tabRef?.modal?.current?.hide();
                                                                   tabOnClick(subItem);
                                                               }} />;
                                       }}

                                       onDropdownWillHide={() => {
                                           item?.tabRef?.chevron?.current?.setToInitialRotation();
                                       }}
                                       onDropdownWillShow={() => {
                                           item?.tabRef?.chevron?.current?.setToFinalRotation();}}
                        >
                            <View style={{
                                borderRadius: 8,
                                paddingHorizontal: 8,
                                marginVertical: 0,
                                marginRight: 16,
                                backgroundColor: isActive ? appColors.gray100 : undefined,
                            }}>
                                <NavMenuItem index={index} item={item} isActive={isActive} action={()=>tabOnClick(item)}/>
                            </View>
                        </ModalDropdown>
                        </View>
                    );
                }
                return <NavMenuItem  key={index.toString() + item.label}
                                     item={item}
                                     index={index}
                                     isActive={isActive}
                                     action={()=>tabOnClick(item)}


                />;
            })}
        </View>
    );
};

export {DesktopMenu};
