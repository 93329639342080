export const DEFAULT_TABLE_MAX_ROWS = 10;

export const enum SERVER_ERROR_CODES {
    EMAIL_ALREADY_IN_USE = 'EMAIL_ALREADY_IN_USE',
    CLIENT_DATA_MISMATCH_ERROR = 'CLIENT_DATA_MISMATCH_ERROR',
    INVALID_ACCESS_TOKEN = 'INVALID_ACCESS_TOKEN',
    INVALID_REFRESH_TOKEN = 'INVALID_REFRESH_TOKEN',
    EXPIRED_ACCESS_TOKEN = 'EXPIRED_ACCESS_TOKEN',
    INVALID_PASSWORD = 'INVALID_PASSWORD',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    NETWORK_ERROR = 'NETWORK_ERROR',
    INACTIVE_USER = 'INACTIVE_USER',
}

export const STRING_CONSTANTS = {
    SUCCESS: 'Success',
    ERROR: 'Error',
    EMAIL_ALREADY_IN_USE:
        'This email address is already taken. Please try to log in or use a different email.',
    EMAIL_INVALID: 'Invalid email',
    ENTER_VALID_EMAIL_OR_PHONE: 'Please enter a valid email or phone number',
    FAILED_TO_LOG_IN: 'Failed to log in, please try again',
    PASSWORDS_DO_NOT_MATCH: 'Passwords do not match',
    REQUIRED: 'This field is required',
    // NEW_PASSWORD_IS_SAME_AS_OLD: 'The new password can`t be the same as the old password',
    CURRENT_PASSWORD_WAS_INVALID: 'Current password was invalid',
    SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN:
        'Something went wrong. Please try again',
    INVALID_PASSWORD: 'Invalid password',
    INVALID_CUSTOM_DOMAIN: 'Invalid custom domain. Only letters are allowed',
    PASSWORD_RECOVERY_LINK_SEND_SUCCESS:
        'Password reset link sent to your given email address',
    PASSWRORD_RECOVERY_EXPIRED:
        'Password reset link expired. Please request a new password recovery link',
    END_DATE_MUST_BE_AFTER_START_DATE: 'End date must be after start date',
    INVALID_ADDRESS: 'Please specify street, city, state, and zip code',
    NO_SPECIAL_CHARS: 'Can have only letters, numbers, and spaces',
    INVALID_CODE: 'Code should be 6 characters long',
    EMAIL_OR_PHONE_REQUIRED: 'Email or phone number is required',
    // REGISTRATION_CODE_WAS_SENT_TO_YOUR_EMAIL: 'We sent a verification code to your email.\nPlease enter the code below.',
    // IMAGE_UPLOADER_ERROR_TOO_MANY_FILES: 'You can only upload one file',
    // IMAGE_UPLOADER_ERROR_INCORRECT_FORMAT: 'Incorrect file format',
    // FILE_IS_TOO_LARGE: 'Provided file was too large.\nPlease try an image with < ' + (CONSTANTS.MAX_IMAGE_SIZE_AFTER_COMPRESSION / (1024 * 1024)) + ' MB in size',
    // VIDEO_FILE_IS_TOO_LARGE: 'Provided video file was too large.\nPlease try a video file with < ' + (CONSTANTS.MAX_VIDEO_SIZE / (1024 * 1024)) + ' MB in size',
    // VIDEO_FILE_INCORRECT_FORMAT: 'This video file could not be read by your browser\nTo continue, please choose another video file.',
    // VIDEO_FILE_TOO_LONG: 'Provided video was too long.\nMaximum allowed video duration is 1 minute',
    // FILL_IN_ALL_THE_REQUIRED_FIELDS: 'Please fill in all the required fields',
    // FIELDS_CANT_BE_NEGATIVE: 'Some of the fields contain negative numbers.\nPlease fix the issue and try again',
    // FIELDS_CANT_BE_LESS_OR_EQUAL_ZERO: ' can\'t be less than or equal to zero',
};

export const enum REDIRECT_REASON {
    passwordRecovery = 'passwordRecovery',
    setPassword = 'setPassword',
}
