import React, { useState, useEffect, useContext, useRef } from 'react';
import { ImageUrls, UserRole } from '@temptrack/business';
import {Linking} from 'react-native';
import { trpc } from '../apiCalls/trpcClient';
import { navRef } from '../navigations/RootNavigator';
import { navigatorTypes } from '../navigations/CompanyDashboardNavigator/CompanyDashboardNavigator';
import { AdminViewRoutes, CompanyDashboardRoutes } from '../navigations/types';
import { utils } from '../utils/utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { envs } from '../../env';

export interface ICurrentCompanyContext {
    currentCompanyId: string | undefined,
    setCurrentCompanyId: (companyId: string | undefined) => void,
    currentCompanyLogoUrl: ImageUrls | undefined,
    currentCompanyName: string | undefined,
    setCurrentCompanyLogoUrl: (companyLogoUrl: ImageUrls | undefined) => void,
    setCurrentCompanyName: (companyName: string | undefined) => void,
    currentCustomDomain: string | undefined,
    setCurrentCustomDomain: (subDomain: string | undefined) => void,
    clear: () => void,
    goToCompanyDashboard: (companyId: string, navType: navigatorTypes) => void,
    returnToGlobalDashboard: () => void,
    setUserRole: (role: UserRole | undefined) => void,
}

// export const signedInContextGlobalFunction: {
//     signOut?: (params: ISignOut) => void,
//     getSignedInStatus?: () => SIGNED_IN_STATUS,
// } = {};
export const currentCompanyContextGlobalFunction:  {
    setCurrentCustomDomain?: (subdomain: string) => void,
    currentCustomDomain?: string,
} = {};


const getDomainFromUrl = async () => {
    const path = await Linking.getInitialURL();
    if (!path) {
        return {
            companyId: '',
            domain: '',
        };
    }
    const url = new URL(path);
    let companyId = '';
    if (url.pathname.includes('dashboard')) {
        companyId = url.pathname.split('dashboard/')?.[1]?.split('/')?.[0];
    }
    return {
        companyId: companyId,
        domain: utils.getSubDomainFromUrl(path),
    };
};

const CurrentCompanyContext = React.createContext<ICurrentCompanyContext | undefined>(undefined);

const CurrentCompanyContextProvider = ({ children }: { children?: React.ReactNode }) => {
    const [currentCompanyId, setCurrentCompanyId] = useState<string | undefined>();
    const [currentCompanyLogoUrl, setCurrentCompanyLogoUrl] = useState<ImageUrls | undefined>();
    const [currentCompanyName, setCurrentCompanyName] = useState<string | undefined>();
    const [currentCustomDomain, setCurrentCustomDomain] = useState<string | undefined>();
    currentCompanyContextGlobalFunction.setCurrentCustomDomain = setCurrentCustomDomain;

    const userRole = useRef<UserRole | undefined>();

   const { data: companyData } = trpc.company.getById.useQuery({ companyId: currentCompanyId ?? '' }, {
       enabled: !!currentCompanyId && (
           !!userRole?.current && userRole.current !== UserRole.client && userRole.current !== UserRole.employee),
       retry: 1,
   });

   const {data: basicCompanyData} = trpc.company.getBasicDetailsByDomain.useQuery({domain: currentCustomDomain ?? ''},
       {enabled: currentCustomDomain !== undefined && currentCustomDomain.length > 0 && currentCustomDomain !== envs.TEMPTRACK_SUBDOMAIN,
           retry: 2,
       });

    useEffect(() => {
        if (isMobileApp) {
            return;
        }
        getDomainFromUrl().then((res) => {
            setCurrentCustomDomain(res.domain);
            setCurrentCompanyId(res.companyId);

        });
    }, []);

    useEffect(() => {
        if (companyData) {
            setCurrentCompanyLogoUrl(companyData.companyData.logoUrl);
            setCurrentCompanyName(companyData.companyData.name);
            setCurrentCustomDomain(companyData.companyData.customDomain);
        }
    }, [companyData]);

    useEffect(() => {
        if (basicCompanyData) {
            setCurrentCompanyLogoUrl(basicCompanyData.logoUrl);
            setCurrentCompanyName(basicCompanyData.name);
        }
    }, [basicCompanyData]);


    const clearCurrentCompanyContext = () => {
        setCurrentCompanyId(undefined);
    };

    const goToCompanyDashboard = (companyId: string, navigatorType: navigatorTypes) => {
        if (!companyId || !navRef.current) { return; }
        setCurrentCompanyId(companyId);
        setCurrentCustomDomain(undefined);
        navRef.current.navigate(navigatorType, {screen: CompanyDashboardRoutes.users, params: {companyId: companyId ?? ''}});
    };

    const returnToGlobalDashboard = () => {
        if (!navRef.current) { return; }
        clearCurrentCompanyContext();
        navRef.current.navigate('AdminNavigator', {screen: AdminViewRoutes.companies, params: {companyId: ''}});
    };

    return (
        <CurrentCompanyContext.Provider value={{
            currentCompanyId,
            setCurrentCompanyId,
            currentCompanyLogoUrl,
            setCurrentCompanyLogoUrl,
            currentCompanyName,
            setCurrentCompanyName,
            currentCustomDomain,
            setCurrentCustomDomain,
            clear: clearCurrentCompanyContext,
            goToCompanyDashboard,
            returnToGlobalDashboard,
            setUserRole: (role: UserRole | undefined) => {
                userRole.current = role;
            },
        }}>
            {children}
        </CurrentCompanyContext.Provider>
    );
};

function useCurrentCompanyContext() {
    const context = useContext(CurrentCompanyContext);
    if (context === undefined) {
        throw new Error('useCurrentCompanyContext must be used within a CurrentCompanyContextProvider');
    }

    return context;
}

export { CurrentCompanyContextProvider, useCurrentCompanyContext };
