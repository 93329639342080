import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePagination } from '../../../../hooks/usePagination';
import { trpc } from '../../../../apiCalls/trpcClient';
import { EmployeeJobsProps } from '../../../../typesAndInterfaces/componentProps';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import {
    EmployeeWorkOrderDataForClient,
    EmployeeWorkOrderType,
} from '@temptrack/business';
import {
    GetDataResponseBase,
    ItemsPageData,
} from '../../../../typesAndInterfaces/typesAndInterfaces';

type GetJobsReturnType = GetDataResponseBase & {
    jobsData:
        | (ItemsPageData & { items: EmployeeWorkOrderDataForClient[] })
        | undefined;
    setType: (type: EmployeeWorkOrderType) => void;
    invalidatePage: () => void;
};

const useGetJobs = ({
    navigation,
    route,
}: EmployeeJobsProps): GetJobsReturnType => {
    const trpcContext = trpc.useUtils();
    const { page, pageSize, PaginationComponent, setPage } = usePagination({
        navigation,
        route,
    });
    const [type, setType] = useState(EmployeeWorkOrderType.offer);

    const [nextToken, setNextToken] = useState<string | undefined>(undefined);

    useEffect(() => {
        setNextToken(undefined);
    }, [pageSize, setNextToken]);

    useEffect(() => {
        setNextToken(undefined);
        setPage(1);
    }, [type, setPage, setNextToken]);

    const {
        data: recordData,
        isLoading,
        isPreviousData,
    } = trpc.company.workOrder.employee.getWorkOrdersPage.useQuery(
        {
            type: type,
            page,
            pageSize,
            lastToken: nextToken,
        },
        {
            enabled: !isMobileApp,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        setNextToken(recordData?.nextToken ?? undefined);
    }, [recordData, setNextToken]);

    // Infinite fetch
    const {
        data: infiniteRecordData,
        fetchNextPage,
        hasNextPage,
        isPreviousData: infiniteFetchIsPreviousData,
        isLoading: infiniteFetchIsLoading,
    } = trpc.company.workOrder.employee.listWorkOrders.useInfiniteQuery(
        {
            type: type,
            limit: 10,
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled: isMobileApp,
        },
    );

    const dataToReturn = useMemo(() => {
        if (isMobileApp) {
            const pages = infiniteRecordData?.pages ?? [];
            return {
                items: pages.map((p) => p.items).flat(),
            };
        }
        return recordData;
    }, [recordData, infiniteRecordData]);

    const invalidate = useCallback(() => {
        if (isMobileApp) {
            trpcContext.company.workOrder.employee.listWorkOrders.invalidate({
                type: type,
            });
        } else {
            trpcContext.company.workOrder.employee.getWorkOrdersPage.invalidate(
                {
                    type: type,
                },
            );
        }
    }, [
        trpcContext.company.workOrder.employee.listWorkOrders,
        trpcContext.company.workOrder.employee.getWorkOrdersPage,
        type,
    ]);

    return {
        PaginationComponent: isMobileApp ? undefined : PaginationComponent,
        jobsData: dataToReturn,
        isLoading: isMobileApp ? infiniteFetchIsLoading : isLoading,
        isPreviousData: isMobileApp
            ? infiniteFetchIsPreviousData
            : isPreviousData,
        fetchNextPage: isMobileApp ? fetchNextPage : undefined,
        hasNextPage: isMobileApp ? hasNextPage : undefined,
        setType: setType,
        invalidatePage: invalidate,
    };
};

export { useGetJobs };
