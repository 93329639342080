import * as Font from 'expo-font';
import {
    Muli_200ExtraLight,
    Muli_300Light,
    Muli_400Regular,
    Muli_500Medium,
    Muli_600SemiBold,
    Muli_700Bold,
    Muli_800ExtraBold,
    Muli_200ExtraLight_Italic,
    Muli_300Light_Italic,
    Muli_400Regular_Italic,
    Muli_500Medium_Italic,
    Muli_600SemiBold_Italic,
    Muli_700Bold_Italic,
    Muli_800ExtraBold_Italic,
    Muli_900Black,
    Muli_900Black_Italic,
} from '@expo-google-fonts/muli';

import {
    Inter_100Thin,
    Inter_200ExtraLight,
    Inter_300Light,
    Inter_400Regular,
    Inter_500Medium,
    Inter_600SemiBold,
    Inter_700Bold,
    Inter_800ExtraBold,
    Inter_900Black,
} from '@expo-google-fonts/inter';

export const fontLoader = async (): Promise<any> => {
    const expoFontPromise = Font.loadAsync({
        AntDesign: require('../../node_modules/react-native-vector-icons/Fonts/AntDesign.ttf'),
        Feather: require('../../node_modules/react-native-vector-icons/Fonts/Feather.ttf'),
        FontAwesome: require('../../node_modules/react-native-vector-icons/Fonts/FontAwesome.ttf'),
        MaterialCommunityIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'),
        MaterialIcons: require('../../node_modules/react-native-vector-icons/Fonts/MaterialIcons.ttf'),
        Entypo: require('../../node_modules/react-native-vector-icons/Fonts/Entypo.ttf'),
        Fontisto: require('../../node_modules/react-native-vector-icons/Fonts/Fontisto.ttf'),
        Muli_200: Muli_200ExtraLight,
        Muli_200_italic: Muli_200ExtraLight_Italic,
        Muli_300: Muli_300Light,
        Muli_300_italic: Muli_300Light_Italic,
        Muli_400: Muli_400Regular,
        Muli_400_italic: Muli_400Regular_Italic,
        Muli_500: Muli_500Medium,
        Muli_500_italic: Muli_500Medium_Italic,
        Muli_600: Muli_600SemiBold,
        Muli_600_italic: Muli_600SemiBold_Italic,
        Muli_700: Muli_700Bold,
        Muli_700_italic: Muli_700Bold_Italic,
        Muli_800: Muli_800ExtraBold,
        Muli_800_italic: Muli_800ExtraBold_Italic,
        Muli_900: Muli_900Black,
        Muli_900_italic: Muli_900Black_Italic,
        Inter_100: Inter_100Thin,
        Inter_200: Inter_200ExtraLight,
        Inter_300: Inter_300Light,
        Inter_400: Inter_400Regular,
        Inter_500: Inter_500Medium,
        Inter_600: Inter_600SemiBold,
        Inter_700: Inter_700Bold,
        Inter_800: Inter_800ExtraBold,
        Inter_900: Inter_900Black,
    });

    return Promise.all([expoFontPromise]);
};
