import { useEffect, useRef, useState } from 'react';
import { Dimensions, Linking, useWindowDimensions } from 'react-native';
import {pageHeaderHeight} from '../components/helperComponents/Header/Header';
import {copyRightFooterHeight} from '../components/helperComponents/CopyrightFooter';
import { listViewHeaderHeight } from '../constants/elementSizes';

const utilHooks = {
    useWidthChange: (isActive: boolean, callBack: () => void) => {
        const dims = useWindowDimensions();
        const windowWidth = useRef(dims.width);

        // We need to set the width when window gets activated, otherwise if you change the window width and
        // then mount this, callback will get called.
        useEffect(() => {
            if (isActive) {
                windowWidth.current = Dimensions.get('window').width;
            }
        }, [isActive]);

        useEffect(() => {
            if (!isActive) {
                return;
            }

            if (dims.width !== windowWidth.current) {
                callBack();
                windowWidth.current = dims.width;
            }
        }, [callBack, dims.width, isActive]);
    },

    useTableHeight: () => {
        const dims = useWindowDimensions();
        const [screenHeight, setScreenHeight] = useState(dims.height);

        useEffect(() => {
            const onLayout = () => {
                const {height} = Dimensions.get('window');
                setScreenHeight(height);
            };

            Dimensions.addEventListener('change', onLayout);

            return () => {
                Dimensions.removeEventListener('change', onLayout);
            };
        }, []);

        return screenHeight - pageHeaderHeight - copyRightFooterHeight - listViewHeaderHeight - 90;
    },

    useInitialURL: () => {

        const [url, setUrl] = useState<string | null>(null);
        const [processing, setProcessing] = useState(true);

        useEffect(() => {
            const getUrlAsync = async () => {
                // Get the deep link used to open the app
               return  await Linking.getInitialURL();
            };

            getUrlAsync().then((res)=> {
                setUrl(res);
                setProcessing(false);

            });
        },[]);

        return {url, processing};
    },
    useMaxModalHeight: () => {
        const dims = useWindowDimensions();
        const [screenHeight, setScreenHeight] = useState(dims.height);

        useEffect(() => {
            const onLayout = () => {
                const {height} = Dimensions.get('window');
                setScreenHeight(height);
            };

            Dimensions.addEventListener('change', onLayout);

            return () => {
                Dimensions.removeEventListener('change', onLayout);
            };
        }, []);

        return screenHeight * 0.9;
    },
};

export { utilHooks };
