import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { appColors, applyTTStyles } from '../../../../constants/colors';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import { ValueRow} from '../../../helperComponents/misc/ValueRow';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import {  isMobileApp } from '@mightybyte/rnw.utils.device-info';
import {utils} from '../../../../utils/utils';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import {
    CreateShortlistInput,
    EmployeeAcceptanceStatus, EmployeePosition,
    toWorkOrderPositionLabel, toWorkOrderPositionTypeLabel,
    WorkOrderForDropdown, WorkOrderPositionType,
} from '@temptrack/business';
import { useWorkOrders } from '../../../../hooks/useWorkOrders';
import { MB_TextInputToolTipPortal, MB_TextInputToolTipPortalRef } from '@mightybyte/rnw.components.text-input';
import { WrappedDatePicker } from '../../../helperComponents/misc/WrappedDatePicker';

const CreateShortlistForm = (
    {
        action,
        submitInProgress,
        submitErrorMessage,
        resetSubmitError,
        cancelAction,
    }: {
        action: (data: CreateShortlistInput)=>void,
        submitInProgress: boolean,
        submitErrorMessage: string | undefined,
        resetSubmitError: (err: string)=>void,
        closeAction: ()=>void,
        cancelAction: ()=>void,
    }) => {

    const {currentCompanyId} = useCurrentCompanyContext();
    const [selectedWorkOrder, setSelectedWorkOrder] = useState<WorkOrderForDropdown | undefined>();
    const [checklistDate, setChecklistDate] = useState<string | undefined>(undefined);

    useEffect(() => {
        setChecklistDate(undefined);
    }, [selectedWorkOrder]);

    const {dropdownOptions: workOrderDropdownOptions, fullOptions} = useWorkOrders({disabled: !currentCompanyId});

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const minDateForDatePicker = useMemo(()=> {
        const startDate = selectedWorkOrder?.startDate;
        if (!startDate) {
            return undefined;
        }
        const dateISOString = utils.getIsoDateStringFromObject(startDate);
        if (utils.isBeforeToday(dateISOString)) {
            return new Date().toISOString();
        } else {
            return dateISOString;
        }

    } ,[selectedWorkOrder]);

    const initialMonthForDatePicker = useMemo(()=> {
        const startDate = selectedWorkOrder?.startDate;
        if (!startDate) {
            return undefined;
        }
        if (utils.isBeforeToday(utils.getIsoDateStringFromObject(startDate))) {
            const today = new Date();
            let month = today.getMonth() + 1;
            if (month < 10) {
                return `${today.getFullYear()}-0${month}-01`;
            }
            return `${today.getFullYear()}-${month}-01`;
        } else {
            let month = startDate.month;
            if (month < 10) {
                return `${startDate.year}-0${month}-01`;
            }
            return `${startDate.year}-${month}-01`;
        }
    }, [selectedWorkOrder]);

    const [addedEmployees, setAddedEmployees] = useState<string[]>([]);

    const {data: recordData} = trpc.company.shortlist.getAvailableEmployees.useQuery({
        companyId: currentCompanyId ?? '',
        workOrderId: selectedWorkOrder?.id ?? '',
    }, {
        enabled: !!currentCompanyId && !!selectedWorkOrder?.id,
    });

    const items = useMemo(()=> {
        if (!recordData) {
            return [];
        }
        return recordData.employees.map((employee) => {
            return {label: `${employee.firstName} ${employee.lastName}`, value: employee.id};
        });
    }, [recordData]);


    const onSelectChange = useCallback((newValue:string[] | undefined)=> {
        if (!newValue) { return; }

        setAddedEmployees(newValue ? newValue.map((v)=> v.toString()) : []);
    },[]);

    const SubmitAction = useCallback(()=> {
        if (!currentCompanyId) {
            return;
        }
        if (!selectedWorkOrder) {
            resetSubmitError('Please select a work order');
            return;
        }

        if (!checklistDate) {
            console.log('no date');
            resetSubmitError('Please select a shortlist date');
            return;
        }


        const employeesToAdd = addedEmployees.map((e)=> ({employeeId: e, acceptanceStatus: EmployeeAcceptanceStatus.pending}));
        action({
            companyId: currentCompanyId,
            workOrderId: selectedWorkOrder?.id ?? '',
            assignedTo: employeesToAdd,
            date: {
                year: new Date(checklistDate).getFullYear(),
                month: new Date(checklistDate).getMonth() + 1,
                day: new Date(checklistDate).getDate(),
            },
        });
    }, [addedEmployees, currentCompanyId, checklistDate,action, selectedWorkOrder, resetSubmitError]);
    console.log('submitErrorMessage', submitErrorMessage);

    return (
        <View style={{
            height: '100%',
            justifyContent: 'space-between',
        }}>
            <View style={{zIndex: 4000, flexDirection: isMobileApp ? 'column' : 'row'}}>
                <View style={[{zIndex: 4000, marginBottom: 0, flex: 1, marginRight: isMobileApp ? undefined : 20}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={workOrderDropdownOptions}
                                           value={selectedWorkOrder?.id}
                                           onValueChange={(newValue:string | undefined)=> {
                                               resetSubmitError('');
                                               setSelectedWorkOrder(fullOptions.find((wo)=> wo.id === newValue));
                                           }}
                                           placeholder={'Select an existing work order'}
                                           title={'Select work order'}
                                           // containerStyle={{width: '100%'}}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           multiple={false}
                                           listMode={'SCROLLVIEW'}


                        />
                    }
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 4000, width: '100%'}}}
                    />
                    <WrappedInput inputComponent={
                        <WrappedDatePicker title={'Checklist date'}
                                           showInitialEmpty={true}
                                           minDate={minDateForDatePicker}
                                           maxDate={selectedWorkOrder?.endDate ? utils.getIsoDateStringFromObject(selectedWorkOrder?.endDate) : null}
                                           initialMonth={initialMonthForDatePicker}
                                           value={checklistDate}
                                           showRequiredAsterisk={true}
                                           disabled={selectedWorkOrder === undefined}
                                           onChange={(newValue)=> {
                                               resetSubmitError('');
                                               setChecklistDate(newValue?.toISOString() ?? '');

                                           }}/>}
                                  subText={selectedWorkOrder ? undefined : 'Please select work order'}
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 3500, width: '100%'}}}
                    />

                    {
                        selectedWorkOrder ? <View style={[{zIndex: 3000, marginBottom: 10}]}>
                            <ValueRow label={'Client'}
                                      value={selectedWorkOrder?.clientName}
                            />
                            <ValueRow label={'Position'}
                                      value={toWorkOrderPositionLabel(selectedWorkOrder?.position as EmployeePosition)}
                                // customLabelStyle={{...initialStyles.textInputTitle}}
                            />
                            <ValueRow label={'Position type'}
                                      value={toWorkOrderPositionTypeLabel(selectedWorkOrder?.positionType as WorkOrderPositionType)}
                            />
                            <ValueRow label={'Duration'}
                                      value={`${utils.formatDateObjectToDisplay(selectedWorkOrder.startDate)} - ${utils.formatDateObjectToDisplay(selectedWorkOrder.endDate)}`}
                            />
                            <ValueRow label={'Qty of employees needed'}
                                      value={selectedWorkOrder.employeesNeededQty.toString()}
                                // customLabelStyle={{...initialStyles.textInputTitle}}
                            />
                        </View> : null
                    }
                </View>


                <View style={[{zIndex: 3000, flex: 1}]}>
                    <WrappedInput errorMessage={''}
                        // containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 4000}}}
                                  inputComponent={<View style={{width: '100%'}}>
                                      <MB_DropDownPicker items={items}
                                                         multiple={true}
                                                         value={addedEmployees}
                                                         onValueChange={onSelectChange}
                                                         listMode={'SCROLLVIEW'}
                                                         title={'Assign Employee(s)'}
                                                         containerStyle={{width: '100%'}}
                                                         dropdownContainerStyle={{
                                                             maxHeight: isMobileApp ? 235 : 300,
                                                         }}
                                                         placeholder={'Select employee(s)'}
                                                         dropDownDirection={'BOTTOM'}
                                                         extendableBadgeContainer={true}
                                                         selectedOptionColor={appColors.green600}
                                      />
                                  </View>}
                    />
                </View>
            </View>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />

            <View style={[recordModalContentStyles.errorContainer]}>
                <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage ?? ''}</Text>
            </View>

            <View style={{zIndex: 1, marginTop: 24}}>
                <View style={[recordModalContentStyles.row]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Save shortlist'}
                                   onPress={SubmitAction}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   loading={submitInProgress}

                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        action={cancelAction}
                                        disabled={submitInProgress}
                        />
                    </View>

                </View>
            </View>

        </View>
    );
};

export {CreateShortlistForm };

