import React from 'react';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../constants/textStyles';
import { StyleSheet, Text, View} from 'react-native';
import { appColors, getColors } from '../constants/colors';
import { MB_Pressable } from '@mightybyte/rnw.components.pressable';
import Entypo from 'react-native-vector-icons/Entypo';
import { Spacer } from '../components/helperComponents/misc/Spacer';
import { useDrawerContext } from '../context/DrawerContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

type Tab = {
    name: string,
    routeName: string,
    onPress: () =>void
    subTabs?: Tab[] | undefined,
    isExpanded?: boolean,
    isActive?: boolean,
    icon?: JSX.Element | undefined | null,
}
const CustomDrawerUI = ({
    header,
    regionName,
    branchName,
    tabs,
    versions,
}: {
    header?: string,
    branchName?: string | undefined,
    regionName?: string | undefined,
    tabs: Tab[],
    versions: {
        jsVersion?: string,
        appVersion?: string,
        buildVersion?: string,
        serverVersion?: string,
    }
})=> {
    const drawer = useDrawerContext();

    return (
        <View style={drawerStyles.drawerContainerStyle}>
            <View style={{
                marginTop: 50,
            }}>
                {header ? <View style={drawerStyles.drawerHeaderWrapper}>
                        {drawer.isOpen ? <>
                            <Text style={drawerStyles.drawerHeader} numberOfLines={2}>{header}</Text>
                            <Spacer y={8}/>
                            {branchName ? <Text style={drawerStyles.drawerRegionAndBranch} numberOfLines={1}>{'Branch:'}
                                <Text style={[drawerStyles.drawerHeader, {marginLeft: 8}]}>{`${branchName}`}</Text>
                            </Text> : null}
                            {regionName ? <Text style={drawerStyles.drawerRegionAndBranch} numberOfLines={1}>{'Region:'}
                                <Text style={[drawerStyles.drawerHeader, {marginLeft: 8}]}>{`${regionName}`}</Text></Text> : null}
                        </> : null}

                </View> : null}

                {tabs.map((tab, index)=> {

                    if (tab.subTabs) {
                        return (<View key={`${tab.routeName}-${index}`}>
                                <MB_Pressable onPress={tab.onPress} style={[drawerStyles.customDrawerContainerStyle, drawerStyles.customDrawerContainerOpen,
                                    tab.isActive ? drawerStyles.customDrawerContainerActiveStyle : {}]} >
                                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                        {tab?.icon ? tab.icon : null}
                                        <Text style={[drawerStyles.drawerLabelStyle,
                                            tab.isActive ? drawerStyles.customDrawerContainerActiveStyle : {},
                                        ]}>{tab.name}</Text>
                                    </View>
                                    {tab.isExpanded ? <Entypo name={'chevron-up'} size={20} color={COLORS.drawerActiveIcon} style={{marginLeft: 'auto', marginRight: 16}}/> : <Entypo name={'chevron-down'} size={20} color={COLORS.drawerActiveIcon} style={{marginLeft: 'auto', marginRight: 16}}/>}


                                </MB_Pressable>
                                {tab.isExpanded ? tab.subTabs.map((subTab, subIndex)=> {
                                    return (
                                        <MB_Pressable key={`${subTab.routeName}-${subIndex}`}
                                                      onPress={subTab.onPress} style={[drawerStyles.subDrawerContainerStyle,
                                                      drawerStyles.openedDrawerSubStyle,
                                                      subTab.isActive ? drawerStyles.customDrawerContainerActiveStyle : {}]} >
                                            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                                                {subTab?.icon ? subTab.icon : null}
                                                <Text style={[subTab.isActive ? drawerStyles.activeDrawerLabelStyle : drawerStyles.drawerLabelStyle]}>{subTab.name}</Text>
                                            </View>
                                            </MB_Pressable>
                                    );
                                }) : null}
                            </View>
                        );
                    }
                    return (
                        <MB_Pressable key={`${tab.routeName}-${index}`}
                                      onPress={tab.onPress} style={[drawerStyles.customDrawerContainerStyle,
                            drawerStyles.customDrawerContainerOpen,
                            tab.isActive ? drawerStyles.customDrawerContainerActiveStyle : {}]} >
                            {tab?.icon ? tab.icon : null}
                             <Text style={[tab.isActive ? drawerStyles.activeDrawerLabelStyle : drawerStyles.drawerLabelStyle]}>{tab.name}</Text>
                        </MB_Pressable>
                    );
                })}
            </View>
            {isMobileApp ? <View style={drawerStyles.versionContainer}>
                <Text style={drawerStyles.versionInfoText}>Server Version: {versions.serverVersion}</Text>
                <Text style={drawerStyles.versionInfoText}>JS Version: {versions.jsVersion}</Text>
                <Text style={drawerStyles.versionInfoText}>App Version: {versions.appVersion}</Text>
                <Text style={drawerStyles.versionInfoText}>Build Number: {versions.buildVersion}</Text>
            </View> : null}
        </View>
    );
};

const COLORS = getColors();

const drawerStyles = StyleSheet.create({
    drawerLabelStyle: mbTextStyles([
        textStyles.Muli_16_600, {
            textAlign: 'left',
            padding: 8,
        },
    ]),

    activeDrawerLabelStyle: mbTextStyles([
        textStyles.Muli_16_800, {
            textAlign: 'left',
            padding: 8,
            color: COLORS.drawerActiveIcon,
        },
    ]),
    openedDrawerSubStyle: {
        marginLeft: 40,
        paddingLeft: 16,
    },

    drawerItemStyle: {
        borderRadius: 7,
        marginHorizontal: 0,
    },
    drawerContainerStyle: {
        backgroundColor: COLORS.backgroundPrimary,
        paddingTop: 6,
        paddingHorizontal: 16,
        position: 'relative',
        flex: 1,
    },
    customDrawerContainerActiveStyle: {
        backgroundColor: COLORS.drawerActiveTabBackground,
        borderRadius: 7,
        marginHorizontal: 0,
    },
    customDrawerContainerStyle: {
        height: 48,
        marginVertical: 4,
        flexDirection: 'row',
        alignItems: 'center',
    },
    customDrawerContainerOpen: {
        justifyContent: 'flex-start',
        paddingLeft: 16,
    },
    subDrawerContainerStyle: {
        height: 40,
        justifyContent: 'center',
        marginVertical: 4,

    },
    drawerContentContainer: {
        flex: 1,
    },
    drawerHeaderWrapper: {
        marginTop: 5,
        paddingLeft: 8,
        marginBottom: 24,
        minHeight: 30,
    },
    drawerHeader: mbTextStyles([
        textStyles.Muli_16_700, {
            color: appColors.gray900,
            textAlign: 'left',

        },
    ]),
    drawerRegionAndBranch: mbTextStyles([
        textStyles.Muli_16_400, {
            color: COLORS.textSecondary,
            textAlign: 'left',
        },
    ]),
    versionContainer: {
        // position: 'absolute',
        // bottom: 0,
        padding: 16,
    },
    versionInfoText: mbTextStyles([
        textStyles.Muli_16_400, {
            color: COLORS.textSecondary,
            textAlign: 'left',
            fontSize: 12,
        },
    ]),

    iconButtonStyle: {
        borderRadius: 60,
        width: 50,
        height: 50,
        alignItems: 'center',
        justifyContent: 'center',
    },

});

export { drawerStyles, CustomDrawerUI };
