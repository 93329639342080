import { UserRole, UserStatus } from '.';

export function toUserRoleLabel(userRole: UserRole): string {
    return {
        [UserRole.admin]: 'Super Admin',
        [UserRole.companyAdmin]: 'Company Admin',
        [UserRole.regionAdmin]: 'Region Admin',
        [UserRole.recruiter]: 'Recruiter',
        [UserRole.client]: 'Client',
        [UserRole.employee]: 'Employee',
    }[userRole];
}

export function toUserStatusLabel(userStatus: UserStatus): string {
    return {
        [UserStatus.active]: 'Active',
        [UserStatus.inactive]: 'Inactive',
    }[userStatus];
}
