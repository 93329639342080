import React, { useMemo, useState } from 'react';
import {View, StyleSheet, ViewStyle, StyleProp} from 'react-native';

import {RadioButton} from '../components/helperComponents/RadioButton';

const useBooleanRadioGroup = ({

                                 initialValue,
                                 disabled,
    viewOnly,
                                 containerStyle,

                             }: {
    initialValue: boolean
    disabled?: boolean;
    viewOnly?: boolean;
    containerStyle?: StyleProp<ViewStyle>
}) => {

    const containerStyleRef = React.useRef(containerStyle);
    const [value, setValue] = useState(initialValue);


    const Component = useMemo(() => {
        return (
            <View style={[styles.container, containerStyleRef.current]}>
                <RadioButton
                    value={true}
                    groupValue={value}
                    setGroupValue={()=>setValue(true)}
                    radioText={'Yes'}
                    disabled={disabled}
                    viewOnly={viewOnly}
                    containerStyle={styles.radioButtonContainer}
                />
                <RadioButton
                    value={false}
                    groupValue={value}
                    setGroupValue={()=>setValue(false)}
                    radioText={'No'}
                    disabled={disabled}
                    viewOnly={viewOnly}
                    containerStyle={styles.radioButtonContainer}
                />
            </View>
        );
    }, [value, disabled, viewOnly]);

    return {
        value,
        RadioButtonGroup: Component,
        setValue,

    };
};

export {useBooleanRadioGroup};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    radioButtonContainer: {
        marginEnd: 16,
    },
});
