import { FilterFieldType, filterPhoneSchema, filterTextSchema } from '..';
import { createFilterMapping } from './helpers';

export const CompanyFilterMap = createFilterMapping({
    name: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'name',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
});
