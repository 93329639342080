import { z } from 'zod';
import { CompanyUserRoles, UserRole, UserStatus } from '../../..';
import { CompanyUserFilterMap } from '../../../filter';
import {
    EmailSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    PhoneNumberSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';

const BaseCreateUserInputSchema = z.object({
    companyId: ObjectIdSchema,
    firstName: StringSchema,
    lastName: StringSchema,
    email: EmailSchema,
    phoneNumber: PhoneNumberSchema,
    status: z.nativeEnum(UserStatus),
    role: z.enum(CompanyUserRoles),
    regionId: ObjectIdSchema.optional(),
    branchId: ObjectIdSchema.optional(),
});

export const CreateUserInputSchema = BaseCreateUserInputSchema.refine(
    (input) => {
        const rolesWithOptionalRegionId = [UserRole.companyAdmin];
        return (
            rolesWithOptionalRegionId.includes(input.role) || !!input.regionId
        );
    },
    {
        message: 'A region ID is required for this role.',
        path: ['regionId'],
    },
).refine(
    (input) => {
        const rolesWithOptionalBranchId = [
            UserRole.companyAdmin,
            UserRole.regionAdmin,
        ];
        return (
            rolesWithOptionalBranchId.includes(input.role) || !!input.branchId
        );
    },
    {
        message: 'A branch ID is required for this role.',
        path: ['branchId'],
    },
);

export const GetUsersPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(CompanyUserFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const ListUsersInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(CompanyUserFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const GetUserByIdInputSchema = z.object({
    userId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const EditUserInputSchema = BaseCreateUserInputSchema.partial().extend({
    userId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const ChangeUserPasswordInputSchema = z.object({
    /**
     * The user to change the password for.
     */
    userId: ObjectIdSchema,

    companyId: ObjectIdSchema,

    newPassword: StringSchema,
});

export const ResendInviteInputSchema = z.object({
    /**
     * The user to resend the invite to.
     */
    userId: ObjectIdSchema,

    companyId: ObjectIdSchema,
});

export const DeleteUserInputSchema = z.object({
    userId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetUserTimelineInputSchema = z.object({
    userId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
