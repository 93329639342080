import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import {
    ChecklistDataForClient,
    EmployeePosition, ShortlistDataForClient,
    toWorkOrderPositionLabel,
} from '@temptrack/business';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { AssignmentStatusRenderer } from '../../../helperComponents/misc/badges/AssignmentStatusRenderer';
import { CheckinsStatusBadge } from '../../../helperComponents/misc/badges/CheckinsStatusBadge';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { ChecklistTableProps } from './types';

const COLORS = getColors();

const header = [
    {content: 'checklist#', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'client', conatinerStyle: { minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'activity status', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'assignment status', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content:  'check-ins status', conatinerStyle: { minWidth: 220, maxWidth: 220, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'position', conatinerStyle: { minWidth: 170, maxWidth: 170, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'Checklist Date', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1}, textStyle: tableStyles.headerTextStyle },
];

const ChecklistsTable = ({data,
                             isLoading,
                             openRowMenu,
                             onAddPress,
                             addButtonText,
                             hideAddButton,
                             hasFilters,
}: ChecklistTableProps) => {
    const tableHeight = utilHooks.useTableHeight();
    const {isClient} = useSignedInContext();

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const NumberColumnRenderer = useCallback(({item}:{item: ChecklistDataForClient | ShortlistDataForClient}) => {
        return (<View>
                <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.number}</Text>
                <Text style={[tableStyles.subText]} numberOfLines={1}>{`Work order#: ${item?.workOrder.number}`}</Text>
            </View>
        );
    }, []);

    const headerItems = useMemo(()=> {
        if (isClient){
            return header.filter((item)=> item.content !== 'client');
        }
       return header;
    },[isClient]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const columns = {
                number:  <NumberColumnRenderer item={item}/>,
                client: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.clientName}</Text>,
                activityStatus: <StatusRenderer status={item?.activityStatus} recordType={RecordTypes.checklist}/>,
                assignmentStatus: <AssignmentStatusRenderer assignmentStatus={item?.assignmentStatus}/>,
                checkIns: <CheckinsStatusBadge todayCheckins={item?.checkIns} checklistDate={utils.displayDateObj(item?.date)} noMargin/>,
                position: <Text style={tableStyles.tableRowText} numberOfLines={2}>{toWorkOrderPositionLabel(item.position as EmployeePosition)}</Text>,
                checklistDate: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.displayDateObj(item?.date)}</Text>,
            };

            const actions = <View style={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                position: 'relative',
            }}>
                <IconButton icon={
                    <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                }
                            action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                />
            </View>;
            if (isClient){
                return [columns.number,
                    columns.activityStatus,
                    columns.assignmentStatus,
                    columns.checkIns,
                    columns.position,
                    columns.checklistDate,
                    actions];
            } else {
                return [columns.number,
                    columns.client,
                    columns.activityStatus,
                    columns.assignmentStatus,
                    columns.checkIns,
                    columns.position,
                    columns.checklistDate,
                    actions];
            }

        });
    }, [data, openRowMenu, NumberColumnRenderer, isClient]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={headerItems}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No checklists yet'}
                                                          subtitle={'Start adding checklists to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          hideAddButton={hideAddButton}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export {  ChecklistsTable  };
