import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { UpdateAppButton } from './UpdateAppButton';

const UpdateApp = ({ version }: { version: string }) => {

    return (
        <View style={styles.container}>
            <Text style={styles.textLarge}>New Version  available</Text>
            <Text style={styles.textSmall}>V{version}</Text>
            <Text style={styles.textSmall}>The current version of this application is no longer supported.</Text>
            <UpdateAppButton title="Update Now" />
        </View>
    );
};

export { UpdateApp };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: 30,
    },
    textLarge: mbTextStyles([
        textStyles.Muli_16_500, {
            color: COLORS.textSecondary,
        },
    ]),
    textSmall: mbTextStyles([
        textStyles.Muli_16_400,{
            fontSize: 14,
            color: COLORS.textSecondary,
        },
    ]),
});
