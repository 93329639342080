import { z } from 'zod';
import {
    AddressSchema,
    EmailSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    PhoneNumberSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';
import {
    EmployeePosition,
    EmployeePositionType,
    EmployeeStatus,
} from '../../..';
import { EmployeeFilterMap } from '../../../filter';

export const CreateEmployeeInputSchema = z.object({
    email: EmailSchema.optional(),
    firstName: StringSchema,
    lastName: StringSchema,
    phoneNumber: PhoneNumberSchema,
    address: AddressSchema,
    status: z.nativeEnum(EmployeeStatus),
    branchId: ObjectIdSchema,
    companyId: ObjectIdSchema,
    position: z.nativeEnum(EmployeePosition),
    positionType: z.nativeEnum(EmployeePositionType),
    tempWorksId: StringSchema.optional(),
});

export const GetEmployeeByIdInputSchema = z.object({
    employeeId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetEmployeesPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(EmployeeFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const ListEmployeesInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(EmployeeFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const EditEmployeeInputSchema =
    CreateEmployeeInputSchema.partial().extend({
        employeeId: ObjectIdSchema,
        companyId: ObjectIdSchema,
    });

export const DeleteEmployeeInputSchema = z.object({
    employeeId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetEmployeeTimelineInputSchema = z.object({
    employeeId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
