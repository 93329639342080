import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import {Path, Svg} from 'react-native-svg';

const PersonIconSvg = React.memo((props: { size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <View style={props.containerStyle}>
            <Svg
                width={props.size ?? 12}
                height={props.size ? props.size * (13 / 12) : 13}
                viewBox="0 0 12 13"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M5.998 5.7a2.4 2.4 0 100-4.8 2.4 2.4 0 000 4.8zm-5.6 7.2a5.6 5.6 0 1111.2 0H.398z"
                    fill={props.color ?? '#9CA3AF'}
                />
            </Svg>
        </View>
    );
});

export { PersonIconSvg};
