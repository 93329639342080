import { z } from 'zod';
import { CompanyDomainSchema, ObjectIdSchema } from '../common';

export const GetBasicCompanyByDomainInputSchema = z.object({
    domain: CompanyDomainSchema,
});

export const GetCompanyByIdInputSchema = z.object({
    companyId: ObjectIdSchema,
});
