import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { GestureResponderEvent, Text, View } from 'react-native';
import { trpc } from '../../../../apiCalls/trpcClient';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { CompanyUserForm } from './CompanyUserContent';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { UIDrawerRightContentWrapper } from '../../../helperComponents/DrawerRightContentWrapper';
import { UsersTable } from './TableView';
import { CompanyUsersProps } from '../../../../typesAndInterfaces/componentProps';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { UserDataForClient, UserRole, UserStatus } from '@temptrack/business';
import { ChangePasswordContent } from './ChangePasswordContent';
import { routeParams } from '../../../../constants/urlConstants';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { utils } from '../../../../utils/utils';
import { useGetUsers } from './useGetUsers';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { toUserRoleLabel, FilterFieldType } from '@temptrack/business';
import { shouldShowBranchForRole, shouldShowRegionForRole } from '../../../../utils/permissionUtils';
import { NotFilterFields } from '../../../../hooks/filters/types';

const COLORS = getColors();

const Users = ({navigation, route}: CompanyUsersProps) => {
    const rolesThatCanManageUsers = useRef([
        UserRole.admin,
        UserRole.companyAdmin,
    ]);

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {currentUserData} = useSignedInContext();

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.userId);
    const [showChangePasswordModalId, setShowChangePasswordModalId] = useState<string | undefined>(undefined);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });


   const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

  const toggleShowTimeline = useCallback(()=> {
    setShowTimeline((prev)=>!prev);
  }, [setShowTimeline]);

  useEffect(() => {
      if (!showTimeline && route.params?.timeline) {
          navigation.setParams({timeline: undefined});
      }
  }, [showTimeline, navigation, route.params?.timeline]);

    const [currentRowData, setCurrentRowData] = useState<UserDataForClient | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const {usersData, PaginationComponent,
              isPreviousData, isLoading,  fetchNextPage, hasNextPage, setFilters, setExtraParams} = useGetUsers({navigation, route});

    const applyFiltersCallback = useCallback(({ filters, otherParams}: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
        if (otherParams) {
            setExtraParams?.(otherParams);
        }
    }, [setFilters, setExtraParams]);

    const shouldShowRegion = useMemo(()=> {
        return shouldShowRegionForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const shouldShowBranch = useMemo(()=> {
        return shouldShowBranchForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'First Name',
                apiName: 'firstName',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Last Name',
                apiName: 'lastName',
                filterType:  FilterFieldType.text,
            },
            {   label: 'Region',
                apiName: 'regionId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowRegion,
            },
            {   label: 'Branch',
                apiName: 'branchId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowBranch,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
            {
                label: 'Role',
                apiName: 'role',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [UserRole.recruiter, UserRole.companyAdmin, UserRole.regionAdmin].map((role)=> {
                    return {label: toUserRoleLabel(role), value: role};

                }),
            },
            {
                label: 'Status',
                apiName: 'status',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [
                    {label: 'Active', value: UserStatus.active},
                    {label: 'Inactive', value: UserStatus.inactive},
                ],
            },
            {
                label: 'Invite Pending',
                apiName: NotFilterFields.isInvitePending,
                filterType:  FilterFieldType.checkbox,
            },
        ];
    }, [shouldShowBranch, shouldShowRegion]);


    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    useEffect(() => {
        if (showMenuForRowId) {
            setCurrentRowData(usersData?.items.find((item)=>item.id === showMenuForRowId));
        } else {
            setCurrentRowData(undefined);
        }

    }, [showMenuForRowId, usersData?.items, setCurrentRowData]);

    const onUserAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.users, {...route.params, ...{userId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
        } , [navigation, currentCompanyId, route.params]);

    const {mutate: deleteUser, isLoading: isLoadingUserDelete} = trpc.company.user.delete.useMutation({
        onSuccess: ()=> {
            refreshList();
            hideDeleteUser();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting User',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteUser, show: showDeleteUser, component:DeleteUser, setDeleteError} = useDeleteRecord({
        recordType: 'User',
        deleteAction: (id)=>{
            if (!currentCompanyId || currentCompanyId === '') { return; }
            deleteUser({userId: id, companyId: currentCompanyId ?? ''},{
            onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: isLoadingUserDelete,
    });

    const addButtonText = 'Add new user';

    const closeModal = useCallback(()=> {
        navigation.navigate(CompanyDashboardRoutes.users, {...route.params, ...{userId: '', companyId: currentCompanyId ?? '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, currentCompanyId, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            const newParams = {...route.params, ...{userId: d, timeline: timeline}};
            navigation.navigate(CompanyDashboardRoutes.users, newParams);
            setShowRecordModal(true);
        } else {
           closeModal();
        }

    }, [navigation, closeModal, route.params]);

    const modalUserId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.userId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.userId, showRecordModal]);

    const {mutate: createUser, isLoading: isLoadingUserCreate} = trpc.company.user.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Creating User',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editUser, isLoading: isLoadingUserEdit } = trpc.company.user.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.userData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing user',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: resendInvite} = trpc.company.user.resendInvite.useMutation({
        onSuccess: ()=> {
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Resending Invite',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: changePassword, isLoading: isLoadingChangePassword} = trpc.company.user.changeUserPassword.useMutation({
        onSuccess: ()=> {
            setShowChangePasswordModalId(undefined);
            setTimeout(()=>{
                mbShowToast({ text1: 'Success changing password',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const refreshList = useCallback((id?:string)=> {
        if (id) {
            trpcContext.company.user.getById.invalidate({userId: id, companyId: currentCompanyId ?? ''});
        }
        if (isMobileApp) {
            trpcContext.company.user.list.invalidate();
        } else {
            trpcContext.company.user.getPage.invalidate();
        }

    }, [trpcContext.company.user.getPage, trpcContext.company.user.getById, currentCompanyId, trpcContext.company.user.list]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const editNotAllowed = useMemo(()=> {
        return !currentUserData?.role || !rolesThatCanManageUsers.current.includes(currentUserData?.role);
    } , [currentUserData?.role]);

    const menuItems = useMemo(()=> {
        const menuOptions = {
            editOrView: {title: editNotAllowed ? 'View' : 'Edit', onPress: ()=>{
                    setShowMenuForRowId(undefined);
                    onEditRecordPressed(showMenuForRowId);
                }},
            inviteOrPassword: currentRowData?.isInvitePending ? {title: 'Resend Invite', onPress: ()=>{
                    if (!currentCompanyId) { return; }
                    resendInvite({userId: showMenuForRowId ?? '', companyId: currentCompanyId});
                    setShowMenuForRowId(undefined);
                }} : {
                title: 'Change Password', onPress: ()=>{
                    setShowChangePasswordModalId(showMenuForRowId);
                    setShowMenuForRowId(undefined);
                }},
            viewTimeline: {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
            }},
            delete: {title: 'Delete',  onPress: ()=>{showDeleteUser({
                    recordId: showMenuForRowId ?? '',
                    recordName: `${currentRowData?.firstName} ${currentRowData?.lastName}`,
                });}, textStyle: {color: COLORS.dangerTextColor}},
        };
        const baseItems = [];
        if (isMobileApp) {
            baseItems.push(menuOptions.editOrView);
        }
        baseItems.push(menuOptions.viewTimeline);
        if (!editNotAllowed) {
            baseItems.push(menuOptions.inviteOrPassword);
            baseItems.push(menuOptions.delete);
        }
        return baseItems.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [showMenuForRowId, resendInvite,currentRowData, currentCompanyId, showDeleteUser, onEditRecordPressed, editNotAllowed]);


    const userModalTitleProps = useMemo(()=> {
        if (showTimeline) {
            const user = usersData?.items.find((item)=>item.id === modalUserId);
            if (!user) {
                return {title: 'Timeline'};
            }
            return {
                title : `Timeline for ${user?.firstName} ${user?.lastName}`,
            };
        }
        if (editNotAllowed && modalUserId){
            const user = usersData?.items.find((item)=>item.id === modalUserId);
            if (!user) {
                return {title: 'View user'};
            }
            return {
                titleCustomComponent: <View style={{flexDirection: 'row'}}>
                    <Text style={initialStyles.modalTitle}>{`${user?.firstName} ${user?.lastName}` }</Text>
                    <Spacer x={10}/>
                    <StatusRenderer status={user?.status} recordType={RecordTypes.user}/>
                </View>,
            };
        } else {
            return {title: modalUserId ? 'Edit user' : 'Add new user'};
        }

    }, [editNotAllowed, modalUserId, usersData, showTimeline]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return (
                <ListViewHeader title={'User Management'}
                                hideAddButton={editNotAllowed}
                                subtitle={'View and manage all company users.'}
                                buttonText={addButtonText}
                                onAddPress={onUserAddPress}
                                buttonColor={applyTTStyles(false).primaryButtonBg}
                                filterComponent={filters.Button}

                />
        );
    }, [editNotAllowed, onUserAddPress, filters.Button]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            <>
            {DeleteUser}
                <ModalWrapper show={showRecordModal}
                              closeAction={()=>onEditRecordPressed(undefined)}
                              {...userModalTitleProps}
                              maxHeight={undefined}
                >
                    {showRecordModal ? <CompanyUserForm isEditing={!!modalUserId}
                                                         closeAction={()=>onEditRecordPressed(undefined)}
                                                         userId={modalUserId}
                                                         action={modalUserId ? editUser : createUser}
                                                         submitInProgress={modalUserId ? isLoadingUserEdit : isLoadingUserCreate}
                                                         submitErrorMessage={errorMessage}
                                                         resetSubmitError={()=>setErrorMessage('')}
                                                         editNotAllowed={editNotAllowed}
                                                         showTimeline={showTimeline}
                                                         toggleShowTimeline={toggleShowTimeline}

                    /> : null}
                </ModalWrapper>

                <ModalWrapper show={!!showChangePasswordModalId}
                              closeAction={()=> setShowChangePasswordModalId(undefined)}
                              title={'Change password'}
                              height={400}
                              width={600}
                              visibleOverflow={true}
                >
                    {showChangePasswordModalId ? <ChangePasswordContent userId={showChangePasswordModalId}
                                                                      closeAction={()=> setShowChangePasswordModalId(undefined)}
                                                                      action={changePassword}
                                                                      submitInProgress={isLoadingChangePassword}
                                                                      submitErrorMessage={errorMessage}
                                                                        resetSubmitError={()=>setErrorMessage('')}
                        />
                        : null}
                </ModalWrapper>
                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                                callerX={position.x}
                                                callerY={position.y}
                                                visible={!!showMenuForRowId}
                                                menuItems={menuItems}
                                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}
                {isMobileApp ? null : HeaderComponent}
                {filters.FilterPanel}
                <UsersTable data={usersData?.items}
                            canManageRecords={!editNotAllowed}
                            isLoading={usersData ? (isLoading && !isPreviousData) : isLoading}
                            onEditIconPressed = {onEditRecordPressed}
                            openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                            onAddPress={onUserAddPress}
                            addButtonText={addButtonText}
                            hideAddButton={editNotAllowed}
                            fetchMore={hasNextPage ? fetchNextPage : undefined}
                            headerComponent={HeaderComponent}
                            hasFilters={filters.filtersApplied}

                />
                {isMobileApp ? null : <View style={{minHeight:60}}>
                    {PaginationComponent ? PaginationComponent({totalItems: usersData?.totalItems, currentItemsLength: usersData?.items?.length ?? 0}) : null}
                </View>}


            </>
        </UIDrawerRightContentWrapper>
    );
};

export { Users };
