import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { View, StyleSheet, TouchableOpacity, Text } from 'react-native';
import { DateObj } from '../../typesAndInterfaces/typesAndInterfaces';
import Entypo from 'react-native-vector-icons/Entypo';
import { COLORS } from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
const DateIterator = ({
    dates,
    currentId,
    onPress,
}: {
    dates: {
        date: DateObj,
        id: string,    //record id
    }[],
    currentId: string,
    onPress: (nextId:string)=>void,
}) => {

    const getInitialIndex = useCallback((id:string) => {
        const index = dates.findIndex((date)=> date.id === id);
        return index === -1 ? 0 : index;
    },[dates]);
    const [selectedIndex, setSelectedIndex] = useState<number>(getInitialIndex(currentId));

    useEffect(() => {
      setSelectedIndex(getInitialIndex(currentId));

    }, [currentId, getInitialIndex]);

    useEffect(() => {

    }, []);

    const handleLeftArrowPress = () => {
       const nextIndex = selectedIndex - 1;
         if (nextIndex >= 0) {
              const id = dates[nextIndex].id;
              onPress(id);
         }
    };

    const handleRightArrowPress = () => {
        const nextIndex = selectedIndex + 1;
        if (nextIndex < dates.length) {
            const id = dates[nextIndex].id;
            onPress(id);
        }
    };
    const rightIsDisabled = useMemo(() => selectedIndex === dates.length - 1, [selectedIndex, dates.length]);
    const leftIsDisabled = useMemo(() => selectedIndex === 0, [selectedIndex]);

    const monthNumberToMonthName = (monthNumber: number) => {
        //month in short format
        const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return monthNames[monthNumber - 1];
    };

    const current = useMemo(()=> dates[selectedIndex], [selectedIndex, dates]);
    if (dates.length === 0) {return null;}
    if (!current) {return null;}

    return (
        <View style={styles.container}>
            <TouchableOpacity onPress={handleLeftArrowPress} disabled={leftIsDisabled} style={{paddingRight: 16}}>
                <Entypo name="chevron-left" size={24} color={ leftIsDisabled ? COLORS.textSecondary : COLORS.textPrimary}/>
            </TouchableOpacity>
            <View style={styles.textWrapper}>
                <Text style={styles.dateText}>{`${monthNumberToMonthName(dates[selectedIndex]?.date?.month)} ${dates[selectedIndex]?.date?.day}, ${dates[selectedIndex]?.date?.year}`}</Text>
            </View>

            <TouchableOpacity onPress={handleRightArrowPress} disabled={rightIsDisabled} style={{paddingLeft: 16}}>
                <Entypo name="chevron-right" size={24} color={rightIsDisabled ? COLORS.textSecondary : COLORS.textPrimary}/>
            </TouchableOpacity>
        </View>
    );
};

export {DateIterator};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    dateText: mbTextStyles([
        textStyles.Muli_16_600, {
         fontSize: 18,
        },
    ]),
    textWrapper: {
        width: 120,
    },
});
