import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import {
    toWorkOrderPositionTypeLabel,
    toWorkOrderPositionLabel,
    WorkOrderPosition,
} from '@temptrack/business';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { ViewIconButton } from '../../../helperComponents/misc/TableRowActions';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { WorkOrdersTableProps } from './types';

const COLORS = getColors();

const header = [
    {content: 'Work order #', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'client', conatinerStyle: { minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'Start date', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'End date', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'position', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'position type', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: '# of checklists', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'status', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    {content: '', conatinerStyle: { flex: 1, minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const WorkOrdersTable = ({data, isLoading, openRowMenu, onAddPress, addButtonText, onViewPressed, hasFilters}: WorkOrdersTableProps) => {
    const tableHeight = utilHooks.useTableHeight();
    const {isClient} = useSignedInContext();


    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const columns = {
                number: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.number}</Text>,
                client: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.clientName}</Text>,
                startDate: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.formatDateObjectToDisplay(item?.startDate)}</Text>,
                endDate: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.formatDateObjectToDisplay(item?.endDate)}</Text>,
                position: <Text style={tableStyles.tableRowText} numberOfLines={2}>{item?.position ? toWorkOrderPositionLabel(item?.position as WorkOrderPosition) : ''}</Text>,
                positionType: <Text style={tableStyles.tableRowText} numberOfLines={2}>{item?.positionType ? toWorkOrderPositionTypeLabel(item?.positionType) : ''}</Text>,
                checklists: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.checklistIds?.length}</Text>,
                status: <Text style={tableStyles.tableRowText} numberOfLines={1}>{
                    <StatusRenderer status={item?.status} recordType={RecordTypes.workOrder}/>}</Text>,

            };
            const actions =
                      <View style={{
                          flexDirection: 'row',
                          alignSelf: 'flex-end',
                          position: 'relative',
                      }}>
                          <ViewIconButton action={()=>onViewPressed(item.id)}/>
                          <IconButton icon={
                              <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                          }
                                      action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                          />
                      </View>;

            if (isClient) {
                return [
                    columns.number,
                    columns.startDate,
                    columns.endDate,
                    columns.position,
                    columns.positionType,
                    columns.checklists,
                    columns.status,
                    actions,
                ];
            } else {
                return [
                    columns.number,
                    columns.client,
                    columns.startDate,
                    columns.endDate,
                    columns.position,
                    columns.positionType,
                    columns.checklists,
                    columns.status,
                    actions,
                ];
            }
        });
    }, [data, openRowMenu, isClient, onViewPressed]);

    const headerItems = useMemo(()=> {
        if (isClient) {
            return header.filter((item)=> item.content !== 'client');
        }
        return header;
    }, [isClient]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={headerItems}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No work orders yet'}
                                                          subtitle={'Start adding work orders to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { WorkOrdersTable };
