import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const BriefcaseIconSvg = React.memo(({color,size, containerStyle}: {
    color?: string, size?: number, containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 24}
                height={size ?? 24}
                viewBox="0 0 24 24"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.2 7.2V6a3.6 3.6 0 013.6-3.6h2.4A3.6 3.6 0 0116.8 6v1.2h2.4a2.4 2.4 0 012.4 2.4v4.284A27.542 27.542 0 0112 15.6a27.542 27.542 0 01-9.6-1.716V9.6a2.4 2.4 0 012.4-2.4h2.4zM9.6 6a1.2 1.2 0 011.2-1.2h2.4A1.2 1.2 0 0114.4 6v1.2H9.6V6zm1.2 6a1.2 1.2 0 011.2-1.2h.012a1.2 1.2 0 110 2.4H12a1.2 1.2 0 01-1.2-1.2z"
                    fill={color ?? '#9CA3AF'}
                />
                <Path
                    d="M2.4 16.43v2.77a2.4 2.4 0 002.4 2.4h14.4a2.4 2.4 0 002.4-2.4v-2.77A29.97 29.97 0 0112 18c-3.355 0-6.584-.552-9.6-1.57z"
                    fill={color ?? '#9CA3AF'}
                />
            </Svg>


        </View>
    );
});

export { BriefcaseIconSvg };
