import React from 'react';

import { StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../constants/colors';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { signedInContextGlobalFunction } from '../../context/SignedInContext';

const LoadingPage = ({barColor, hideTakeLonger}: {
    barColor?: string,
    hideTakeLonger?: boolean,
}) => {
    const COLORS = getColors();

    const [isStuck, setIsStuck] = React.useState(false);

    const timerRef = React.useRef<NodeJS.Timeout | null>(null);
    React.useEffect(() => {
        if (hideTakeLonger) {
            return;
        }
        timerRef.current = setTimeout(() => {
            setIsStuck(true);
        }, 10000);
        return () => {
            clearTimeout(timerRef.current as NodeJS.Timeout);
        };
    }, [hideTakeLonger]);

    return (
        <View style={styles.container}>

            <View>
                <BarIndicator color={barColor ?? COLORS.buttonPrimaryBg} count={5} style={{ alignSelf: 'center', marginBottom: 32, flex: undefined }} />
                <Text style={{}}>Loading...</Text>
                {isStuck && <Text style={{}}>This is taking longer than expected...</Text>}
                {isStuck && <MB_Button title={'Refresh'} onPress={()=> signedInContextGlobalFunction?.signOut?.({})} />}
            </View>
        </View>
    );
};

export { LoadingPage };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundSecondary,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
