import React, { useEffect } from 'react';
import { StyleSheet, View, Text } from 'react-native';
import { getColors } from '../../constants/colors';
import { useCurrentCompanyContext } from '../../context/CurrentCompanyContext';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { navigatorTypes } from '../../navigations/CompanyDashboardNavigator/CompanyDashboardNavigator';

const Home = () => {

    const {currentCompanyId, goToCompanyDashboard} = useCurrentCompanyContext();

    useEffect(() => {
        if (currentCompanyId) {
            goToCompanyDashboard(currentCompanyId, navigatorTypes.CustomerDashboardNavigator);
        }
    }, [currentCompanyId, goToCompanyDashboard]);



    return (
        <View style={styles.container}>
            <View>
                <BarIndicator color={COLORS.buttonPrimaryBg} count={5} style={{ alignSelf: 'center', marginBottom: 32, flex: undefined }} />
                <Text style={{}}>Loading...</Text>
            </View>
        </View>
    );
};

export { Home };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
    },
});
