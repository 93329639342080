import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from './textStyles';
import { getColors } from './colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();

const platformStyles = ({ isMobile }: {
    isMobile: boolean;
}) => StyleSheet.create({
    container: {
        flex: isMobile ? 1 : undefined,
        justifyContent: 'space-between',
    },
    row: {
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'space-between',
        marginBottom: isMobile ? undefined : 18,
        paddingRight: 10,
    },

    buttonRow: {
        marginBottom: isMobile ? 36 : undefined,
    },

    rowItem: {
        width: isMobile ? '100%' : '45%',
    },

    inputLabel: mbTextStyles([
        textStyles.Muli_16_700, {
            color: COLORS.inputLabel,
            fontSize: 14,
            textAlign: 'left',
            marginBottom: 8,
        },
    ]),
    errorContainer: {
        marginVertical: 18,
        alignItems: 'center',
    },
    errorMessage: {
        color: COLORS.inputErrorLabel,
    },
});

const recordModalContentStyles = platformStyles({isMobile: isMobileApp});

export { recordModalContentStyles };
