import React, { ReactNode } from 'react';
import { View, Platform } from 'react-native';
import {MB_Modal, MB_ModalMobileBottom} from '@mightybyte/rnw.components.modal';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { GestureHandlerRootView } from 'react-native-gesture-handler';


const COLORS = getColors();

const ModalWrapper = ({children, show, closeAction, title, titleCustomComponent, height, width, maxWidth, maxHeight, visibleOverflow, minHeight}: {
    children: ReactNode,
    show: boolean
    closeAction: () => void,
    title?: string | undefined,
    titleCustomComponent?: JSX.Element | undefined,
    height?: string | number,
    width?: string | number,
    maxWidth?: string | number,
    maxHeight?: string | number | undefined,
    minHeight?: string | number | undefined,
    visibleOverflow?: boolean,
    hideCloseButton?: boolean,
}) => {
    if (isMobileApp) {
        return <MB_ModalMobileBottom isVisible={show}
                                     onDismiss={closeAction}
                                     title={title}
                                     titleCustomComponent={titleCustomComponent}
                                     childrenWrapperStyle={{
                                         backgroundColor: COLORS.backgroundPrimary,
                                         minHeight: minHeight,
                                         paddingTop: 56,

                                     }}
                                     keyboardShouldPersistTaps="handled"
                                     keyboardAwareProps={Platform.OS === 'ios' ? {extraScrollHeight: 50} : undefined}

        >
            <GestureHandlerRootView style={{ flex: 1, width: '100%' }}>
                <View style={{width: '100%', flex: 1,  padding:  isMobileApp ? 8 : 16}}>
                    {children}
                </View>
            </GestureHandlerRootView>



        </MB_ModalMobileBottom>;
    }
    return (
        <MB_Modal isVisible={show}
                  onBackdropPress={closeAction}
                  onCloseButtonPressed={closeAction}
                  title={title}
                  titleCustomComponent={titleCustomComponent}
                  childrenWrapperStyle={{
                      maxWidth: maxWidth ?? 800,
                      width: width ?? '80%',
                      height: height ?? '90%',
                      maxHeight: maxHeight ?? 600,
                      justifyContent: 'flex-start',
                      backgroundColor: COLORS.backgroundPrimary,
                      paddingTop: 68,
                      overflow: visibleOverflow ? 'visible' : 'hidden',
                  }}
                  titleStyle={mbTextStyles([textStyles.Muli_16_700, {
                      fontSize: 24,
                      textAlign: 'left',
                  }])}
                  titleWrapperStyle={{
                      paddingLeft: 24,
                      paddingBottom: 8,
                      borderBottomColor: COLORS.textSecondary,
                  }}

        >
            <View style={{width: '100%', flex: 1}}>
                {children}
            </View>
        </MB_Modal>
    );
};

export {ModalWrapper};
