import { NavigationState } from '@react-navigation/native';
import {CompanyNavigatorTab, AdminNavigatorTab} from './types';

export const getCurrentRoute = (nav:NavigationState) => {
    if (Array.isArray(nav.routes) && nav.routes.length > 0) {
        return nav.routes[nav.index].name;
    } else {
        return undefined;
    }
};
export const isActiveRoute = (tab: CompanyNavigatorTab | AdminNavigatorTab, activeRoute:string | undefined) => {
    if (!activeRoute) {return false;}
    if (tab?.subTabs) {
        return tab.subTabs.some((subTab) => subTab.routeName === activeRoute);
    }
    return activeRoute === tab.routeName;
};
