import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text} from 'react-native';
import { appColors, applyTTStyles } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { BranchesTableProps } from './types';
import { Actions } from './BranchesActionsUI';
import { shouldShowRegionForRole } from '../../../../utils/permissionUtils';


const header = [
    {content: 'Branch name', conatinerStyle: { minWidth: 170, maxWidth: 170, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'region', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'address', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'email', conatinerStyle: {  minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'phone number', conatinerStyle: { minWidth: 120, maxWidth: 120, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1, minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const BranchesTable = ({data, isLoading, onEditIconPressed, openRowMenu, onAddPress, addButtonText, hasFilters}: BranchesTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const {currentUserData} = useSignedInContext();
    const shouldShowRegion = useMemo(()=> {
        return shouldShowRegionForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const onEditPress = useCallback((d:string) => {
        onEditIconPressed(d);
    } , [onEditIconPressed]);

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const headerItems = useMemo(()=> {
        if (shouldShowRegion) {
            return header;
        }

        return header.filter((item)=> item.content !== 'region');
    }, [shouldShowRegion]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const userData = item;
            const columns = {
                name: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.name}</Text>,
                region: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.regionName}</Text>,
                address: <Text style={tableStyles.tableRowText} numberOfLines={2}>
                    {utils.getAddressToDisplay(item?.address)}
                </Text>,
                email: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.email}</Text>,
                phoneNumber: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.phoneNumberToDisplay(item?.phoneNumber)}</Text>,
            };

            const actions = <Actions onEditPress={()=> onEditPress(userData.id)}
                                     threeDotAction={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}/>;

            if (shouldShowRegion) {
                return [
                    columns.name,
                    columns.region,
                    columns.address,
                    columns.email,
                    columns.phoneNumber,
                    actions,
                ];
            } else {
                return [
                    columns.name,
                    columns.address,
                    columns.email,
                    columns.phoneNumber,
                    actions,
                ];
            }


        });
    }, [data, onEditPress, openRowMenu, shouldShowRegion]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                    header={headerItems}
                    headerStyle={tableStyles.tableHeader}
                    data={[...dataToDisplay]}
                    style={[tableStyles.tableStyle]}
                    rowStyle={tableStyles.rowStyle}
                    keyExtractor={keyExtractor}
                    rowTextStyle={tableStyles.tableRowText}
                    loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No branches yet'}
                                                          subtitle={'Start adding branches to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { BranchesTable };
