import React, { useMemo } from 'react';
import { trpc } from '../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../context/CurrentCompanyContext';

const useWorkOrders = (
    {disabled}: {
    disabled?: boolean;
})=> {
    const disabledRef = React.useRef(disabled);
    const {currentCompanyId} = useCurrentCompanyContext();
    const {data: workOrderOptions, isLoading: gettingWorkOrders} = trpc.company.workOrder.getDropdown.useQuery({companyId: currentCompanyId ?? ''}, {
        enabled: !!currentCompanyId && !disabledRef.current,
    });

    const options = useMemo(() => {
        if (!workOrderOptions || !workOrderOptions?.items || gettingWorkOrders) {
            return {
                dropdownOptions: [],
                options: [],
            };
        }
        return {
            dropdownOptions: workOrderOptions.items.map((wo)=> {
                return {label: `${wo.number}  ${wo.clientName}`, value: wo.id};
            }),
            options: workOrderOptions.items,
        };
    }, [workOrderOptions, gettingWorkOrders]);

    return {
        dropdownOptions: options.dropdownOptions,
        fullOptions: options.options,
    };
};

export { useWorkOrders };
