export const APIError = {
    internalServerError: {
        code: 'INTERNAL_SERVER_ERROR',
        statusCode: 500,
        message: 'Something went wrong.',
    },

    loginFailed: {
        code: 'LOGIN_FAILED',
        statusCode: 400,
        message: 'Email or password does not match.',
    },

    invalidParameter: {
        code: 'INVALID_PARAMETER',
        statusCode: 400,
        message: 'One or more parameters are invalid',
    },

    emailAlreadyInUse: {
        code: 'EMAIL_ALREADY_IN_USE',
        statusCode: 400,
        message: 'The provided email address is already in use.',
    },

    signUpFailed: {
        code: 'SIGN_UP_FAILED',
        statusCode: 500,
        message: 'Failed to create a new user',
    },

    accessDenied: {
        code: 'ACCESS_DENIED',
        statusCode: 403,
        message: 'You do not have permission to perform this task.',
    },

    inactiveUser: {
        code: 'INACTIVE_USER',
        statusCode: 401,
        message: 'Your account is inactive.',
    },

    invalidAccessToken: {
        code: 'INVALID_ACCESS_TOKEN',
        statusCode: 401,
        message: 'The provided access token is invalid.',
    },

    expiredAccessToken: {
        code: 'EXPIRED_ACCESS_TOKEN',
        statusCode: 401,
        message: 'The provided access token expired.',
    },

    expiredPasswordRecoveryToken: {
        code: 'EXPIRED_PASSWORD_RECOVERY_TOKEN',
        statusCode: 401,
        message: 'The provided password recovery token expired.',
    },

    invalidRefreshToken: {
        code: 'INVALID_REFRESH_TOKEN',
        statusCode: 401,
        message:
            'The provided refresh token is invalid, please try signing in again.',
    },

    invalidUpdateCurrentUserDataParams: {
        code: 'INVALID_UPDATE_CURRENT_USER_DATA_PARAMS',
        statusCode: 400,
        message: 'At least one of the user data params has to be changed',
    },

    invalidPassword: {
        code: 'INVALID_PASSWORD',
        statusCode: 400,
        message: 'Old password was invalid',
    },

    invalidVerificationCode: {
        code: 'INVALID_VERIFICATION_CODE',
        statusCode: 400,
        message: 'The verification code is invalid',
    },

    invalidSignUpCode: {
        code: 'INVALID_SIGN_UP_CODE',
        statusCode: 400,
        message: 'Sign up code was invalid',
    },

    signUpCodeExpired: {
        code: 'SIGN_UP_CODE_EXPIRED',
        statusCode: 400,
        message: 'Sign up code expired',
    },

    notFound: {
        code: 'NOT_FOUND',
        statusCode: 404,
        message: 'Resource not found.',
    },

    requestBatchingNotAllowed: {
        code: 'REQUEST_BATCHING_NOT_ALLOWED',
        statusCode: 400,
        message: 'Request batching is not allowed for this call.',
    },

    inviteNotPending: {
        code: 'INVITE_NOT_PENDING',
        statusCode: 400,
        message: "The user doesn't have a pending invite.",
    },

    companyDomainAlreadyInUse: {
        code: 'COMPANY_DOMAIN_ALREADY_IN_USE',
        statusCode: 400,
        message: 'The provided company domain is already in use.',
    },

    thisRecordHasDependencies: {
        code: 'THIS_RECORD_HAS_DEPENDENCIES',
        statusCode: 400,
        message: 'This record has dependencies.',
    },
};
