import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import {UIDrawerRightContentWrapper} from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { routeParams } from '../../../../constants/urlConstants';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { GestureResponderEvent, View } from 'react-native';
import { RegionsProps } from '../../../../typesAndInterfaces/componentProps';
import {utils} from '../../../../utils/utils';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import {RegionForm} from './RegionContent';
import { RegionsTable } from './RegionsTable';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { useGetRegions } from './useGetRegions';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { FilterFieldType } from '@temptrack/business';

const COLORS = getColors();

const Regions = ({navigation, route}: RegionsProps)=> {

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.regionId);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);

    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);


    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const deleteRegion = trpc.company.region.delete.useMutation({
        onSuccess: ()=> {
            setShowMenuForRowId(undefined);
            refreshList();
            hideDeleteRegion();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting region',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteRegion, show: showDeleteRegion, component:DeleteRegion, setDeleteError} = useDeleteRecord({
        recordType: 'region',
        deleteAction: (id)=>{deleteRegion.mutate({regionId: id, companyId: currentCompanyId ?? ''}, {
            onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: deleteRegion.isLoading,
    });

    const {regionsData,
              PaginationComponent,
              isPreviousData,
              isLoading,
              fetchNextPage,
              hasNextPage, setFilters} = useGetRegions({navigation, route});

    const applyFiltersCallback = useCallback(({ filters }: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
    }, [setFilters]);

    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'Region Name',
                apiName: 'name',
                filterType: FilterFieldType.text,
            },
        ];
    }, []);

    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    const onRecordAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.regions, {...route.params, ...{regionId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    const addButtonText = 'Add new region';

    const closeModal = useCallback(()=> {
        navigation.navigate(CompanyDashboardRoutes.regions, {...route.params, ...{regionId: '', companyId: currentCompanyId ?? '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, currentCompanyId, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            navigation.navigate(CompanyDashboardRoutes.regions, {...route.params, ...{regionId: d, companyId: currentCompanyId ?? '', timeline: timeline}});
            setShowRecordModal(true);
        } else {
            closeModal();
        }

    }, [navigation, closeModal, currentCompanyId, route.params]);

    const modalRecordId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.regionId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.regionId, showRecordModal]);

    const {mutate: createRecord, isLoading: isLoadingRecordCreate} = trpc.company.region.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Creating region',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editRecord, isLoading: isLoadingRecordEdit } = trpc.company.region.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.regionData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing region',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });


    const refreshList = useCallback((id?:string)=> {
        if (id) {
            trpcContext.company.region.getById.invalidate({regionId: id, companyId: currentCompanyId ?? ''});
        }
        if (isMobileApp) {
            trpcContext.company.region.list.invalidate();
        } else {
            trpcContext.company.region.getPage.invalidate();
        }

    }, [trpcContext.company.region.getPage, trpcContext.company.region.list, trpcContext.company.region.getById, currentCompanyId]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const menuItems = useMemo(()=> {
        const base :{
            title: string,
            onPress: ()=>void,
            textStyle?: any,
        }[] = [
            {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
            }},

            {title: 'Delete', onPress: ()=>{showDeleteRegion({

                    recordId: showMenuForRowId,
                    recordName: regionsData?.items.find((item)=>item.id === showMenuForRowId)?.name,
                });}, textStyle: {color: COLORS.dangerTextColor}},
        ];
        if (isMobileApp) {
            base.unshift({title: 'Edit', onPress: ()=>{
                setShowMenuForRowId(undefined);
                onEditRecordPressed(showMenuForRowId);}}
            );
        }
        return base.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [showMenuForRowId, regionsData?.items, showDeleteRegion, onEditRecordPressed]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return (
            <ListViewHeader title={'List of regions'}
                            subtitle={'View and manage our regions.'}
                            buttonText={addButtonText}
                            onAddPress={onRecordAddPress}
                            buttonColor={applyTTStyles(false).primaryButtonBg}
                            filterComponent={filters.Button}

            />
        );
    }, [onRecordAddPress, filters.Button, addButtonText]);

    const regionModalTitleProps = useMemo(()=> {
        if (showTimeline) {
            const regionName = regionsData?.items.find((item)=>item.id === modalRecordId)?.name;
            return {
                title: `Timeline for ${regionName}`,
            };

        }
        return {
            title: modalRecordId ? 'Edit region' : 'Add new region',
        };

    }, [modalRecordId, showTimeline, regionsData?.items]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            {DeleteRegion}
            <ModalWrapper show={showRecordModal}
                          closeAction={()=>onEditRecordPressed(undefined)}
                          {...regionModalTitleProps}
                          maxHeight={showTimeline ? undefined : 300}
            >
                {showRecordModal ? <RegionForm isEditing={!!modalRecordId}
                                                    closeAction={()=>onEditRecordPressed(undefined)}
                                                    regionId={modalRecordId}
                                                    action={modalRecordId ? editRecord : createRecord}
                                                    submitInProgress={modalRecordId ? isLoadingRecordEdit : isLoadingRecordCreate}
                                                    submitErrorMessage={errorMessage}
                                               resetSubmitError={()=>setErrorMessage('')}
                                               showTimeline={showTimeline}
                                               toggleShowTimeline={toggleShowTimeline}

                /> : null}
            </ModalWrapper>

            {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                            callerX={position.x}
                                            callerY={position.y}
                                            visible={!!showMenuForRowId}
                                            menuItems={menuItems}
                                            closeMenu={()=>setShowMenuForRowId(undefined)}
            /> : null}
            {isMobileApp ? null : HeaderComponent}
            {filters.FilterPanel}
                <RegionsTable data={regionsData?.items}
                              isLoading={regionsData ? (isLoading && !isPreviousData) : isLoading}
                              onEditIconPressed = {onEditRecordPressed}
                              openRowMenu={(e, recordId)=> {
                                  openRowMenu(e, recordId);
                              }}
                              onAddPress={onRecordAddPress}
                              addButtonText={addButtonText}
                              headerComponent={HeaderComponent}
                                fetchMore={hasNextPage ? fetchNextPage : undefined}
                              hasFilters={filters.filtersApplied}
                />
            {isMobileApp ? null : <View style={{minHeight:60}}>
            {PaginationComponent ? PaginationComponent({totalItems: regionsData?.totalItems, currentItemsLength: regionsData?.items?.length ?? 0}) : null}
            </View>}
            </UIDrawerRightContentWrapper>
    );
};

export { Regions };
