import { UserStatus } from '../user';
export { UserStatus as EmployeeStatus };

export enum EmployeePositionType {
    fullTime = 'fullTime',
    partTime = 'partTime',
}

export enum EmployeePosition {
    administrative = 'administrative',
    generalLabor = 'generalLabor',
    shippingClerk = 'shippingClerk',
    forklift = 'forklift',
    customerService = 'customerService',
    server = 'server',
    supervisor = 'supervisor',
}
