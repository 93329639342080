import React from 'react';
import { StyleProp, ViewStyle, View, Text, StyleSheet } from 'react-native';
import {MB_DatePicker} from '@mightybyte/rnw.components.date-picker';
import { initialStyles } from '../../../hooks/useAppInitialSetup';
import { appColors, getColors } from '../../../constants/colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();
const WrappedDatePicker = ({
    title,
    value,
    onChange,
    containerStyle,
    showRequiredAsterisk,
    minDate,
    maxDate,
    initialMonth,
    showInitialEmpty,
    disabled,
    noLimits,
                           }: {
    title?: string,
    value?: string | undefined,
    onChange: (newValue: Date | undefined) => void,
    containerStyle?: StyleProp<ViewStyle>,
    showRequiredAsterisk?: boolean,
    disabled?: boolean,
    minDate?: string | undefined,
    maxDate?: string | undefined | null,
    initialMonth?: string | undefined,
    showInitialEmpty?: boolean,
    noLimits?: boolean,
}) => {
    return (
        <View style={containerStyle}>
            {title ? <View>
                <Text style={initialStyles.textInputTitle}>{title}{showRequiredAsterisk ? <Text style={initialStyles.requiredAsteriksColor}>*</Text> : null}</Text>
            </View> : null}
            <MB_DatePicker disabled={disabled}
                           initialMonth={initialMonth}
                initialDate={value ? new Date(value) : showInitialEmpty ? undefined : new Date()}
                minDate={noLimits ? undefined : minDate ? new Date(minDate) : new Date(new Date().setDate(new Date().getDate()))}
                           maxDate={maxDate ? new Date(maxDate) : undefined}
                onDateChanged={onChange}
                calendarStyle={{
                    backgroundColor: COLORS.backgroundPrimary,
                    borderColor: appColors.gray300,
                    borderWidth: 1,
                    borderRadius: 6,
                }}
                style={[initialStyles.textInputStyle, { width: '100%', paddingLeft: 0, maxWidth: isMobileApp ? 400 : undefined}]}
                calendarTheme={{
                    calendarBackground: COLORS.backgroundPrimary,
                    dayTextColor: COLORS.textPrimary,
                    textDisabledColor: appColors.gray300,
                    selectedDayTextColor: appColors.white,
                    todayTextColor: appColors.blue800,
                    todayBackgroundColor: appColors.blue100,
                    textDayFontWeight: '400',
                    textMonthFontWeight: '400',
                    textDayHeaderFontWeight: '400',
                    textDayFontSize: 16,
                    textMonthFontSize: 16,
                    textDayHeaderFontSize: 16,
                }}
                selectedDateContainerStyle={styles.selectedDateContainerStyle}
                inputTextColor={COLORS.textPrimary}
                headerTextStyle={styles.headerTextStyle}
                arrowColor={appColors.blue600}
                disabledArrowColor={appColors.blue300}
            />
        </View>
    );
};
const styles = StyleSheet.create({
    headerTextStyle: {
        color: COLORS.textPrimary,
    },
    selectedDateContainerStyle: {
        backgroundColor: COLORS.buttonPrimaryBg,
        borderColor: COLORS.buttonPrimaryBg,
        borderWidth: 1,
    },
});

export {WrappedDatePicker};
