import React, { RefObject, useCallback, useMemo, useRef } from 'react';
import {MB_TextInput, Masks} from '@mightybyte/rnw.components.text-input';
import { WrappedInput } from '../components/helperComponents/misc/WrappedInput';
import { getColors } from '../constants/colors';
import { STRING_CONSTANTS } from '../constants/constants';
import { MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { utils } from '../utils/utils';

const COLORS = getColors();

const usePhoneNumber = ({isRequired,  nextToFocus, onSubmitEditing, disabled, label, placeholder, subText, hideError}: {
    isRequired?: boolean;
    nextToFocus?: RefObject<MB_TextInputRef>;
    onSubmitEditing?: () => void;
    disabled?: boolean;
    label?: string;
    placeholder?: string;
    subText?: string;
    hideError?: boolean;
}) => {
    const [phoneNumber, setPhoneNumber] = React.useState({
        masked:  '',
        unmasked: '',
    });
    const [phoneNumberError, setPhoneNumberError] = React.useState<string | undefined>(undefined);

    const phoneNumberRef = useRef<MB_TextInputRef>(null);

    const onFieldChange = useCallback((value) => {
        setPhoneNumber({
            masked: value.masked,
            unmasked: value.unmasked,
        });
        setPhoneNumberError(undefined);
    }, []);


    const validatePhoneNumber = useCallback(() => {
        let isValid = true;
        if (isRequired && (!phoneNumber?.unmasked || phoneNumber?.unmasked.length === 0)) {
            isValid = false;
            setPhoneNumberError(STRING_CONSTANTS.REQUIRED);
        } else if (phoneNumber && !phoneNumber.unmasked.match(/^\d{10}$/i)) {
            isValid = false;
            setPhoneNumberError('Invalid phone number');
        }
        return isValid;
    }, [phoneNumber, isRequired]);

    const field = useMemo(() => {
        return (
            <WrappedInput errorMessage={hideError ? undefined : phoneNumberError}
                          subText={subText}
                             inputComponent={<MB_TextInput
                                 style = {{width: '100%'}}
                                 placeholderTextColor={COLORS.inputPlaceholder}
                                 value = {phoneNumber.masked}
                                 title={label ?? 'Phone number'}
                                 placeholder={placeholder ?? 'Add a phone number'}
                                 textContentType={'telephoneNumber'}
                                 showRequiredAsteriks={isRequired}
                                 MB_Ref={phoneNumberRef}
                                    nextToFocus={nextToFocus}
                                    onSubmitEditing={onSubmitEditing ?? undefined}
                                 disable={disabled}
                                 mask={{
                                     maskType: Masks.USA_PHONE,
                                     onChangeText: onFieldChange,
                                 }}

                             />}
        />
        );
    }, [phoneNumber, phoneNumberError, onFieldChange, isRequired, disabled,
        nextToFocus, onSubmitEditing, label, placeholder, subText, hideError]);

    return {
        value: phoneNumber.unmasked,
        emailError: phoneNumberError,
        setInitialValue: (v: string | undefined)=>{setPhoneNumber({masked: utils.phoneNumberToDisplay(v), unmasked: v ?? ''});},
        validate: validatePhoneNumber,
        field,
        ref: phoneNumberRef,
    };
};

export { usePhoneNumber};
