import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleProp, Text, TouchableOpacity, View, ViewStyle } from 'react-native';
import DynamicStyleSheet, { useDynamicStyles } from '../../../utils/DynamicStyleSheet';
import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { useSignedInContext } from '../../../context/SignedInContext';
import { NotificationEvent, toUserRoleLabel, UserRole } from '@temptrack/business';
import { useCurrentCompanyContext } from '../../../context/CurrentCompanyContext';
import { DisplayLogo } from '../misc/DisplayLogo';
import {ModalDropdown} from '@mightybyte/rnw.components.modal-dropdown';
import { appColors, applyTTStyles, COLORS } from '../../../constants/colors';
import { MB_AnimatedChevron, MB_AnimatedChevronRef } from '@mightybyte/rnw.components.animated-chevron';
import { headerStyles } from './styles';
import { OutlinedButton } from '../misc/OutlinedButton';
import { LogoutIconSvg } from '../../../resources/svgComponents/LogoutIconSvg';
import Feather from 'react-native-vector-icons/Feather';
import { isMobileApp} from '@mightybyte/rnw.utils.device-info';
import { GetCompanyDrawerIcon, getUserRoutes } from '../../../navigations/CompanyDashboardNavigator/CompanyDashboardNavigator';
import {GetAdminDrawerIcon, getAdminRoutes} from '../../../navigations/AdminNavigator/AdminNavigator';
import { drawerStyles } from '../../../navigations/CustomDrawerUI';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { DesktopMenu } from './DesktopMenu';
import { MenuItem, MenuItemTabRef } from './types';
import {getRootNavRef} from '../../../navigations/RootNavigator';
import { Notifications } from '../Notifications';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { trpc } from '../../../apiCalls/trpcClient';
import { Spacer } from '../misc/Spacer';


const Header = React.memo(({ containerStyle, routeName, headerMenuAction}: {
    containerStyle?: StyleProp<ViewStyle>,
    altLogoColors?: boolean
    routeName: string,
    headerMenuAction: (()=>void) | undefined,
}) => {
    const navigation = getRootNavRef();

    const [currentActiveTab, setCurrentActiveTab] = useState<string | undefined>(routeName);

    useEffect(() => {
        setCurrentActiveTab(routeName);
    }, [routeName]);

    const styles = useDynamicStyles(dynamicStyles, { minWidth: 375, maxWidth: 1440 });

    const userModalDropdownRef = useRef<ModalDropdown>(null);
    const companyModalDropdownRef = useRef<ModalDropdown>(null);

    const userMenuChevronRef = useRef<MB_AnimatedChevronRef>(null);
    const companyChevronRef = useRef<MB_AnimatedChevronRef>(null);

    const refMapping = useMemo<Record<string,MenuItemTabRef>>(() => {
        return {
            Company: {
                chevron: companyChevronRef,
                modal: companyModalDropdownRef,
            },
        };
    }, [companyChevronRef]);


    const {signOut, currentUserData} = useSignedInContext();
    const { mutate: sendDummyNotification } = trpc.notification.dev.createDummyNotification.useMutation();


    const {currentCompanyId,
              returnToGlobalDashboard,
              currentCompanyName,
              currentCompanyLogoUrl,
          clear} = useCurrentCompanyContext();
    const isTTEmployee = useMemo(()=> {
        if (!currentUserData) {return false;}
        const role = currentUserData.role;
        return role === UserRole.admin;
    }, [currentUserData]);

    const logout = () => {
        userModalDropdownRef.current?.hide();
        setTimeout(() => {
            clear();
            signOut();
        }, 400);

    };

    const toggleNavMenu = () => {
        if (headerMenuAction) {
            headerMenuAction();
        }
    };

    const LogoComponent = useMemo(() => {
        return (
            <TouchableOpacity disabled={true}
                              onPress={() => {}}
                              style={styles.logoContainer}
            >
                <DisplayLogo
                    isTempTrack={!currentCompanyId}
                    logoUrl={currentCompanyLogoUrl}
                    companyName={currentCompanyName}
                    svgSize={146}
                    width={146}
                    ignoreName={true}
                />
            </TouchableOpacity>
        );
    }, [currentCompanyLogoUrl, currentCompanyName, styles.logoContainer, currentCompanyId]);

    const initials = useMemo(() => {
        if (!currentUserData) {return '';}
        if (currentUserData.lastName) {
            return `${currentUserData.firstName[0].toUpperCase()}${currentUserData.lastName[0].toUpperCase()}`;
        } else {
            return `${currentUserData.firstName[0].toUpperCase()}`;
        }

    }, [currentUserData]);

    const companyNavMenuItems = useMemo<MenuItem[]>(() => {
        if (!currentUserData) {return [];}
        const routesForUser = getUserRoutes(currentUserData.role);
        return routesForUser.map((i) => {
            return { tab: i.routeName,
                     label: i.label,
                     icon: ()=>GetCompanyDrawerIcon(i.routeName, currentActiveTab === i.routeName),
                subTabs: i.subRoutes ? i.subRoutes.map((subRoute) => {
                    return { tab: subRoute.routeName,
                        label: subRoute.label,
                        isSubItem: true,
                        icon: ()=>GetCompanyDrawerIcon(subRoute.routeName, currentActiveTab === i.routeName),
                    };
                }) : undefined,


            };
        });
    }, [currentUserData, currentActiveTab]);

    const adminNavMenuItems = useMemo<MenuItem[]>(() => {
        if (!currentUserData) {return [];}
        const routesForUser = getAdminRoutes();
        return routesForUser.map((i) => {
            return { tab: i.routeName,
                label: i.name,
                icon: ()=>GetAdminDrawerIcon(i.routeName, currentActiveTab === i.routeName),
            };
        });

    }, [currentActiveTab, currentUserData]);

    const navMenuItems = useMemo(() => {
        if (!currentCompanyId) {
            return adminNavMenuItems;
        }
        return companyNavMenuItems.map((item:MenuItem) => {
            if (refMapping[item.tab]) {
                item.tabRef = refMapping[item.tab];
            }
            return item;
        });
    }, [currentCompanyId, companyNavMenuItems, adminNavMenuItems, refMapping]);


    const navMenuOnPress = useCallback((item) => {
        if (!navigation) {return;}

        if (item.isSubItem) {
            companyChevronRef.current?.setToInitialRotation();
        }
        navigation.navigate(item.tab);
    }, [navigation]);

    const isTabActive = useCallback((tab: MenuItem) => {
        if (tab.subTabs) {
            return tab.subTabs.some(subTab => subTab.tab === currentActiveTab);
        }
        return currentActiveTab ? currentActiveTab === tab.tab : false;
    }, [currentActiveTab]);


    const renderMenuItem = useCallback((item, index, isSubItem = false) => {
        if (item?.subTabs && !isMobileApp) {
            const subs = item.subTabs.map((subItem:MenuItem) => subItem.tab);
            const isActive = currentActiveTab ? subs.includes(currentActiveTab) : false;
            const iconProps = {
                color: isActive ? COLORS.drawerActiveIcon : undefined,
            };
            return (
                <ModalDropdown
                               options={item.subTabs}
                               key={index + item.tab + currentActiveTab}
                               ref={item.tabRef.modal}


                    renderRow={(subItem:MenuItem, subIndex:number) => {
                        return renderMenuItem(subItem, subIndex, true);
                   }}
                    onDropdownWillHide={() => {
                        item?.tabRef.chevron?.current?.setToInitialRotation();
                    }}
                               onDropdownWillShow={() => {
                                   item?.tabRef.chevron?.current?.setToFinalRotation();}}
                    children={
                    <View style={{
                        borderRadius: 8,
                        paddingHorizontal: 8,
                        marginVertical: isMobileApp ? 8 : 0,
                        marginRight: isMobileApp ? 0 : 16,
                        backgroundColor: isActive && isMobileApp ? appColors.gray100 : undefined,
                    }}>
                        <View style={{flexDirection: 'row', alignItems:'center'}} >
                            {item.icon(iconProps)}
                            <Text style={[isActive ? drawerStyles.activeDrawerLabelStyle : drawerStyles.drawerLabelStyle]}>{item.label}</Text>
                            <MB_AnimatedChevron ref={item.tabRef.chevron}
                                                chevronColor={COLORS.textPrimary}
                                                chevronSize={12}
                                                containerStyle={{marginLeft: 'auto'}}/>
                        </View>
                    </View>
                    }
                />

            );
        }
        const isActive = currentActiveTab ? currentActiveTab === item.tab : false;
        const iconProps = {
            color: isActive ? COLORS.drawerActiveIcon : undefined,
        };
        return (
            <MB_OnHoverWrapper key={index}
                                 wrapperStyle={{
                                      borderRadius: isSubItem ? 0 : 8,
                                      paddingHorizontal: isSubItem ? 16 : 8,
                                      marginVertical: isMobileApp ? 8 : 0,
                                      marginRight: isMobileApp ? 0 : 16,
                                      width: isSubItem ? '100%' : 'auto',
                                      backgroundColor: isActive && isMobileApp ? appColors.gray100 : undefined,
                                 }}
                               onHoverWrapperStyleModifier={{backgroundColor: appColors.gray100 }}
                               onPress={()=> {navMenuOnPress(item);}}
            >
            <View  style={{flexDirection: 'row', alignItems:'center'}} >
                {item.icon(iconProps)}
                <Text style={[isActive ? drawerStyles.activeDrawerLabelStyle : drawerStyles.drawerLabelStyle]}>{item.label}</Text>
                {item?.subTabs && <MB_AnimatedChevron ref={item?.tabRef} chevronColor={COLORS.textPrimary} chevronSize={12}/>}
            </View>
            </MB_OnHoverWrapper>

        );
}, [currentActiveTab, navMenuOnPress]);

    const renderUserDropdown = (option: string, _index: number) => {
        // console.log('renderUserDropdown')
        return (
            <View key={_index + option}>
                <View style={{ flexDirection: 'row' }}>
                    <View style={[headerStyles.profileIconContainerMobile, {
                        backgroundColor: applyTTStyles(isTTEmployee).profileContainerBackground,
                        width: 38,
                        height: 38,
                    }]}>
                        <Text
                            style={[headerStyles.initials, { color: applyTTStyles(isTTEmployee).profileContainerText, fontSize: 16 }]}>{initials}</Text>
                    </View>
                    <View style={headerStyles.userNameBlock}>
                        <Text numberOfLines={1}
                            style={headerStyles.userNameText}>{`${currentUserData?.firstName} ${currentUserData?.lastName}`}</Text>
                        <Text numberOfLines={1}
                            style={headerStyles.roleText}>{currentUserData?.role ? toUserRoleLabel(currentUserData?.role) : ''}</Text>
                    </View>

                </View>
                <OutlinedButton title={'Logout'}
                                action={logout}
                                leftIcon={<LogoutIconSvg color={COLORS.dangerTextColor} />}
                                textStyles={mbTextStyles([
                                    { color: COLORS.dangerTextColor, fontSize: 16 },
                                ])}
                                extraStyles={{ borderColor: COLORS.dangerTextColor, marginVertical: 24 }}
                />

                {(isTTEmployee && currentCompanyId) ? <TouchableOpacity onPress={()=> {
                    returnToGlobalDashboard();
                }}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginBottom: 16}}>
                        <MB_AnimatedChevron ref={null}
                                            startingRotation={90}
                                            chevronSize={12}
                                            chevronColor={COLORS.textPrimary}
                                            containerStyle={{ marginRight: 8 }} />

                        <Text style={headerStyles.logoutText}>Back to global dashboard</Text>
                    </View>
                </TouchableOpacity> : null}
            </View>
        );
    };

    const NavMenuDesktop = useMemo(() => {
        return (
            <DesktopMenu items={navMenuItems}
                              currentActiveTab={currentActiveTab}
                              tabIsActive={isTabActive}
                              tabOnClick={navMenuOnPress}
                              // openSubTab={openSubTab}

            />
        );
    },[navMenuItems, currentActiveTab, isTabActive, navMenuOnPress]);


    if (!currentUserData) {return null;}
    return (
        <View style={[styles.container, containerStyle]}>
            {isMobileApp ? <TouchableOpacity style={{justifyContent: 'center', alignItems: 'center'}} onPress={toggleNavMenu}>
                <View style={[headerStyles.menuButton]}>
                    <Feather name={'menu'} color={COLORS.textPrimary} size={24} />
                </View>
            </TouchableOpacity> : null}
            {LogoComponent}
            {!isMobileApp ? NavMenuDesktop : null}
            <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <ModalDropdown
                    ref={userModalDropdownRef}
                    dropdownStyle={headerStyles.modalContentContainer}
                    onSelect={() => false}
                    renderSeparator={() => null}
                    accessible={false}
                    options={['user']}
                    renderRow={renderUserDropdown}

                >
                    <View style={[headerStyles.menuButton, headerStyles.userMenu, {width: isMobileApp ? 48 : 'auto'}]}>
                        <UserButtonContent userName={currentUserData ? {initials, firstName: currentUserData.firstName, lastName: currentUserData?.lastName} : undefined}
                                           isTTEmployee={isTTEmployee}
                                           role={currentUserData?.role ? toUserRoleLabel(currentUserData?.role) : ''}
                                           ChevronComponent={<MB_AnimatedChevron ref={userMenuChevronRef}
                                                                                 chevronSize={14}
                                                                                 chevronColor={COLORS.textPrimary}
                                                                                 containerStyle={{marginHorizontal: 8}}/>}
                        />
                    </View>
                </ModalDropdown>
                <Spacer x={16}/>
                {process.env.NODE_ENV === 'development' ? <MB_Button title={'DN'}
                                                                    onPress={()=>sendDummyNotification({
                                                                        event: NotificationEvent.NewChecklist,
                                                                    })}/>
                    : null}
                <Notifications navigation={navigation}/>
            </View>


        </View>

    );
});

export const UserButtonContent = ({
    userName,
    role,
    isTTEmployee,
    ChevronComponent,
}: {
    userName: {
        initials: string,
        firstName: string,
        lastName: string
    } | undefined,
    role: string | undefined,
    isTTEmployee: boolean,
    ChevronComponent: React.ReactNode

})=> {
    if (isMobileApp) {
        return (
            <>
                <View style={[headerStyles.profileIconContainerMobile, {backgroundColor: applyTTStyles(isTTEmployee).profileContainerBackground}]}>
                    <Text style={[headerStyles.initials, {color: applyTTStyles(isTTEmployee).profileContainerText}]}>{userName?.initials ?? ''}</Text>
                </View>
                {ChevronComponent}
            </>
        );
    } else {
        return (
            <View style={[headerStyles.profileIconContainer, {
                backgroundColor: applyTTStyles(isTTEmployee).profileContainerBackground,
            }]}>
                <View style={{marginHorizontal: 8, alignItems: 'flex-start'}}>
                    <Text numberOfLines={1} style={headerStyles.userNameText}>{`${userName?.firstName} ${userName?.lastName}`}</Text>
                    <Text style={headerStyles.roleText}>{role}</Text>
                </View>
                {ChevronComponent}
            </View>
        );

    }
};

const pageHeaderHeight = 66;

export { Header, pageHeaderHeight };

const dynamicStyles = DynamicStyleSheet.create({
    container: {
         ...mbShadow({ elevation: 3, color: '#000000', opacity: 0.1, radius: 2, offsetWidth: 1, offsetHeight: 1 }),
        width: '100%',
        alignSelf: 'stretch',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: {
            xs: 'space-between',
            m: 'space-between',
        },
        backgroundColor: COLORS.backgroundPrimary,
        minHeight: pageHeaderHeight,
        height: pageHeaderHeight,
        paddingHorizontal: [10, 24],
    },
    logoContainer: {

        justifyContent: 'center',
        alignItems: 'center',
        marginHorizontal: {
            xs: 16,
            m: 0,
        },
    },
});
