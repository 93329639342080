import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { DisplayLogo } from '../helperComponents/misc/DisplayLogo';
import { Spacer } from '../helperComponents/misc/Spacer';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { BOX_SHADOW } from '../../utils/styleUtils';
import { appColors, COLORS } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';

const Support = () => {
    return (
        <View style={styles.container}>

            <View style={[styles.containerWrapper, styles.containerPlatformStyles]}>
                <DisplayLogo isTempTrack={true} svgSize={146} width={150}/>
                <Spacer y={20} />
                <Text style={styles.text}>If you have any questions please contact our support team.</Text>
                <Spacer y={20} />
                <Text style={styles.email}>dev@temptrackapp.com</Text>
            </View>
        </View>
    );
};

export { Support };


const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: COLORS.backgroundSecondary,
    },

    containerWrapper: {
        width: '100%',
        paddingHorizontal: 28,
        alignItems: 'center',
        justifyContent: 'center',

    },
    containerPlatformStyles: mbPlatformStyle({
        web: {
            maxWidth: 442,
            ...BOX_SHADOW,
            backgroundColor: COLORS.backgroundPrimary,
            paddingVertical: 31,
            paddingHorizontal: 28,
            borderRadius: 7,
        },

    }),

    text: mbTextStyles([
        textStyles.Muli_16_400,
    ]),

    email: mbTextStyles([
        textStyles.Muli_16_400, {
        fontSize: 18,
            color: appColors.primary600,
        },
    ]),
});
