import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import {
    CreateEmployeeInput,
    EditEmployeeInput,
    EmployeePosition,
    EmployeePositionType,
    EmployeeStatus,
    toEmployeePositionLabel,
    toEmployeePositionTypeLabel,
    UserRole,
} from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputRef,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { DefaultSwitch } from '../../../helperComponents/misc/DefaultSwitch';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { useEmail } from '../../../../hooks/useEmail';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { useAddress } from '../../../../hooks/useAddress';
import { ConditionalFields, useConditionalFields } from '../../../../hooks/useConditionalFields';
import { useRegions } from '../../../../hooks/useRegions';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { utils } from '../../../../utils/utils';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { envs } from '../../../../../env';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    firstName = 'firstName',
    lastName = 'lastName',
    branch = 'branch',
    region = 'region',
    position = 'position',
    positionType = 'positionType',
    tempWorksId = 'tempWorksId',
}

const EmployeeForm = (
    {
        isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        employeeId,
        resetSubmitError,
        editNotAllowed,
        showTimeline,
    }: {
    employeeId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data:any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    editNotAllowed: boolean;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {

    const {currentCompanyId} = useCurrentCompanyContext();
    const {getFieldSettings} = useConditionalFields();
    const {currentUserData} = useSignedInContext();

    const lastNameRef = useRef<MB_TextInputRef>(null);

    const addressForm = useAddress({isOptional: false, disabled: editNotAllowed});
    const phoneNumber = usePhoneNumber({
        isRequired: true,
        disabled: editNotAllowed,
        label: 'Cell phone number',
    });

    const email = useEmail({
        isRequired: false,
        disabled: editNotAllowed,
        nextToFocus: phoneNumber.ref,
    });

    const [position, setPosition] = useState<EmployeePosition | undefined>(undefined);
    const positionOptions = useMemo(() => {
        return Object.values(EmployeePosition).map((p)=> {
            return {label: toEmployeePositionLabel(p), value: p};
        });
    }, []);
    const [positionType, setPositionType] = useState<EmployeePositionType | undefined>(undefined);

    const positionTypeOptions = useMemo(() => {
        return Object.values(EmployeePositionType).map((p)=> {
            return {label: toEmployeePositionTypeLabel(p), value: p};
        });
    }, []);

    const [initialIsDone, setInitialIsDone] = useState(false);

    const [tempWorksId, setTempWorksId] = useState<string | undefined>(undefined);
    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');

    const [status, setStatus] = React.useState<EmployeeStatus>(EmployeeStatus.active);

    const regionOptions = getFieldSettings(ConditionalFields.region, {
        regionId: undefined,
        regionName: undefined,
    });
    const [regionId, setRegionId] = React.useState<string | undefined>(regionOptions.allowedToEdit ? undefined : regionOptions.defaultValue?.regionId);
    const {regionDropdownOptions} = useRegions({disabled: !regionOptions.allowedToView});

    const [branchId, setBranchId] = React.useState<string | undefined>(undefined);

    const {data: recordData, isLoading} = trpc.company.employee.getById.useQuery({employeeId: employeeId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isEditing && !!employeeId && !!currentCompanyId,
    });

    const {data: branchOptions, isLoading: gettingBranches} = trpc.company.branch.getDropdown.useQuery({companyId: currentCompanyId ?? '', regionId: regionId ?? ''}, {
        enabled: !!currentCompanyId && !!regionId,
    });

    const branchDropdownOptions = useMemo(() => {
        if (!branchOptions || !branchOptions?.items || gettingBranches) {
            if (currentUserData?.role === UserRole.recruiter) {
                return [{label: currentUserData?.branchName, value: currentUserData?.branchId}];
            }
            return [];
        }
        return branchOptions.items.map((branch)=> {
            return {label: branch.name, value: branch.id};
        });
    }, [branchOptions, gettingBranches, currentUserData?.branchId, currentUserData?.branchName, currentUserData?.role]);

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.employeeData) {
            const initialData = recordData.employeeData;
            setFirstName(initialData.firstName);
            setLastName(initialData.lastName);
            setStatus(initialData.status);
            if (initialData?.email){
                email.setInitialEmail(initialData.email);
            }


            phoneNumber.setInitialValue(initialData.phoneNumber);
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: initialData.address?.line1,
                city: initialData.address?.city,
                state: initialData.address?.state,
                zipCode: initialData.address?.zipCode,
            }));
            addressForm.setCity(initialData.address?.city ?? '');
            addressForm.setState(initialData.address?.state ?? '');
            addressForm.setZipCode(initialData.address?.zipCode ?? '');
            addressForm.setAddress(initialData.address?.line1 ?? '');
            addressForm.setAddress2(initialData.address?.line2 ?? '');
            setBranchId(initialData.branchId);
            setRegionId(initialData.regionId);
            setPosition(initialData.position);
            setPositionType(initialData.positionType);
            setInitialIsDone(true);
            setTempWorksId(initialData.tempWorksId);
        }

    }, [recordData, phoneNumber, email, addressForm, initialIsDone]);

    const fieldsMap = useMemo<{
        [key in InputTypes]: {
            value: any;
        };
    }>(()=> {
        const base = {
            [InputTypes.firstName]: {value: firstName},
            [InputTypes.lastName]: {value: lastName},
            [InputTypes.branch]: {value: branchId},
            [InputTypes.region]: {value: regionId},
            [InputTypes.position]: {value: position},
            [InputTypes.positionType]: {value: positionType},
            [InputTypes.tempWorksId]: {value: tempWorksId},
        };
        return base;
    }, [firstName, lastName, branchId, regionId, position, positionType, tempWorksId]);

    const {data: timeline, isLoading: gettingTimeline} = trpc.company.employee.getTimeline.useQuery({employeeId: employeeId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!employeeId && !!currentCompanyId,
    });

    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
        [InputTypes.firstName]: '',
        [InputTypes.lastName]: '',
        [InputTypes.branch]: '',
        [InputTypes.region]: '',
        [InputTypes.position]: '',
        [InputTypes.positionType]: '',
        [InputTypes.tempWorksId]: '',
    });


    const isEmpty = (value: string | undefined) => {
        if (!value) {
            return true;
        }
        return value.trim().length === 0;
    };

    const requiredFields = useMemo(()=> {
        const required = [InputTypes.firstName, InputTypes.lastName, InputTypes.branch, InputTypes.region, InputTypes.position, InputTypes.positionType];
        return required;
    }, []);

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        //check required fields
        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });

        setFormErrorMessages(errors);
        const phoneNumberIsValid = phoneNumber.validate();
        const addressIsValid = addressForm.validateAddress();
        const isValidEmail = email.validateEmail();

        return isValid && phoneNumberIsValid && addressIsValid && isValidEmail;
    },[fieldsMap, formErrorMessages, setFormErrorMessages, requiredFields, phoneNumber, addressForm, email]);

    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);


    const createRecord = () => {
        if (!branchId || !currentCompanyId || !positionType || !position) {
            return;
        }

        const data: CreateEmployeeInput = {
            companyId: currentCompanyId,
            firstName,
            lastName,
            email: email?.value?.length === 0 ? undefined : email.value,
            phoneNumber: phoneNumber.value,
            status,
            address: addressForm.values,
            branchId: branchId,
            position: position,
            positionType: positionType,
            tempWorksId: tempWorksId && tempWorksId.length > 0 ? tempWorksId : undefined,
        };
        action(data);
    };

    const editRecord = () => {
        if (!branchId || !currentCompanyId) {
            //just for TS. We already validate this in validateForm
            return;
        }
        const data: EditEmployeeInput = {
            employeeId: employeeId as string,
            firstName,
            lastName,
            email: email.value.length > 0 ? email.value : undefined,
            phoneNumber: phoneNumber.value,
            status,
            companyId: currentCompanyId as string,
            address: addressForm.values,
            branchId: branchId,
            position: position,
            positionType: positionType,
            tempWorksId: tempWorksId,
        };
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {
            createRecord();

        }
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:InputTypes.firstName | InputTypes.lastName | InputTypes.tempWorksId)=> {
        const fieldStrings = {
            [InputTypes.firstName]: {title: 'First Name', placeholder: 'Enter first name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.firstName)},
            [InputTypes.lastName]: {title: 'Last Name', placeholder: 'Enter last name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.lastName)},
            [InputTypes.tempWorksId]: {title: 'TempWorks ID', placeholder: 'Enter TempWorks ID', textContentType: 'name', showRequiredAsteriks: false},
        };


        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
            disable: editNotAllowed,
            preventDisabledStyleChange: true,
        };
    }, [fieldsMap, requiredFields, editNotAllowed]);

    const cleanErrorsOnFieldChange = useCallback((field: InputTypes) => {
        resetSubmitError();
        setFormErrorMessages((prevErrors)=> {
            return {...prevErrors, ...{[field]: ''}};
        });
    }, [resetSubmitError, setFormErrorMessages]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                      isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1, zIndex: 4000}}>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.firstName)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.firstName)}
                                                                   onChangeText={(newValue)=> {
                                                                       cleanErrorsOnFieldChange(InputTypes.lastName);
                                                                       setFirstName(newValue);
                                                                   }}
                                                                   nextToFocus={lastNameRef}
                                      />}
                        />
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.lastName)}
                                      inputComponent={<MB_TextInput {...getInputProps(InputTypes.lastName)}
                                          onChangeText={(newValue)=> {
                                                cleanErrorsOnFieldChange(InputTypes.lastName);
                                                setLastName(newValue);
                                          }}
                                                                    MB_Ref={lastNameRef}
                                                                    nextToFocus={email.ref}
                                      />}
                        />
                    </View>
                </View>
                <View style={[recordModalContentStyles.row, {zIndex: 4000}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={regionOptions.allowedToView ? regionDropdownOptions : regionOptions.defaultValue ? [{
                            label: regionOptions.defaultValue.regionName,
                            value: regionOptions.defaultValue.regionId,
                        }] : []}
                                           value={regionId}
                                           onValueChange={setRegionId}
                                           placeholder={'Select Region'}
                                           title={'Assign Region'}
                                           containerStyle={{width: '100%', zIndex: 4000}}
                                           disabled={!regionOptions.allowedToView || editNotAllowed}
                                           disableInteractions={!regionOptions.allowedToView || editNotAllowed}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}

                        />
                    } errorMessage={fieldError(InputTypes.region)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 4000}}}
                                  subText={(!regionOptions.allowedToView || editNotAllowed) ?  'You can only invite employees to your region' : undefined}
                    />

                   <WrappedInput inputComponent={
                        <MB_DropDownPicker items={branchDropdownOptions}
                                           value={branchId}
                                           onValueChange={setBranchId}
                                           placeholder={'Select Branch'}
                                           title={'Assign Branch'}
                                           containerStyle={{width: '100%'}}
                                           disabled={editNotAllowed}
                                           disableInteractions={editNotAllowed}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}
                        />
                    } errorMessage={fieldError(InputTypes.branch)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 3500}}}/>

                </View>
                <View style={[recordModalContentStyles.row, {zIndex: 3000}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={positionOptions}
                                         value={position}
                                         onValueChange={setPosition}
                                         placeholder={'Select a position'}
                                         title={'Position'}
                                         containerStyle={{width: '100%'}}
                                         disabled={editNotAllowed}
                                           disableInteractions={editNotAllowed}
                                         showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}

                        />
                    }
                                  errorMessage={fieldError(InputTypes.position)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 3000}}}
                    />

                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={positionTypeOptions}
                                         value={positionType}
                                         onValueChange={setPositionType}
                                         placeholder={'Select a position type'}
                                         title={'Position type'}
                                         containerStyle={{width: '100%'}}
                                           zIndex={2500}
                                         disabled={editNotAllowed}
                                         disableInteractions={editNotAllowed}
                                         showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}
                        />
                    } errorMessage={fieldError(InputTypes.positionType)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 2500}}}
                    />

                </View>
                <View style={[recordModalContentStyles.row,  {zIndex: 2000}]}>
                    <View style={[recordModalContentStyles.rowItem, {flex: 1, zIndex: 2000}]}>
                        <WrappedInput errorMessage={addressForm.error}
                                      inputComponent={<View style={{ width: '100%'}}>
                                          <MB_LocationPicker
                                              key={initialIsDone.toString()}
                                              apiKey={envs.LOCATION_PICKER_API}
                                              title={'Address'}
                                              containerStyle={{width: '100%'}}
                                              titleStyle={{color: COLORS.textPrimary}}
                                              disabled={editNotAllowed}
                                              disableInteractions={editNotAllowed}
                                              showRequiredAsteriks={true}
                                              value={addressForm.fullAddress}
                                              onSelect={addr => {
                                                  addressForm.setAddress(utils.getStreetFromFullAddress(addr?.text, addr?.city));
                                                  addressForm.setCity(addr?.city ?? '');
                                                  addressForm.setState(addr?.state ?? '');
                                                  addressForm.setZipCode(addr?.postalCode ?? '');
                                              }}
                                              dropDownDirection={'BOTTOM'}
                                              listMode={'SCROLLVIEW'}

                                          />
                                      </View>}
                        />
                    </View>

                    <View style={[recordModalContentStyles.rowItem, isMobileApp ? undefined : {width: 200, marginLeft: 16}]}>
                        {addressForm.addressSecondLineField}
                    </View>

                </View>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>
                <View style={recordModalContentStyles.row}>
                    <WrappedInput errorMessage={fieldError(InputTypes.tempWorksId)}
                                  inputComponent={<MB_TextInput {...getInputProps(InputTypes.tempWorksId)}
                                                                onChangeText={(newValue)=> {
                                                                    cleanErrorsOnFieldChange(InputTypes.tempWorksId);
                                                                    setTempWorksId(newValue);
                                                                }}
                                  />}
                    />
                </View>
                <View style={recordModalContentStyles.row}>
                    {editNotAllowed ? null : <View style={[{justifyContent: 'flex-start'}]}>
                    <Text style={recordModalContentStyles.inputLabel}>Status</Text>

                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <DefaultSwitch isToggled={status === EmployeeStatus.active}
                                       onToggle={(newValue)=> {
                                           setStatus(newValue ? EmployeeStatus.active : EmployeeStatus.inactive);}}
                                       shouldUseTTStyles={false}
                                       label={status}
                                       disabled={editNotAllowed}

                        />
                    </View>

                </View>}
                </View>
            </View>


            <View style={{zIndex: 1}}>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                {editNotAllowed ? <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow, {justifyContent: 'center'}]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Close'}
                                   onPress={cancelAction}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                        />
                    </View>
                    </View> :
                    <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={[recordModalContentStyles.rowItem]}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add Employee'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                        />
                    </View>
                        {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={editNotAllowed ? 'Close' : 'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>}
            </View>


        </ScrollView>
    );
};

export { EmployeeForm };
