import React from 'react';
import { IconButton } from './IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { appColors } from '../../../constants/colors';
import AntDesign from 'react-native-vector-icons/AntDesign';

const EditIconButton = ({action}: {action: () => void}) => {
    return  <IconButton icon={<Entypo name={'edit'} size={20} color={appColors.blue700}/>} action={action}/>;
};

const ViewIconButton = ({action}: {action: () => void}) => {
    return  <IconButton icon={<Entypo name={'eye'} size={20} color={appColors.blue700}/>} action={action}/>;
};

const DeleteIconButton = ({action}: {action: () => void}) => {
    return <IconButton icon={<AntDesign name={'delete'} size={20} color={appColors.red700}/>} action={action}/>;
};

export {EditIconButton, DeleteIconButton, ViewIconButton};
