export {
    EmployeePositionType as WorkOrderPositionType,
    EmployeePosition as WorkOrderPosition,
} from '../employee';

export enum WorkOrderStatus {
    open = 'open',

    /**
     * 1st checklist is active.
     */
    inProgress = 'inProgress',

    /**
     * WO duration ended.
     */
    completed = 'completed',
}

export enum WorkOrderPayFrequency {
    weekly = 'weekly',
    biweekly = 'biweekly',
    monthly = 'monthly',
}

export enum WorkOrderShift {
    morning = 'morning',
    afternoon = 'afternoon',
    night = 'night',
}

export enum WorkOrderPPE {
    steelToeBoots = 'steelToeBoots',
    slipResistantShoes = 'slipResistantShoes',
    safetyVest = 'safetyVest',
    gloves = 'gloves',
    other = 'other',
}

export enum WorkOrderJobsiteEnvironment {
    food = 'food',
    industrial = 'industrial',
    produce = 'produce',
    machinery = 'machinery',
    apparel = 'apparel',
    freezer = 'freezer',
    other = 'other',
}

export enum WorkOrderLanguage {
    bilingual = 'bilingual',
    english = 'english',
    spanish = 'spanish',
    other = 'other',
}

export enum EmployeeWorkOrderType {
    active = 'active',
    previous = 'previous',
    offer = 'offer',
}
