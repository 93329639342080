import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { StyleProp, ViewStyle } from 'react-native';

const BellIconSvg = React.memo((props: { size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <Svg
            width={props.size ?? 18}
            height={props.size ? props.size * (20 / 18) : 20}
            viewBox="0 0 18 20"
            fill="none"
            {...props}
        >
            <Path
                d="M9 .4a7.2 7.2 0 00-7.2 7.2v4.304l-.848.848A1.2 1.2 0 001.8 14.8h14.4a1.201 1.201 0 00.849-2.048l-.849-.848V7.6A7.2 7.2 0 009 .4zm0 19.2A3.6 3.6 0 015.4 16h7.2A3.6 3.6 0 019 19.6z"
                fill="#6B7280"
            />
        </Svg>
    );
});

export {BellIconSvg};
