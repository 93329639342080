import { z } from 'zod';
import {
    AddressSchema,
    EmailSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';
import { ClientFilterMap } from '../../../filter';

export const CreateClientInputSchema = z.object({
    companyId: ObjectIdSchema,
    branchId: ObjectIdSchema,
    name: StringSchema,
    email: EmailSchema,
    workersCompensationCode: StringSchema.array(),
    phoneNumber: StringSchema,
    address: AddressSchema,
});

export const GetClientsPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ClientFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const ListClientsInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ClientFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const GetClientByIdInputSchema = z.object({
    clientId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetClientsForDropdownInputSchema = z.object({
    companyId: ObjectIdSchema,
    regionId: ObjectIdSchema.optional(),
    branchId: ObjectIdSchema.optional(),
});

export const GetClientsForDropdownByBranchIdInputSchema = z.object({
    companyId: ObjectIdSchema,
    branchId: ObjectIdSchema,
});

export const EditClientInputSchema = CreateClientInputSchema.partial().extend({
    clientId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const DeleteClientInputSchema = z.object({
    clientId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const ResendClientInviteInputSchema = z.object({
    clientId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetClientTimelineInputSchema = z.object({
    clientId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
