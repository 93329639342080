import React, { useCallback, useEffect, useMemo } from 'react';
import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer';
import {Companies} from '../../components/screens/Admin/Companies/Companies';
import {Users} from '../../components/screens/Admin/Users/Users';
import { useCurrentCompanyContext } from '../../context/CurrentCompanyContext';
import {PaginationRouteParams} from '../../hooks/usePagination';
import { applyTTStyles, getColors } from '../../constants/colors';
import { BriefcaseIconSvg } from '../../resources/svgComponents/BriefcaseIconSvg';
import { UserIconSvg } from '../../resources/svgComponents/UserIconSvg';
import { UserDataForClient } from '@temptrack/business';
import { getCurrentRoute, isActiveRoute } from '../utils';
import { CustomDrawerUI } from '../CustomDrawerUI';
import { useSignedInContext } from '../../context/SignedInContext';
import { BOX_SHADOW } from '../../utils/styleUtils';
import { AdminViewRoutes, SharedRouteParams } from '../types';
import { trpc } from '../../apiCalls/trpcClient';
import { getVersionNumber } from '../../utils/getVersionNumber/getVersionNumber';
import { LoadingPage } from '../../components/helperComponents/LoadingPage';



export type AdminNavigatorParamList = {
    [AdminViewRoutes.users]: PaginationRouteParams & {userId?: string, changePassword?: boolean} & SharedRouteParams,
    [AdminViewRoutes.companies]: PaginationRouteParams & {companyId?: string} & SharedRouteParams,
}

const Drawer = createDrawerNavigator<AdminNavigatorParamList>();

const COLORS = getColors();
export function GetAdminDrawerIcon(routeName: AdminViewRoutes, focused: boolean) {
    const iconProps = {
        color: focused ? COLORS.drawerActiveIcon : undefined,
        containerStyle: {marginRight: 4},
    };
    switch (routeName) {
        case AdminViewRoutes.users:
            return <UserIconSvg {...iconProps} />;
        case AdminViewRoutes.companies:
            return <BriefcaseIconSvg {...iconProps} />;
        default:
            return null;

    }
}

type DrawerProps = DrawerContentComponentProps & {
    currentUserData: UserDataForClient | undefined,
    serverVersion: string | undefined,
};

type Tab = {
    name: string,
    routeName:  AdminViewRoutes,
    label: string,
}

export const getAdminRoutes = (): Tab[] => {
    return [
        {
            name: 'Users',
            routeName: AdminViewRoutes.users,
            label: 'Users',
        },
        {
            name: 'Companies',
            routeName: AdminViewRoutes.companies,
            label: 'Companies',
        },
    ];
};

const versions = getVersionNumber();

const CustomDrawerContent = (props: DrawerProps)=> {
    const { navigation, currentUserData} = props;

    const activeRoute = getCurrentRoute(props.navigation.getState());

    const tabs = useMemo<{
        name: string,
        routeName: AdminViewRoutes,
        isExpanded?: boolean,
        onPress?: () => void
    }[]>(() => {
        if (!currentUserData) {return [];}

        return getAdminRoutes().map((route) => {
            return {
                name: route.name,
                routeName: route.routeName,
            };
        });
    }, [currentUserData]);


    const navigateTo = useCallback((routeName: AdminViewRoutes) => {
        navigation.navigate('AdminNavigator', {screen: routeName});

    }, [navigation]);

    const tabIsActive = useCallback((tab) => {
        return isActiveRoute(tab, activeRoute);
    }, [activeRoute]);

    return (
        <CustomDrawerUI tabs={
                            tabs.map((tab) => {
                                const isActive = tabIsActive(tab);
                                return {
                                    ...tab,
                                    isActive: isActive,
                                    icon: GetAdminDrawerIcon(tab.routeName, isActive),
                                    onPress: () => {
                                        navigateTo(tab.routeName);
                                    },
                                };
                            })
                        }
                        versions={{...versions, ...{
                                serverVersion: props.serverVersion,
                        }}}
        />
    );
};

const AdminNavigator = () => {
    const {setCurrentCompanyId} = useCurrentCompanyContext();

    const { data: serverVersion} = trpc.util.getVersion.useQuery(undefined, { retry: 2, refetchOnWindowFocus: false });

    useEffect(() => {
        setCurrentCompanyId(undefined);
    }, [setCurrentCompanyId]);

    const { currentUserData} = useSignedInContext();
    const drawerContent = useCallback((props: DrawerContentComponentProps) =>
        <CustomDrawerContent {...props}
                             serverVersion={serverVersion?.version}
                             currentUserData={currentUserData}
        />, [currentUserData, serverVersion]);

    if (!currentUserData) {
        return <LoadingPage  barColor={applyTTStyles(true).loaderColor}/>;
    }



    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: 'front',
                drawerStyle: {
                    ...BOX_SHADOW,
                    width: 340,
                },
            }}

            drawerContent={(props)=> drawerContent(props)}
            backBehavior={'history'}
        >
            <Drawer.Screen name={AdminViewRoutes.users} component={Users}/>
            <Drawer.Screen name={AdminViewRoutes.companies} component={Companies}/>
        </Drawer.Navigator>
    );
};

export { AdminNavigator };
