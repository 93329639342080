import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GestureResponderEvent, Text, View } from 'react-native';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import { UIDrawerRightContentWrapper } from '../../../helperComponents/DrawerRightContentWrapper';
import { appColors, applyTTStyles, COLORS } from '../../../../constants/colors';
import { EmployeeJobsProps } from '../../../../typesAndInterfaces/componentProps';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { useGetJobs } from './useGetJobs';
import { JobsTable } from './JobsTable';
import { trpc } from '../../../../apiCalls/trpcClient';
import {
    EmployeeAcceptanceStatus,
    EmployeeWorkOrderDataForClient,
    EmployeeWorkOrderType,
    GetEmployeeWorkOrdersCountResponse,
} from '@temptrack/business';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { JobDetails } from './JobDetails';
import { AcceptOrDeclineJob } from './AcceptOrDeclineJob';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { BriefcaseIconSvg } from '../../../../resources/svgComponents/BriefcaseIconSvg';
import { CompanyDashboardRoutes } from '../../../../navigations/types';

const EmployeesJobManagement = ({navigation, route}:EmployeeJobsProps)=> {

    const trpcContext = trpc.useUtils();

    const [refreshState, setRefreshState] = useState(0);

    const {data: amounts, isLoading: amountsIsLoading} = trpc.company.workOrder.employee.getWorkOrderCounts.useQuery();
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [showRecordModal, setShowRecordModal] = useState(!!route?.params?.workorderId);

    const [showActionModal, setShowActionModal] = useState<EmployeeAcceptanceStatus.accepted | EmployeeAcceptanceStatus.declined | undefined>(undefined);

    const [currentRowData, setCurrentRowData] = useState<EmployeeWorkOrderDataForClient | undefined>(undefined);

    const [viewType, setViewType] = useState<EmployeeWorkOrderType>(route.params?.viewMode ?? EmployeeWorkOrderType.active);

    const {jobsData,  PaginationComponent,
              isLoading,
              isPreviousData, fetchNextPage, hasNextPage, setType, invalidatePage} = useGetJobs({navigation, route});

    const {data: jobData, isLoading: recordDataIsLoading} = trpc.company.workOrder.employee.getWorkOrderById.useQuery({workOrderId: route?.params?.workorderId ?? '', checklistId: route?.params?.checklistId ?? ''}, {
        enabled: !!route?.params?.workorderId && route?.params?.workorderId.length > 0 && !!route?.params?.checklistId && route?.params?.checklistId.length > 0 && showRecordModal,
        retry: false,
    });

    const closeModal = useCallback(()=> {
        navigation.setParams({...route.params, ...{workorderId: undefined, checklistId: undefined}});

        setShowRecordModal(false);
        setCurrentRowData(undefined);
    },[navigation, route.params]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            closeModal();
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId, setPosition, setShowMenuForRowId, closeModal]);

    const onViewRecordPressed = useCallback((d: string|undefined) => {
        if (d) {
            setShowMenuForRowId(undefined);
            const record = jobsData?.items?.find((item)=> item.workOrderId === d);
            setCurrentRowData(jobsData?.items?.find((item)=> item.workOrderId === d));
            navigation.navigate(CompanyDashboardRoutes.employeeJobs, {...route.params, ...{workorderId: d, checklistId: record?.checklistId}});
            setShowRecordModal(true);
        } else {
            closeModal();
        }

    }, [closeModal, jobsData?.items, navigation, route.params]);

    useEffect(() => {
        setType(viewType);
    }, [setType, viewType]);

    const switchViewAction = useCallback((type: EmployeeWorkOrderType)=> {
        navigation.navigate(CompanyDashboardRoutes.employeeJobs, {...route.params, ...{viewMode: type}});
        setViewType(type);
    },[setViewType, navigation, route.params]);


    const ToggleViewComponent = useMemo(()=> {
        type Button = {title: string, value: EmployeeWorkOrderType, amountsKey: string};
        const buttons:Button[] = [
            {title: 'Active Assignments', value:  EmployeeWorkOrderType.active, amountsKey: 'activeCount'},
            {title: 'Previous Assignments', value:  EmployeeWorkOrderType.previous, amountsKey: 'previousCount'},
            {title: 'Job Offers', value:  EmployeeWorkOrderType.offer, amountsKey: 'offerCount'},
        ];

        const getLabel = (button:Button)=> {
          if (amountsIsLoading) {
                return `${button.title} (...)`;
          }
            const count:number = amounts?.[button?.amountsKey as keyof GetEmployeeWorkOrdersCountResponse] ?? 0;
            return `${button.title} (${count?.toString() ?? 0})`;
        };
        if (isMobileApp) {
            return (
                <View>
                    <MB_DropDownPicker multiple={false} onValueChange={(v)=>{
                        if (!v) {
                            return;
                        }
                        switchViewAction(v);
                    }}
                                       items={buttons.map((b)=> {
                                           return {label:getLabel(b), value: b.value};
                                       })}
                                       value={viewType}

                    />
                </View>
            );
        }
        return (
            <View style={{flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                {buttons.map((button, index)=> {
                    if (button.value ===  viewType){
                        return <MB_Button key={index} title={getLabel(button)} onPress={()=>switchViewAction(button.value)}

                                          style={{backgroundColor: applyTTStyles(false).primaryButtonBg, borderRadius: 20}}/>;
                    } else {
                        return <OutlinedButton key={index} title={getLabel(button)}
                                               extraStyles={{ borderColor:COLORS.backgroundPrimary, borderRadius: 20}}
                                              action={()=>switchViewAction(button.value)}/>;
                    }
                })}

            </View>
        );
    },[viewType,switchViewAction, amounts, amountsIsLoading]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return (
            <ListViewHeader title={'Jobs Management'}
                            hideAddButton={true}
                            subtitle={'View and manage all your jobs'}
                            buttonText={''}
                            onAddPress={()=>{}}
                            buttonColor={applyTTStyles(false).primaryButtonBg}
                            toggleViewComponent={ToggleViewComponent}
                            // filterComponent={filters.Button}

            />
        );
    },[ToggleViewComponent]);

    const refreshPage = useCallback(()=> {

        trpcContext.company.workOrder.employee.getWorkOrderCounts.invalidate();
        invalidatePage();
        setRefreshState(Date.now());

    } ,[invalidatePage, trpcContext.company.workOrder.employee.getWorkOrderCounts]);

    useEffect(() => {
        if (route.params?.update) {
            navigation.setParams({...route.params, update: false});
            refreshPage();
            if (route.params?.workorderId) {
                setShowRecordModal(true);
            }
        }
    }, [navigation, refreshPage, route.params, route.params?.update, route.params?.workorderId]);

    const {mutate: employeeAction, isLoading: employeeActionIsLoading} = trpc.company.workOrder.employee.acceptOrRejectJob.useMutation({
        onSuccess: (data)=> {

            const text1 = `Job offer ${data?.accepted ? 'accepted' : 'declined'}.`;
            const text2 = data?.accepted ?  'You will be notified when the recruiter adds you to the job checklist.' : 'Thanks, we\'ll notify you for the next available job.';
            const type = data?.accepted ? 'success' : 'info';

            setShowActionModal(undefined);
            navigation.setParams({...route.params, ...{workorderId: undefined, checklistId: undefined}});
            setShowRecordModal(false);
            setShowMenuForRowId(undefined);
            setCurrentRowData(undefined);
            trpcContext.company.workOrder.employee.getWorkOrdersPage.invalidate();
            trpcContext.company.workOrder.employee.getWorkOrderCounts.invalidate();
            invalidatePage();
            setTimeout(()=> {
                mbShowToast({ text1: text1,
                    type: type,
                    text2: text2,
                });
            }, 400);
        },
        onError: ()=> {},
    });

    const menuItems = useMemo(()=> {

        const options = {
            view: {
                title: 'View',
                onPress: () => {onViewRecordPressed(showMenuForRowId);},
            },
            accept: {
                title: 'Accept',
                onPress: () => {
                    setCurrentRowData(jobsData?.items?.find((item)=> item.workOrderId === showMenuForRowId));
                    setShowMenuForRowId(undefined);
                    setShowActionModal(EmployeeAcceptanceStatus.accepted);
                },
            },
            decline: {
                title: 'Decline',
                onPress: () => {
                    setCurrentRowData(jobsData?.items?.find((item)=> item.workOrderId === showMenuForRowId));
                    setShowMenuForRowId(undefined);
                    setShowActionModal(EmployeeAcceptanceStatus.declined);
                },
                textStyle: {color: COLORS.dangerTextColor},
            },
        };
        const base = [options.view];
        if (viewType === EmployeeWorkOrderType.offer) {
            base.push(options.accept);
            base.push(options.decline);
        }
        return base;
    }, [jobsData?.items, viewType, showMenuForRowId, onViewRecordPressed]);

    const JobViewModalCustomHeader = useMemo(()=> {

        return (
            <View style={{
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                paddingVertical: 8,
                paddingHorizontal: 16,
                backgroundColor: appColors.blue100,
                borderRadius: 20,
                alignSelf: 'flex-start',
            }}>
                <BriefcaseIconSvg color={appColors.blue600} containerStyle={{marginRight: 8}}/>
              <Text style={mbTextStyles([textStyles.Inter_16_600, {
                    color: appColors.blue700,
              }])}>{`${jobData?.workOrderData?.workOrderNumber ?? ''} Job Offer Details`}</Text>


            </View>
        );
    },[jobData]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     key={refreshState}
        >
            <ModalWrapper show={!!showActionModal}
                          closeAction={()=> setShowActionModal(undefined)}
                          title={`${showActionModal === EmployeeAcceptanceStatus.accepted ? 'Accept' : 'Decline'} offer?`}
                          maxHeight={300}
                          maxWidth={400}
            >
                { showActionModal ? <AcceptOrDeclineJob actionType={showActionModal}
                                                        woNumber={currentRowData?.workOrderNumber ?? ''}
                                                        action={()=> {
                                                            employeeAction({shortlistId: currentRowData?.checklistId ?? '',
                                                                accept: showActionModal === EmployeeAcceptanceStatus.accepted});
                                                        }}
                                                        cancelAction={()=> {
                                                            setShowActionModal(undefined);
                                                            setShowMenuForRowId(undefined);
                                                            setCurrentRowData(undefined);
                                                        }}
                                                        isLoading={employeeActionIsLoading}
                    />
                    : null}

            </ModalWrapper>
            <ModalWrapper show={showRecordModal}
                          closeAction={closeModal}
                          maxHeight={undefined}
                          titleCustomComponent={JobViewModalCustomHeader}
                          minHeight={400}
            >
                <JobDetails jobData={jobData}
                            isLoading={recordDataIsLoading}
                            action={employeeAction}
                />
            </ModalWrapper>
            <>
                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                                callerX={position.x}
                                                callerY={position.y}
                                                visible={!!showMenuForRowId}
                                                menuItems={menuItems}
                                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}
                {isMobileApp ? undefined : HeaderComponent}

                <JobsTable key={jobsData?.items?.map((item)=> item.workOrderId).join('')}
                           data={jobsData?.items || []}
                           isLoading={jobsData ? (isLoading && !isPreviousData) : isLoading}
                           openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                           fetchMore={hasNextPage ? fetchNextPage : undefined}
                           viewType={viewType}
                           headerComponent={isMobileApp ? HeaderComponent : undefined}
                />

                {isMobileApp ? null : <View style={{minHeight:60}}>
                    {PaginationComponent ? PaginationComponent({totalItems: jobsData?.totalItems, currentItemsLength: jobsData?.items?.length ?? 0}) : null}
                </View>}
            </>
        </UIDrawerRightContentWrapper>
    );
};

export {EmployeesJobManagement};
