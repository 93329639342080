import './src/css/cssLoader';
// Note: Webpack adds these files to ./well-known folder when building and we need to import them somewhere
// otherwise webpack will just ignore the files.
// Also note that for some reason just putting these into public/.well-known was giving us problems because
// assetlinks.json was just showing up as empty json.
import './src/wellKnownForWebpack/apple-app-site-association.json';
import './src/wellKnownForWebpack/assetlinks.json';
import { AppRegistry } from 'react-native';
import { name as appName } from './app.json';
import App from './App';

if (module.hot) {
  module.hot.accept();
}
AppRegistry.registerComponent(appName, () => App);
AppRegistry.runApplication(appName, {
  initialProps: {},
  rootTag: (global as any).document.getElementById('app-root'),
});
