export enum FilterFieldType {
    text = 'text',
    dropdown = 'dropdown',
    datetime = 'datetime',
    date = 'date',
    number = 'number',
    phone = 'phone',
    checkbox = 'checkbox',
}

export enum FilterQueryType {
    isEmpty = 'isEmpty',
    notEmpty = 'notEmpty',
    contains = 'contains',
    notContain = 'notContain',
    equals = 'equals',
    notEqual = 'notEqual',
    isAnyOf = 'isAnyOf',
    isNoneOf = 'isNoneOf',
    before = 'before',
    after = 'after',
    between = 'between',
}
