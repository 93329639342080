import {
    filterPhoneSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '..';
import { FilterFieldType } from '../enums';
import { createFilterMapping } from './helpers';

export const ClientFilterMap = createFilterMapping({
    name: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'firstName',
    },
    regionId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'regionId',
    },
    branchId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'branchId',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
});
