import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const UserIconSvg = React.memo(({color,size, containerStyle}: {
    color?: string, size?: number, containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 20}
                height={size ?? 20}
                viewBox="0 0 20 20"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M19.6 10A9.6 9.6 0 11.401 10a9.6 9.6 0 0119.2 0zm-7.2-3.6a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM10 11.2a6 6 0 00-5.455 3.5A7.184 7.184 0 0010 17.2a7.185 7.185 0 005.456-2.5A6 6 0 0010 11.2z"
                    fill={color ?? '#9CA3AF'}
                />
            </Svg>


        </View>
    );
});

export { UserIconSvg  };
