import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import {UIDrawerRightContentWrapper} from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { useSignedInContext } from '../../../../context/SignedInContext';
import {
    EmployeeDataForClient,
    EmployeePosition, EmployeePositionType,
    FilterFieldType, toEmployeePositionLabel, toEmployeePositionTypeLabel,
    UserRole,
    UserStatus,
} from '@temptrack/business';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { routeParams } from '../../../../constants/urlConstants';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { GestureResponderEvent, Text, View } from 'react-native';
import { EmployeesProps } from '../../../../typesAndInterfaces/componentProps';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import {EmployeesTable} from './TableView';
import { EmployeeForm } from './EmployeeContent';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { utils } from '../../../../utils/utils';
import {useGetEmployees} from './useGetEmployees';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { shouldShowBranchForRole, shouldShowRegionForRole } from '../../../../utils/permissionUtils';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { NotFilterFields } from '../../../../hooks/filters/types';

const COLORS = getColors();
const Employees = ({navigation, route}:EmployeesProps)=> {

    const rolesThatCanManageEmployees = useRef([
        UserRole.admin,
        UserRole.companyAdmin,
        UserRole.regionAdmin,
    ]);

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {currentUserData} = useSignedInContext();

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.employeeId);
    const [currentRowData, setCurrentRowData] = useState<EmployeeDataForClient | undefined>(undefined);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);


    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

   const {employeesData, isLoading, isPreviousData,
             PaginationComponent, fetchNextPage, hasNextPage,
             setFilters, setExtraParams} = useGetEmployees({navigation, route});

    const applyFiltersCallback = useCallback(({ filters, otherParams}: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
        if (otherParams) {
            setExtraParams?.(otherParams);
        }
    }, [setFilters, setExtraParams]);

    const shouldShowRegion = useMemo(()=> {
        return shouldShowRegionForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const shouldShowBranch = useMemo(()=> {
        return shouldShowBranchForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const initialOptions = useMemo(()=> {
        const positionOptions = Object.values(EmployeePosition).map((p)=> {
            return {label: toEmployeePositionLabel(p), value: p};
        });
        const positionTypeOptions = Object.values(EmployeePositionType).map((p)=> {
            return {label: toEmployeePositionTypeLabel(p), value: p};
        });
        return [
            {
                label: 'First Name',
                apiName: 'firstName',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Last Name',
                apiName: 'lastName',
                filterType:  FilterFieldType.text,
            },
            {   label: 'Region',
                apiName: 'regionId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowRegion,
            },
            {   label: 'Branch',
                apiName: 'branchId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowBranch,
            },
            {
                label: 'Position',
                apiName: 'position',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: positionOptions,
            },
            {
                label: 'Position Type',
                apiName: 'positionType',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: positionTypeOptions,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
            {
                label: 'TempWorks Id',
                apiName: 'tempWorksId',
                filterType:  FilterFieldType.text,

            },
            {
                label: 'Status',
                apiName: 'status',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [
                    {label: 'Active', value: UserStatus.active},
                    {label: 'Inactive', value: UserStatus.inactive},
                ],
            },
            {
                label: 'Invite Pending',
                apiName: NotFilterFields.isInvitePending,
                filterType:  FilterFieldType.checkbox,
            },

        ];
    }, [shouldShowBranch, shouldShowRegion]);


    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });


    const onRecordAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.employees, {...route.params, ...{employeeId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    const deleteEmployee = trpc.company.employee.delete.useMutation({
        onSuccess: ()=> {
            setShowMenuForRowId(undefined);
            refreshList();
            hideDeleteEmployee();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting Employee',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteEmployee, show: showDeleteEmployee, component:DeleteEmployee, setDeleteError} = useDeleteRecord({
        recordType: 'employee',
        deleteAction: (id)=>{
            deleteEmployee.mutate({employeeId: id, companyId: currentCompanyId ?? ''}, {
                onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
            });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: deleteEmployee.isLoading,
    });

    const closeModal = useCallback(()=> {
        navigation.navigate(CompanyDashboardRoutes.employees, {...route.params, ...{employeeId: '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?:boolean) => {
        if (d) {
            navigation.navigate(CompanyDashboardRoutes.employees, {...route.params, ...{ employeeId: d, companyId: currentCompanyId ?? '', timeline: timeline}});
            setShowRecordModal(true);
        } else {
            closeModal();
        }

    }, [navigation, closeModal, currentCompanyId, route.params]);

    const modalEmployeeId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.employeeId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.employeeId, showRecordModal]);

    const {mutate: createEmployee, isLoading: isLoadingEmployeeCreate} = trpc.company.employee.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Adding Employee',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editEmployee, isLoading: isLoadingEmployeeEdit } = trpc.company.employee.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data?.employeeData?.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing Employee',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: resendInvite} = trpc.company.user.resendInvite.useMutation({
        onSuccess: ()=> {
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Resending Invite',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const refreshList = useCallback((id?:string | undefined)=> {

        if (id) {
            trpcContext.company.employee.getById.invalidate({employeeId: id, companyId: currentCompanyId ?? ''});
        }
        if (isMobileApp) {
            trpcContext.company.employee.list.invalidate();
        } else {
            trpcContext.company.employee.getPage.invalidate();
        }

    }, [trpcContext.company.employee.getPage, trpcContext.company.employee.list, trpcContext.company.employee.getById, currentCompanyId]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const editNotAllowed = useMemo(()=> {
        return !currentUserData?.role || !rolesThatCanManageEmployees.current.includes(currentUserData?.role);
    } , [currentUserData?.role]);

    useEffect(() => {
        if (showMenuForRowId) {
            setCurrentRowData(employeesData?.items.find((item)=>item.id === showMenuForRowId));
        } else {
            setCurrentRowData(undefined);
        }

    }, [showMenuForRowId, employeesData?.items, setCurrentRowData]);

    const menuItems = useMemo(()=> {
        const options = {
            viewTimeline:  {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
                }},
            delete:       {title: 'Delete', onPress: ()=>{showDeleteEmployee({

                    recordId: showMenuForRowId,
                    recordName: (()=> {
                        const employee = employeesData?.items.find((item)=>item.id === showMenuForRowId);
                        return employee?.firstName ? `${employee.firstName} ${employee.lastName}` : '';
                    })(),
                });}, textStyle: {color: COLORS.dangerTextColor}},
            editOrView: {title: editNotAllowed ? 'View' : 'Edit',
                onPress: ()=>{
                setShowMenuForRowId(undefined);
                onEditRecordPressed(showMenuForRowId);
            }},
            resendInvite: {title: 'Resend Invite', onPress: ()=>{
                    if (!currentCompanyId) { return; }
                    resendInvite({userId: showMenuForRowId ?? '', companyId: currentCompanyId});
                    setShowMenuForRowId(undefined);
                }},
        };
        const base = [options.viewTimeline];
        if (isMobileApp) {
            base.push(options.editOrView);
        }
        if (currentRowData?.isInvitePending) {
            base.push(options.resendInvite);
        }
        if (!editNotAllowed) {
            base.push(options.delete);
        }
        return base.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [currentCompanyId, showDeleteEmployee, showMenuForRowId, employeesData?.items, editNotAllowed, onEditRecordPressed, currentRowData, resendInvite]);

    const addButtonText = 'Add new employee';

    const employeeModalTitleProps = useMemo(()=> {
        if (showTimeline) {
            const employee = employeesData?.items.find((item)=>item.id === modalEmployeeId);
            return {
                title: `Timeline for ${employee?.firstName} ${employee?.lastName}`,
            };
        }
        if (editNotAllowed) {
            const employee = employeesData?.items.find((item)=>item.id === modalEmployeeId);
            if (!employee) {
                return {
                    title: 'View Employee',
                };
            }
            return {
                titleCustomComponent: <View style={{flexDirection: 'row'}}>
                    <Text style={initialStyles.modalTitle}>{`${employee?.firstName} ${employee?.lastName}` }</Text>
                    <Spacer x={10}/>
                    <StatusRenderer status={employee?.status} recordType={RecordTypes.employee}/>
                </View>,
            };
        } else {
            return {
                title: modalEmployeeId ? 'Edit employee' : 'Add new employee',
            };
        }
    },[employeesData, modalEmployeeId, editNotAllowed, showTimeline]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return (
            <ListViewHeader title={'Employee Management'}
                            hideAddButton={editNotAllowed}
                            subtitle={'View and manage our employees.'}
                            buttonText={addButtonText}
                            onAddPress={onRecordAddPress}
                            buttonColor={applyTTStyles(false).primaryButtonBg}
                            filterComponent={filters.Button}

            />
        );
    },[onRecordAddPress, editNotAllowed,  filters.Button ]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            <>
                {DeleteEmployee}
                <ModalWrapper show={showRecordModal}
                              closeAction={()=>onEditRecordPressed(undefined)}
                              {...employeeModalTitleProps}
                              maxHeight={undefined}
                >
                    {showRecordModal ? <EmployeeForm isEditing={!!modalEmployeeId}
                                                        closeAction={()=>onEditRecordPressed(undefined)}
                                                        employeeId={modalEmployeeId}
                                                        action={modalEmployeeId  ? editEmployee : createEmployee}
                                                        submitInProgress={modalEmployeeId ? isLoadingEmployeeEdit : isLoadingEmployeeCreate}
                                                        submitErrorMessage={errorMessage}
                                                        resetSubmitError={()=>setErrorMessage('')}
                                                        editNotAllowed={editNotAllowed}
                                                     showTimeline={showTimeline}
                                                     toggleShowTimeline={toggleShowTimeline}

                    /> : null}
                </ModalWrapper>

                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                                callerX={position.x}
                                                callerY={position.y}
                                                visible={!!showMenuForRowId}
                                                menuItems={menuItems}
                                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}

                {isMobileApp ? undefined : HeaderComponent}
                {filters.FilterPanel}
                <EmployeesTable data={employeesData?.items}
                                canManageRecords={!editNotAllowed}
                                isLoading={employeesData ? (isLoading && !isPreviousData) : isLoading}
                                onEditIconPressed = {onEditRecordPressed}
                                openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                                addButtonText={addButtonText}
                                onAddPress={onRecordAddPress}
                                fetchMore={hasNextPage ? fetchNextPage : undefined}
                                headerComponent={HeaderComponent}
                                hasFilters={filters.filtersApplied}
                />
                {isMobileApp ? null : <View style={{minHeight:60}}>
                {PaginationComponent ? PaginationComponent({totalItems: employeesData?.totalItems, currentItemsLength: employeesData?.items?.length ?? 0}) : null}
                </View>}
                </>
        </UIDrawerRightContentWrapper>
    );
};

export {Employees};
