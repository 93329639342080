import React, { useMemo } from 'react';
import { trpc } from '../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../context/CurrentCompanyContext';

const useRegions = ({
    disabled,
}: {
    disabled?: boolean;
})=> {
    const disabledRef = React.useRef(disabled);
    const {currentCompanyId} = useCurrentCompanyContext();
    const {data: regionOptions, isLoading: gettingRegions} = trpc.company.region.getDropdown.useQuery({companyId: currentCompanyId ?? ''}, {
        enabled: !!currentCompanyId && !disabledRef.current,
    });

    const regionDropdownOptions = useMemo(() => {
        if (!regionOptions || !regionOptions?.items || gettingRegions) {
            return [];
        }
        return regionOptions.items.map((region)=> {
            return {label: region.name, value: region.id};
        });
    }, [regionOptions, gettingRegions]);

    return {
        regionDropdownOptions: regionDropdownOptions,
    };
};

export { useRegions };
