import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GestureResponderEvent, View } from 'react-native';
import { trpc } from '../../../../apiCalls/trpcClient';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import { applyTTStyles} from '../../../../constants/colors';
import { AdminUserForm } from './AdminUserContent';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import {mbShowToast} from '@mightybyte/rnw.components.toast';
import { UIDrawerRightContentWrapper } from '../../../helperComponents/DrawerRightContentWrapper';
import { UsersTable } from './TableView';
import { AdminUsersProps } from '../../../../typesAndInterfaces/componentProps';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import {getColors} from '../../../../constants/colors';
import { FilterFieldType, UserDataForClient, UserStatus } from '@temptrack/business';
import { ChangePasswordContent } from './ChangePasswordContent';
import { routeParams } from '../../../../constants/urlConstants';
import { AdminViewRoutes } from '../../../../navigations/types';
import { utils } from '../../../../utils/utils';
import { useGetUsers } from './useGetUsers';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { NotFilterFields } from '../../../../hooks/filters/types';

const COLORS = getColors();
const Users = ({navigation, route}: AdminUsersProps) => {
    const trpcContext = trpc.useUtils();

    const {usersData, PaginationComponent, isLoading,
              isPreviousData, hasNextPage, fetchNextPage, setFilters, setExtraParams} = useGetUsers({navigation, route});

    const applyFiltersCallback = useCallback(({ filters, otherParams}: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
        if (otherParams) {
            setExtraParams?.(otherParams);
        }
    }, [setFilters, setExtraParams]);

    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'First Name',
                apiName: 'firstName',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Last Name',
                apiName: 'lastName',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
            {
                label: 'Status',
                apiName: 'status',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [
                    {label: 'Active', value: UserStatus.active},
                    {label: 'Inactive', value: UserStatus.inactive},
                ],
            },
            {
                label: 'Invite Pending',
                apiName: NotFilterFields.isInvitePending,
                filterType:  FilterFieldType.checkbox,
            },
        ];
    }, []);

    const filters = useFilter({
        isTempTrack: true,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
    });


    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.userId);
    const [showChangePasswordModalId, setShowChangePasswordModalId] = useState<string | undefined>(undefined);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);

    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);

    const [currentRowData, setCurrentRowData] = useState<UserDataForClient | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    useEffect(() => {
        if (showMenuForRowId) {
            setCurrentRowData(usersData?.items.find((item)=>item.id === showMenuForRowId));
        } else {
            setCurrentRowData(undefined);
        }

    }, [showMenuForRowId, usersData?.items, setCurrentRowData]);

    const onUserAddPress = useCallback(() => {
        navigation.navigate(AdminViewRoutes.users, {...route.params, ...{userId: routeParams.newRecord}});
        setShowRecordModal(true);
        } , [navigation, route.params]);

    const {mutate: deleteUser, isLoading: isLoadingUserDelete} = trpc.admin.user.delete.useMutation({
onSuccess: ()=> {
            refreshList();
            hideDeleteUser();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting User',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteUser, show: showDeleteUser, component:DeleteUser, setDeleteError} = useDeleteRecord({
        recordType: 'User',
        deleteAction: (id)=>{deleteUser({userId: id},{
            onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: isLoadingUserDelete,
    });

    const addButtonText = 'Add new user';

    const closeModal = useCallback(()=> {
        navigation.navigate(AdminViewRoutes.users, {...route.params, ...{userId: '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            navigation.navigate(AdminViewRoutes.users, {...route.params, ...{userId: d, timeline: timeline}});
            setShowRecordModal(true);
        } else {
           closeModal();
        }

    }, [navigation, closeModal, route.params]);

    const modalUserId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.userId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.userId, showRecordModal]);

    const {mutate: createUser, isLoading: isLoadingUserCreate} = trpc.admin.user.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Creating User',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editUser, isLoading: isLoadingUserEdit } = trpc.admin.user.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.userData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing user',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: resendInvite} = trpc.admin.user.resendInvite.useMutation({
        onSuccess: ()=> {
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Resending Invite',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: changePassword, isLoading: isLoadingChangePassword} = trpc.admin.user.changeUserPassword.useMutation({
        onSuccess: ()=> {
            setShowChangePasswordModalId(undefined);
            setTimeout(()=>{
                mbShowToast({ text1: 'Success changing password',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const refreshList = useCallback((id?: string | undefined)=> {
        if (id) {
            trpcContext.admin.user.getById.invalidate({userId:id});
        }
        if (isMobileApp) {
            trpcContext.admin.user.list.invalidate();
        } else {
            trpcContext.admin.user.getPage.invalidate();
        }

    }, [trpcContext.admin.user.getPage, trpcContext.admin.user.getById, trpcContext.admin.user.list]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const menuItems = useMemo(()=> {
        const base = [
            {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
            }},
            currentRowData?.isInvitePending ? {title: 'Resend Invite', onPress: ()=>{
                    resendInvite({userId: showMenuForRowId ?? ''});
                    setShowMenuForRowId(undefined);
                }} : {
                title: 'Change Password', onPress: ()=>{
                    setShowChangePasswordModalId(showMenuForRowId);
                    setShowMenuForRowId(undefined);
                }},

            {title: 'Delete', onPress: ()=>{showDeleteUser({
                    recordId: showMenuForRowId ?? '',
                    recordName: `${currentRowData?.firstName} ${currentRowData?.lastName}`,
                });
                    setShowMenuForRowId(undefined);
                }, textStyle: {color: COLORS.dangerTextColor}},
        ];
        if (isMobileApp) {
            base.unshift({title: 'Edit', onPress: ()=>{
                onEditRecordPressed(showMenuForRowId);
                setShowMenuForRowId(undefined);
            }});
        }
        return base.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [showMenuForRowId, resendInvite,currentRowData, showDeleteUser, onEditRecordPressed]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const headerComponent = useMemo(()=> {
        return <ListViewHeader title={'User Management'}
                               subtitle={'View and manage all global users.'}
                               buttonText={addButtonText}
                               onAddPress={onUserAddPress}
                               buttonColor={applyTTStyles(true).primaryButtonBg}
                               filterComponent={filters.Button}

        />;
    }, [onUserAddPress, filters.Button]);

    const recordModalTitleProps = useMemo(()=> {
        if (showTimeline) {
            const user = usersData?.items.find((item)=>item.id === modalUserId);
            return `Timeline for ${user?.firstName} ${user?.lastName}`;
        }
        if (modalUserId) {
            const user = usersData?.items.find((item)=>item.id === modalUserId);
            return `Edit user ${user?.firstName} ${user?.lastName}`;
        }
        return 'Add new user';
    } , [showTimeline, modalUserId, usersData?.items]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            <>
            {DeleteUser}
                <ModalWrapper show={showRecordModal}
                              closeAction={()=>onEditRecordPressed(undefined)}
                              title={recordModalTitleProps}
                >
                    {showRecordModal ? <AdminUserForm isEditing={!!modalUserId}
                                                         closeAction={()=>onEditRecordPressed(undefined)}
                                                         userId={modalUserId}
                                                         action={modalUserId ? editUser : createUser}
                                                         submitInProgress={modalUserId ? isLoadingUserEdit : isLoadingUserCreate}
                                                         submitErrorMessage={errorMessage}
                                                      resetSubmitError={()=>setErrorMessage('')}
                                                      showTimeline={showTimeline}
                                                      toggleShowTimeline={toggleShowTimeline}

                    /> : null}
                </ModalWrapper>

                <ModalWrapper show={!!showChangePasswordModalId}
                              closeAction={()=> setShowChangePasswordModalId(undefined)}
                              title={'Change password'}
                              height={400}
                              width={600}
                              visibleOverflow={true}

                >
                    {showChangePasswordModalId ? <ChangePasswordContent userId={showChangePasswordModalId}
                                                                      closeAction={()=> setShowChangePasswordModalId(undefined)}
                                                                      action={changePassword}
                                                                      submitInProgress={isLoadingChangePassword}
                                                                      submitErrorMessage={errorMessage}
                                                                        resetSubmitError={()=>setErrorMessage('')}
                        />
                        : null}
                </ModalWrapper>
                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                                callerX={position.x}
                                                callerY={position.y}
                                                visible={!!showMenuForRowId}
                                                menuItems={menuItems}
                                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}

                {isMobileApp ? null : headerComponent}
                {filters.FilterPanel}
                <UsersTable data={usersData?.items}
                            isLoading={usersData ? (isLoading && !isPreviousData) : isLoading}
                            onEditIconPressed = {onEditRecordPressed}
                            openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                            onAddPress={onUserAddPress}
                            addButtonText={addButtonText}
                            headerComponent={headerComponent}
                            fetchMore={hasNextPage ? fetchNextPage : undefined}
                            hasFilters={filters.filtersApplied}
                />
                {isMobileApp ? null : <View style={{minHeight:60}}>
                {PaginationComponent ? PaginationComponent({totalItems: usersData?.totalItems, currentItemsLength: usersData?.items?.length ?? 0}) : null}
                </View>}
                </>
        </UIDrawerRightContentWrapper>
    );
};

export { Users };
