import React, { useEffect, useRef } from 'react';
import { Text, View } from 'react-native';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { initialStyles } from '../../../hooks/useAppInitialSetup';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';


//Works for 6 inputs only
const SegmentedInput = ({onChange, title, showRequiredAsterisk}:{
    onChange: (value: string, index: number)=> void
    title?: string,
    showRequiredAsterisk?: boolean
}) => {
    const itemsRefs = [
        useRef<MB_TextInputRef>(null),
        useRef<MB_TextInputRef>(null),
        useRef<MB_TextInputRef>(null),
        useRef<MB_TextInputRef>(null),
        useRef<MB_TextInputRef>(null),
        useRef<MB_TextInputRef>(null),
    ];

    useEffect(() => {

    }, []);

    const inputs = Array(6).fill('').map((_, i) => {
        return (
            <View style={{width: 40}}  key={i}>
                <MB_TextInput

                    MB_Ref={itemsRefs[i]}
                    maxLength={1}
                    style={{width: 40, paddingLeft: 0}}
                    onChangeText={(value) => {
                        onChange(value, i);
                        if (value !== '') {
                            if (i < 5) {
                                itemsRefs[i + 1].current?.focus();
                            }
                        } else {
                            if (i > 0) {
                                itemsRefs[i - 1].current?.focus();
                            }
                        }
                    }}
                    textInputStyle={mbTextStyles(textStyles.Muli_16_600)}
                />
            </View>

        );
    });
    return (
        <View>
            {title ? <View>
                <Text style={initialStyles.textInputTitle}>{title}{showRequiredAsterisk ? <Text style={initialStyles.requiredAsteriksColor}>*</Text> : null}</Text>
            </View> : null}
            <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                {inputs}
            </View>
        </View>

    );
};

export { SegmentedInput };
