import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_TYPES } from '../../../constants/textStyles';
import { appColors } from '../../../constants/colors';
import {ClickableText} from '../../helperComponents/misc/ClickableText';

const SelectAnotherSubdomainUI = ({action, title, buttonColor}:{
    action: ()=>void,
    title: string,
    buttonColor?: string | undefined,
})=> {
    return (
        <View style={styles.container}>
            <Text style={styles.text}>{title}</Text>
            <ClickableText text={'Sign in here'} action={action} color={buttonColor}/>
        </View>
    );
};

export {SelectAnotherSubdomainUI};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        marginTop: 20,
        alignItems: 'center',
        justifyContent: 'center',
    },
    text: mbTextStyles([
        {
            fontFamily: FONT_TYPES.Inter500Medium,
            fontSize: 14,
            textAlign: 'left',
            color: appColors.gray500,
        },
    ]),
});
