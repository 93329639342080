import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Image, Pressable, ScrollView, StyleSheet, Text, View } from 'react-native';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
    MB_TextInputRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import {
    AdminCreateCompanyInput,
    AdminEditCompanyInput,
    ImageFileExtension,
    ImageUrls,
    isCompanyDomainValid,
} from '@temptrack/business';
import { utils } from '../../../../utils/utils';
import {
    MB_RESOURCE_PICKER_TYPE,
    MB_ResourceUploader,
    MB_ResourceUploaderFunctionProps,
} from '@mightybyte/rnw.components.resource-uploader';
import { trpc } from '../../../../apiCalls/trpcClient';
import { resourceUploadApiCalls } from '../../../../apiCalls/resourceUploadApiCalls';
import { MB_utilHooks } from '@mightybyte/rnw.utils.util-hooks';
import { UploadLogo } from '../../../../resources/svgComponents/UploadLogo';
import { mbGetMediumImage } from '../../../../utils/imageUtils';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import {  useAddress } from '../../../../hooks/useAddress';
import { useEmail } from '../../../../hooks/useEmail';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { envs } from '../../../../../env';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    companyName = 'companyName',
    customDomain = 'customDomain',
}

const AdminCompanyForm = (
    {
        isEditing,
        companyId,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        resetSubmitError,
        showTimeline,
    }: {
    companyId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data:any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {
    const [initialIsDone, setInitialIsDone] = useState(false);

    const addressForm = useAddress({isOptional: false});
    const phoneNumber = usePhoneNumber({isRequired: true});
    const email = useEmail({isRequired: true, nextToFocus: phoneNumber.ref});

    const [companyName, setCompanyName] = useState('');
    const [customDomain, setCustomDomain] = useState( '');
    const customDomainRef = useRef<MB_TextInputRef>(null);

    const [logoFilename, setLogoFilename] = useState<string | undefined>(undefined);

    const [logoUrl, setLogoUrl] = useState<ImageUrls | undefined>(undefined);

    const {data: recordData, isLoading} = trpc.admin.company.getById.useQuery({id: companyId ?? '' }, {
        enabled: isEditing && !!companyId,
    });

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.companyData) {
            const {companyData} = recordData;
            setCompanyName(companyData.name);
            setCustomDomain(companyData.customDomain ?? '');
            setLogoUrl(companyData?.logoUrl ?? undefined);
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: companyData.address?.line1,
                city: companyData.address?.city,
                state: companyData.address?.state,
                zipCode: companyData.address?.zipCode,
            }));
            addressForm.setCity(companyData.address?.city ?? '');
            addressForm.setState(companyData.address?.state ?? '');
            addressForm.setZipCode(companyData.address?.zipCode ?? '');
            addressForm.setAddress(companyData.address?.line1 ?? '');
            addressForm.setAddress2(companyData.address?.line2 ?? '');
            email.setInitialEmail(companyData.email);
            phoneNumber.setInitialValue(companyData.phoneNumber);
            setInitialIsDone(true);
        }
    }, [recordData, addressForm, initialIsDone, email, phoneNumber]);

    const {data: timeline, isLoading: gettingTimeline} = trpc.admin.company.getTimeline.useQuery({id: companyId ?? '' }, {
        enabled: showTimeline && !!companyId,
    });
    //used to display uploaded image when creating company
    const [tempLogoUrl, setTempLogoUrl] = useState<string | undefined>(undefined);

    const [isResourcePickerVisible, showResourcePicker, hideResourcePicker] = MB_utilHooks.useBool();

    const {mutate: getUploadUrl} = trpc.admin.company.getLogoUploadUrl.useMutation();
    const fieldsMap = useMemo(()=> {
        const base = {
            [InputTypes.companyName]: {value: companyName, action: setCompanyName},
            [InputTypes.customDomain]: {value: customDomain, action: setCustomDomain},
        };
        return base;
    }, [customDomain, companyName]);

    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
        [InputTypes.companyName]: '',
        [InputTypes.customDomain]: '',
    });

    const isEmpty = (value: string) => {
        return value?.trim().length === 0;
    };

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;
        const requiredFields = [InputTypes.companyName, InputTypes.customDomain];

        //check required fields
        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });

        if (errors[InputTypes.customDomain].length === 0) {
            if (!isCompanyDomainValid(fieldsMap[InputTypes.customDomain].value)) {
                isValid = false;
                errors[InputTypes.customDomain] = STRING_CONSTANTS.INVALID_CUSTOM_DOMAIN;
            } else {
                errors[InputTypes.customDomain] = '';
            }
        }

        const addressIsValid = addressForm.validateAddress();
        const emailIsValid = email.validateEmail();
        const phoneNumberIsValid = phoneNumber.validate();


        setFormErrorMessages(errors);
        return isValid && addressIsValid && emailIsValid && phoneNumberIsValid;
    },[fieldsMap, formErrorMessages, setFormErrorMessages, addressForm, email, phoneNumber]);

    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);

    const setFormValue = useCallback((field: InputTypes, value: string) => {
        fieldsMap[field].action(value);
    }, [fieldsMap]);

    const createAction =  () => {
        const data: AdminCreateCompanyInput = {
            name: companyName,
            customDomain: customDomain,
            address: addressForm.values,
            phoneNumber: phoneNumber.value,
            email: email.value,
        };
        if (logoFilename){
            data.logoFilename = logoFilename;
        }
        action(data);
    };

    const editAction =  () => {
        const data: AdminEditCompanyInput = {
            id: companyId!,
            name: companyName,
            customDomain: customDomain,
            address: addressForm.values,
            phoneNumber: phoneNumber.value,
            email: email.value,
        };
        if (logoFilename){
            data.logoFilename = logoFilename;
        }
        action(data);
    };


    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }

     if (isEditing){
            editAction();
            return;
     }
        createAction();
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.companyName]: {title: 'Company name', placeholder: 'Enter company name', textContentType: 'organizationName', required: true},
            [InputTypes.customDomain]: {title: 'Custom domain', placeholder: 'Enter subdomain name', textContentType: '', required: true},
        };

        const onChange = (newText: string) => {

            setFormErrorMessages((prevState)=> {
                return {...prevState, [field]: ''};
            });
            resetSubmitError();
            setFormValue(field, newText);
        };

        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            onChangeText: onChange,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].required,
        };
    }, [fieldsMap, setFormValue, resetSubmitError]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const logoSource = useMemo(() => {
        if (logoUrl){
          return  mbGetMediumImage(logoUrl, {asUri: true});
        }
        if (tempLogoUrl){
            return {uri: tempLogoUrl};
        }
        return undefined;
    }, [logoUrl, tempLogoUrl]);

    const styles = useMemo(()=> platformStyles({isMobile: isMobileApp}), []);

    const LogoSection = useMemo(()=> {

        function imageUploader({ fileProps, uploadProgressListener }: { fileProps: MB_ResourceUploaderFunctionProps, uploadProgressListener: (percentCompleted: number) => void }): Promise<string> {

            return new Promise<string>((resolve, reject) => {
                if (!fileProps.blob) {
                    reject('Something went wrong');
                }

                const extension = utils.enumFromStringValue(ImageFileExtension, fileProps.mimeType.split('/')[1]);

                if (!extension) {
                    reject('Error: Invalid image format');
                    return;
                }


                getUploadUrl({ fileExtension: extension }, {
                    onSuccess: async (data) => {
                        resourceUploadApiCalls.uploadResource({
                            method: data.requestMethod,
                            url: data.signedUrl,
                            imageData: fileProps.blob!,
                            headers: data.headers,
                            uploadProgress: uploadProgressListener,
                        })
                                              .then(() => {
                                                  setLogoFilename(data.fileName);
                                                  resolve(data.fileName);
                                              })
                                              .catch(() => reject('Something went wrong during upload'));
                    },
                    onError: (error) => {
                        // TODO: Yulia: Finish
                        console.log('Error data', error);
                    },
                });
            });
        }
        const imageVideoUploader = (uploadProgressListener: (percentCompleted: number) => void) => async (fileProps: MB_ResourceUploaderFunctionProps): Promise<void> => {
            return new Promise((resolve, reject) => {

                imageUploader({ fileProps, uploadProgressListener })
                    .then(() => {
                        setTempLogoUrl(fileProps.uri);
                        resolve();
                    })
                    .catch(error => reject(error));
            });
        };
        if (isMobileApp) {
            return (
                <View style={{marginVertical: 16}}>
                    {logoSource ? <View style={{height: 66}}>
                        <Image source={logoSource} resizeMode="contain"
                               style={{width: '100%', height: '100%'}}
                        />
                    </View> : null}
                    <Text style={{textAlign: 'center'}}>Please use browser to add/edit company logo</Text>
                </View>
            );
        }
        return <>
             <MB_ResourceUploader
                isVisible={isResourcePickerVisible}
                onDismiss={() => {
                    hideResourcePicker();
                }}
                uploaderFunction={imageVideoUploader}
                pageTitle={'Select Company Logo'}
                uploadButtonTitle="Upload"
                resourcePickerType={MB_RESOURCE_PICKER_TYPE.image}
                uploadButtonStyle={styles.button}

            />
            <View style={recordModalContentStyles.row}>
                <View style={{width: '30%', marginRight: 24}}>
                    <Text style={styles.textInputTitle}>
                        Company logo
                    </Text>
                    <View style={{height: 66, justifyContent: 'center', alignItems: 'center'}}>
                        {logoSource ?
                            <Image source={logoSource}
                                   resizeMode="contain"
                                   style={{width: '100%', height: '100%'}}
                            /> : <UploadLogo/>}
                    </View>


                    {/*{}*/}
                </View>
                <View style={styles.fileUploadWrapper}>
                    <Pressable style={[{
                        alignItems: 'flex-start',
                        justifyContent: 'center',

                    }]} onPress={showResourcePicker}>
                        <View style={[styles.chooseFileSubsection]}>
                            <Text style={{color: appColors.white}}>Choose file</Text>
                        </View>

                    </Pressable>
                    <Text style={{
                        marginTop: 4,
                        color: getColors().inputPlaceholder,
                    }}>{'PNG, JPG, JPEG or WEBP'}</Text>
                </View>

            </View>
        </>;
    }, [logoSource, styles, isResourcePickerVisible, showResourcePicker, hideResourcePicker, getUploadUrl]);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.primary500}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1, zIndex: 3000}}>
                {LogoSection}
                <View style={recordModalContentStyles.row}>
                        <WrappedInput errorMessage={fieldError(InputTypes.companyName)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.companyName)}
                                          nextToFocus={customDomainRef}
                                      />}
                        />
                </View>
                <View style={recordModalContentStyles.row}>
                        <View style={[styles.inputSubSection, styles.end]}>
                            <Text>.temptrackapp.com</Text>
                        </View>
                        <View style={[styles.inputSubSection, styles.start]}>
                            <Text>https://</Text>
                        </View>
                        <WrappedInput errorMessage={fieldError(InputTypes.customDomain)}
                                      inputComponent={
                            <MB_TextInput {...getInputProps(InputTypes.customDomain)}
                                textInputStyle={{paddingLeft: isMobileApp ? 66 : 88}}
                                          nextToFocus={email.ref}
                            />}
                        />
                </View>
                <View style={[recordModalContentStyles.row,  {zIndex: 3000}]}>
                    <View style={[recordModalContentStyles.rowItem, {flex: 1, zIndex: 3000}]}>
                        <WrappedInput errorMessage={addressForm.error}
                                      inputComponent={<View style={{ width: '100%'}}>
                                          <MB_LocationPicker
                                              key={initialIsDone.toString()}
                                              apiKey={envs.LOCATION_PICKER_API}
                                              title={'Address'}
                                              containerStyle={{width: '100%'}}
                                              titleStyle={{color: COLORS.textPrimary}}
                                              showRequiredAsteriks={true}
                                              value={addressForm.fullAddress}
                                              onSelect={addr => {
                                                  addressForm.setAddress(utils.getStreetFromFullAddress(addr?.text, addr?.city));
                                                  addressForm.setCity(addr?.city ?? '');
                                                  addressForm.setState(addr?.state ?? '');
                                                  addressForm.setZipCode(addr?.postalCode ?? '');
                                              }}
                                              dropDownDirection={'BOTTOM'}
                                              listMode={'SCROLLVIEW'}
                                          />
                                      </View>}
                        />
                    </View>

                    <View style={[recordModalContentStyles.rowItem, isMobileApp ? undefined : {width: 200, marginLeft: 16}]}>
                        {addressForm.addressSecondLineField}
                    </View>
                </View>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>

            </View>


            {/*<View style={{marginTop: 70}}>*/}
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>

                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow, {zIndex: 1}]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add company'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(true).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                            // preventDisabledStyleChange={true}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>


        </ScrollView>
    );
};

export { AdminCompanyForm };
const platformStyles = ({isMobile}:{
    isMobile: boolean;

}) => StyleSheet.create({
    fileUploadWrapper: {
        width: '100%',
        justifyContent: isMobile ? 'flex-start' : 'flex-end',
        flexDirection: isMobile ? 'row' : 'column',
        alignItems: isMobile ? 'center' : 'flex-start',
        marginVertical: isMobile ? 24 : 0,

    },
    inputSubSection: {
        position: 'absolute',
        top: 28,
        borderColor: COLORS.inputBorder,
        height: 42,
        backgroundColor: appColors.gray100,
        paddingHorizontal: isMobile ? 8 : 20,
        justifyContent: 'center',
        borderWidth: 1,
        zIndex: 200,
    },
    end: {
        right: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
    },
    start: {
        left: 0,
        borderBottomLeftRadius: 6,
        borderTopLeftRadius: 6,
        width: isMobile ? undefined : 88,
    },

    textInputTitle: mbTextStyles([
        textStyles.Muli_16_700, {
            color: COLORS.inputLabel,
            fontSize: 14,
            textAlign: 'left',
            marginBottom: 8,
        },
    ]),
    // textInputStyle: {
    //     borderRadius: 8,
    //     borderWidth: 1,
    //     borderColor: COLORS.inputBorder,
    //     backgroundColor: COLORS.inputBackground,
    //     height: 42,
    //     paddingHorizontal: 10,
    // },
    // textInputTextStyle: mbTextStyles([
    //     textStyles.Muli_16_600, {
    //         color: COLORS.inputText,
    //         fontSize: 14,
    //         textAlign: 'left',
    //     },
    // ]),
    chooseFileSubsection: {
        borderColor: appColors.gray800,
        height: 41,
        backgroundColor: appColors.gray800,
        paddingHorizontal: 20,
        justifyContent: 'center',
        borderWidth: 1,
        zIndex: 200,
        borderRadius: 8,
        width: 120,
        marginRight: isMobile ? 16 : undefined,
    },

    button: {
        backgroundColor: appColors.primary500,
        borderRadius: 8,
        height: 41,
    },

});
