import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { getColors, appColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { AddButton } from './misc/AddButton';
import Entypo from 'react-native-vector-icons/Entypo';

const COLORS = getColors();

const EmptyTableView = ({ title, subtitle, buttonText, onAddPress, iconColor, buttonColor, hideAddButton, height, hasFilters }:{
    title: string,
    subtitle?: string,
    buttonText?: string,
    buttonColor?: string,
    onAddPress?: () => void,
    iconColor?: string,
    hideAddButton?: boolean,
    height?: number,
    hasFilters?: boolean,
}) => {
    return (
        <View style={{height: height}}>
            <View style={[styles.container]}>
                <Entypo name={'block'} size={64} color={iconColor ?? appColors.blue100} style={{marginBottom: 20}}/>
                <Text style={styles.noCompaniesTitle}>{hasFilters ? 'No records matching filter settings.' : title}</Text>
                {hideAddButton || !subtitle || hasFilters ? null : <Text style={styles.noCompaniesSubtitle}>{subtitle}</Text>}
                {hideAddButton || !onAddPress || hasFilters ? null : <AddButton buttonText={buttonText ?? ''} onAddPress={onAddPress}  style={{marginTop: 20, backgroundColor: buttonColor ?? undefined}}/>}
            </View>
        </View>

    );
};

export { EmptyTableView };

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: COLORS.backgroundSecondary,
    },
    noCompaniesTitle: mbTextStyles([
        textStyles.Muli_16_600, {
            fontSize: 24,
        },
    ]),
    noCompaniesSubtitle: mbTextStyles([
        textStyles.Muli_16_400, {
            fontSize: 13,
            color: COLORS.textSecondary,
            marginTop: 6,
        },
    ]),
});
