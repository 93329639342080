import { z } from 'zod';
import {
    ObjectIdSchema,
    PaginationInputSchema,
    filterMappingToSchema,
} from '../../common';
import {
    AlphanumericSchema,
    InfiniteScrollingInputSchema,
} from '../../common/schemas';
import { RegionFilterMap } from '../../../filter';

export const CreateRegionInputSchema = z.object({
    companyId: ObjectIdSchema,
    name: AlphanumericSchema,
});

export const GetRegionByIdInputSchema = z.object({
    regionId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetRegionsPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(RegionFilterMap),
});

export const ListRegionsInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(RegionFilterMap),
});

export const GetRegionsDropdownInputSchema = z.object({
    companyId: ObjectIdSchema,
});

export const EditRegionInputSchema = CreateRegionInputSchema.partial().extend({
    regionId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const DeleteRegionInputSchema = z.object({
    regionId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetRegionTimelineInputSchema = z.object({
    regionId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
