import React, { useCallback, useMemo } from 'react';
import {View, Text, StyleSheet} from 'react-native';
import { Spacer } from '../../../../helperComponents/misc/Spacer';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { EmployeeBadge } from '../EmployeeBadge';
import { ShortlistEmployeeTableData } from '../types';
import { appColors } from '../../../../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { EmployeeAcceptanceStatus,
    toEmployeeAcceptanceStatusLabel,
} from '@temptrack/business';
import {shortlistColumnStyles} from './styles';
import { OutlinedButton } from '../../../../helperComponents/misc/OutlinedButton';
import { DeleteIconButton } from '../../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../../helperComponents/misc/IconButton';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

const acceptanceTextColorMap = {
    [EmployeeAcceptanceStatus.accepted]: appColors.green600,
    [EmployeeAcceptanceStatus.declined]: appColors.red600,
    [EmployeeAcceptanceStatus.pending]: appColors.gray400,
};

const ShortlistEmployeeRow = (
    {
        employee,
        isEditing,
        hasUnsavedChanges,
        markedForDelete,
        toggleDeleteAction,
        toggleAcceptanceStatus,
        unsavedAcceptanceChanges,
}:{
    employee: ShortlistEmployeeTableData;
    isEditing: boolean;
    hasUnsavedChanges?: boolean;
    markedForDelete?: boolean;
    toggleDeleteAction: (id: string)=> void;
    toggleAcceptanceStatus: (id: string, status: EmployeeAcceptanceStatus | undefined)=> void;
        unsavedAcceptanceChanges: {
            [key: string]: EmployeeAcceptanceStatus;
        }
}) => {

    const AcceptanceStatusButtonsRenderer = useCallback(()=> {
        const recentlyChanged = unsavedAcceptanceChanges?.[employee.employeeId];
        const recentlyAccepted = recentlyChanged === EmployeeAcceptanceStatus.accepted;
        const recentlyDeclined = recentlyChanged === EmployeeAcceptanceStatus.declined;

        return (
            <View style={{flexDirection: 'row'}}>
                <Spacer x={16}/>
                <OutlinedButton

                    title={recentlyAccepted ? 'Accepted' : 'Accept'}
                    extraStyles={[isMobileApp ? null : styles.buttonBase, recentlyAccepted ? styles.accept : styles.acceptOutlined]}
                    textStyles={mbTextStyles([textStyles.Inter_16_500, {
                        color: recentlyAccepted ? appColors.white : appColors.green600,
                        fontSize: 12,
                    }])}
                    action={()=> {toggleAcceptanceStatus(employee.employeeId, EmployeeAcceptanceStatus.accepted);}}
                />
                <Spacer x={8}/>
                <OutlinedButton
                    title={recentlyDeclined ? 'Declined' : 'Decline'}

                    extraStyles={[isMobileApp ? null : styles.buttonBase,recentlyDeclined ? styles.decline : styles.declineOutlined]}
                    textStyles={mbTextStyles([textStyles.Inter_16_500, {
                        color: recentlyDeclined ? appColors.white : appColors.red500,
                        fontSize: 12,
                    }])}
                    action={()=> {toggleAcceptanceStatus(employee.employeeId, EmployeeAcceptanceStatus.declined);}}/>
            </View>
        );
    },[toggleAcceptanceStatus, unsavedAcceptanceChanges, employee.employeeId]);


    const notSavedChangesWarning = useMemo(()=> {
        if (markedForDelete) {
            return <Text style={[styles.changesText, {color: appColors.red500}]}>Marked for delete</Text>;
        }
        if (hasUnsavedChanges) {
            return <Text style={[styles.changesText, {color: appColors.red500}]}>Changes not saved</Text>;
        }

        return null;
    }, [hasUnsavedChanges, markedForDelete]);

    return (
        <View>
        <View style={[styles.row, markedForDelete ? styles.markedForDelete : null]}>
            <View style={{flexDirection:  isMobileApp ? 'column' : 'row', flex: 1}}>
                <View style={shortlistColumnStyles.col1}>
                    <EmployeeBadge name={`${employee?.employeeFirstName} ${employee.employeeLastName}`}
                                   disabled={true} noVerticalSpacing={true}
                                   containerStyle={{marginRight: 8}}
                    />
                </View>
                <View style={[shortlistColumnStyles.col2, {flexDirection:'row',  alignItems: 'center'}]}>
                    <Text style={[styles.statusText, {color: acceptanceTextColorMap[employee.acceptanceStatus]}]}>
                        {toEmployeeAcceptanceStatusLabel(employee.acceptanceStatus)}
                    </Text>

                    {(isEditing && employee.acceptanceStatus === EmployeeAcceptanceStatus.pending && !markedForDelete) ? AcceptanceStatusButtonsRenderer() : null}

                </View>
            </View>


            <View style={isMobileApp ? shortlistColumnStyles.actionColumnMobile : shortlistColumnStyles.actionColumn}>
                <View style={{ flexDirection: isMobileApp ? 'column' : 'row', alignItems: 'center'}}>
                    {isMobileApp ? null : notSavedChangesWarning}
                    {markedForDelete ? <IconButton icon={<MaterialCommunityIcons name={'undo'} color={appColors.green600} size={24}/>}
                                                    action={()=>toggleDeleteAction(employee.employeeId)} />
                        : <DeleteIconButton  action={()=>toggleDeleteAction(employee.employeeId)}/> }
                    </View>

            </View>


        </View>

            {isMobileApp ? <View style={{alignItems: 'flex-end'}}>
            <Spacer y={4}/>
            {notSavedChangesWarning}
                <Spacer y={4}/>
            </View> : null}

        </View>
    );
};

export {ShortlistEmployeeRow};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 2,
        flex: 1,
    },
    markedForDelete: {
        opacity: 0.7,
        backgroundColor: appColors.gray100,
    },
    rowItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 4,
    },
    statusText: mbTextStyles([
        textStyles.Inter_16_500, {
            fontSize: 12,
            textAlign: 'left',
        },
    ]),

    buttonBase: {
        maxHeight: 20,
        width: 80,
    },

    accept: {
        backgroundColor: appColors.green600,
    },
    acceptOutlined: {
        borderColor: appColors.green600,
    },

    decline: {
        backgroundColor: appColors.red500,
    },
    declineOutlined: {
        borderColor: appColors.red500,
    },

    changesText: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
        },
    ]),
});
