import React, { useState, useRef, useCallback, useMemo, useEffect } from 'react';
import { View, Text, StyleSheet, ViewStyle, StyleProp } from 'react-native';
import {Checkbox} from '../components/helperComponents/misc/Checkbox';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { initialStyles } from './useAppInitialSetup';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const useCheckboxGroup = ({
    options,
    initialValues,
    disabled,
    containerStyle,
    otherFieldPlaceholder,
    watchForOther,
    onValueChange,
    viewOnly,
}: {
    options: { label: string; value: string; }[]
    initialValues: string[];
    disabled?: boolean;
    containerStyle?: StyleProp<ViewStyle>
    otherFieldPlaceholder?: string;
    watchForOther?: string;
    onValueChange?: () => void;
    viewOnly?: boolean;

}) => {
    const [values, setValues] = useState(initialValues);
    const watchForOtherRef = useRef(watchForOther);
    const reset = useRef(onValueChange);
    const groupOptions = useRef(options);
    const viewOnlyRef = useRef(viewOnly);
    const [showOther, setShowOther] = useState(false);
    const [otherValue, setOtherValue] = useState('');

    useEffect(() => {
        if (!watchForOtherRef.current) {
            return;
        }
        const checkBoxValues = groupOptions.current.map(option => option.value);
        const other = values.find(value => !checkBoxValues.includes(value));
        if (other) {
            setOtherValue(other);
        }
        setShowOther(values.includes(watchForOtherRef.current));

    }, [values, setShowOther, setOtherValue]);

    const handleChange = useCallback((value, isChecked) => {
        if (viewOnlyRef.current) {
            return;
        }
        if (isChecked) {
            setValues(prev=>prev.filter(v=>v !== value));
        } else {
            setValues(prev=>[...prev, value]);
        }
    }, [setValues]);

    const isChecked = useCallback((value) => {
        return values.includes(value);
    }, [values]);

    const getValues = useMemo(() => {
        if (otherValue?.replace(/\s/g, '').length > 0) {
            return [...values, otherValue];
        }
        return values;
    }, [otherValue, values]);

    useEffect(() => {
       if (reset.current) {
           reset.current();
       }
    }, [values]);

    const styles = useMemo(()=>platformStyles({isMobile: isMobileApp}),[]);

    const Component = useMemo(() => {
         return (
             <View style={[styles.container, containerStyle]}>
                        {groupOptions.current.map((option, index) => {
                            const isSelected = isChecked(option.value);
                            return (
                               <View style={styles.checkboxContainer} key={`${index}-${option.value}`}>
                                   <Checkbox isChecked={isSelected} onPress={() => handleChange(option.value, isSelected)} disabled={viewOnlyRef.current}/>
                                   <Text style={[initialStyles.textInputTextStyle, {marginLeft: -4}]}>{option.label}</Text>
                               </View>
                           );
                        })}
                       {(watchForOtherRef.current && showOther) ? <MB_TextInput placeholder={otherFieldPlaceholder}
                                                                        value={otherValue}
                                                                        onChangeText={(newText: string) => {
                                                                            setOtherValue(newText);
                                                                        }}
                                                                        style={{marginTop: 8, marginLeft: 10}}
                                                                        disable={disabled}
                           />
                           : null}

             </View>
         );
    }, [isChecked, handleChange, showOther, otherValue, disabled, containerStyle, otherFieldPlaceholder, styles]);

    return {
        CheckBoxGroup: Component,
        values: getValues,
        setValues,
        setShowOther,
        setOtherValue,
    };

};

export {useCheckboxGroup};

const platformStyles = ({isMobile}: {
    isMobile: boolean;
}) => StyleSheet.create({
    container: {
        alignItems: isMobile ? 'flex-start' : 'center',
        flexDirection: isMobile ? 'column' : 'row',
        flexWrap: 'wrap',
        marginLeft: -10,
    },
    checkboxContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: 20,
    },
});
