import React from 'react';
import { StyleSheet, Linking } from 'react-native';
import { COLORS } from '../../constants/colors';
import { MB_Button, MB_ButtonProps } from '@mightybyte/rnw.components.button';
import DeviceInfo from 'react-native-device-info';
import { envs } from '../../../env';


const UpdateAppButton = (props: MB_ButtonProps) => {
    const openAppPageOnStore = async () => {
        const baseOS = await DeviceInfo.getBaseOs();
        if (baseOS === 'Android') {
            Linking.openURL(envs.PLAY_STORE_DEEP_LINK);
        } else {
            Linking.openURL(envs.APP_STORE_DEEP_LINK);
        }
    };

    return (
        <MB_Button
            {...props}
            onPress={openAppPageOnStore}
            style={[styles.updateBtn, props.style]}
        />
    );
};

export { UpdateAppButton };

const styles = StyleSheet.create({
    updateBtn: {
        marginTop: 30,
        width: '100%',
        alignSelf: 'center',
        height: 44,
        backgroundColor: COLORS.buttonPrimaryBg,
    },
});
