import React, {useState, useCallback} from 'react';
import {drawerWidth} from '../constants/elementSizes';
import { StyleProp, ViewStyle } from 'react-native';

export interface IDrawerContext {
    drawerWidth: number,
    hideDrawer: () => void,
    showDrawer: () => void,
    isOpen: boolean,
    toggleDrawer: () => void,
    drawerStyle: StyleProp<ViewStyle>
}

const DrawerContext = React.createContext<IDrawerContext | undefined>(undefined);

const DrawerContextProvider = ({ children }: { children?: React.ReactNode }) => {
    const [width, setWidth] = useState<number>(drawerWidth);

    const toggleDrawer = useCallback(() => {
        setWidth(width === 100 ? drawerWidth : 100);

    }, [setWidth, width]);

    const hideDrawer = () => {
        setWidth(100);
    };

    const showDrawer = () => {
        setWidth(width);
    };

    return (
        <DrawerContext.Provider value={{
            drawerWidth: width,
            hideDrawer,
            showDrawer,
            isOpen: width > 100,
            toggleDrawer,
            drawerStyle: {
                paddingHorizontal: 0,
                paddingTop: 16,
                width: width,
            },
        }}>
            {children}
        </DrawerContext.Provider>
    );
};

function useDrawerContext() {
    const context = React.useContext(DrawerContext);
    if (context === undefined) {
        throw new Error('useDrawerContext must be used within a DrawerContextProvider');
    }
    return context;
}

export { DrawerContextProvider, useDrawerContext };
