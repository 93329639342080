import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React, { useCallback, useMemo, useState } from 'react';
import { Text, View } from 'react-native';
import { getColors } from '../../../constants/colors';
import {applyTTStyles} from '../../../constants/colors';
import {
    UniversalScreenNavigationProp,
} from '../../../typesAndInterfaces/componentProps';
import { sharedStyles } from '../../../utils/styleUtils';
import { errorInputStyles, WrappedInput } from '../../helperComponents/misc/WrappedInput';
import { OutlinedButton } from '../../helperComponents/misc/OutlinedButton';
import { Spacer } from '../../helperComponents/misc/Spacer';
import { useNavigation } from '@react-navigation/core';
import {isValidEmail} from '../../../utils/validationUtils';
import { STRING_CONSTANTS } from '../../../constants/constants';
import {getStyles} from './ForgotPasswordStyles';
import { createWebsiteBaseUrl, envs } from '../../../../env';
import {trpc} from '../../../apiCalls/trpcClient';
import {MB_customWindowForWeb} from '@mightybyte/rnw.utils.custom-window-for-web';
import { utils } from '../../../utils/utils';
import {navigateToSignIn} from './utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { usePhoneNumber } from '../../../hooks/usePhoneNumber';
import { textStyles } from '../../../constants/textStyles';

const RequestReset = ({subdomain}: {
    subdomain: string,
    recoveryFailed?: boolean,
}) => {
    const COLORS = getColors();
    const navigation = useNavigation<UniversalScreenNavigationProp>();
    const shouldUseTTStyles = utils.isTTEmployee(subdomain);
    const styles = useMemo(() => getStyles(COLORS), [COLORS]);
    const [email, setEmail] = useState('');
    const [errorText, setErrorText] = useState('');

    const [recoveryMethod, setRecoveryMethod] = useState<'email' | 'phone' | undefined>(undefined);

    const [linkSent, setLinkSent] = useState(false);

    const {mutate: requestPasswordRecovery, isLoading} = trpc.user.sendPasswordRecoveryEmail.useMutation({
        trpc: { context: { skipBatch: true } },
        onSuccess: () => {
            setRecoveryMethod('email');
        },
    });
    const {mutate: requestPasswordRecoveryByPhone, isLoading: isLoadingByPhone} = trpc.user.sendPasswordRecoverySMS.useMutation({
        trpc: { context: { skipBatch: true } },
        onSuccess: () => {
            setRecoveryMethod('phone');
        },
    });

    const phoneNumber = usePhoneNumber({
        subText: 'Note: only temporary workers may use their phone number to sign in.',
        label: '',
        placeholder: 'Enter your cell phone number',
        hideError: true,
    });


    const onSubmitReset = useCallback(() => {
        const method = email && email.length > 0 ? 'email' : 'phone';
        if (method === 'phone'){
            const validPhone = phoneNumber.validate();
            if (!validPhone) {
                setErrorText(STRING_CONSTANTS.ENTER_VALID_EMAIL_OR_PHONE);
                return;
            } else {
                requestPasswordRecoveryByPhone({phoneNumber: phoneNumber.value});
            }
        } else {
            const validEmail = isValidEmail(email);

            if (!validEmail) {
                setErrorText(STRING_CONSTANTS.ENTER_VALID_EMAIL_OR_PHONE);
                return;
            } else {
                requestPasswordRecovery({email: email});
            }
        }

        setLinkSent(true);
    }, [email, phoneNumber, requestPasswordRecovery, requestPasswordRecoveryByPhone]);

    const emailHasError = useMemo(() => {
        return errorText.length > 0 && phoneNumber.value.length === 0;
    } , [errorText, phoneNumber.value]);

    const submitDisabled = useMemo(() => {
        return (!email || email.length === 0 || emailHasError || isLoading) &&
            (phoneNumber.value.length === 0 || isLoadingByPhone);
    }, [email, emailHasError, isLoading, isLoadingByPhone, phoneNumber.value]);



    const navigateToEnterUrl = useCallback(() => {
        if (isMobileApp) {
            navigation.navigate('EnterUrl');
        } else {
            const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
            MB_customWindowForWeb.open(`${baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN)}/enterUrl`, '_self');
        }

    }, [navigation]);

    const goBack = useCallback(() => {
        navigation.goBack();
    }, [navigation]);

    const cancelResetPassword = useCallback(() => {
        if (navigation.canGoBack()) {
            goBack();
        } else {
            navigateToEnterUrl();
        }
    },[navigation, goBack, navigateToEnterUrl]);

    const repeatResetPassword = useCallback(() => {
        setLinkSent(false);
        setEmail('');
        navigation.navigate('ForgotPassword');
    }, [navigation]);


    const resetPasswordContent = useMemo(() => {
        const header = linkSent ? recoveryMethod === 'email' ? 'Reset Email Sent' : 'Reset SMS Sent'
            : 'Reset Password';
        const helperText = linkSent ? recoveryMethod === 'email' ?
            'Check your inbox and follow the instructions to reset your password.' : 'SMS sent to your phone number. Follow the instructions to reset your password.' :
            'Enter the email address or phone number associated with your account and we\'ll send you a link to reset your password.';
        const helperText2 = linkSent && recoveryMethod === 'email' ? 'If you don\'t see the email, make sure to check your spam or junk folder.' : null;
        const actionButton = {
            text: linkSent ? 'Return to sign-in' : 'Send reset link',
            action: linkSent ? ()=>navigateToSignIn(utils.isTTEmployee(subdomain), subdomain) : onSubmitReset,
            disabled: linkSent ? false : submitDisabled,
        };
        const secondaryButton = {
            text: linkSent ? recoveryMethod === 'email' ? 'Didn\'t get an email?' : 'Didn\'t get an SMS?' : 'Cancel',
            action: linkSent ? repeatResetPassword : cancelResetPassword,
            disabled: linkSent ? false : false, //todo disable while request is in progress
        };
        return (
            <View style={styles.resetPasswordContainer}>
                <View style={[styles.subContainer, styles.subContainerPlatformStyles, {width: '100%'}]}>
                    <Text style={[styles.forgotPwdText, styles.forgotPwdPlatformText]}>{header}</Text>
                    <View style={styles.textContainer}>
                        <Text style={[styles.helperText, styles.helperTextPlatformStyles]}>{helperText}</Text>
                    </View>

                        {linkSent ? <>
                            <Spacer y={21}/>
                            <Text style={styles.helperText}>{helperText2}</Text>
                        </> : <View>
                            <WrappedInput
                                            inputComponent={<MB_TextInput
                                                autoCapitalize={'none'}
                                                style={[sharedStyles.textInputFullWidthAndTopSpace, emailHasError && errorInputStyles.input]}
                                                placeholderTextColor={emailHasError ? getColors().inputErrorText : getColors().inputPlaceholder}
                                                textInputStyle={mbTextStyles([
                                                    emailHasError && errorInputStyles.text,
                                                ])}
                                                titleStyle={emailHasError && errorInputStyles.label}
                                                value={email}
                                                onChangeText={(newText: string) => {
                                                    setErrorText('');
                                                    setEmail(newText);
                                                }}
                                                placeholder="Enter your email address"
                                                textContentType="emailAddress"
                                                onSubmitEditing={actionButton.action}
                                            />}
                        />
                            {!utils.isTTEmployee(subdomain) ? <View style={{alignItems: 'center'}}>
                                <Text style={[styles.helperText, styles.helperTextPlatformStyles, {marginBottom: 16}]}>or</Text>
                                {phoneNumber.field}
                            </View> : null}
                        </View>}
                    <Spacer y={24}/>
                    <Text>
                        <Text style={[textStyles.popUpErrorMessageText]}>{errorText}</Text>
                    </Text>
                    <Spacer y={6}/>

                    <MB_Button
                        title={actionButton.text}
                        onPress={actionButton.action}
                        disabled={actionButton.disabled}
                        key={`Disabled:${actionButton.disabled}`}
                        style={[styles.submitButton, {backgroundColor: applyTTStyles(shouldUseTTStyles).primaryButtonBg}, actionButton.disabled && sharedStyles.disabledButton]}
                        preventDisabledStyleChange={true}
                        loading={isLoading || isLoadingByPhone}
                    />
                    <Spacer y={12}/>

                    <OutlinedButton title={secondaryButton.text}
                                    action={secondaryButton.action}
                                    disabled={secondaryButton.disabled}
                                    extraStyles={styles.submitButton}
                    />

                </View>
            </View>
        );
    }, [linkSent, recoveryMethod, onSubmitReset, submitDisabled, repeatResetPassword, cancelResetPassword,
        errorText, emailHasError, email, subdomain, phoneNumber.field, shouldUseTTStyles, styles, isLoadingByPhone, isLoading]);

    return resetPasswordContent;
};

export { RequestReset };
