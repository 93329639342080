import React, { useMemo } from 'react';
import {
    ShortlistAcceptanceStatus,
    toShortlistAcceptanceStatusLabel,
} from '@temptrack/business';
import { appColors } from '../../../../constants/colors';
import { Badge } from './Badge';


const colorMap = {
    ['NA']: {
        text: appColors.gray800,
        background: appColors.gray200,
    },
    [ShortlistAcceptanceStatus.none]: {
        text: appColors.red800,
        background: appColors.red100,
    },
    [ShortlistAcceptanceStatus.some]: {
        text: appColors.yellow800,
        background: appColors.yellow100,
    },
    [ShortlistAcceptanceStatus.all]: {
        text: appColors.green800,
        background: appColors.green100,
    },
};

const getBadgeColor = (status: ShortlistAcceptanceStatus | 'NA' |undefined ) => {
    if (!status) {return {text: '', background: ''};}
    return colorMap[status];
};


const AcceptanceStatusRenderer = ({acceptanceStatus, displayRow}: {
    acceptanceStatus: {
                          status: ShortlistAcceptanceStatus
                          assignedToQty: number | undefined,
                          acceptedQty?: number | undefined,

                      } | undefined,
    displayRow?: boolean
}) => {


    const color = useMemo(()=>{
       return getBadgeColor(acceptanceStatus?.assignedToQty === 0 ? 'NA' : acceptanceStatus?.status);
    },[acceptanceStatus?.status, acceptanceStatus?.assignedToQty]);

    if (!acceptanceStatus) {return null;}

    const amountsString = acceptanceStatus.acceptedQty !== undefined
    && acceptanceStatus.assignedToQty === undefined ? `${acceptanceStatus.acceptedQty ?? 0}/${acceptanceStatus.assignedToQty}` : '';


    return (
        <Badge text={toShortlistAcceptanceStatusLabel(acceptanceStatus.status)}
                amounts={amountsString}
                color={color}
                displayRow={displayRow ?? false}
        />
    );

};

export {AcceptanceStatusRenderer};
