import {
    ChecklistActivityStatus,
    ChecklistAssignmentStatus,
    CheckInsStatus,
    CheckInRejectionReason,
    ShortlistAcceptanceStatus,
    EmployeeAcceptanceStatus,
} from '.';
import { WorkOrderStatus } from '../workOrder';

export function toActivityStatusLabel(
    activityStatus: ChecklistActivityStatus,
): string {
    return {
        [ChecklistActivityStatus.active]: 'Active',
        [ChecklistActivityStatus.inactive]: 'Inactive',
        [ChecklistActivityStatus.closed]: 'Closed',
    }[activityStatus];
}

export function toActivityStatus(
    assignedToQty: number,
    workOrderStatus: WorkOrderStatus,
    currentActivityStatus?: ChecklistActivityStatus,
): ChecklistActivityStatus {
    if (currentActivityStatus === ChecklistActivityStatus.closed) {
        return currentActivityStatus;
    }

    if (workOrderStatus === WorkOrderStatus.completed) {
        return ChecklistActivityStatus.inactive;
    }

    return assignedToQty > 0
        ? ChecklistActivityStatus.active
        : ChecklistActivityStatus.inactive;
}

export function toAssignmentStatusLabel(
    assignmentStatus: ChecklistAssignmentStatus,
): string {
    return {
        [ChecklistAssignmentStatus.none]: 'None assigned',
        [ChecklistAssignmentStatus.some]: 'Some assigned',
        [ChecklistAssignmentStatus.all]: 'All assigned',
    }[assignmentStatus];
}

export function toAssignmentStatus(
    assignedToQty: number,
    employeesNeededQty: number,
): ChecklistAssignmentStatus {
    if (assignedToQty === 0) {
        return ChecklistAssignmentStatus.none;
    }

    if (assignedToQty === employeesNeededQty) {
        return ChecklistAssignmentStatus.all;
    }

    return ChecklistAssignmentStatus.some;
}

export function toCheckInsStatusLabel(checkInsStatus: CheckInsStatus): string {
    return {
        [CheckInsStatus.notApplicable]: 'N/A',
        [CheckInsStatus.none]: 'None checked-in',
        [CheckInsStatus.some]: 'Some checked-in',
        [CheckInsStatus.all]: 'All checked-in',
    }[checkInsStatus];
}

export function toCheckInsStatus(
    activityStatus: ChecklistActivityStatus,
    checkInsQty: number,
    assignedToQty: number,
): CheckInsStatus {
    if (
        activityStatus === ChecklistActivityStatus.inactive ||
        activityStatus === ChecklistActivityStatus.closed
    ) {
        return CheckInsStatus.notApplicable;
    }

    if (checkInsQty === 0) {
        return CheckInsStatus.none;
    }

    if (checkInsQty === assignedToQty) {
        return CheckInsStatus.all;
    }

    return CheckInsStatus.some;
}

export function toCheckInRejectionReasonLabel(
    reason: CheckInRejectionReason,
): string {
    return {
        [CheckInRejectionReason.noShow]: 'No-show',
        [CheckInRejectionReason.late]: 'Late',
        [CheckInRejectionReason.performance]: 'Performance',
        [CheckInRejectionReason.missingEquipment]: 'Missing Equipment',
    }[reason];
}

export function toShortlistAcceptanceStatusLabel(
    acceptanceStatus: ShortlistAcceptanceStatus,
): string {
    return {
        [ShortlistAcceptanceStatus.none]: 'None accepted',
        [ShortlistAcceptanceStatus.some]: 'Some accepted',
        [ShortlistAcceptanceStatus.all]: 'All accepted',
    }[acceptanceStatus];
}

export function toShortlistAcceptanceStatus(
    acceptedQty: number,
    assignedToQty: number,
): ShortlistAcceptanceStatus {
    if (acceptedQty === 0) {
        return ShortlistAcceptanceStatus.none;
    }

    if (acceptedQty === assignedToQty) {
        return ShortlistAcceptanceStatus.all;
    }

    return ShortlistAcceptanceStatus.some;
}

export function toEmployeeAcceptanceStatusLabel(
    acceptanceStatus: EmployeeAcceptanceStatus,
): string {
    return {
        [EmployeeAcceptanceStatus.pending]: 'Awaiting response',
        [EmployeeAcceptanceStatus.declined]: 'Declined',
        [EmployeeAcceptanceStatus.accepted]: 'Accepted',
    }[acceptanceStatus];
}
