import { ViewType } from './types';

export const getEmptyStrings = (viewType: ViewType) => {
    switch (viewType) {
        case 'active':
            return {
                title: 'You have no active jobs',
                subtitle: '',
            };
        case 'previous':
            return {
                title: 'You have no previous jobs',
                subtitle: '',
            };
        case 'offers':
            return {
                title: 'You have no job offers',
                subtitle: '',
            };
        default: {
            return {
                title: 'No records found',
                subtitle: '',
            };
        }
    }
};
