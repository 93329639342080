import React from 'react';
import { GestureResponderEvent, View } from 'react-native';
import { EditIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { COLORS } from '../../../../constants/colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const Actions = ({onEditPress, threeDotAction}: {
    onEditPress: () => void | undefined,
    threeDotAction: (e:GestureResponderEvent)=>void
})=> {
    return (
        <View style={{
            flexDirection: 'row',
            alignSelf: 'flex-end',
            position: 'relative',
        }}>
            {!isMobileApp ? <EditIconButton action={onEditPress}/> : null}
            <IconButton icon={
                <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
            }
                        action={(e:GestureResponderEvent)=>threeDotAction(e)}
            />
        </View>
    );
};

export {Actions};
