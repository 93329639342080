import { StyleSheet } from 'react-native';

const shortlistColumnStyles = StyleSheet.create({
    col1: {
        flex: 1,
    },
    col2: {
        flex: 1,
        paddingVertical: 4,
    },
    col3: {
        flex: 1,
    },
    actionColumn: {
        width: 300,
        alignItems: 'flex-end',
    },
    actionColumnMobile: {
        width: 50,
        alignItems: 'flex-end',
    },
});

export {shortlistColumnStyles};
