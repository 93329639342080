import { z } from 'zod';
import { ImageFileExtension, TEMPTRACK_SUBDOMAIN } from '../..';

export const StringSchema = z.string().trim();

export const PaginationInputSchema = z.object({
    page: z.number().int().positive(),

    /**
     * @max 100
     * @default 10
     */
    pageSize: z.number().int().positive().max(100).optional().default(10),

    /**
     * Token obtained from the previous call.
     */
    lastToken: StringSchema.optional().nullable(),
});

export const InfiniteScrollingInputSchema = z.object({
    /**
     * Cursor obtained from a previous call. The result will be
     * items after this cursor. If ommitted, the result will be
     * items from the beginning of the list.
     */
    cursor: StringSchema.optional().nullable(),

    /**
     * The number of items to return.
     *
     * @max 100
     * @default 10
     */
    limit: z.number().int().positive().max(100).optional().default(10),
});

export const ObjectIdSchema = z
    .string()
    .regex(/^[0-9a-fA-F]{24}$/, 'The provided id has an invalid format.');

/**
 * Valid time formats:
 * - 1:00am
 * - 01:00am
 * - 12:59pm
 */
export const TimeInputSchema = z
    .string()
    .regex(/^(?:0?\d|1[0-2]):[0-5]\d(?:am|pm)$/);

export const DateInputSchema = z.object({
    /**
     * Day of the month between 1 and 31.
     */
    day: z.number().int().positive().min(1).max(31),

    /**
     * Month of the year between 1 and 12.
     */
    month: z.number().int().positive().min(1).max(12),

    /**
     * Full year, e.g. 2024.
     */
    year: z.number().int().positive().min(2000),
});

export const LogoUploadInputSchema = z.object({
    fileExtension: z.nativeEnum(ImageFileExtension),
});

export const CompanyDomainSchema = z
    .string()
    .transform((value) => value.trim().toLowerCase())
    .refine(
        (value) => {
            return value !== TEMPTRACK_SUBDOMAIN;
        },
        {
            message: `The domain must not be "${TEMPTRACK_SUBDOMAIN}".`,
        },
    )
    .refine(
        (value) => {
            return /^[a-z]+$/.test(value);
        },
        {
            message: 'The domain must contain only letters.',
        },
    );

export const EmailSchema = StringSchema.email().trim().toLowerCase();

export const PhoneNumberSchema = StringSchema.trim();

export const AlphanumericSchema = z
    .string()
    .min(1)
    .transform((value) => value.trim())
    .refine(
        (value) => {
            return /^[a-zA-Z0-9\s]+$/.test(value);
        },
        {
            message: 'Must contain only alphanumeric characters and spaces.',
        },
    );

export const AddressSchema = z.object({
    line1: StringSchema,
    line2: StringSchema.optional(),
    state: StringSchema,
    city: StringSchema,
    zipCode: StringSchema,
});
