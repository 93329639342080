import React, { useImperativeHandle, useMemo, useRef } from 'react';
import { View, StyleSheet} from 'react-native';
import { KeyboardAwareScrollView } from 'react-native-keyboard-aware-scroll-view';
import { getColors } from '../../../constants/colors';
import { ComponentWrapperProps } from './ComponentWrapperUtils';
import { Header } from '../Header/Header';


const ComponentWrapper = ({
                              MB_Ref,
                              children,
                              containerStyle,
    innerContainerStyle,
    hideWebHeader,
    headerProps,
    onInnerComponentLayout,
                              onLayout,
                              onScroll,
                              wrapInScrollView = true,
                              keyboardShouldPersistTapsForScrollView = 'always',
                          }: ComponentWrapperProps) => {
    const scrollRef = useRef<KeyboardAwareScrollView>(null);

    useImperativeHandle(MB_Ref, () => ({
        scrollTo: ({ x, y, animated }) => {
            if (!scrollRef.current) {
                console.warn('Warning: ScrollRef was not set. Was wrapInScrollView set to false?');
                return;
            }
            scrollRef.current.scrollToPosition( x ?? 0, y ?? 0, animated);
        },
    }));

    const content = useMemo(() => {
        return (
            <>
                <View style={[styles.innerContainer, innerContainerStyle]} onLayout={onInnerComponentLayout}>
                    {hideWebHeader ? null : <Header routeName={headerProps?.routeName || ''}
                            headerMenuAction={headerProps?.headerMenuAction}
                    />}
                    {children}
                </View>
            </>
        );
    }, [children, headerProps?.headerMenuAction, headerProps?.routeName, hideWebHeader, innerContainerStyle, onInnerComponentLayout]);

    if (wrapInScrollView) {
        return (
            <KeyboardAwareScrollView
                ref={scrollRef}
                onLayout={onLayout}
                onScroll={onScroll}
                scrollEventThrottle={64}
                contentContainerStyle={[styles.container,containerStyle]}
                keyboardShouldPersistTaps={keyboardShouldPersistTapsForScrollView}
            >
                {content}
            </KeyboardAwareScrollView>
        );
    }

    return (
        <View style={[styles.container, containerStyle]} onLayout={onLayout}>
            {content}
        </View>
    );
};

export { ComponentWrapper };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        justifyContent: 'space-between',
    },
    innerContainer: {
        //maxWidth: 1680,
        //paddingHorizontal: 54,
        alignSelf: 'center',
        width: '100%',
        flex: 1,
    },
});
