import { z } from 'zod';
import { FilterMapping } from '../..';
import { DateInput } from './types';

function dateInputToDate(date: DateInput): Date {
    return new Date(date.year, date.month - 1, date.day);
}

export function isStartDateBeforeEndDate(data: {
    startDate: DateInput;
    endDate?: DateInput;
}): boolean {
    return data.endDate === undefined
        ? true
        : dateInputToDate(data.startDate) <= dateInputToDate(data.endDate);
}

export const startDateNotBeforeEndDateError = {
    message: 'The start date must be before the end date.',
    path: ['startDate', 'endDate'],
};

type FilterSchemaMap<T extends FilterMapping> = {
    [K in keyof T]: T[K]['filterFieldSchema'];
};
export function filterMappingToSchema<M extends FilterMapping>(mapping: M) {
    const schema = Object.entries(mapping).reduce((acc, [key, value]) => {
        return {
            ...acc,
            [key]: value.filterFieldSchema,
        };
    }, {} as FilterSchemaMap<M>);

    return z.object(schema).partial().optional();
}
