import { z } from 'zod';
import {
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    StringSchema,
} from '../common';

export const AddFirebaseDeviceTokenInputSchema = z.object({
    token: StringSchema,
});

export const MarkNotificationAsReadInputSchema = z.union([
    z.object({
        notificationId: ObjectIdSchema,
        notificationIds: z.undefined(),
        all: z.undefined(),
    }),
    z.object({
        notificationIds: z
            .array(ObjectIdSchema)
            .transform((ids) => Array.from(new Set(ids))),
        notificationId: z.undefined(),
        all: z.undefined(),
    }),
    z.object({
        all: z.literal(true),
        notificationId: z.undefined(),
        notificationIds: z.undefined(),
    }),
]);

export const ListNotificationsInputSchema = InfiniteScrollingInputSchema.extend(
    {
        isRead: z.boolean().optional(),
    },
);
