import { WC_CODE } from '@temptrack/business';

const getCodeOptionsForState = (stateName?: string) => {
    if (!stateName) {
        return [];
    }
    return Object.values(WC_CODE)
        .filter((c) => c.state.toLowerCase() === stateName.toLowerCase())
        .map((c) => ({
            value: c.code,
            label: `${c.code}`,
        }));
};

const getDescriptionForCode = (code: string) => {
    return (
        Object.values(WC_CODE).find((c) => c.code === code)?.description ?? code
    );
};

export { getCodeOptionsForState, getDescriptionForCode };
