import React from 'react';
import { ViewChecklistProps } from '../../../../typesAndInterfaces/componentProps';
import { ViewChecklist } from './Checklist/ViewChecklist';
import { ViewShortlist } from './Shortlist/ViewShortlist';

const CheckOrShortListView = ({navigation, route}:ViewChecklistProps)=>{
    const currentScreen = route.params?.viewMode;

    if (currentScreen === 'shortlist'){
        return <ViewShortlist navigation={navigation} route={route}/>;
    }
    return <ViewChecklist navigation={navigation} route={route}/>;
};

export {CheckOrShortListView};
