import React, { useCallback, useMemo, useRef, RefObject } from 'react';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { WrappedInput } from '../components/helperComponents/misc/WrappedInput';
import { getColors } from '../constants/colors';
import { STRING_CONSTANTS } from '../constants/constants';

const COLORS = getColors();

const useEmail = ({isRequired, nextToFocus, onSubmitEditing, disabled}: {
    isRequired?: boolean;
    nextToFocus?: RefObject<MB_TextInputRef>;
    onSubmitEditing?: () => void;
    disabled?: boolean;
}) => {
    const [email, setEmail] = React.useState<string>('');
    const [emailError, setEmailError] = React.useState<string | undefined>(undefined);

    const ref = useRef<MB_TextInputRef>(null);

    const onEmailChange = useCallback((newEmail: string) => {
        setEmail(newEmail);
        setEmailError(undefined);
    }, []);

    const validateEmail = useCallback(() => {
        let isValid = true;
        if (isRequired && (!email || email.length === 0)) {
            isValid = false;
            setEmailError(STRING_CONSTANTS.REQUIRED);
        } else if (email && !email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
            isValid = false;
            setEmailError('Invalid email');
        }
        return isValid;
    }, [email, isRequired]);

    const field = useMemo(() => {
        return (
            <WrappedInput errorMessage={emailError}
                             inputComponent={<MB_TextInput
                                 MB_Ref={ref}
                                 style = {{width: '100%'}}
                                 placeholderTextColor={COLORS.inputPlaceholder}
                                 value = {email}
                                 onChangeText={onEmailChange}
                                 title={'Email'}
                                 placeholder={'name@company.com'}
                                 textContentType={'emailAddress'}
                                 showRequiredAsteriks={isRequired}
                                 nextToFocus={nextToFocus}
                                 onSubmitEditing={onSubmitEditing ?? undefined}
                                 disable={disabled}
                             />}
        />
        );
    }, [email, emailError, onEmailChange, isRequired, disabled, nextToFocus, onSubmitEditing]);

    return {
        value: email,
        emailError,
        setInitialEmail: setEmail,
        validateEmail,
        field,
        ref: ref,
    };
};

export { useEmail };
