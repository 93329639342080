import React, { useEffect, useMemo, useState } from 'react';
import {View, StyleSheet, ViewStyle, StyleProp} from 'react-native';

import {RadioButton} from '../components/helperComponents/RadioButton';

const useRadioButtonGroup = ({
                                 options,
                                 initialValue,
                                 disabled,
                                 containerStyle,
    onValueChange,
    viewOnly,

                             }: {
    options: { label: string; value: string; }[]
    initialValue: string | undefined;
    disabled?: boolean;
    viewOnly?: boolean;
    containerStyle?: StyleProp<ViewStyle>
    onValueChange?: () => void;
}) => {
    const optionsRef = React.useRef(options);
    const reset = React.useRef(onValueChange);
    const containerStyleRef = React.useRef(containerStyle);
    const [value, setValue] = useState<string|undefined>(initialValue);

    const handleChange = (v: string) => {
        setValue(v);
    };

    useEffect(() => {
        if (reset.current) {
            reset.current();
        }
    }, [value]);


    const Component = useMemo(() => {
        return (
            <View style={[styles.container, containerStyleRef.current]}>
                {optionsRef.current.map((option, index) => {
                    return (
                        <RadioButton
                            key={index}
                            value={option.value}
                            groupValue={value}
                            setGroupValue={handleChange}
                            radioText={option.label}
                            containerStyle={styles.radioButtonContainer}
                            disabled={disabled}
                            viewOnly={viewOnly}
                        />
                    );
                })}
            </View>
        );
    }, [value, disabled, viewOnly]);

    return {
        value,
        RadioButtonGroup: Component,
        setValue,

    };
};

export {useRadioButtonGroup};

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        flexDirection: 'row',
        flexWrap: 'wrap',
    },
    radioButtonContainer: {
        marginEnd: 16,
    },
});
