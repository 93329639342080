import React from 'react';
import { UserRole, toUserRoleLabel } from '@temptrack/business';
import { useSignedInContext } from '../context/SignedInContext';


const useRoles = ()=> {
    const {currentUserData} = useSignedInContext();
    const role = currentUserData?.role;


    const roleOptions = React.useMemo(()=> {
        let options:UserRole[] = [];
        switch (role) {
            case UserRole.admin: {
                options = [UserRole.companyAdmin, UserRole.regionAdmin, UserRole.recruiter];
                break;
            }
            case UserRole.companyAdmin: {
                options = [UserRole.companyAdmin, UserRole.regionAdmin, UserRole.recruiter];
                break;
            }
            case UserRole.regionAdmin: {
                options = [UserRole.recruiter];
                break;
            }

            default: {
                break;
            }
        }
        return options.map((r)=> {
            return {label: toUserRoleLabel(r), value: r};
        });

    }, [role]);

    return {
        roleOptions,
    };
};

export {useRoles};
