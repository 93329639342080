import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ScrollView, Text, View } from 'react-native';
import {
    CreateWorkOrderInput,
    toWorkOrderJobsiteEnvironmentLabel,
    toWorkOrderLanguageLabel,
    toWorkOrderPayFrequencyLabel,
    toWorkOrderPositionLabel,
    toWorkOrderPositionTypeLabel,
    toWorkOrderPPELabel,
    toWorkOrderShiftLabel,
    UserRole,
    WorkOrderJobsiteEnvironment,
    WorkOrderLanguage,
    WorkOrderPayFrequency,
    WorkOrderPosition,
    WorkOrderPositionType,
    WorkOrderPPE,
    WorkOrderShift,
} from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputInputType,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { WrappedDatePicker } from '../../../helperComponents/misc/WrappedDatePicker';
import { ConditionalFields, useConditionalFields } from '../../../../hooks/useConditionalFields';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { DropDownDirectionType, MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { startAndEndDatesAreValid } from '../../../../utils/validationUtils';
import { useAddress } from '../../../../hooks/useAddress';
import { dollarMask, utils } from '../../../../utils/utils';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { envs } from '../../../../../env';
import { useRegions } from '../../../../hooks/useRegions';
import { useCheckboxGroup } from '../../../../hooks/useCheckboxGroup';
import { useRadioButtonGroup } from '../../../../hooks/useRadioButtonGroup';
import { useBooleanRadioGroup } from '../../../../hooks/useBooleanRadionGroup';
import { TimePicker } from '../../../helperComponents/TimePicker';
import {addDays} from 'date-fns';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    clientName        = 'clientName',
    startDate         = 'startDate',
    endDate           = 'endDate',
    startTime         = 'startTime',
    endTime           = 'endTime',
    position          = 'position',
    positionType      = 'positionType',
    numberOfEmployees = 'numberOfEmployees',
    payRate           = 'payRate',
    payFrequency      = 'payFrequency',
    shift             = 'shift',
    experience         = 'experience',
    license            = 'license',
    certification      = 'certification',
    jobEnvironment     = 'jobEnvironment',
    ppe                = 'ppe',
    note               = 'note',
    branch             = 'branch',
    region = 'region',
    language = 'language',
}

const rolesWithoutRegion = [UserRole.admin, UserRole.companyAdmin];
const rolesWithoutBranch = [UserRole.admin, UserRole.companyAdmin, UserRole.regionAdmin];
const WorkOrderForm = (
    {
        isViewing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        workOrderId,
        resetSubmitError,
        idToDuplicate,
        showTimeline,
    }: {
    workOrderId: string | undefined;
    isViewing: boolean;
    closeAction: () => void;
    action: (data: CreateWorkOrderInput) => void
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    idToDuplicate?: string;
        showTimeline: boolean;
}) => {

   const {currentCompanyId} = useCurrentCompanyContext();
   const {isClient, currentUserData} = useSignedInContext();

    const {getFieldSettings} = useConditionalFields();

    const [initialIsDone, setInitialIsDone] = useState(false);

    const [clientName, setClientName] = useState<string>('');
    const [branchId, setBranchId] = useState<string | undefined>(currentUserData?.branchId ?? undefined);
    const [regionId, setRegionId] = useState<string | undefined>(currentUserData?.regionId ?? undefined);

    useEffect(() => {
        const client = getFieldSettings(ConditionalFields.clientName, undefined);
        if (!client?.allowedToView) {
            setClientName(client.defaultValue);
        }
    }, [getFieldSettings]);

    const [startDate, setStartDate] = useState<string | undefined>(undefined);
    const [endDate, setEndDate] = useState<string | undefined>(undefined);

    useEffect(() => {

        //Be carefull here not to introduce an infinite loop
        if (startDate && utils.isEndDateBeforeStartDate(startDate, endDate)) {
            setEndDate(addDays(new Date(startDate), 1).toISOString());
        }
    }, [startDate, setEndDate, endDate]);

    const [position, setPosition] = useState<WorkOrderPosition | undefined>(undefined);
    const [positionType, setPositionType] = useState<WorkOrderPositionType | undefined>(undefined);
    const [numberOfEmployees, setNumberOfEmployees] = useState<string>('');
    const [payRate, setPayRate] = useState({
        masked: '',
        unmasked: '',
    });

    const payFrequency = useRadioButtonGroup({
        options: Object.values(WorkOrderPayFrequency).map((p) => {
            return {
                label: toWorkOrderPayFrequencyLabel(p),
                value: p,
            };
        }),
        initialValue: undefined,
        onValueChange: () => {
            resetFormErrorMessages(InputTypes.payFrequency);
            resetSubmitError();
        },
        viewOnly: isViewing,
    });

    const shift = useRadioButtonGroup({
        options: Object.values(WorkOrderShift).map((s) => {
            return {
                label: toWorkOrderShiftLabel(s),
                value: s,
            };
        }),
        initialValue: '',
        onValueChange: () => {
            resetFormErrorMessages(InputTypes.shift);
            resetSubmitError();
        },
        viewOnly: isViewing,
    });

    const [startTime, setStartTime] = useState< { hours: number; minutes: number; } | undefined>(undefined);
    const [endTime, setEndTime] = useState< { hours: number; minutes: number; } | undefined>(undefined);
    const [experience, setExperience] = useState<string>('');
    const [license, setLicense] = useState<string>('');
    const [certification, setCertification] = useState<string>('');

    const ppe = useCheckboxGroup({
        options: Object.values(WorkOrderPPE).map((p) => {
            return {
                label: toWorkOrderPPELabel(p),
                value: p,
            };
        }),
        initialValues: [],
        watchForOther: WorkOrderPPE.other,
        otherFieldPlaceholder:  'Please specify other PPE',
        onValueChange: () => {
            resetFormErrorMessages(InputTypes.ppe);
            resetSubmitError();
        },
        viewOnly: isViewing,
    });

    const [jobEnvironment, setJobEnvironment] = useState<WorkOrderJobsiteEnvironment | undefined>(undefined);

    const resume = useBooleanRadioGroup({
        initialValue: false,
        viewOnly: isViewing,
    });

    const interview = useBooleanRadioGroup({
        initialValue: false,
        viewOnly: isViewing,
    });

    const eVerify = useBooleanRadioGroup({
        initialValue: false,
        viewOnly: isViewing,
    });

    const language = useCheckboxGroup({
        options: Object.values(WorkOrderLanguage).map((p) => {
            return {
                label: toWorkOrderLanguageLabel(p),
                value: p,
            };
        }),
        initialValues: [],
        onValueChange: () => {
            resetFormErrorMessages(InputTypes.language);
            resetSubmitError();
        },
        watchForOther: WorkOrderLanguage.other,
        otherFieldPlaceholder:  'Please specify other',
        viewOnly: isViewing,
    });

    const [note, setNote] = useState<string>('');

    const addressForm = useAddress({isOptional: false});

    // Region, branches, client info handling for company roles

    const role = currentUserData?.role;
    const needBranch = useMemo(()=> {
        if (!role || isClient) {return false;}
        return rolesWithoutBranch.includes(role);
    }, [role, isClient]);

    const needRegion = useMemo(()=> {
        if (!role || isClient) {return false;}
        return rolesWithoutRegion.includes(role);
    }, [role, isClient]);

    const branchPermissions = getFieldSettings(ConditionalFields.branch, {
        branchId: currentUserData?.branchId ?? undefined,
        branchName: currentUserData?.branchName ?? undefined,
    });


    const regionPermissions = getFieldSettings(ConditionalFields.region, {
        regionId: currentUserData?.regionId ?? undefined,
        regionName: currentUserData?.regionName ?? undefined,
    });

    const {regionDropdownOptions} = useRegions({disabled: !regionPermissions.allowedToView});

    const {data: branchOptions, isLoading: gettingBranches} = trpc.company.branch.getDropdown.useQuery({companyId: currentCompanyId ?? '', regionId: regionId ?? ''}, {
        enabled: !!currentCompanyId && !!regionId && branchPermissions.allowedToView,
    });

    const branchDropdownOptions = useMemo(() => {
        if (!branchOptions || !branchOptions?.items || gettingBranches) {
            return [];
        }
        return branchOptions.items.map((branch)=> {
            return {label: branch.name, value: branch.id};
        });
    }, [branchOptions, gettingBranches]);

    const {data: timeline, isLoading: gettingTimeline} = trpc.company.workOrder.getTimeline.useQuery({workOrderId: workOrderId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!workOrderId && !!currentCompanyId,
    });

    const {data: recordData, isLoading} = trpc.company.workOrder.getById.useQuery({workOrderId: workOrderId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isViewing && !!workOrderId && !!currentCompanyId,
    });

    const {data: dataToDuplicate, isFetching: isDataToDuplicateLoading} = trpc.company.workOrder.getById.useQuery({workOrderId: idToDuplicate ?? '', companyId: currentCompanyId ?? ''}, {
        enabled: !!idToDuplicate && !isViewing && !!currentCompanyId,
    });

    const {data: clients, isLoading: isClientsLoading} = trpc.company.client.getDropdown.useQuery({
        companyId: currentCompanyId ?? '',
        branchId: branchId ?? undefined,
    }, {
        enabled: !!currentCompanyId && !isClient,
    });

    const clientOptions = useMemo(() => {
        if (!clients || isClientsLoading || !branchId) {
            return [];
        }
        return clients.items?.map((client) => {
            return {
                label: client.name,
                value: client.id,
            };
        });
    }, [clients, isClientsLoading, branchId]);

   const positionTypeOptions = useMemo(() => {
       return Object.values(WorkOrderPositionType).map((p) => {
              return {
                value: p,
                label: toWorkOrderPositionTypeLabel(p),
              };
       });
   },[]);

   const jobEnvironmentOptions = useMemo(() => {
         return Object.values(WorkOrderJobsiteEnvironment).map((s) => {
                  return {
                 value: s,
                 label: toWorkOrderJobsiteEnvironmentLabel(s),
                  };
         });
   }, []);

   const positionOptions = useMemo(() => {
       return Object.values(WorkOrderPosition).map((p) => {
           return {
               value: p,
               label: toWorkOrderPositionLabel(p),
           };
       });
   }, []);

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.workOrderData && !idToDuplicate) {
            const initialData = recordData?.workOrderData;
            setClientName(isViewing ? initialData?.clientName ?? '' : initialData?.clientId ?? '');
            setStartDate(utils.getIsoDateStringFromObject(initialData?.startDate));
            if (initialData?.endDate){
                setEndDate(utils.getIsoDateStringFromObject(initialData?.endDate));
            }

            setPosition(initialData?.position as WorkOrderPosition);
            setPositionType(initialData?.positionType);
            setNumberOfEmployees(initialData?.employeesNeededQty?.toString() ?? '');
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: initialData.jobsiteAddress?.line1,
                city: initialData.jobsiteAddress?.city,
                state: initialData.jobsiteAddress?.state,
                zipCode: initialData.jobsiteAddress?.zipCode,
            }));
            addressForm.setCity(initialData.jobsiteAddress?.city ?? '');
            addressForm.setState(initialData.jobsiteAddress?.state ?? '');
            addressForm.setZipCode(initialData.jobsiteAddress?.zipCode ?? '');
            addressForm.setAddress(initialData.jobsiteAddress?.line1 ?? '');
            addressForm.setAddress2(initialData.jobsiteAddress?.line2 ?? '');
            ppe.setValues(initialData?.ppe ?? []);
            shift.setValue(initialData?.shift ?? '');
            payFrequency.setValue(initialData?.payFrequency);
            setPayRate({
                masked: utils.currencyToDisplay(initialData?.payRate),
                unmasked: initialData?.payRate?.toString() ?? '',
            });

            interview.setValue(initialData?.isInterviewRequired ?? false);
            resume.setValue(initialData?.isResumeRequired ?? false);
            eVerify.setValue(initialData?.isEVerifyRequired ?? false);
            setExperience(initialData?.experience ?? '');
            setLicense(initialData?.license ?? '');
            setCertification(initialData?.certification ?? '');
            setJobEnvironment(initialData?.jobsiteEnvironment as WorkOrderJobsiteEnvironment);
            setNote(initialData?.notes ?? '');
            language.setValues(initialData?.language ?? []);
            setStartTime(utils.timeStringToTimeObject(initialData?.startTime));
            setEndTime(utils.timeStringToTimeObject(initialData?.endTime));
            if (initialData?.branchId) {
                setBranchId(initialData?.branchId);
            }
            if (initialData?.regionId) {
                setRegionId(initialData?.regionId);
            }
            setInitialIsDone(true);

        } else if (dataToDuplicate) {
            const initialData = dataToDuplicate?.workOrderData;
            setClientName(initialData?.clientId ?? '');
            setStartDate(utils.getIsoDateStringFromObject(initialData?.startDate));
            setEndDate(utils.getIsoDateStringFromObject(initialData?.endDate));
            setPosition(initialData?.position as WorkOrderPosition);
            setPositionType(initialData?.positionType);
            setNumberOfEmployees(initialData?.employeesNeededQty?.toString() ?? '');
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: initialData.jobsiteAddress?.line1,
                city: initialData.jobsiteAddress?.city,
                state: initialData.jobsiteAddress?.state,
                zipCode: initialData.jobsiteAddress?.zipCode,
            }));
            addressForm.setCity(initialData.jobsiteAddress?.city ?? '');
            addressForm.setState(initialData.jobsiteAddress?.state ?? '');
            addressForm.setZipCode(initialData.jobsiteAddress?.zipCode ?? '');
            addressForm.setAddress(initialData.jobsiteAddress?.line1 ?? '');
            addressForm.setAddress2(initialData.jobsiteAddress?.line2 ?? '');
            ppe.setValues(initialData?.ppe ?? []);
            shift.setValue(initialData?.shift ?? '');
            payFrequency.setValue(initialData?.payFrequency);
            setPayRate({
                masked: utils.currencyToDisplay(initialData?.payRate),
                unmasked: initialData?.payRate?.toString() ?? '',
            });
            setInitialIsDone(true);
            interview.setValue(initialData?.isInterviewRequired ?? false);
            resume.setValue(initialData?.isResumeRequired ?? false);
            eVerify.setValue(initialData?.isEVerifyRequired ?? false);
            setExperience(initialData?.experience ?? '');
            setLicense(initialData?.license ?? '');
            setCertification(initialData?.certification ?? '');
            setJobEnvironment(initialData?.jobsiteEnvironment as WorkOrderJobsiteEnvironment);
            setNote(initialData?.notes ?? '');
            language.setValues(initialData?.language ?? []);
            setStartTime(utils.timeStringToTimeObject(initialData?.startTime));
            setEndTime(utils.timeStringToTimeObject(initialData?.endTime));
            if (initialData?.branchId) {
                setBranchId(initialData?.branchId);
            }
            if (initialData?.regionId) {
                setRegionId(initialData?.regionId);
            }
        }

    }, [recordData, initialIsDone, addressForm, setInitialIsDone, ppe, shift, payFrequency, interview, resume, eVerify, language, isViewing, dataToDuplicate, idToDuplicate]);
   //
    const initialErrors = useMemo(() => {
        const obj: {
            [key in InputTypes]?: string;
        } = {};
        Object.values(InputTypes).forEach((key) => {
            obj[key] = '';
        });
        return obj;
    }, []);
   // //
    const [formErrorMessages, setFormErrorMessages] = useState(initialErrors);

    const isEmpty = (value: string | string[] |undefined | Date) => {
        if (!value) {
            return true;
        }
        if (Array.isArray(value)) {
            return value.filter((v) => v?.trim()?.length !== 0).length === 0;
        }
        if (value instanceof Date){
            return false;
        }
        return value.trim().length === 0;
    };

    const fieldsMap = useMemo(() => {
        return {
            [InputTypes.clientName]: {value: clientName},
            [InputTypes.startDate]: {value: startDate},
            [InputTypes.endDate]: {value: endDate},
            [InputTypes.position]: {value: position},
            [InputTypes.positionType]: {value: positionType},
            [InputTypes.numberOfEmployees]: {value: numberOfEmployees},
            [InputTypes.payRate]: {value: payRate.unmasked},
            [InputTypes.payFrequency]: {value: payFrequency.value},
            [InputTypes.shift]: {value: shift.value},
            [InputTypes.experience]: {value: experience},
            [InputTypes.license]: {value: license},
            [InputTypes.certification]: {value: certification},
            [InputTypes.ppe]: {value: ppe.values},
            [InputTypes.jobEnvironment]: {value: jobEnvironment},
            [InputTypes.note]: {value: note},
            [InputTypes.branch]: {value: branchId},
            [InputTypes.region]: {value: regionId},
        };

    }, [clientName, startDate, endDate, position, positionType, numberOfEmployees, payRate.unmasked, payFrequency.value, shift.value, experience, license, certification, ppe.values, jobEnvironment, note, branchId, regionId]);

    const requiredFields = useMemo(() => {
        const required = [InputTypes.startDate,
            InputTypes.position,
            InputTypes.positionType,
            InputTypes.numberOfEmployees,
            InputTypes.payFrequency,
            InputTypes.payRate,
            InputTypes.shift,
            InputTypes.experience,
            InputTypes.license,
            InputTypes.certification,
            InputTypes.jobEnvironment,
        ];
        if (!isClient){
            required.push(InputTypes.clientName);
            required.push(InputTypes.branch);
            required.push(InputTypes.region);
        }
        return required;
    }, [isClient]);


    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });


        if (startDate && endDate){
            if (!startAndEndDatesAreValid(new Date(startDate), new Date(endDate))){
                errors[InputTypes.endDate] = STRING_CONSTANTS.END_DATE_MUST_BE_AFTER_START_DATE;
                isValid = false;
            }
        }

        if (!startTime) {
            errors[InputTypes.startTime] = STRING_CONSTANTS.REQUIRED;
            isValid = false;
        }

        if (!endTime) {
            errors[InputTypes.endTime] = STRING_CONSTANTS.REQUIRED;
            isValid = false;
        }

        setFormErrorMessages(errors);
        const addressIsValid = addressForm.validateAddress();

        return isValid && addressIsValid;
    },[formErrorMessages, requiredFields, startDate, endDate, addressForm, endTime, startTime, fieldsMap]);

    const resetFormErrorMessages = useCallback((field) => {
        setFormErrorMessages((prev) => {
            return {
                ...prev,
                [field]: '',
            };
        });

    }, [setFormErrorMessages]);

    const createRecord = () => {
        if (!positionType || !currentCompanyId || !shift.value
            || !jobEnvironment || !payFrequency.value || !positionType || !position || !startDate) {
            return;
        }
        const start = startDate ? new Date(startDate) : new Date();
        const end = endDate ? new Date(endDate) : new Date();

        const data: CreateWorkOrderInput = {
            companyId: currentCompanyId ?? '',
            clientId: clientName,
            startDate:{
                year: start.getFullYear(),
                month: start.getMonth() + 1,
                day: start.getDate(),
            },
            endDate: endDate && endDate !== '' ? {
                year: end.getFullYear(),
                month: end.getMonth() + 1,
                day: end.getDate(),
            } : undefined,
            shift: shift.value as WorkOrderShift,
            startTime: utils.timeTo12HoursDBString(startTime),
            endTime: utils.timeTo12HoursDBString(endTime),
            position: position as WorkOrderPosition,
            positionType: positionType as WorkOrderPositionType,
            payRate: Number(payRate.unmasked),
            payFrequency: payFrequency.value as WorkOrderPayFrequency,
            employeesNeededQty: Number(numberOfEmployees),
            experience: experience,
            license: license,
            certification: certification,
            ppe: ppe.values,
            isInterviewRequired: interview.value,
            isResumeRequired: resume.value,
            isEVerifyRequired: eVerify.value,
            language: language.values,
            jobsiteAddress: addressForm.values,
            jobsiteEnvironment: jobEnvironment as WorkOrderJobsiteEnvironment,
            notes: note,
        };
        action(data);
    };
    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        createRecord();
    };
    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);

    const cancelAction = ()=>{
        closeAction();
    };

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const cleanErrorsOnFieldChange = useCallback((field: InputTypes) => {
        setFormErrorMessages((prev) => {
            return {
                ...prev,
                [field]: '',
            };
        });
    }, [setFormErrorMessages]);

    const dropdownSharedProps = useCallback((fieldName) => {
        return {
            containerStyle: {width: '100%'},
        showRequiredAsteriks: requiredFields.includes(fieldName),
        disabled: isViewing,
        disableInteractions: isViewing,
        dropDownDirection: 'BOTTOM' as DropDownDirectionType,
        };
    }, [isViewing, requiredFields]);

    const defaultTextInputProps = useCallback((fieldName)  => {
        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            disable: isViewing,
            showRequiredAsteriks: requiredFields.includes(fieldName),
        };
    }, [isViewing, requiredFields]);


    const initialEndMonth = useMemo(() => {
        if (!startDate){
            return undefined;
        }
        const plusOneDay = addDays(new Date(startDate), 1);
        return plusOneDay.toISOString();
    }, [startDate]);


    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if ((isViewing && (isLoading || !recordData)) || isDataToDuplicateLoading) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );
    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex: 1, zIndex: 4000}} >
                {/*Region and branch*/}
                {isClient ? null : <View style={[recordModalContentStyles.row, {zIndex: 1100}]}>
                    {needRegion ? <WrappedInput inputComponent={
                            <MB_DropDownPicker items={regionPermissions.allowedToView ? regionDropdownOptions : regionPermissions.defaultValue ? [{
                                label: regionPermissions.defaultValue.regionName,
                                value: regionPermissions.defaultValue.regionId,
                            }] : []}
                                               value={regionId}
                                               onValueChange={(newValue:string|undefined)=> {
                                                   if (!newValue || newValue === regionId) {
                                                       return;
                                                   }
                                                   resetSubmitError();
                                                   resetFormErrorMessages(InputTypes.region);
                                                   setRegionId(newValue ?? undefined);
                                                   setBranchId(undefined);
                                                   setClientName('');
                                               }}
                                               placeholder={'Select Region'}
                                               title={`${isViewing ? '' : 'Assign'} Region`}
                                               {...dropdownSharedProps(InputTypes.region)}
                                               multiple={false}
                                               listMode={'SCROLLVIEW'}



                            />
                        } errorMessage={fieldError(InputTypes.region)}
                                                containerStyle={needBranch ? {...recordModalContentStyles.rowItem, ...{zIndex: 1100}} : {zIndex: 1100}}
                        /> : null}
                        {needBranch ? <WrappedInput inputComponent={
                        <MB_DropDownPicker items={branchDropdownOptions}
                                           value={branchId}
                                           onValueChange={(newValue: string | undefined)=> {
                                               if (!newValue || newValue === branchId) {
                                                   return;
                                               }
                                               resetSubmitError();
                                               resetFormErrorMessages(InputTypes.branch);
                                               setBranchId(newValue ?? undefined);
                                               setClientName('');
                                           }}
                                           placeholder={'Select Branch'}
                                           title={`${isViewing ? '' : 'Assign'} Branch`}
                                           {...{...dropdownSharedProps(InputTypes.branch), ...{
                                               containerStyle: {width: needRegion ? undefined : '100%'},
                                           }}}
                                           listEmptyMessage={regionId ? 'No branches for selected region' : 'Select a region first'}
                                           multiple={false}
                                           listMode={'SCROLLVIEW'}
                        />
                    } errorMessage={fieldError(InputTypes.branch)} containerStyle={needRegion ? {...recordModalContentStyles.rowItem, ...{zIndex: 1000}} : {zIndex: 1000}}
                                                    subText={isViewing ? undefined : needRegion && !regionId ? 'Select a region first' : undefined}
                        /> : null}


                </View>}

                {/*Client*/}
                {isClient ? null : <View style={[recordModalContentStyles.row, {zIndex: 900}]}>
                    <View style={[{flex: 1}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.clientName)}
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 900, width: '100%'}}}
                                  inputComponent={isViewing ? <MB_TextInput title={'Client'}
                                                                        value={clientName}
                                                                        key={JSON.stringify(initialIsDone)}
                                                                        placeholder={'Client'}
                                                                        {...defaultTextInputProps(InputTypes.clientName)}
                                      /> :
                                      <MB_DropDownPicker items={clientOptions}
                                                                     value={clientName}
                                                                     onValueChange={(newValue: string | undefined)=> {
                                                                         resetSubmitError();
                                                                         resetFormErrorMessages(InputTypes.clientName);
                                                                         setClientName(newValue ?? '');
                                                                     }}
                                                                     placeholder={'Select a Client'}
                                                                     title={'Client'}
                                                                     {...dropdownSharedProps(InputTypes.clientName)}
                                                         multiple={false}
                                                         listMode={'SCROLLVIEW'}
                                      listEmptyMessage={branchId ? 'No clients for this branch' : 'Select a branch first'}
                                  />}
                                  subText={isViewing ? undefined : needBranch && !branchId ? 'Select a branch first' : undefined}
                    />
                </View>
                </View>}

                {/*Position and Position type*/}
                <View style={[recordModalContentStyles.row, {zIndex: 800}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.position)}
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 800}}}
                                  inputComponent={<MB_DropDownPicker items={positionOptions}
                                                                     value={position}
                                                                     onValueChange={(newValue)=> {
                                                                         resetSubmitError();
                                                                         resetFormErrorMessages(InputTypes.position);
                                                                         setPosition(newValue ?? undefined);
                                                                     }}
                                                                     placeholder={'Select a position'}
                                                                     title={'Position'}
                                                                     {...dropdownSharedProps(InputTypes.position)}
                                                                     multiple={false}
                                                                     listMode={'SCROLLVIEW'}
                                  />}
                    />
                    <WrappedInput errorMessage={fieldError(InputTypes.positionType)}
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 750}}}
                                  inputComponent={<MB_DropDownPicker items={positionTypeOptions}
                                                                     value={positionType}
                                                                     onValueChange={(newValue)=> {
                                                                         resetSubmitError();
                                                                            resetFormErrorMessages(InputTypes.positionType);
                                                                         setPositionType(newValue ?? undefined);
                                                                     }}
                                                                     placeholder={'Select a position type'}
                                                                     title={'Position Type'}
                                                                     {...dropdownSharedProps(InputTypes.positionType)}
                                                                     multiple={false}
                                                                     listMode={'SCROLLVIEW'}
                                  />}
                    />
                </View>
                {/*Qty  and pay rate*/}
                <View style={[recordModalContentStyles.row]}>
                    <View style={[recordModalContentStyles.rowItem]}>
                        <WrappedInput errorMessage={fieldError(InputTypes.numberOfEmployees)}
                                      inputComponent={<MB_TextInput
                                          onChangeText={(newValue)=> {
                                              resetSubmitError();
                                              cleanErrorsOnFieldChange(InputTypes.numberOfEmployees);
                                              setNumberOfEmployees(newValue ?? undefined);
                                          }}
                                          placeholder={'How many employees?'}
                                          value={numberOfEmployees}
                                          title={'Qty of employees needed'}
                                          inputType={MB_TextInputInputType.positiveNumbers}
                                          {...defaultTextInputProps(InputTypes.numberOfEmployees)}
                                      />}
                        />
                    </View>
                    <View style={[recordModalContentStyles.rowItem]}>
                        <WrappedInput errorMessage={fieldError(InputTypes.payRate)}
                                      inputComponent={<MB_TextInput
                                          placeholder={'$0.00'}
                                          value={payRate.masked}
                                          title={'Pay Rate'}
                                          inputType={MB_TextInputInputType.positiveNumbers}
                                          mask={{
                                              maskType: dollarMask,
                                              onChangeText: (newValue)=> {
                                                  resetSubmitError();
                                                  cleanErrorsOnFieldChange(InputTypes.payRate);
                                                  setPayRate({
                                                      masked: newValue?.masked ?? '',
                                                      unmasked: newValue?.unmasked ?? '',
                                                  });
                                              },
                                          }}
                                          {...defaultTextInputProps(InputTypes.payRate)}


                                      />}
                        />
                    </View>
                </View>
                {/*Pay frequency*/}
                <View style={[recordModalContentStyles.row]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.payFrequency)}
                                  inputComponent={payFrequency.RadioButtonGroup}
                                  title={'Pay Frequency'}
                                  showRequiredAsterisk={true}
                    />
                </View>
                {/*Job Address*/}
                <View style={[recordModalContentStyles.row,  {zIndex: 900}]}>
                    <View style={[recordModalContentStyles.rowItem, {flex: 1, zIndex: 900}]}>
                        <WrappedInput errorMessage={addressForm.error}
                                      inputComponent={<View style={{ width: '100%'}}>
                                          <MB_LocationPicker
                                              key={initialIsDone.toString()}
                                              apiKey={envs.LOCATION_PICKER_API}
                                              title={'Address'}
                                              containerStyle={{width: '100%'}}
                                              titleStyle={{color: COLORS.textPrimary}}
                                              disabled={isViewing}
                                              disableInteractions={isViewing}
                                              showRequiredAsteriks={true}
                                              value={addressForm.fullAddress}
                                              onSelect={addr => {
                                                  addressForm.setAddress(utils.getStreetFromFullAddress(addr?.text, addr?.city));
                                                  addressForm.setCity(addr?.city ?? '');
                                                  addressForm.setState(addr?.state ?? '');
                                                  addressForm.setZipCode(addr?.postalCode ?? '');
                                              }}
                                              dropDownDirection={'BOTTOM'}
                                              listMode={'SCROLLVIEW'}
                                          />
                                      </View>}
                        />
                    </View>

                    <View style={[recordModalContentStyles.rowItem,  isMobileApp ? undefined : {width: 200, marginLeft: 16}]}>
                        {addressForm.addressSecondLineField}
                    </View>

                </View>
                {/*Start/end dates*/}
                <View style={[recordModalContentStyles.row, {zIndex: 800}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.startDate)} inputComponent={
                        <WrappedDatePicker title={'Start Date'}
                                           value={startDate}
                                           showInitialEmpty
                                           showRequiredAsterisk={true}
                                           disabled={isViewing}
                                           onChange={(newValue)=> {
                                               resetSubmitError();
                                               cleanErrorsOnFieldChange(InputTypes.startDate);
                                               setStartDate(newValue?.toISOString() ?? '');

                                           }}/>}
                                  containerStyle={ recordModalContentStyles.rowItem}
                    />
                    <WrappedInput errorMessage={fieldError(InputTypes.endDate)} inputComponent={
                        <WrappedDatePicker title={'End Date'}
                                           value={endDate}
                                           showInitialEmpty
                                           minDate={startDate ? addDays(new Date(startDate), 1).toISOString() : undefined }
                                           initialMonth={initialEndMonth}
                                           disabled={isViewing}
                                           onChange={(newValue)=> {
                                               resetSubmitError();
                                               cleanErrorsOnFieldChange(InputTypes.endDate);
                                               setEndDate(newValue?.toISOString() ?? '');
                                           }}/>}
                                  containerStyle={ recordModalContentStyles.rowItem}

                    />

                </View>
                {/*Shift*/}
                <View style={[recordModalContentStyles.row, {zIndex: 700, marginBottom: 0}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.shift)}
                                  inputComponent={shift.RadioButtonGroup}
                                  title={'Shift'}
                                  showRequiredAsterisk={true}
                                  errorContainerStyle={{top:-10}}
                    />
                </View>

                {/*Start and end time*/}
                <View style={[recordModalContentStyles.row, {zIndex: 600}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.startTime)}
                                  title={'Start Time'}
                                  showRequiredAsterisk={true}

                                    containerStyle={recordModalContentStyles.rowItem}
                        inputComponent={<TimePicker value={startTime}
                                                    placeholder={'Select start time'}
                                                    disabled={isViewing}
                                                    onChange={(t)=>{
                                                        resetSubmitError();
                                                        cleanErrorsOnFieldChange(InputTypes.startTime);
                                                        setStartTime(t);
                                                    }}/>
                        }/>
                    <WrappedInput errorMessage={fieldError(InputTypes.endTime)}
                                  title={'End Time'}
                                  showRequiredAsterisk={true}
                                  containerStyle={recordModalContentStyles.rowItem}
                                  inputComponent={<TimePicker value={endTime}
                                                              placeholder={'Select end time'}
                                                              disabled={isViewing}
                                                              onChange={(t)=>{
                                                                  resetSubmitError();
                                                                  cleanErrorsOnFieldChange(InputTypes.endTime);
                                                                  setEndTime(t);}}
                                  />}/>
                </View>

                {/*Experience*/}
                <View style={[recordModalContentStyles.row]}>
                        <WrappedInput errorMessage={fieldError(InputTypes.experience)}
                                      inputComponent={<MB_TextInput
                                          title={'Experience'}
                                          placeholder={'Enter experience required'}
                                          value={experience}
                                          onChangeText={(newValue)=> {
                                                resetSubmitError();
                                                cleanErrorsOnFieldChange(InputTypes.experience);
                                                setExperience(newValue ?? '');
                                          }}
                                          {...defaultTextInputProps(InputTypes.experience)}
                                      />}
                        />
                </View>

                {/*License*/}
                <View style={[recordModalContentStyles.row]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.license)}
                                  inputComponent={<MB_TextInput
                                      title={'License'}
                                      placeholder={'Enter license required'}
                                      value={license}
                                      onChangeText={(newValue)=> {
                                            resetSubmitError();
                                            cleanErrorsOnFieldChange(InputTypes.license);
                                            setLicense(newValue ?? '');
                                      }}
                                      {...defaultTextInputProps(InputTypes.license)}
                                  />}
                    />
                </View>

                {/*Certification*/}
                <View style={[recordModalContentStyles.row]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.certification)}
                                  inputComponent={<MB_TextInput
                                      title={'Certification'}
                                      placeholder={'Enter certification required'}
                                      value={certification}
                                      onChangeText={(newValue)=> {
                                            resetSubmitError();
                                            cleanErrorsOnFieldChange(InputTypes.certification);
                                            setCertification(newValue ?? '');
                                      }}
                                      {...defaultTextInputProps(InputTypes.certification)}
                                  />}
                    />
                </View>

                {/*PPE*/}
                <View style={[recordModalContentStyles.row]}>
                <WrappedInput errorMessage={fieldError(InputTypes.ppe)}
                              inputComponent={ppe.CheckBoxGroup}
                              title={'PPE'}
                              {...dropdownSharedProps(InputTypes.ppe)}
                />
                </View>

                {/*Job Environment*/}
                <View style={[recordModalContentStyles.row, {zIndex: 700}]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.jobEnvironment)}
                                  inputComponent={<MB_DropDownPicker items={jobEnvironmentOptions}
                                                                     value={jobEnvironment}
                                                                     onValueChange={(newValue)=> {
                                                                         resetSubmitError();
                                                                         setJobEnvironment(newValue ?? undefined);
                                                                     }}
                                                                     placeholder={'Select a job environment'}
                                                                     title={'Job Environment'}
                                                                     {...dropdownSharedProps(InputTypes.jobEnvironment)}
                                                                     multiple={false}
                                  />}
                    />
                </View>

                {/*Resume, Interview, E-verify*/}
                <View style={[recordModalContentStyles.row]}>
                    <View style={[recordModalContentStyles.rowItem, {width: isMobileApp ? '100%' : '30%'}]}>
                        <WrappedInput
                                      inputComponent={resume.RadioButtonGroup}
                                      title={'Resume'}
                        />
                    </View>
                    <View style={[recordModalContentStyles.rowItem, {width: isMobileApp ? '100%' : '30%'}]}>
                        <WrappedInput
                                      inputComponent={interview.RadioButtonGroup}
                                      title={'Interview'}
                        />
                    </View>
                    <View style={[recordModalContentStyles.rowItem, {width: isMobileApp ? '100%' : '30%'}]}>
                        <WrappedInput
                                      inputComponent={eVerify.RadioButtonGroup}
                                      title={'E-Verify'}
                        />
                    </View>
                </View>


                {/*Language*/}
                <View style={[recordModalContentStyles.row]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.language)}
                                  inputComponent={language.CheckBoxGroup}
                                    title={'Language'}
                                    {...dropdownSharedProps(InputTypes.language)}
                    />
                </View>

                {/*Notes*/}
                <View style={[recordModalContentStyles.row]}>
                    <WrappedInput errorMessage={fieldError(InputTypes.note)}
                                  {...defaultTextInputProps(InputTypes.note)}
                                  inputComponent={<MB_TextInput
                                      title={'Notes'}
                                      placeholder={isViewing ? '' : 'Leave a note...'}
                                      value={note}
                                      multiline={true}
                                      style={{width: '100%', height: 100}}
                                      onChangeText={(newValue)=> {
                                          cleanErrorsOnFieldChange(InputTypes.note);
                                          setNote(newValue ?? '');
                                      }}
                                      disable={isViewing}
                                  />}
                    />
                </View>
            </View>

            {isViewing ? null : <View style={{zIndex: 1}}>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Add work order'}
                                   onPress={submitAction}
                                   loading={submitInProgress}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>}


        </ScrollView>
    );
};

export {WorkOrderForm };
