import React from 'react';
import {View, Text, StyleSheet} from 'react-native';
import {MB_ToggleSwitch} from '@mightybyte/rnw.components.toggle-switch';
import {applyTTStyles, appColors} from '../../../constants/colors';
import {mbTextStyles} from '@mightybyte/rnw.utils.style-utils';
import {textStyles} from '../../../constants/textStyles';
import {getColors} from '../../../constants/colors';

const COLORS = getColors();

const DefaultSwitch = ({isToggled, onToggle, shouldUseTTStyles, label, labelLeft}: {
    isToggled: boolean;
    onToggle: (isToggled: boolean) => void;
    disabled?: boolean;
    shouldUseTTStyles?: boolean;
    label?: string;
    labelLeft?: boolean;
}) => {
    const customizedColors  = applyTTStyles(shouldUseTTStyles ?? false);
    const trackActiveBackgroundColor = customizedColors.switchTrackActive;
    const trackInactiveBackgroundColor = customizedColors.switchTrackInactive;

    return (
        <View style={{flexDirection: labelLeft ? 'row-reverse' : 'row', alignItems: 'center'}}>
            <MB_ToggleSwitch isToggled={isToggled }
                             // disable={disabled}
                             onToggle={onToggle}
                             trackBar={{
                                 activeBackgroundColor: trackActiveBackgroundColor,
                                 inActiveBackgroundColor: trackInactiveBackgroundColor,
                                 width: 60,
                                 radius: 30,
                                 height: 30,
                                 borderWidth: 3,
                                 borderActiveColor: trackActiveBackgroundColor,
                                 borderInActiveColor: trackInactiveBackgroundColor,
                             }}
                             thumbButton={{
                                 activeBackgroundColor: appColors.white,
                                 inActiveBackgroundColor: appColors.gray100,
                                 height: 30,
                                 width: 30,
                             }}
            />
            {label ?  <Text style={[styles.switchText, {
                marginLeft: labelLeft ? 0 : 8,
                marginRight: labelLeft ? 8 : 0,
            }]}>{label}</Text> : null}
        </View>
    );

};

export {DefaultSwitch };

const styles = StyleSheet.create({
    switchText: mbTextStyles([
        textStyles.Muli_16_600, {
            color: COLORS.inputLabel,
            textAlign: 'left',
            textTransform: 'capitalize',
        },
    ]),
});
