import { z } from 'zod';
import {
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    filterMappingToSchema,
} from '../../common';
import { CheckInRejectionReason } from '../../../entities';
import { ChecklistFilterMap } from '../../../filter';

export const CreateChecklistInputSchema = z.object({
    companyId: ObjectIdSchema,
    shortlistId: ObjectIdSchema,
    employeeIds: ObjectIdSchema.array().min(1),
});

export const GetChecklistsPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ChecklistFilterMap),
});

export const ListChecklistsInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ChecklistFilterMap),
});

export const CloseChecklistInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetChecklistByIdInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetChecklistsByWorkOrderIdInputSchema = z.object({
    workOrderId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const AssignChecklistEmployeesInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,

    /**
     * Note: this will overwrite the currently assigned employees.
     */
    employeeIds: z.array(ObjectIdSchema),
});

export const GetAssignedChecklistEmployeesInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetAvailableEmployeesInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const CheckInEmployeesInputSchema = z.object({
    companyId: ObjectIdSchema,
    checklistId: ObjectIdSchema,
    employees: z
        .object({
            /**
             * The employee's id.
             */
            id: ObjectIdSchema,

            isApproved: z.boolean(),

            rejectionReasons: z
                .nativeEnum(CheckInRejectionReason)
                .array()
                .optional(),
        })
        .transform((input) => {
            if (input.isApproved) {
                // Remove the reason if the employee is approved
                input.rejectionReasons = undefined;
            }

            return input;
        })
        .refine(
            ({ isApproved, rejectionReasons }) => {
                return (
                    isApproved ||
                    (rejectionReasons !== undefined &&
                        rejectionReasons.length > 0)
                );
            },
            {
                message: 'The rejection reason is required.',
                path: ['employees', 'rejectionReasons'],
            },
        )
        .array(),
});

export const GetChecklistTimelineInputSchema = z.object({
    checklistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
