export const appColors = {
    primary50: '#FDECD1',
    primary100: '#FBDAAD',
    primary200: '#F8C98A',
    primary300: '#F5B866',
    primary400: '#F2A743',
    primary500: '#EA7B19',
    primary600: '#E16E15',
    primary700: '#D86111',
    primary800: '#CF540D',
    primary900: '#C54709',

    blue100: '#E1EFFE',
    blue200: '#C5DBFE',
    blue300: '#A4CAFE',
    blue600: '#1C64F2',
    blue700: '#1A56DB',
    blue800: '#1E429F',

    gray50: '#F9FAFB',
    gray100: '#F3F4F6',
    gray200: '#E5E7EB',
    gray300: '#D1D5DB',
    gray400: '#9CA3AF',
    gray500: '#6B7280',
    gray600: '#374151',
    gray800: '#1F2A37',
    gray900: '#111928',

    green100: '#DCFCE7',
    green500: '#22C55E',
    green600: '#16A34A',
    green800: '#166534',

    red100: '#FDE8E8',
    red500: '#F05252',
    red600: '#E02424',
    red700: '#C81E1E',
    red800: '#9B1C1C',

    white: '#FFFFFF',

    yellow100: '#FDF6B2',
    yellow800: '#723B13',
};

export enum ColorSchemaTypes {
    dark = 'dark',
    light = 'light',
}

type ColorSchemaType = ColorSchemaTypes;

export type ColorSchema = {
    textPrimary: string;
    textSecondary: string;
    backgroundPrimary: string;
    backgroundSecondary: string;
    buttonDangerBg: string;
    buttonPrimaryBg: string;
    buttonPrimaryText: string;
    buttonPrimaryBorder: string;
    buttonSecondaryBg: string;
    buttonSecondaryText: string;
    buttonSecondaryBorder: string;
    inputLabel: string;
    inputBackground: string;
    inputBorder: string;
    inputText: string;
    inputPlaceholder: string;
    inputErrorLabel: string;
    inputErrorBorder: string;
    inputErrorText: string;
    modalBackground: string;
    modalBorder: string;
    closeIconColor: string;
    errorTextColor: string;
    dangerTextColor: string;
    attentionTextColor: string;

    drawerActiveTabBackground: string;
    drawerActiveIcon: string;

    stroke: string;

    statusActive: string;
    statusInactive: string;
    statusInactiveGray: string;
    statusInProgress: string;

    notificationBadge: string;
    notificationBadgeText: string;

    timeLineDot: string;
    timeLineLine: string;
    timelineEntryBorder: string;
    timelineSubText: string;
};

const lightSchema: ColorSchema = {
    textPrimary: appColors.gray900,
    textSecondary: appColors.gray500,
    backgroundPrimary: appColors.white,
    backgroundSecondary: appColors.gray50,
    buttonDangerBg: appColors.red600,
    buttonPrimaryBg: appColors.blue700,
    buttonPrimaryText: appColors.white,
    buttonPrimaryBorder: appColors.blue700,
    buttonSecondaryBg: appColors.white,
    buttonSecondaryText: appColors.gray800,
    buttonSecondaryBorder: appColors.gray200,
    inputLabel: appColors.gray900,
    inputBackground: appColors.gray50,
    inputBorder: appColors.gray300,
    inputPlaceholder: appColors.gray500,
    inputText: appColors.gray900,
    inputErrorLabel: appColors.red600,
    inputErrorBorder: appColors.red500,
    inputErrorText: appColors.red700,
    modalBackground: appColors.white,
    modalBorder: appColors.gray300,
    closeIconColor: appColors.gray500,
    errorTextColor: appColors.red500,
    dangerTextColor: appColors.red700,
    attentionTextColor: appColors.green800,

    drawerActiveTabBackground: appColors.gray100,
    drawerActiveIcon: appColors.gray900,

    stroke: appColors.gray200,

    statusActive: appColors.green500,
    statusInactive: appColors.red500,
    statusInactiveGray: appColors.gray400,
    statusInProgress: appColors.blue200,

    notificationBadge: appColors.red700,
    notificationBadgeText: appColors.white,
    timeLineDot: appColors.blue600,
    timeLineLine: appColors.gray500,
    timelineEntryBorder: appColors.gray200,
    timelineSubText: appColors.gray400,
};

export const getColors = (mode?: ColorSchemaType | undefined): ColorSchema => {
    if (!mode) {
        return lightSchema;
    }

    return lightSchema;
};

export const COLORS = getColors();

type CustomizableComponents = {
    primaryButtonBg: string;
    textButtonColor: string | undefined;
    loaderColor: string;
    activePageIndicatorBackground: string;
    hoverPageIndicatorBackground?: string;

    switchTrackInactive: string;
    switchTrackActive: string;

    profileContainerBackground: string;
    profileContainerText: string;
};

export const applyTTStyles = (
    shouldUseTTStyles: boolean,
): CustomizableComponents => {
    return {
        primaryButtonBg: shouldUseTTStyles
            ? appColors.primary500
            : COLORS.buttonPrimaryBg,
        textButtonColor: shouldUseTTStyles
            ? appColors.primary600
            : appColors.blue600,
        loaderColor: shouldUseTTStyles
            ? appColors.primary500
            : appColors.blue700,
        activePageIndicatorBackground: shouldUseTTStyles
            ? appColors.primary600
            : appColors.blue600,
        hoverPageIndicatorBackground: shouldUseTTStyles
            ? appColors.primary200
            : appColors.blue200,

        switchTrackInactive: shouldUseTTStyles
            ? appColors.primary200
            : appColors.blue200,
        switchTrackActive: shouldUseTTStyles
            ? appColors.primary600
            : appColors.blue600,
        profileContainerBackground: shouldUseTTStyles
            ? appColors.primary100
            : appColors.blue100,
        profileContainerText: shouldUseTTStyles
            ? appColors.primary600
            : appColors.blue600,
    };
};

//     borderActiveColor: colors.switchTrackPrimaryChecked,
//                                  borderInActiveColor: colors.switchTrackPrimary,
//                              }}
//                              thumbButton={{
//                                  activeBackgroundColor: colors.switchThumbPrimaryChecked,
//                                  inActiveBackgroundColor: colors.switchThumbPrimary,
