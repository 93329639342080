import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const LogoutIconSvg = React.memo(({color,size, containerStyle}: {
    color?: string, size?: number, containerStyle?: StyleProp<ViewStyle>
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 24}
                height={size ? size * (25 / 24) : 25}
                viewBox="0 0 24 25"
                fill="none"
            >
                <Path
                    d="M17.29 8.399h0l.005.006 3.6 3.6s0 0 0 0a.7.7 0 010 .99s0 0 0 0L17.3 16.59a.7.7 0 01-.99-.99l1.548-1.548.854-.853H8.4a.7.7 0 010-1.4h10.311l-.854-.854-1.552-1.551h0l-.006-.006a.7.7 0 11.99-.99zM3.104 4.805A.7.7 0 014.3 5.3v14.4a.7.7 0 01-1.4 0V5.3a.7.7 0 01.205-.495z"
                    fill={color ?? '#111928'}
                    stroke={color ?? '#111928'}
                />
            </Svg>


        </View>
    );
});

export { LogoutIconSvg };
