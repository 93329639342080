import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePagination } from '../../../../hooks/usePagination';
import { trpc } from '../../../../apiCalls/trpcClient';
import { ChecklistsProps } from '../../../../typesAndInterfaces/componentProps';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import {
    GetDataResponseBase,
    ItemsPageData,
} from '../../../../typesAndInterfaces/typesAndInterfaces';
import {
    ChecklistDataForClient,
    ShortlistDataForClient,
} from '@temptrack/business';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { ChecklistViewMode } from '../../../../navigations/types';

type GetChecklistsReturnType = GetDataResponseBase & {
    checkListsData:
        | (ItemsPageData & {
              items: ChecklistDataForClient[];
          })
        | undefined;
};

type GetShortlistReturnType = GetDataResponseBase & {
    checkListsData:
        | (ItemsPageData & {
              items: ShortlistDataForClient[];
          })
        | undefined;
};
type ChecklistViewProps = {
    navigation: ChecklistsProps['navigation'];
    route: ChecklistsProps['route'] & { viewMode: ChecklistViewMode.checkList };
};

type ShortlistViewProps = {
    navigation: ChecklistsProps['navigation'];
    route: ChecklistsProps['route'] & {
        viewMode?: ChecklistViewMode.shortList | undefined;
    };
};

type ViewProps = ChecklistViewProps | ShortlistViewProps;
//conditional return type if passed viewMode is checkList return GetChecklistsReturnType else GetShortlistReturnType
type ConditionalReturnType<T> = T extends ChecklistViewMode.checkList
    ? GetChecklistsReturnType
    : GetShortlistReturnType;

const useGetChecklists = ({
    navigation,
    route,
}: ChecklistViewProps | ShortlistViewProps): ConditionalReturnType<
    ViewProps['route']['params']['viewMode']
> => {
    const viewMode = route.params?.viewMode ?? ChecklistViewMode.shortList;
    const { currentCompanyId } = useCurrentCompanyContext();
    const { page, pageSize, PaginationComponent, setPage } = usePagination({
        navigation,
        route,
    });

    const [filters, setFilters] = useState<Record<string, any>>({});

    const [checklistNextToken, setChecklistNextToken] = useState<
        string | undefined
    >(undefined);
    const [shortlistNextToken, setShortlistNextToken] = useState<
        string | undefined
    >(undefined);
    useEffect(() => {
        setChecklistNextToken(undefined);
        setShortlistNextToken(undefined);
    }, [pageSize, setChecklistNextToken, setShortlistNextToken]);

    useEffect(() => {
        setPage(1);
    }, [viewMode, setPage]);

    const {
        data: shortlistData,
        isLoading: shortlistIsLoading,
        isPreviousData: shortlistIsPreviousData,
    } = trpc.company.shortlist.getPage.useQuery(
        {
            companyId: currentCompanyId ?? '',
            page,
            pageSize,
            lastToken: shortlistNextToken,
            filter: filters,
        },
        {
            enabled: !!currentCompanyId && !isMobileApp,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        setShortlistNextToken(shortlistData?.nextToken ?? undefined);
    }, [shortlistData?.nextToken, setShortlistNextToken]);

    const {
        data: checklistRecordData,
        isLoading: checklistIsLoading,
        isPreviousData: checklistIsPreviousData,
    } = trpc.company.checklist.getPage.useQuery(
        {
            companyId: currentCompanyId ?? '',
            page,
            pageSize,
            lastToken: checklistNextToken,
            filter: filters,
        },
        {
            enabled: !!currentCompanyId && !isMobileApp,
            refetchOnWindowFocus: false,
        },
    );

    useEffect(() => {
        setChecklistNextToken(checklistRecordData?.nextToken ?? undefined);
    }, [checklistRecordData, setChecklistNextToken]);

    // Infinite fetch
    const {
        data: infiniteChecklistRecordData,
        fetchNextPage: fetchNextPageChecklist,
        isPreviousData: infiniteChecklistFetchIsPreviousData,
        isLoading: infiniteChecklistFetchIsLoading,
        hasNextPage: hasNextChecklistPage,
    } = trpc.company.checklist.list.useInfiniteQuery(
        {
            companyId: currentCompanyId ?? '',
            limit: 10,
            filter: filters,
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled:
                !!currentCompanyId &&
                isMobileApp &&
                viewMode === ChecklistViewMode.checkList,
        },
    );

    const {
        data: infiniteShortlistRecordData,
        fetchNextPage: fetchNextPageShortlist,
        isPreviousData: infiniteShortlistFetchIsPreviousData,
        isLoading: infiniteShortlistFetchIsLoading,
        hasNextPage: hasNextShortlistPage,
    } = trpc.company.shortlist.list.useInfiniteQuery(
        {
            companyId: currentCompanyId ?? '',
            limit: 10,
            filter: filters,
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled:
                !!currentCompanyId &&
                isMobileApp &&
                viewMode === ChecklistViewMode.shortList,
        },
    );

    const dataToReturn = useMemo(() => {
        if (isMobileApp) {
            const pages =
                viewMode === ChecklistViewMode.checkList
                    ? infiniteChecklistRecordData?.pages ?? []
                    : infiniteShortlistRecordData?.pages ?? [];
            return {
                items: pages.map((p) => p.items).flat(),
            };
        }
        return viewMode === ChecklistViewMode.checkList
            ? checklistRecordData
            : shortlistData;
    }, [
        checklistRecordData,
        infiniteChecklistRecordData,
        shortlistData,
        infiniteShortlistRecordData,
        viewMode,
    ]);

    const setFiltersAction = useCallback(
        (newFilters: Record<string, any>) => {
            setChecklistNextToken(undefined);
            setShortlistNextToken(undefined);
            setPage(1);
            setFilters?.(newFilters);
        },
        [setPage],
    );

    const isLoading = useMemo(() => {
        if (isMobileApp) {
            return viewMode === ChecklistViewMode.checkList
                ? infiniteChecklistFetchIsLoading
                : infiniteShortlistFetchIsLoading;
        }
        return viewMode === ChecklistViewMode.checkList
            ? checklistIsLoading
            : shortlistIsLoading;
    }, [
        checklistIsLoading,
        infiniteChecklistFetchIsLoading,
        viewMode,
        shortlistIsLoading,
        infiniteShortlistFetchIsLoading,
    ]);

    const isPreviousData = useMemo(() => {
        if (isMobileApp) {
            return viewMode === ChecklistViewMode.checkList
                ? infiniteChecklistFetchIsPreviousData
                : infiniteShortlistFetchIsPreviousData;
        }
        return viewMode === ChecklistViewMode.checkList
            ? checklistIsPreviousData
            : shortlistIsPreviousData;
    }, [
        checklistIsPreviousData,
        infiniteChecklistFetchIsPreviousData,
        viewMode,
        shortlistIsPreviousData,
        infiniteShortlistFetchIsPreviousData,
    ]);

    const fetchNextPage = useMemo(() => {
        if (isMobileApp) {
            return viewMode === ChecklistViewMode.checkList
                ? fetchNextPageChecklist
                : fetchNextPageShortlist;
        }
        return undefined;
    }, [fetchNextPageChecklist, fetchNextPageShortlist, viewMode]);

    const hasNextPage = useMemo(() => {
        if (isMobileApp) {
            return viewMode === ChecklistViewMode.checkList
                ? hasNextChecklistPage
                : hasNextShortlistPage;
        }
        return undefined;
    }, [hasNextChecklistPage, hasNextShortlistPage, viewMode]);

    const returnData = {
        PaginationComponent,
        isLoading: isLoading,
        isPreviousData: isPreviousData,
        fetchNextPage: fetchNextPage,
        hasNextPage: hasNextPage,
        // checkListsData: dataToReturn,
        setFilters: setFiltersAction,
    };

    if (viewMode === ChecklistViewMode.checkList) {
        return {
            ...returnData,
            checkListsData:
                dataToReturn as GetChecklistsReturnType['checkListsData'],
        };
    }

    return {
        ...returnData,
        checkListsData:
            dataToReturn as GetShortlistReturnType['checkListsData'],
    };
};

export { useGetChecklists };
