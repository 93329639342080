import { EnumLike, z } from 'zod';
import { FilterQueryType } from '.';

const StringSchema = z.string().trim(); // Can't import this from common inputs due to circular dependency

const isEmptySchema = z.object({
    queryType: z.literal(FilterQueryType.isEmpty),
});

const notEmptySchema = z.object({
    queryType: z.literal(FilterQueryType.notEmpty),
});

const equalsStringSchema = z.object({
    queryType: z.literal(FilterQueryType.equals),
    value: StringSchema,
});

const notEqualStringSchema = z.object({
    queryType: z.literal(FilterQueryType.notEqual),
    value: StringSchema,
});

const isAnyOfSchema = <T extends z.Schema>(schema: T) => {
    return z.object({
        queryType: z.literal(FilterQueryType.isAnyOf),
        values: z.array(schema),
    });
};

const isNoneOfSchema = <T extends z.Schema>(schema: T) => {
    return z.object({
        queryType: z.literal(FilterQueryType.isNoneOf),
        values: z.array(schema),
    });
};

export const filterTextSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    equalsStringSchema,
    notEqualStringSchema,
    z.object({
        queryType: z.literal(FilterQueryType.contains),
        value: StringSchema,
    }),
    z.object({
        queryType: z.literal(FilterQueryType.notContain),
        value: StringSchema,
    }),
]);

export const filterDropdownSchema = <Enum extends EnumLike>(
    enumObject: Enum,
) => {
    const enumSchema = z.nativeEnum(enumObject);

    return z.union([
        isEmptySchema,
        notEmptySchema,
        z.object({
            queryType: z.literal(FilterQueryType.equals),
            value: enumSchema,
        }),
        z.object({
            queryType: z.literal(FilterQueryType.notEqual),
            value: enumSchema,
        }),
        isAnyOfSchema(enumSchema),
        isNoneOfSchema(enumSchema),
    ]);
};

export const filterStringDropdownSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    equalsStringSchema,
    notEqualStringSchema,
    isAnyOfSchema(StringSchema),
    isNoneOfSchema(StringSchema),
]);

export const filterDateSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    z.object({
        queryType: z.literal(FilterQueryType.before),
        value: StringSchema.date(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.after),
        value: StringSchema.date(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.between),
        values: z.object({
            from: StringSchema.date(),
            to: StringSchema.date(),
        }),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.equals),
        value: StringSchema.date(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.notEqual),
        value: StringSchema.date(),
    }),
]);

export const filterDatetimeSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    z.object({
        queryType: z.literal(FilterQueryType.before),
        value: StringSchema.datetime(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.after),
        value: StringSchema.datetime(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.between),
        values: z.object({
            from: StringSchema.datetime(),
            to: StringSchema.datetime(),
        }),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.equals),
        value: StringSchema.datetime(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.notEqual),
        value: StringSchema.datetime(),
    }),
]);

export const filterNumberSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    z.object({
        queryType: z.literal(FilterQueryType.equals),
        value: z.number(),
    }),
    z.object({
        queryType: z.literal(FilterQueryType.notEqual),
        value: z.number(),
    }),
]);

export const filterPhoneSchema = z.union([
    isEmptySchema,
    notEmptySchema,
    equalsStringSchema,
    notEqualStringSchema,
]);
