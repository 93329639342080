import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { getColors } from '../../../constants/colors';

const COLORS = getColors();

const AddButton = ({ buttonText, onAddPress, style,
                       hideLeftElement, disabled, isLoading, buttonIcon }: {
    buttonText: string,
    onAddPress: () => void,
    style?: StyleProp<ViewStyle>,
    hideLeftElement?: boolean,
    disabled?: boolean,
    isLoading?: boolean,
    buttonIcon?: JSX.Element
}) => {
    return (
        <MB_Button
            title={buttonText}
            leftElement={hideLeftElement ? undefined : buttonIcon ?? <AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
            style={style}
            onPress={onAddPress}
            disabled={disabled}
            loading={isLoading}
        />
    );
};


export { AddButton };

