import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePagination } from '../../../../hooks/usePagination';
import { trpc } from '../../../../apiCalls/trpcClient';
import { CompanyUsersProps } from '../../../../typesAndInterfaces/componentProps';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { utils } from '../../../../utils/utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { GetDataResponseBase, ItemsPageData } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { UserDataForClient } from '@temptrack/business';

type GetUsersReturnType = GetDataResponseBase & {
    usersData: (ItemsPageData & {
        items: UserDataForClient[];
    }) | undefined;
};
const useGetUsers = ({navigation, route}:CompanyUsersProps):GetUsersReturnType => {
    const {currentCompanyId, currentCustomDomain} = useCurrentCompanyContext();
    const {page, pageSize, PaginationComponent, setPage} = usePagination({navigation, route});
    const [filters, setFilters] = useState<Record<string, any>>({});
    const [extraParams, setExtraParams] = useState<Record<string, any>>({});

    const [nextToken, setNextToken] = useState<string | undefined>(undefined);
    useEffect(() => {
       setNextToken(undefined);
    }, [pageSize, setNextToken]);

    const queryParameters = useMemo(() => {
      let params: {
            companyId: string;
            page: number;
            pageSize: number;
            lastToken?: string;
            filter?: Record<string, any>;
      } = { companyId: currentCompanyId ?? '', page, pageSize, lastToken: nextToken};
       if (Object.keys(filters).length) {
           params = {...params, filter: filters};
       }
       if (Object.keys(extraParams).length) {
           params = {...params, ...extraParams};
       }
       return params;
    }, [currentCompanyId, page, pageSize, filters, extraParams, nextToken]);

    const infiniteFetchQueryParameters = useMemo(() => {
        let params: {
            companyId: string;
            limit: number
            filter?: Record<string, any>;
        } = { companyId: currentCompanyId ?? '', limit:10};
        if (Object.keys(filters).length) {
            params = {...params, filter: filters};
        }
        if (Object.keys(extraParams).length) {
            params = {...params, ...extraParams};
        }
        return params;
    }, [currentCompanyId, extraParams, filters]);


    const { data: recordData,
              isLoading,
              isPreviousData,
          } = trpc.company.user.getPage.useQuery(queryParameters, {
        enabled: !!currentCompanyId && !(utils.isTTEmployee(currentCustomDomain)) && !isMobileApp,
        refetchOnWindowFocus: false,
    });

    useEffect(()=> {
       setNextToken(recordData?.nextToken ?? undefined);
    }, [recordData]);


    // Infinite fetch
    const {
              data: infiniteRecordData,
              fetchNextPage,
              isPreviousData: infiniteFetchIsPreviousData,
              isLoading: infiniteFetchIsLoading,
              hasNextPage,
          } = trpc.company.user.list.useInfiniteQuery(infiniteFetchQueryParameters,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled: !!currentCompanyId && !(utils.isTTEmployee(currentCustomDomain)) && isMobileApp,
        },
    );

    const dataToReturn = useMemo(() => {
        if (isMobileApp) {
            const pages = infiniteRecordData?.pages ?? [];
            return {
                items: pages.map((p) => p.items).flat(),
            };
        }
        return recordData;
    }, [recordData, infiniteRecordData]);

    const setFiltersAction = useCallback((newFilters: Record<string, any>) => {
        setPage(1);
        setNextToken(undefined);
        setFilters?.(newFilters);
    }, [setPage]);

    const setExtraParamsAction = useCallback((newExtraParams: Record<string, any>) => {
        setPage(1);
        setNextToken(undefined);
        setExtraParams(newExtraParams);

    }, [setPage]);

    return {
        PaginationComponent,
        isLoading: isMobileApp ? infiniteFetchIsLoading : isLoading,
        isPreviousData: isMobileApp
            ? infiniteFetchIsPreviousData
            : isPreviousData,
        fetchNextPage: isMobileApp ? fetchNextPage : undefined,
        hasNextPage: isMobileApp ? hasNextPage : undefined,
        usersData: dataToReturn,
        setFilters: setFiltersAction,
        setExtraParams: setExtraParamsAction,
    };
};

export {useGetUsers};
