import React, { useCallback, useMemo, useRef, useState } from 'react';
import { Text, View } from 'react-native';
import { recordModalContentStyles } from '../../../../../constants/recordModalContentStyles';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { appColors, applyTTStyles } from '../../../../../constants/colors';
import { WrappedInput } from '../../../../helperComponents/misc/WrappedInput';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../../helperComponents/misc/OutlinedButton';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../../context/CurrentCompanyContext';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../../helperComponents/misc/Spacer';
import { MB_TextInputToolTipPortal, MB_TextInputToolTipPortalRef } from '@mightybyte/rnw.components.text-input';


const AddEmployees = (
    {
        workOrderId,
        shortlistId,
        submitAction,
        cancelAction,
        submitInProgress,
        submitError,
    }: {
        workOrderId: string,
        shortlistId: string,
        submitAction: (employees: string[])=>void,
        cancelAction: ()=>void,
        submitError: string
        submitInProgress: boolean,
}) => {
    const {currentCompanyId} = useCurrentCompanyContext();

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const [addedEmployees, setAddedEmployees] = useState<string[]>([]);

    const {data: recordData} = trpc.company.shortlist.getAvailableEmployees.useQuery({
        companyId: currentCompanyId ?? '',
       workOrderId: workOrderId,
        shortlistId: shortlistId,
    }, {
        enabled: !!currentCompanyId,
    });

    const items = useMemo(()=> {
        if (!recordData) {
            return [];
        }
        return recordData.employees.map((employee) => {
            return {label: `${employee.firstName} ${employee.lastName}`, value: employee.id};
        });
    }, [recordData]);


    const onSelectChange = useCallback((newValue:string[] | undefined)=> {
        if (!newValue) { return; }

        setAddedEmployees(newValue ? newValue.map((v)=> v.toString()) : []);
    },[]);


    return (
        <View style={{
            height: '100%',
            justifyContent: 'space-between',
        }}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={[recordModalContentStyles.rowItem, {zIndex: 3000, width: isMobile || isMobileApp ? '100%' : '55%', marginRight: 24}]}>
                <WrappedInput errorMessage={''}
                              // containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 4000}}}
                              inputComponent={<View style={{width: '100%'}}>
                                  <MB_DropDownPicker items={items}
                                                                 multiple={true}
                                                                 value={addedEmployees}
                                                                 onValueChange={onSelectChange}
                                                                 listMode={'SCROLLVIEW'}
                                                                 title={'Assign Employee(s)'}
                                                                 containerStyle={{width: '100%'}}
                                                                 dropdownContainerStyle={{
                                                                     maxHeight: 400,
                                                                 }}
                                                                 placeholder={'Select employee(s)'}
                                                                 showRequiredAsteriks={true}
                                                                 dropDownDirection={'BOTTOM'}
                                                                 extendableBadgeContainer={true}
                                                                 selectedOptionColor={appColors.green600}
                              />
                              </View>}
                />
            </View>

            <View style={recordModalContentStyles.errorContainer}>
                <Text style={recordModalContentStyles.errorMessage}>{submitError ?? ''}</Text>
            </View>

            <View style={{zIndex: 1, marginTop: 24}}>
                <View style={[recordModalContentStyles.row]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Save changes'}
                                   onPress={()=> {
                                        submitAction(addedEmployees);
                                   }}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   loading={submitInProgress}

                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        action={cancelAction}
                                        disabled={submitInProgress}
                        />
                    </View>

                </View>
            </View>
        </View>
    );
};

export {AddEmployees};

