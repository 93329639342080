import React, { useCallback, useEffect, useMemo } from 'react';
import { WrappedInput } from '../components/helperComponents/misc/WrappedInput';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { STRING_CONSTANTS } from '../constants/constants';
import { getColors } from '../constants/colors';

const COLORS = getColors();


const useAddress = ({ isOptional, nextToFocus, onSubmitEditing, disabled}: {
    isOptional?: boolean;
    disabled?: boolean;
    nextToFocus?: React.RefObject<MB_TextInputRef>;
    onSubmitEditing?: ()=> void;
})=> {
    const [fullAddress, setFullAddress] = React.useState<string>('');
    const [address, setAddress] = React.useState<string>('');

    const [address2, setAddress2] = React.useState<string>('');

    const [city, setCity] = React.useState<string>('');

    const [state, setState] = React.useState<string>('');

    const [zipCode, setZipCode] = React.useState<string>('');

    const [addressError, setAddressError] = React.useState<string>('');

    useEffect(() => {
        setAddressError('');
    }, [address, city, state, zipCode, setAddressError]);

    const isEmpty = (value: string) => {
        return value.trim().length === 0;
    };

    const validateAddress = useCallback(() => {
        if (isOptional) {
            return true;
        }
        const cityisEmpty = isEmpty(city);
        const stateisEmpty = isEmpty(state);
        const zipCodeisEmpty = isEmpty(zipCode);
        const addressisEmpty = isEmpty(address);

        if (cityisEmpty && stateisEmpty && zipCodeisEmpty && addressisEmpty) {
            setAddressError(STRING_CONSTANTS.REQUIRED);
            return false;
        } else if (cityisEmpty || stateisEmpty || zipCodeisEmpty || addressisEmpty) {
            setAddressError(STRING_CONSTANTS.INVALID_ADDRESS);
            return false;
        }
        return true;
    }, [isOptional, address, state, zipCode, city]);


    const secondLineField = useMemo(()=> {
        return (<WrappedInput inputComponent={<MB_TextInput style={{width: '100%'}}
                                                            placeholderTextColor={COLORS.inputPlaceholder}
                                                            value={address2}
                                                            onChangeText={(newText: string) => {
                                                                setAddress2(newText);
                                                            }}
                                                            title={'Address 2'}
                                                            placeholder={'Apt#, suite, etc.'}
                                                            textContentType={'fullStreetAddress'}
                                                            nextToFocus={nextToFocus}
                                                            onSubmitEditing={onSubmitEditing}
                                                            disable={disabled}
                              />}
        />);
    }, [setAddress2, nextToFocus, address2, disabled,onSubmitEditing]);

    const values = useMemo(()=> {
        return {
            line1: address,
            line2: address2,
            city,
            state,
            zipCode,
        };
    }, [address, address2, city, state, zipCode]);

    return {
        values,
        error: addressError,
        validateAddress: validateAddress,
        currentState: state,
        fullAddress,
        setFullAddress,
        setAddress,
        setAddress2,
        setCity,
        setState,
        setZipCode,
        addressSecondLineField: secondLineField,

    };
};

export {useAddress};
