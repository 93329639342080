import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { getColors } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import {
    ChecklistActivityStatus,
    DateInput,
    EmployeePosition, ShortlistAcceptanceStatus,
    toEmployeePositionTypeLabel,
    toWorkOrderPositionLabel, WorkOrderPosition, WorkOrderPositionType,
} from '@temptrack/business';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { DisplayOptions, ValueRow } from '../../../helperComponents/misc/ValueRow';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import {
    AssignmentStatusRenderer,
    AssignmentStatusRendererProps,
} from '../../../helperComponents/misc/badges/AssignmentStatusRenderer';
import { utils, formatDateOptions } from '../../../../utils/utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { AcceptanceStatusRenderer } from '../../../helperComponents/misc/badges/AcceptanceStatusRenderer';

const COLORS = getColors();

type Base = {
    position: WorkOrderPosition | undefined;
    positionType: WorkOrderPositionType | undefined;
    startDate: DateInput | undefined;
    activityStatus: ChecklistActivityStatus | undefined;
    endDate: DateInput | undefined;
    createdAt: string | undefined;
    number: string
    clientName: string | undefined;
    assignmentStatus: AssignmentStatusRendererProps['assignmentStatus'];
}

type ListData <T extends RecordTypes> = Base & (
    T extends RecordTypes.checklist ? {
        acceptanceStatus: undefined;
    } : T extends RecordTypes.shortlist ? {
        acceptanceStatus: {
            status: ShortlistAcceptanceStatus,
            assignedToQty: number,
            acceptedQty: number,
        };
    } : never
);


const ListInfoBlockUI = <T extends RecordTypes>({data, recordType}: {
    recordType: RecordTypes.checklist | RecordTypes.shortlist;
    data: ListData<T>;
}) => {

    const styles = useMemo(()=> {
        return platformStyles({isMobile: isMobileApp});
    },[]);

    const RowDefaultProps = useMemo(()=> {
        return {
            displayOption: DisplayOptions.secondary,
            containerStyles: styles.rowItemContainer,
        };
    },[styles.rowItemContainer]);

    const CheckListItems = useMemo(()=> {
        if (recordType === RecordTypes.shortlist) {
return [
    {label: 'Activity Status', valueComponent: <StatusRenderer status={data.activityStatus} recordType={RecordTypes.shortlist}/>},
                {label: 'Assignment Status', valueComponent: <AssignmentStatusRenderer displayRow={true} assignmentStatus={data.assignmentStatus}/>},
                {label: 'Acceptance Status', valueComponent: data?.acceptanceStatus ? <AcceptanceStatusRenderer acceptanceStatus={
                            data.acceptanceStatus} displayRow={true}/> : undefined},
                {label: 'Date created', value: utils.formatUTCDateToDisplay(data.createdAt, formatDateOptions['MMM, d, yyyy'])},
            ];
        }
        return [
            {label: 'Activity Status', valueComponent: <StatusRenderer status={data.activityStatus} recordType={RecordTypes.checklist}/>},
            {label: 'Assignment Status', valueComponent: <AssignmentStatusRenderer displayRow={true} assignmentStatus={data?.assignmentStatus}/>},
            {label: 'Date created', value: utils.formatUTCDateToDisplay(data.createdAt, formatDateOptions['MMM, d, yyyy'])},
        ];
    },[data, recordType]);

    const WorkOrderItems = useMemo(()=> {
        return   [
            {label: 'Work Order Number', value:data.number},
            {label: 'Position', value: toWorkOrderPositionLabel(data.position as EmployeePosition)},
            {label: 'Position Type', value: toEmployeePositionTypeLabel(data.positionType as WorkOrderPositionType)},
            {label: 'Duration', value: `${utils.formatDateObjectToDisplay(data.startDate)} - ${utils.formatDateObjectToDisplay(data.endDate)}`},
        ];

    },[data]);

    return (
        <View style={styles.container}>
           <View style={styles.columnContainer}>
               <Text style={styles.title}>{`${recordType === RecordTypes.shortlist ? 'Shortlist' : 'Checklist'} Information`}</Text>
                {CheckListItems.map((item, index)=> {
                     return <ValueRow key={index} {...item} {...RowDefaultProps}/>;
                })}
           </View>
            {isMobileApp ? <Spacer y={24}/> : null}
            <View style={styles.columnContainer}>
                <Text style={styles.title}>Related Work Order Information</Text>
                {WorkOrderItems.map((item, index)=> {
                    return <ValueRow key={index} {...item} {...RowDefaultProps}/>;
                })}
            </View>
            {isMobileApp ? <Spacer y={100}/> : null}
        </View>
    );
};

export {ListInfoBlockUI};

const platformStyles = ({isMobile}: {
    isMobile: boolean;
}) => StyleSheet.create({
    container: {
        paddingVertical: 20,
        backgroundColor: COLORS.backgroundSecondary,
        flexDirection: isMobile ? 'column' : 'row',
    },
    columnContainer: {
        width: isMobile ? '100%' : '50%',
        alignItems: 'flex-start',
        paddingHorizontal: 16,
    },
    title: mbTextStyles([
        textStyles.Inter_16_600,
        {
            color: COLORS.textPrimary,
            fontSize: 14,
            marginBottom: 8,
        },
    ]),
    rowItemContainer: {
        marginVertical: 2,
    },
});
