import { useEffect, useMemo, useState, useCallback } from 'react';
import { usePagination } from '../../../../hooks/usePagination';
import { trpc } from '../../../../apiCalls/trpcClient';
import { WorkOrdersProps } from '../../../../typesAndInterfaces/componentProps';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { GetDataResponseBase, ItemsPageData } from '../../../../typesAndInterfaces/typesAndInterfaces';
import { WorkOrderDataForClient } from '@temptrack/business';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';


type GetWorkOrdersReturnType = GetDataResponseBase & {
    workOrdersData:
        | (ItemsPageData & { items: WorkOrderDataForClient[] })
        | undefined;

};
const useGetWorkOrders = ({navigation, route}:WorkOrdersProps):GetWorkOrdersReturnType => {
    const {currentCompanyId} = useCurrentCompanyContext();
    const {page, pageSize, PaginationComponent, setPage} = usePagination({navigation, route});
    const [filters, setFilters] = useState<Record<string, any>>({});


    const [nextToken, setNextToken] = useState<string | undefined>(undefined);
    useEffect(() => {
        setNextToken(undefined);
    }, [pageSize, setNextToken]);

    const { data: recordData,
              isLoading,
              isPreviousData,
          } = trpc.company.workOrder.getPage.useQuery({ companyId: currentCompanyId ?? '', page, pageSize,
        lastToken: nextToken,
        filter: filters,
    }, {
        enabled: !!currentCompanyId && !isMobileApp,
        retry: false,
        refetchOnWindowFocus: false,
    });

    useEffect(()=> {
        setNextToken(recordData?.nextToken ?? undefined);
    }, [recordData, setNextToken]);

    // Infinite fetch
    const {
              data: infiniteRecordData,
              fetchNextPage,
              hasNextPage,
              isPreviousData: infiniteFetchIsPreviousData,
              isLoading: infiniteFetchIsLoading,
          } = trpc.company.workOrder.list.useInfiniteQuery(
        {
            companyId: currentCompanyId ?? '',
            limit: 10,
            filter: filters,
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled: !!currentCompanyId && isMobileApp,
        },
    );

    const dataToReturn = useMemo(() => {
        if (isMobileApp) {
            const pages = infiniteRecordData?.pages ?? [];
            return {
                items: pages.map((p) => p.items).flat(),
            };
        }
        return recordData;
    }, [recordData, infiniteRecordData]);
    const setFiltersAction = useCallback((newFilters: Record<string, any>) => {
        setPage(1);
        setNextToken(undefined);
        setFilters?.(newFilters);
    }, [setPage]);


    return {
        PaginationComponent,
        workOrdersData: dataToReturn,
        isLoading: isMobileApp ? infiniteFetchIsLoading : isLoading,
        isPreviousData: isMobileApp
            ? infiniteFetchIsPreviousData
            : isPreviousData,
        fetchNextPage: isMobileApp ? fetchNextPage : undefined,
        hasNextPage: isMobileApp ? hasNextPage : undefined,
        setFilters: setFiltersAction,
    };
};

export {useGetWorkOrders};
