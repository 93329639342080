import React, { useCallback, useMemo } from 'react';
import {StyleSheet, FlatList, View, ListRenderItemInfo, Text} from 'react-native';
import { COLORS } from '../../../../../constants/colors';
import { BOX_SHADOW } from '../../../../../utils/styleUtils';
import { utilHooks } from '../../../../../hooks/utilHooks';
import { EmployeeUpdateCheckInData, ShortlistEmployeeTableData } from '../types';
import { EmptyTableView } from '../../../../helperComponents/EmptyTableView';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { ShortlistEmployeeRow } from './ShortlistEmployeeRow';
import { tableStyles } from '../../../../../constants/tableStyles';
import { shortlistColumnStyles } from './styles';
import { EmployeeAcceptanceStatus } from '@temptrack/business';


const ShortListTableUI = (
    {
        data,
        isEditing,
        footerComponent,
        headerComponent,
        toggleDeleteAction,
        markedForDelete,
        toggleAcceptanceStatus,
        unsavedAcceptanceChanges,
    }:{
    data: ShortlistEmployeeTableData[];
    isEditing: boolean;
    updateCheckinData: (data: EmployeeUpdateCheckInData)=> void;
    headerComponent?: JSX.Element;
    footerComponent?: JSX.Element;
    toggleDeleteAction: (id: string)=> void;
    markedForDelete: string[];
    toggleAcceptanceStatus: (id: string, status: EmployeeAcceptanceStatus | undefined)=> void;
    unsavedAcceptanceChanges: {
        [key: string]: EmployeeAcceptanceStatus;
    }

}) => {

    const tableHeight = utilHooks.useTableHeight();
    const renderItem = useCallback(({ item}: ListRenderItemInfo<ShortlistEmployeeTableData>)=> {

        return <ShortlistEmployeeRow employee={item}
                                     isEditing={isEditing}
                                     toggleDeleteAction={toggleDeleteAction}
                                     markedForDelete={markedForDelete.includes(item.employeeId)}
                                     toggleAcceptanceStatus={toggleAcceptanceStatus}
                                     hasUnsavedChanges={Object.keys(unsavedAcceptanceChanges).includes(item.employeeId)}
                                     unsavedAcceptanceChanges={unsavedAcceptanceChanges}

        />;
    },[isEditing, markedForDelete, toggleDeleteAction, unsavedAcceptanceChanges, toggleAcceptanceStatus]);

    const keyExtractor = useCallback((item:ShortlistEmployeeTableData, index)=> `${item?.employeeId}-${index}`, []);

    const ListEmptyComponent = useMemo(()=> {
        return (
            <EmptyTableView onAddPress={()=>{}}
                            title={'No Employees assigned'}
                            hideAddButton={true}
            />
        );
    },[]);

    const separator = useMemo(()=> {
       return <View style={styles.separator}/>;
    },[]);

    const tableheaderComponent = useMemo(()=> {
        if (isMobileApp) {
            return null;
        }
        return <View style={styles.header}>
            <View style={{flexDirection: 'row', flex: 1}}>
            <View style={shortlistColumnStyles.col1}>
                <Text style={[tableStyles.headerTextStyle, {textAlign: 'left'}]}>Employee name</Text>
            </View>
            <View style={shortlistColumnStyles.col2}>
                <Text style={[tableStyles.headerTextStyle, {textAlign: 'left'}]}>Employee Acceptance Status</Text>
            </View>
            </View>
            <View style={shortlistColumnStyles.actionColumn}/>
        </View>;
    },[]);

    const wrapperHeight = useMemo(()=> {
    const footerHeight = isMobileApp ? 0 : 65;
    return tableHeight - footerHeight;
    },[tableHeight]);


    return (
        <View style={[styles.container,{maxHeight: wrapperHeight, height: wrapperHeight}]}>
          <FlatList data={[...data]}
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                    ListEmptyComponent={ListEmptyComponent}
                    ItemSeparatorComponent={()=>separator}
                    ListFooterComponent={isMobileApp  ? footerComponent : undefined}
                    ListHeaderComponent={isMobileApp ? headerComponent : tableheaderComponent}
                    showsVerticalScrollIndicator={!isMobileApp}

          />
        </View>
    );

};

export {ShortListTableUI};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 16,
        paddingVertical: 6,
        backgroundColor: COLORS.backgroundPrimary,
        ...BOX_SHADOW,
        width: '100%',
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.inputBorder,
    },
    header: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 2,
        borderBottomWidth: 1,
        borderBottomColor: COLORS.inputBorder,
    },
});
