import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { appColors, applyTTStyles, COLORS } from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { drawerWidth } from '../../constants/elementSizes';
import { ClickableText } from '../../components/helperComponents/misc/ClickableText';
import { BOX_SHADOW } from '../../utils/styleUtils';
import { Checkbox } from '../../components/helperComponents/misc/Checkbox';
import { Masks, MB_TextInput, MB_TextInputInputType } from '@mightybyte/rnw.components.text-input';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { navRef } from '../../navigations/RootNavigator';
import { WrappedDatePicker } from '../../components/helperComponents/misc/WrappedDatePicker';
import { FilterPanelUI } from './FilterPanelUI';
import { FilterOption, FilterTypeToQueryTypeMap, FilterTypeToQueryValueMap } from './types';
import { FilterFieldType, FilterQueryType } from '@temptrack/business';
import { ModalWrapper } from '../../components/helperComponents/misc/ModalWrapper';
import { trpc } from '../../apiCalls/trpcClient';
import { NotFilterFields } from './types';

type FilterReturn = {
    Button: JSX.Element,
    FilterPanel: JSX.Element,
    filterIsOpen: boolean,
    filtersApplied: boolean,
    setOptions: React.Dispatch<React.SetStateAction<FilterOption[]>>
    clearFilters: ()=>void,
};


function getDropdownOptions(filterType: FilterFieldType): {value: FilterQueryType, label: string}[] {
    let options:FilterQueryType[] = [];
    switch (filterType) {
        case FilterFieldType.text: {
            options = [FilterQueryType.contains, FilterQueryType.notContain, FilterQueryType.equals, FilterQueryType.notEqual];
            break;
        }
        case FilterFieldType.dropdown:{
            options = [FilterQueryType.equals, FilterQueryType.notEqual, FilterQueryType.isAnyOf, FilterQueryType.isNoneOf, FilterQueryType.isEmpty, FilterQueryType.notEmpty];
            break;
        }
        case FilterFieldType.number:
        case FilterFieldType.phone:{
            options = [FilterQueryType.equals, FilterQueryType.notEqual];
            break;
        }
        case FilterFieldType.date: {
            options = [FilterQueryType.before, FilterQueryType.after, FilterQueryType.between, FilterQueryType.equals, FilterQueryType.notEqual];
            break;
        }
        default: {
            break;
        }
    }
    return options.map(option => {return {value: option, label: getQueryTypeForDisplay(option)};});
}

export type FilterQueryAndOtherParams = {
    filters:  { [key: string]: { queryType: string, value?: any, values?:string[] | {from: any, to: any} } }
    otherParams?: { [key: string]: string | boolean}
};

type FilterProps = {
    options: FilterOption[],
    applyFiltersCallback: ({filters, otherParams}: FilterQueryAndOtherParams) => void,
    isTempTrack: boolean,
    initialParams?: string,
    userCompanyId?: string,
    userRegionId?: string,
};

enum ActionTypes  {
    addFilter = 'addFilter',
    removeFilter = 'removeFilter',
    setQuery = 'setQuery',
    setQueryType = 'setQueryType',
    resetAll = 'resetAll',
    addBooleanFilter = 'addBooleanFilter',
}

function reducer (state:FilterOption[], action:{type: ActionTypes, value?: any}) {
    switch (action.type) {
        case ActionTypes.addBooleanFilter: {
            return [
                ...state,
                action.value,
            ];
        }
        case ActionTypes.addFilter: {
            return [
                ...state,
                action.value,
            ];
        }
        case ActionTypes.removeFilter: {
            return [
            ...state.filter(option => option.apiName !== action.value.apiName),
            ];
        }
        case ActionTypes.setQuery: {
            return state.map(option => {
                    if (option.apiName === action.value.apiName) {
                        return {...option, queryValue: action.value.queryValue};
                    }
                    return option;
                });
        }
        case ActionTypes.setQueryType: {
            return state.map(option => {
                    if (option.apiName === action.value.apiName) {
                        if (action.value.queryType === FilterQueryType.between){
                            return {...option, queryType: action.value.queryType, queryValue: {startDate: '', endDate:''}};
                        }
                        if (action.value.queryType === FilterQueryType.isAnyOf || action.value.queryType === FilterQueryType.isNoneOf) {
                            if (typeof option.queryValue === 'string') {
                                return {...option, queryType: action.value.queryType, queryValue: [option.queryValue]};
                            } else {
                                return {...option, queryType: action.value.queryType};
                            }
                        }
                        else {
                            if (Array.isArray(option.queryValue)) {
                                return {...option, queryType: action.value.queryType, queryValue: option.queryValue[0]};
                            } else if (typeof option.queryValue === 'object') {
                                if (option.filterType === FilterFieldType.phone){
                                    return {...option, queryType: action.value.queryType};
                                }
                                return {...option, queryType: action.value.queryType, queryValue: ''};
                            }
                            return {...option, queryType: action.value.queryType};
                        }

                    }
                    return option;
                });
        }
        case ActionTypes.resetAll: {
            return [];
        }
        default: {
            return state;
        }
    }
}

function getQueryTypeForDisplay(queryType: FilterQueryType) {
    switch (queryType) {
        case FilterQueryType.contains: {
            return 'Contains';
        }
        case FilterQueryType.notContain: {
            return 'Does not contain';
        }
        case FilterQueryType.equals: {
            return 'Is equal';
        }
        case FilterQueryType.notEqual: {
            return 'Is not equal';
        }
        case FilterQueryType.between: {
            return 'Is between';
        }
        case FilterQueryType.before: {
            return 'Is before';
        }
        case FilterQueryType.after: {
            return 'Is after';
        }
        case FilterQueryType.isAnyOf: {
            return 'Is any of';
        }
        case FilterQueryType.isNoneOf: {
            return 'Is none of';
        }
        case FilterQueryType.isEmpty: {
            return 'Is empty';
        }
        case FilterQueryType.notEmpty: {
            return 'Is not empty';
        }
        default: {
            return '';
        }
    }
}

const sharedInputProps = {
    style: {width: '100%'},
    titleStyle: {color: COLORS.textSecondary},
};

function arrayToUrlParams (array: {[key: string]: {queryType: string, queryValue: any}}[] | undefined) {
    if (!array) {return '';}
    return array.map(obj => {
        const key = Object.keys(obj)[0];
        const value = obj[key];
        const queryValue = typeof value.queryValue === 'string' ? value.queryValue : JSON.stringify(value.queryValue);
        return `${key}=${value.queryType}:${queryValue}`;
    }).join('&');
}

function parseUrlParams (p: {
    urlParams: string  | undefined,
    options: FilterOption[]
}) {

    const params = p?.urlParams;
    if (!params) {return [];}

    const paramArray = params.split('&');
    const initialState:FilterOption[] = [];
    paramArray.forEach((param) => {
        const [key, value] = param.split('=');

        if (value.includes(':{') || value.includes(':[')) {
            const separator = value.includes(':{') ? ':{' : ':[';
            //handle case like: createdAt=between:{"startDate":"2024-03-27","endDate":"2024-03-30"}
            const [queryType, queryValue] = value.split(separator);
            const opt = p.options.find(option => option.apiName === key);
            if (opt) {
                initialState.push({...opt, ...{
                        queryType: queryType as FilterTypeToQueryTypeMap[FilterFieldType],
                        queryValue: JSON.parse(`${separator.split(':')[1]}${queryValue}`),
                    }});

            }
        } else {
            //find first occurrence of ':' and split on that
            const indexOfColon = value.indexOf(':');
            const queryType = value.slice(0, indexOfColon);
            const queryValue = value.slice(indexOfColon + 1);

            const opt = p.options.find(option => option.apiName === key);
            if (opt) {
                initialState.push({...opt, ...{
                        queryType: queryType as FilterTypeToQueryTypeMap[FilterFieldType],
                        queryValue: queryValue,
                    }});
            }
        }
    });
   return initialState;
}

const useFilter = (props: FilterProps):FilterReturn => {
    const {initialParams, applyFiltersCallback} = props;

    const buttonColor = useMemo(()=>applyTTStyles(props.isTempTrack).textButtonColor, [props.isTempTrack]);
    const [show, setShow] = useState(false);
    const [filtersApplied, setFiltersApplied] = useState(false);
    const [initialApplied, setInitialApplied] = useState(false);

    const companyIdRef = React.useRef(props.userCompanyId);
    // const regionIdRef = React.useRef(props.userRegionId);

    const hasRegionFilter = useMemo(() => {
        const regionOption = props.options.find(option => option.apiName === 'regionId');
        if (regionOption) {
            return !regionOption.hide;
        }
        return false;
    }, [props.options]);

    const [regionDropdownOptions, setRegionDropdownOptions] = useState<{label: string, value: string}[]>([]);

    const {
              data: regionOptions,
              isLoading: gettingRegions,
          } = trpc.company.region.getDropdown.useQuery({ companyId: companyIdRef.current ?? ''}, {
        enabled: !!companyIdRef.current && hasRegionFilter && regionDropdownOptions.length === 0,
    });


    useEffect(() => {
        if (!regionOptions || !regionOptions?.items || gettingRegions) {
            return;
        }
        setRegionDropdownOptions(regionOptions.items.map((region)=> {
            return {label: region.name, value: region.id};
        }));
    }, [gettingRegions, regionOptions]);

    const hasBranchFilter = useMemo(() => {
        const branchOption = props.options.find(option => option.apiName === 'branchId');
        if (branchOption) {
            return !branchOption.hide;
        }
        return false;
    },[props.options]);

  const [branchDropdownOptions, setBranchDropdownOptions] = useState<{label: string, value: string}[]>([]);

    const {data: branchOptions, isLoading: gettingBranches} = trpc.company.branch.getDropdown.useQuery({ companyId: companyIdRef.current ?? ''}, {
        enabled: !!companyIdRef.current && hasBranchFilter && branchDropdownOptions.length === 0,
    });

    useEffect(() => {
        if (!branchOptions || !branchOptions?.items || gettingBranches) {
            return;
        }
        setBranchDropdownOptions(branchOptions.items.map((branch)=> {
            return {label: branch.name, value: branch.id};
        }));
    }, [gettingBranches, branchOptions]);

    const hasClientFilter = useMemo(() => {
        const clientOption = props.options.find(option => option.apiName === 'clientId');
        if (clientOption) {
            return !clientOption.hide;
        }
        return false;
    },[props.options]);

    const [clientDropdownOptions, setClientDropdownOptions] = useState<{label: string, value: string}[]>([]);

    const {data: clientOptions, isLoading: gettingClients} = trpc.company.client.getDropdown.useQuery({ companyId: companyIdRef.current ?? ''}, {
        enabled: !!companyIdRef.current && hasClientFilter && clientDropdownOptions.length === 0,
    });

    useEffect(() => {
        if (!clientOptions || !clientOptions?.items || gettingClients) {
            return;
        }
        setClientDropdownOptions(clientOptions.items.map((client)=> {
            return {label: client.name, value: client.id};
        }));
    }, [gettingClients, clientOptions]);

    const toggleShow = useCallback(() => {
        setShow((prevState)=>!prevState);
    }, []);

    const [options, setOptions] = useState<FilterOption[]>(props.options);

    const [selectedOptions, dispatch] = useReducer(reducer, {urlParams: props.initialParams, options} ?? undefined, parseUrlParams);


    function reformatQueryValue (value: any, filterType: FilterFieldType){
        if (filterType === FilterFieldType.date || filterType === FilterFieldType.datetime) {
            const day = new Date(value).getDate();
            const month = new Date(value).getMonth() + 1;
            const formattedMonth = month < 10 ? `0${month}` : month;
            const year = new Date(value).getFullYear();
            return `${year}-${formattedMonth}-${day}`;
        }
        return value;
    }

    const createQuery = useCallback((): FilterQueryAndOtherParams => {

        const query :{[key: string]: {queryType: string,
                value?: any,
                values?:string[] | {from: any, to: any}
            }} = {};
        const otherParams:{
            [key: string]: string | boolean,
        } = {};
        selectedOptions.map(option => {
            //if NotFilterFields
            if (option.apiName === NotFilterFields.isInvitePending){
                otherParams[option.apiName] = true;
                return;
            }
            if (!option.queryType || !option.queryValue) {return;}
            query[option.apiName] = {
                queryType: option.queryType,
            };
            switch (option.queryType) {
                case FilterQueryType.isEmpty:
                case FilterQueryType.notEmpty: {
                    break;
                }
                case FilterQueryType.after:
                case FilterQueryType.before: {
                    query[option.apiName].value = reformatQueryValue(option.queryValue.toString(), option.filterType);
                    break;
                }

                case FilterQueryType.between: {
                    query[option.apiName].values = {
                        from: reformatQueryValue(option?.queryValue?.startDate?.toString(), option.filterType),
                        to: reformatQueryValue(option?.queryValue?.endDate?.toString(), option.filterType),
                    };
                    break;
                }
                case FilterQueryType.isAnyOf:
                case FilterQueryType.isNoneOf:{
                    query[option.apiName].values = option.queryValue.map((v:string) => reformatQueryValue(v, option.filterType));
                    break;
                }
                default: {
                    if (typeof option.queryValue === 'object') {
                        if (option?.queryValue?.unmasked) {
                            query[option.apiName].value = option.queryValue.unmasked;
                        }
                    } else {
                        query[option.apiName].value = reformatQueryValue(option.queryValue.toString(), option.filterType);
                    }

                }
            }
        });
        return {
            filters: query,
            otherParams: otherParams,
        };
    }, [selectedOptions]);


    useEffect(() => {
        if (initialApplied) {return;}
        if (initialParams) {
            setFiltersApplied(true);
            applyFiltersCallback(createQuery());
            setInitialApplied(true);
        }

    }, [createQuery, initialParams, applyFiltersCallback, initialApplied]);

    const handleFilterCheckboxes = useCallback((option, isChecked) => {
        if (!isChecked) {
            if (option.filterType === FilterFieldType.checkbox) {
                dispatch({type: ActionTypes.addBooleanFilter, value: {
                        ...option,
                        queryValue: true,
                        queryType: FilterQueryType.equals,
                    }});
            } else {
                dispatch({type: ActionTypes.addFilter, value: {...option, queryValue: null}});
            }

        } else {
            dispatch({type: ActionTypes.removeFilter, value: option});
        }
    },[]);

    const ResetFiltersComponent = useMemo(() => {

        return (
            <ClickableText text={'Reset'}
                           action={()=>{
                               setFiltersApplied(false);
                               dispatch({type: ActionTypes.resetAll});
                               navRef.current?.setParams({filter: undefined});
                               applyFiltersCallback({
                                      filters: {},
                                      otherParams: {},
                               });
                           }}
                           color={buttonColor}
                           outline={true}
                           removeLeftPadding={true}
                           removeRightPadding={true}
            />
        );
    }, [buttonColor, applyFiltersCallback]);


    const Button = useMemo(() => {
        const title = filtersApplied && selectedOptions.length > 0 ? 'Filtered by' : 'Filter';
        const selectedLabels = selectedOptions.map(option => option.label).join(', ');

        return (
            <View style={{flexDirection: 'row', position: 'relative', padding: isMobileApp ? 8 : undefined}}>
                <MB_Button style={styles.filterButton} onPress={toggleShow}
                           leftElement={<AntDesign name="filter" size={20} color={COLORS.textPrimary} />}
                           textStyle={styles.filterButtonText}
                           title={isMobileApp ? undefined : title}
                />
                {filtersApplied ? <>
                    {selectedOptions.length > 0 ? isMobileApp ?
                        <View style={[styles.badge, isMobileApp ? styles.badgePositionMobile : styles.badgePosition, {backgroundColor: buttonColor}]}>
                            <Text style={styles.badgeText}>{selectedOptions.length}</Text>
                        </View> : <View style={[styles.row, {marginLeft: 8}]}>
                        <Text>{selectedLabels}</Text>
                    </View> : null}
                    {(!isMobileApp && selectedOptions.length > 0) ? <View style={{marginLeft: 8}}>{ResetFiltersComponent}</View> : null}
                </> : null}

            </View>
        );
    }, [selectedOptions, ResetFiltersComponent, filtersApplied, buttonColor, toggleShow]);

    const keyExtractor = useCallback((item: FilterOption, index) => `${index}-${item.apiName}`, []);

    const handleFilterChange = useCallback((key: FilterOption['apiName'], value: FilterOption['queryValue']) => {
       dispatch({type: ActionTypes.setQuery, value: {apiName: key, queryValue: value}});
    }, []);


    const getFilterContent = useCallback( (option: FilterOption,
                               isVisible:boolean,
                               onChange:(key: FilterOption['apiName'], value: FilterOption['queryValue'])=>void,
                               zIndex: number
    ) => {
        if (!isVisible) {return null;}
        const dropdownOptions = getDropdownOptions(option.filterType);

        const PickerComponent = <View style={{zIndex: zIndex}}><MB_DropDownPicker multiple={false}
                                                   onValueChange={(v)=> {
                                                       dispatch({type: ActionTypes.setQueryType, value: {apiName: option.apiName, queryType: v}});
                                                   }}
                                                   value={option?.queryType as string ?? ''}
                                                   items={dropdownOptions}
                                                   placeholder={'Select a filter'}
                                                   style={styles.dropdownStyle}
                                                   dropDownDirection={'BOTTOM'}
                                                   listMode={'SCROLLVIEW'}
                                                                                  containerStyle={{zIndex: zIndex}}
        />
        </View>;

        function applySpecialDropdownOptions (opt: FilterOption) {
            switch (opt.apiName) {
                case 'regionId': {
                    return regionDropdownOptions.map(region => {
                        return {label: region.label, value: region.value};

                    });
                }
                case 'branchId': {
                    return branchDropdownOptions.map(branch => {
                        return {label: branch.label, value: branch.value};

                    });
                }
                case 'clientId': {
                    return clientDropdownOptions.map(client => {
                        return {label: client.label, value: client.value};

                    });
                }
                default: {
                    return opt.dropdownOptions ?? [];
                }
            }
        }

        switch (option.filterType) {
            case FilterFieldType.text:
            case FilterFieldType.number: {
                return (
                    <View style={{zIndex: zIndex}}>
                        {PickerComponent}
                        <MB_TextInput
                            // style={{zIndex: zIndex -50}}
                                      {...sharedInputProps}
                                      onChangeText={(text)=>{
                                          onChange(option.apiName, text);}}
                                      value={option.queryValue as FilterTypeToQueryValueMap[FilterFieldType.text] ?? ''}
                                      inputType={option.filterType === FilterFieldType.number ? MB_TextInputInputType.numbers : undefined}

                        />
                    </View>

                );
            }
            case FilterFieldType.phone: {
                const value: FilterTypeToQueryValueMap[FilterFieldType.phone] = option.queryValue as FilterTypeToQueryValueMap[FilterFieldType.phone];
                return (
                    <View style={{zIndex: zIndex}}>
                        {PickerComponent}
                        <MB_TextInput
                            key={option.apiName}
                            {...sharedInputProps}
                            textContentType={'telephoneNumber'}
                            value={value?.masked ?? ''}
                            mask={{
                                maskType: Masks.USA_PHONE,
                                onChangeText: (v)=>{
                                   onChange(option.apiName, {masked: v.masked, unmasked: v.unmasked});
                                },
                            }}

                        />
                    </View>

                );
            }
            case FilterFieldType.dropdown: {
                const value: FilterTypeToQueryValueMap[FilterFieldType.dropdown] = option.queryValue as FilterTypeToQueryValueMap[FilterFieldType.dropdown];
                if (option.queryType === FilterQueryType.isAnyOf || option.queryType === FilterQueryType.isNoneOf) {
                    let valueOptions = option?.dropdownOptions ? applySpecialDropdownOptions(option) : [];
                    return (
                        <View style={{zIndex: zIndex}}>
                            {PickerComponent}
                            <MB_DropDownPicker multiple={true}
                                               onValueChange={(v)=> {
                                                   onChange(option.apiName, v);}
                                               }
                                               items={valueOptions}
                                               placeholder={''}
                                               value={value ? value as string[] : []}
                                               dropDownDirection={'BOTTOM'}
                                               listMode={'SCROLLVIEW'}
                            />

                        </View>
                    );
                } else {
                    const shouldRenderValuesDropdown = option.queryType !== FilterQueryType.isEmpty && option.queryType !== FilterQueryType.notEmpty;
                    let valueOptions = option?.dropdownOptions ? applySpecialDropdownOptions(option) : [];
                    return (
                        <View style={{zIndex: zIndex}}>
                            {PickerComponent}
                            {shouldRenderValuesDropdown ? <MB_DropDownPicker multiple={false}
                                               onValueChange={(v)=> {
                                                   onChange(option.apiName, v);}
                                               }
                                               items={valueOptions}
                                               placeholder={''}
                                               value={value ? value as string : ''}
                                               dropDownDirection={'BOTTOM'}
                                               listMode={'SCROLLVIEW'}
                            /> : null}

                        </View>
                    );
                }

            }
            case FilterFieldType.date:{
                const value: FilterTypeToQueryValueMap[FilterFieldType.date] = option.queryValue as FilterTypeToQueryValueMap[FilterFieldType.date];
                if (option.queryType === FilterQueryType.between && typeof value === 'object') {
                    return (
                        <View style={{zIndex: zIndex}}>
                            {PickerComponent}
                            <WrappedDatePicker key={option.queryType}
                                title={'From'}
                                value={value?.startDate || ''}
                                showInitialEmpty
                                noLimits={true}
                                onChange={(newValue)=> {
                                    onChange(option.apiName, {startDate: newValue?.toISOString() || '' , endDate: value?.endDate});
                                }}/>
                            <WrappedDatePicker
                                title={'To'}
                                value={value?.endDate || ''}
                                showInitialEmpty
                                noLimits={true}
                                onChange={(newValue)=> {
                                    onChange(option.apiName, {startDate: value?.startDate, endDate: newValue?.toISOString() || ''});
                                }}/>

                        </View>
                    );
                } else {
                    return (<View style={{zIndex: zIndex}}>
                        {PickerComponent}
                        <WrappedDatePicker
                            value={value as string | undefined}
                            showInitialEmpty
                            noLimits={true}
                            onChange={(newValue)=> {
                                onChange(option.apiName, newValue?.toISOString());
                            }}/>

                    </View>);
                }

            }
            default: {
                return null;
            }
        }
    }, [regionDropdownOptions, branchDropdownOptions, clientDropdownOptions]);

    const flatListData:FilterOption[] = useMemo(() => {
        return options.map(option => {
            const current = selectedOptions.find(v => v.apiName === option.apiName);
            return {
                ...option,
                queryValue: current?.queryValue ?? null,
                queryType: current?.queryType ?? null,
            };
        });
    },[selectedOptions, options]);

    const renderFilterOption = useCallback((option: FilterOption,
                                 isSelected:boolean,
                                 onChange:(key: FilterOption['apiName'], value: FilterOption['queryValue'])=>void,
                                 zIndex: number
    ) => {
        if (option.hide) {
            return null;
        }
        return (
            <View style={[styles.filtersContent, {zIndex: zIndex}]} key={keyExtractor(option, zIndex)}>
                <View style={[styles.row, {left: -8}]}>
                    <Checkbox isChecked={isSelected}
                              onPress={()=>handleFilterCheckboxes(option, isSelected)}
                    />
                    <Text style={{}}>{option.label}</Text>

                </View>
                {getFilterContent(option, isSelected, onChange, zIndex)}

            </View>
        );
    },[handleFilterCheckboxes, keyExtractor, getFilterContent]);

    const FiltersHeader = useMemo(() => {
        return (
            <View style={[styles.row, styles.header]}>
                <View style={styles.row}>
                    <AntDesign name={'filter'} size={20} color={COLORS.textPrimary}/>
                    <Text style={mbTextStyles([textStyles.Inter_16_500, {color: COLORS.textPrimary}])}>Filters</Text>
                </View>
                <View style={[styles.row, isMobileApp ? {marginRight: 20} : undefined]}>
                    {ResetFiltersComponent}

                    {isMobileApp ? null : <TouchableOpacity onPress={toggleShow} style={{width: 40, height: 40, justifyContent:'center', alignItems: 'center', right:-10}}>
                        <AntDesign name={'close'} size={20} color={COLORS.textPrimary}/>
                    </TouchableOpacity> }


                </View>
            </View>
        );
    }, [ResetFiltersComponent, toggleShow]);

    const applyButtonAction = useCallback(() => {
        const filters: {[key: string]: {queryType: string, queryValue: any}}[] = [];
        selectedOptions.forEach(option => {
            if (option.queryType && option.queryType !== '' && option.queryValue && option.queryValue !== '') {
                filters.push({
                    [option.apiName]: {
                        queryType: option.queryType,
                        queryValue: option.queryValue,
                    },
                });
            }
        });
        if (filters && filters.length > 0) {
            const urlParams = arrayToUrlParams(filters);
            if (urlParams !== '') {
                navRef.current?.setParams({filter: urlParams});
            }
        }
        setFiltersApplied(true);
        if (isMobileApp) {
            toggleShow();
        }
        applyFiltersCallback(createQuery());


    },[selectedOptions, applyFiltersCallback, createQuery, toggleShow]);

    const FilterFooter = useMemo(() => {
        return (
            <View style={{zIndex: 1}}>
                <MB_Button title={'Apply'}
                           onPress={applyButtonAction}
                           style={[styles.applyButton, {backgroundColor: buttonColor}]}
                />
            </View>

        );
    }, [applyButtonAction, buttonColor]);

    const renderItem = useCallback(({item, index})=>{
        const isSelected = !!selectedOptions.find(v => v.apiName === item.apiName);
        const zIndex = (options.length - index) * 1000;
        return renderFilterOption(item, !!isSelected, handleFilterChange, zIndex);
    }, [options, selectedOptions, handleFilterChange, renderFilterOption]);


    const FilterPanel = useMemo(() => {
        if (isMobileApp) {
            return <ModalWrapper show={show}
                                 closeAction={()=> {
                                     if (!show) {return;}
                                     toggleShow();
                                 }}
                                 maxHeight={undefined}

            >
                <View style={{top: -30}}>
                    {FiltersHeader}
                    <FilterPanelUI renderComponent={renderItem}
                                   listData={flatListData}
                                   numberOfOptions={options.length}
                                   ListFooterComponent={FilterFooter}
                    />
                    {FilterFooter}
                </View>

            </ModalWrapper>;
        }

        return (
            <View style={[styles.panelWrapper, {display: show ? 'flex' : 'none'}]}>
                {FiltersHeader}
                <FilterPanelUI renderComponent={renderItem}
                               listData={flatListData}
                               numberOfOptions={options.length}
                               ListFooterComponent={FilterFooter}
                />
            </View>

        );
    }, [show, flatListData, FiltersHeader, FilterFooter, renderItem, toggleShow, options.length]);

    return {
        Button,
        FilterPanel,
        filterIsOpen: show,
        filtersApplied: filtersApplied,
        setOptions,
        clearFilters: ()=>  dispatch({type: ActionTypes.resetAll}),
    };


};

export {useFilter, FilterFieldType};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    filterButton: {
        backgroundColor: COLORS.backgroundSecondary,
        borderWidth: 1,
        borderColor: COLORS.textSecondary,
    },
    filterButtonText: mbTextStyles([textStyles.Inter_16_500, {
        color: COLORS.textPrimary,
        fontSize: 14,
        fontWeight: '500',
    }]),
    panelWrapper: {
        position: 'absolute',
        bottom: 0,
        top: -6,
        left: 0,
        width: drawerWidth,
        zIndex: 1000,
        paddingVertical: 8,
        paddingHorizontal: 16,
        backgroundColor: COLORS.backgroundPrimary,
        ...BOX_SHADOW,
    },
    header: {
        justifyContent: 'space-between',
        marginBottom: 8,
    },
    applyButton: {
        marginVertical: 24,
    },

    filtersContent: {
        marginBottom: 8,
    },

    dropdownStyle: {
        marginBottom: 8,
    },
    badge: {
        height: 20,
        width: 20,
        borderRadius: 10,
        position: 'absolute',
        zIndex: 1,
        alignItems: 'center',
        justifyContent: 'center',
    },
    badgePosition: {
        top: -6,
        left: 40,
    },
    badgePositionMobile: {
        top: 0,
        left: 50,

    },
    badgeText: mbTextStyles([textStyles.Inter_16_500, {
        color: appColors.white,
        fontSize: 12,
        fontWeight: '400',
    }]),

});
