import React, { useCallback, useMemo, useRef, useState } from 'react';
import { FlatList, StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { BellIconSvg } from '../../resources/svgComponents/BellIconSvg';
import { appColors, applyTTStyles, COLORS } from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { ModalDropdown } from '@mightybyte/rnw.components.modal-dropdown';
import { trpc } from '../../apiCalls/trpcClient';
import { headerStyles } from './Header/styles';
import { MB_ToggleSwitch } from '@mightybyte/rnw.components.toggle-switch';
import {SwitchGradient} from './misc/SwitchGradient';
import {
    ChecklistClosedNotificationData,
    NotificationCategory,
    NotificationDataForClient,
    NotificationEvent,
    EmployeeAddedToChecklistNotificationData,
} from '@temptrack/business';
import { Spacer } from './misc/Spacer';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../context/SignedInContext';
import { CompanyDashboardRoutes } from '../../navigations/types';
import { envs } from '../../../env';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';


function formatPostTime(timestamp: string): string {
    const postTime = new Date(timestamp);

    // Get the current date and time
    const now = new Date();

    // Calculate the time difference in milliseconds
    const timeDifference = now.getTime() - postTime.getTime();

    // Check if the post was posted today
    const isToday = now.toDateString() === postTime.toDateString();

    if (isToday) {
        // Calculate minutes ago
        const minutesAgo = Math.floor(timeDifference / (1000 * 60));

        if (minutesAgo < 60) {
            // Display in minutes
            return `${minutesAgo} minutes ago`;
        } else {
            // Calculate hours ago
            const hoursAgo = Math.floor(minutesAgo / 60);
            return `${hoursAgo} hours ago`;
        }
    } else {
        // If not today, display date in YYYY-MM-DD format
        const year = postTime.getFullYear();
        const month = String(postTime.getMonth() + 1).padStart(2, '0');
        const day = String(postTime.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }
}
export const composeNotification = (notification: NotificationDataForClient, isClient: boolean, category:string, mobileParams:{
    companyId: string,
    domain: string,
} | undefined, isEmployee: boolean) => {
    const {event, data} = notification;
    let actionText = '';
    let recordType = '';
    let recordName = '';
   let navParams: {
       screen: CompanyDashboardRoutes | null,
       params: Record<string, string>
       deepLink?: string
   } = {
       screen: null,
       params: {},
   };
    const companyName = data?.company?.name || '';
    const createdTime = formatPostTime(notification.timestamp);

    const performedBy = data?.performedBy ? `${data?.performedBy?.firstName} ${data.performedBy?.lastName?.replace('N/A', '')}` : '';
    //`${envs.MOBILE_DEEP_LINK}dashboard/${currentCompanyId}/${'checklists'}?domain=${currentCustomDomain}&checklistId=65e77f7fe82933c2ea8f250c
    let deepLink: string|null = null;
    if (mobileParams){
        deepLink = `${envs.MOBILE_DEEP_LINK}dashboard/${mobileParams.companyId}/`;
    }

    switch (category) {
        case NotificationCategory.Checklist: {
            if (isEmployee) {
                const rawData = data as  EmployeeAddedToChecklistNotificationData;
                navParams.screen = CompanyDashboardRoutes.employeeJobs;
                const checklistId = rawData?.checklist?.id ?? '';
                const workOrderId = rawData?.workOrder?.id ?? '';
                navParams.params = {
                    checklistId: checklistId,
                    workorderId: workOrderId,
                    update: 'true',
                };
                if (deepLink){
                    navParams.deepLink = `${deepLink}jobs?checklistId=${checklistId}workOrderId=${workOrderId}&domain=${mobileParams?.domain}`;
                }
            } else {
                const rawData = data as ChecklistClosedNotificationData;
                const checklist = rawData?.checklist;
                recordName = checklist?.number;
                recordType = 'checklist';
                navParams.screen = CompanyDashboardRoutes.viewChecklist;
                navParams.params = {
                    checklistId: checklist?.id,
                };
                if (deepLink){
                    navParams.deepLink = `${deepLink}checklists?checklistId=${checklist?.id}&domain=${mobileParams?.domain}`;
                }
                switch (event) {
                    case NotificationEvent.ChecklistClosed:
                        actionText = 'closed';
                        break;
                    case NotificationEvent.NewChecklist:
                        actionText = 'created';
                        break;
                }
            }

            break;
        }
        case NotificationCategory.WorkOrder:{
            const rawData = data as ChecklistClosedNotificationData;
            const workOrder = rawData?.workOrder;
            recordType = 'work order';
            recordName = workOrder?.number;
            navParams.screen = CompanyDashboardRoutes.workOrders;
            navParams.params = {
                workorderId: workOrder?.id,
            };
            if (deepLink){
                navParams.deepLink = `${deepLink}workorders?workorderId=${workOrder?.id}&domain=${mobileParams?.domain}`;
            }
            switch (event) {
                case NotificationEvent.WorkOrderClosed:
                    actionText = 'closed';
                    break;
                case NotificationEvent.NewWorkOrder:
                    actionText = 'created';
                    break;

                    case NotificationEvent.WorkOrderReopened:
                    actionText = 'reopened';
                    break;

            }
        }
    }

    const baseTextData = [
        {text: performedBy, bold: true},
        {text: actionText, bold: false},
        {text: recordType, bold: false},
        {text: recordName, bold: true},
    ];
    if (isClient) {
        baseTextData.push({text: `(${companyName})`, bold: true});
    }
    return {
        initials: data?.performedBy ? data?.performedBy.firstName.charAt(0) + data?.performedBy.lastName.charAt(0) : '!',
        textData: baseTextData,
        notificationId: notification.id,
        createdTime,
        navParams,
    };
};


const Notifications = ({navigation}: {
    navigation: any,
}) => {
    const {isClient, signedInStatus, isEmployee} = useSignedInContext();
    const [showRead, setShowRead] = useState<boolean>(false);
    const trpcContext = trpc.useUtils();

    const {data:unread} = trpc.notification.getUnreadCount.useQuery(undefined, {
        enabled: signedInStatus === SIGNED_IN_STATUS.signedIn,
    });

    const notificationModalDropdownRef = useRef<ModalDropdown>(null);

    const {data, fetchNextPage, hasNextPage, isFetchingNextPage} = trpc.notification.list.useInfiniteQuery({
        limit: 10,
        isRead: showRead,

    }, {
        getNextPageParam: (lastPage) => {
            return lastPage.nextCursor;
        },
    });

    const notificationsList = useMemo(() => {
        const pages = data?.pages ?? [];
        if (pages) {
            return pages.map((page) => page.items).flat();
        }
        return [];
    }, [data]);


    const emptyContent = useMemo(() => {
        const emptyText = showRead ? 'No notifications' : 'No unread notifications';
        return (
            <View>
                <Text style={mbTextStyles([textStyles.Muli_16_500, {color: COLORS.textSecondary}])}>{emptyText}</Text>
            </View>
        );
    }, [showRead]);

    const {mutate: markAsRead} = trpc.notification.markAsRead.useMutation({
        onSuccess: () => {
            trpcContext.notification.list.invalidate({isRead: false});
            trpcContext.notification.getUnreadCount.invalidate();
        },
    });

    const openRecord = useCallback( ({navParams, skipRead, notificationId}: {
     navParams: {
         screen: any,
         params: Record<string, string>
     },
        skipRead: boolean,
        notificationId: string,
    }) => {
        if (!skipRead) {
            markAsRead({notificationId: notificationId});
        }
        notificationModalDropdownRef.current?.hide();
        setTimeout(() => {
            navigation?.navigate(navParams.screen, navParams.params);
        }, 100);
    }, [markAsRead, navigation]);

    const renderNotificationListItem = useCallback((item: NotificationDataForClient) => {

        const {initials,
                  textData,
                  navParams,
                  createdTime} = composeNotification(item, isClient, item.category, undefined, isEmployee);
        const useDefaultText = textData.map((textItem) => textItem?.text).filter((textItem) => textItem === '').length !== 0;

        return (
            <TouchableOpacity style={styles.notificationItem} onPress={()=> {
                openRecord({

                navParams,
                skipRead: item.isRead,
                notificationId: item.id,
            });}}>
                <View style={styles.notificationBody}>
                <View style={[headerStyles.profileIconContainerMobile,
                    {backgroundColor: applyTTStyles(false).profileContainerBackground}]}
                >
                    <Text style={[headerStyles.initials, {color: applyTTStyles(false).profileContainerText}]}>{initials}</Text>
                </View>
                <Spacer x={12}/>

                    {useDefaultText ?
                        <Text style={[styles.notificationTextBold, {flexWrap: 'wrap', flex: 1}]}>{item.text}</Text> :
                        <View style={{flexDirection: 'row', alignItems: 'flex-start', flexWrap: 'wrap', flex: 1}}>
                            {textData.map((textItem, idx) => {
                                const isLast = idx === textData.length - 1;
                                if (!textItem) {return null;}
                                return (
                                    <Text key={idx} style={[
                                        textItem?.bold ? styles.notificationTextBold : styles.notificationText]}>
                                        {`${textItem.text}${isLast ? '.' : ' '}`}
                                    </Text>
                                );
                            })}
                        </View>
                    }

                </View>
                <Text style={styles.dateText}>{createdTime}</Text>


            </TouchableOpacity>
        );
    }, [isClient, openRecord, isEmployee]);

    const ItemSeparatorComponent = useCallback(() => {
        return <View style={{height: 1, backgroundColor: COLORS.inputBorder}} />;
    }, []);

    const ListHeader = useCallback(() => {
        return (
            <View>
                <View style={styles.header}>
                    <Text style={{...headerStyles.userNameText, ...{color: COLORS.textSecondary}}}>Notifications</Text>
                    <MB_ToggleSwitch isToggled={showRead}
                                     onToggle={()=>{setShowRead((prev)=>!prev);}}
                                     leftComponent={<Text style={[styles.toggleLabel, showRead && styles.inActiveNameColor]}>Unread</Text>}
                                     rightComponent={<Text style={[styles.toggleLabel, !showRead && styles.inActiveNameColor]}>Read</Text>}
                                     thumbInActiveComponent={<SwitchGradient />}
                                     thumbActiveComponent={<SwitchGradient />}
                                     thumbButton={{
                                         width: 80,
                                         height: 30,
                                         borderWidth: 10,
                                     }}
                                     thumbStyle={{ overflow: 'hidden' }}
                                     trackBar={{
                                         width: 170,
                                         height: 30,
                                         activeBackgroundColor: COLORS.buttonSecondaryBorder,
                                         inActiveBackgroundColor:COLORS.buttonSecondaryBorder,
                                         borderActiveColor: COLORS.buttonSecondaryBorder,
                                         borderInActiveColor: COLORS.buttonSecondaryBorder,
                                     }}
                    />

                </View>
                <View style={{flexDirection: 'row', justifyContent: 'flex-end'}}>
                    {(!showRead && notificationsList.length > 0) ?  <TouchableOpacity onPress={() => {
                        markAsRead({all: true});
                    }}>
                        <Text style={styles.markAsReadText}>Mark all as read</Text>
                    </TouchableOpacity> : null}
                </View>
            </View>
        );
    }, [markAsRead, notificationsList.length, showRead]);

    const renderNotificationsDropdown = useCallback(() => {
        return (
                <FlatList data={notificationsList}
                          style={{minHeight: isMobileApp ? Dimensions.get('screen').height - 200 : 400}}
                          renderItem={({item})=>renderNotificationListItem(item)}
                          keyExtractor={(i, idx) => `${i.id}-${idx}`}
                          onEndReached={()=>{
                            if (hasNextPage) {fetchNextPage();}
                          }}
                          onEndReachedThreshold={0.3}
                          scrollEnabled={!isFetchingNextPage}
                          ListEmptyComponent={emptyContent}
                          ItemSeparatorComponent={ItemSeparatorComponent}
                />
        );
    }, [notificationsList, isFetchingNextPage, emptyContent, ItemSeparatorComponent, renderNotificationListItem, hasNextPage, fetchNextPage]);

    const badgeUnreadCount = useMemo(() => {
        if (!unread) { return ''; }
        if (unread?.count > 100) {
            return '99+';
        }
        return unread?.count?.toString();
    }, [unread]);

    return (
        <View>

            <ModalDropdown
                ref={notificationModalDropdownRef}
                dropdownStyle={{...headerStyles.modalContentContainer, ...{width: isMobileApp ? Dimensions.get('window').width - 40 : 360,
                        flexDirection: 'column-reverse',
                }}}

                onSelect={() => false}
                renderSeparator={() => null}
                accessible={false}
                options={['notifications']}
                renderRow={renderNotificationsDropdown}
                onDropdownWillShow={()=> {
                    trpcContext.notification.list.invalidate();
                }}
                renderFooter={()=>ListHeader()}

            >
                <View style={{position: 'relative'}}>
                    <BellIconSvg size={24}/>
                    {(unread && unread?.count > 0) ? <View style={styles.badge}>
                        <Text style={styles.badgeText}>{badgeUnreadCount}</Text>
                    </View> : null}
                </View>

            </ModalDropdown>
        </View>
    );
};

export { Notifications };



const styles = StyleSheet.create({
    badge: {
        minWidth: 15,
        paddingHorizontal: 2,
        height: 15,
        borderRadius: 10,
        backgroundColor: COLORS.notificationBadge,
        position: 'absolute',
        right: -2,
        top: -4,
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 20,
    },
    badgeText: mbTextStyles([
        textStyles.Muli_16_600, {
            color: COLORS.notificationBadgeText,
            textAlign: 'center',
            fontSize: 10,
        },
    ]),
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingBottom: 8,
        borderBottomWidth: 1,
        borderBottomColor: COLORS.inputBorder,
        marginBottom: 8,
    },
    toggleLabel: mbTextStyles([
        textStyles.Muli_16_400,
        {
            fontSize: 14,
            color: COLORS.backgroundPrimary,
        },
    ]),
    inActiveNameColor: {
        color: COLORS.textSecondary,
    },
    markAsReadText: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 12,
            color: appColors.blue600,
        },
    ]),
    notificationItem: {
        paddingVertical: 6,
    },
    notificationBody: {
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
    },

    notificationTextBold: mbTextStyles([
        textStyles.Inter_16_500,
        {
            fontSize: 14,
            color: COLORS.textPrimary,
            textAlign: 'left',
        },
    ]),
    notificationText: mbTextStyles([
        textStyles.Inter_16_500,
        {
            fontSize: 14,
            color: COLORS.textSecondary,
            textAlign: 'left',
        },
    ]),
    dateText: mbTextStyles([
        textStyles.Inter_16_500,
        {
            fontSize: 12,
            color: COLORS.textSecondary,
            alignSelf: 'flex-start',
            paddingLeft: 48,
        },
    ]),
});
