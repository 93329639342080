import { z } from 'zod';
import { ObjectIdSchema, StringSchema } from '../../../common';

export const CreateChecklistCommentInputSchema = z.object({
    companyId: ObjectIdSchema,
    checklistId: ObjectIdSchema,
    employeeId: ObjectIdSchema,
    comment: StringSchema.min(1),
});

export const GetEmployeeChecklistCommentInputSchema = z.object({
    companyId: ObjectIdSchema,
    checklistId: ObjectIdSchema,
    employeeId: ObjectIdSchema,
});

export const EditChecklistCommentInputSchema =
    CreateChecklistCommentInputSchema;

export const DeleteEmployeeChecklistCommentInputSchema = z.object({
    companyId: ObjectIdSchema,
    checklistId: ObjectIdSchema,
    employeeId: ObjectIdSchema,
});
