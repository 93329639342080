import React from 'react';
import { StyleProp, StyleSheet, Text, TextStyle, TouchableOpacity, View, ViewStyle } from 'react-native';
import { COLORS } from '../../constants/colors';
import isEqual from 'lodash/isEqual';
import {mbApplyTransparency} from '@mightybyte/rnw.utils.style-utils';
import { initialStyles } from '../../hooks/useAppInitialSetup';
import { useMemo } from 'react';

export interface RadioButtonProps {
    value: any,
    groupValue?: any,
    setGroupValue?: React.Dispatch<React.SetStateAction<any>>,
    containerStyle?: StyleProp<ViewStyle>,
    onPressCallback?: () => void,
    innerCircleStyle?: StyleProp<ViewStyle>,
    outerCircleStyle?: StyleProp<ViewStyle>,
    radioText?: string,
    radioTextStyle?: StyleProp<TextStyle>,
    disabled?: boolean,
    preventDisableStyleChange?: boolean,
    viewOnly?: boolean,
    selectedColor?: string,
    unselectedColor?: string,
}

const RadioButton = (
    {
        value,
        groupValue,
        setGroupValue,
        containerStyle,
        onPressCallback,
        innerCircleStyle,
        outerCircleStyle,
        radioText,
        radioTextStyle,
        disabled,
        preventDisableStyleChange,
        viewOnly,
        unselectedColor,
        selectedColor,
    }: RadioButtonProps) => {

    function onPress() {
        setGroupValue?.(value);
        onPressCallback?.();
    }

    const isSelected = React.useMemo(() => {
        return isEqual(value, groupValue);
    },[value, groupValue]);

    const colors = useMemo(() => {
       return {
            elementColor: isSelected ? (selectedColor || COLORS.buttonPrimaryBg) : unselectedColor || COLORS.inputBorder,
            backgroundFill: COLORS.backgroundPrimary,
            text: COLORS.textPrimary,
        };
    }, [selectedColor, unselectedColor, isSelected]);


    return (
        <View style={[styles.container, containerStyle]} >
            <TouchableOpacity
                style={styles.touchableContainer}
                disabled={disabled || viewOnly}
                onPress={onPress}
            >
                <View style={[styles.outerCircle,
                    {   borderColor: disabled && !preventDisableStyleChange ? mbApplyTransparency(colors.elementColor, 0.7) : colors.elementColor,
                        backgroundColor: colors.backgroundFill,
                    },
                    outerCircleStyle]}>
                    {isSelected ? <View style={[styles.innerCircle,
                        {backgroundColor: disabled && !preventDisableStyleChange ? mbApplyTransparency(colors.elementColor, 0.7) : colors.elementColor},
                        innerCircleStyle]} /> : null}

                </View>
            </TouchableOpacity>
            <Text style={[initialStyles.textInputTextStyle, styles.label, radioTextStyle,
                { color: disabled && !preventDisableStyleChange ? mbApplyTransparency(colors.text, 0.7) : colors.text }]
            }>{radioText}</Text>
        </View>
    );
};

export { RadioButton };

const styles = StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'row',
        marginLeft: -10,

    },
    touchableContainer: {
        width: 40,
        height: 40,
        alignItems: 'center',
        justifyContent: 'center',
    },
    outerCircle: {
        width: 18,
        height: 18,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 300,
        alignSelf: 'center',
        borderColor: COLORS.buttonPrimaryBg,
        borderWidth: 1,
    },
    // outerCircleNotSelected: {
    //     borderColor: COLORS.inputBorder,
    // },
    innerCircle: {
        backgroundColor: COLORS.buttonPrimaryBg,
        borderRadius: 300,
        width: 11,
        height: 11,
    },
    disabledStyle: {
        backgroundColor: COLORS.backgroundPrimary,
    },
    label: {
        marginLeft: -4,
    },
});
