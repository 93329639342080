export const TEMPTRACK_LOGO =
    'https://temptrack-assets-us-west-2.s3.us-west-2.amazonaws.com/Logo.png';

export const TEMPTRACK_COMPANY_NAME = 'TempTrack';

export const TEMPTRACK_SUBDOMAIN = 'app';

export const WC_CODE = {
    AZ8046: {
        code: 'AZ8046',
        description: 'Auto Parts Store',
        state: 'AZ',
    },
    CA2002: {
        code: 'CA2002',
        description: 'Macaroni MFG',
        state: 'CA',
    },
    CA2003: {
        code: 'CA2003',
        description: 'Cracker mfg',
        state: 'CA',
    },
    CA2163: {
        code: 'CA2163',
        description: 'Bottling',
        state: 'CA',
    },
    CA2501: {
        code: 'CA2501',
        description: 'Clothing MFG',
        state: 'CA',
    },
    CA2576: {
        code: 'CA2576',
        description: 'AWNING, TARP OR CANVAS GOODS M',
        state: 'CA',
    },
    CA2585: {
        code: 'CA2585',
        description: 'Laundries',
        state: 'CA',
    },
    CA2589: {
        code: 'CA2589',
        description: 'Dry Cleaning Laundry retail',
        state: 'CA',
    },
    CA2757: {
        code: 'CA2757',
        description: 'Pallets',
        state: 'CA',
    },
    CA2812: {
        code: 'CA2812',
        description: 'CABINET MFG - WOOD',
        state: 'CA',
    },
    CA3152: {
        code: 'CA3152',
        description: 'Nail Tack Rivet Mfg',
        state: 'CA',
    },
    CA3501: {
        code: 'CA3501',
        description: 'Machinery MFG.',
        state: 'CA',
    },
    CA3573: {
        code: 'CA3573',
        description: 'MFG',
        state: 'CA',
    },
    CA3643: {
        code: 'CA3643',
        description: 'Pending',
        state: 'CA',
    },
    CA3724: {
        code: 'CA3724',
        description: 'Millwright Work',
        state: 'CA',
    },
    CA3840: {
        code: 'CA3840',
        description: 'auto/truck/motorcycle parts',
        state: 'CA',
    },
    CA4130: {
        code: 'CA4130',
        description: 'GLASS MERCHANTS',
        state: 'CA',
    },
    CA4239: {
        code: 'CA4239',
        description: 'Paper or Cardboard Stock mfg',
        state: 'CA',
    },
    CA4244: {
        code: 'CA4244',
        description: 'Corrugated Fiber Container MFG',
        state: 'CA',
    },
    CA4250: {
        code: 'CA4250',
        description: 'Paper Coating Laminating',
        state: 'CA',
    },
    CA4279: {
        code: 'CA4279',
        description: 'paper goods mfg',
        state: 'CA',
    },
    CA4299: {
        code: 'CA4299',
        description: 'Printing, publishing and duplicating',
        state: 'CA',
    },
    CA4497: {
        code: 'CA4497',
        description: 'Plastics-fiber reinforced products mfg',
        state: 'CA',
    },
    CA4498: {
        code: 'CA4498',
        description: 'Plastic Products Manufacturing',
        state: 'CA',
    },
    CA4557: {
        code: 'CA4557',
        description: 'Ink/adhesive/polish/wax products mfg',
        state: 'CA',
    },
    CA4692: {
        code: 'CA4692',
        description: 'Dental Labs',
        state: 'CA',
    },
    CA4720: {
        code: 'CA4720',
        description: 'Soap/ Synthetic Detergent mfg',
        state: 'CA',
    },
    CA5107: {
        code: 'CA5107',
        description: 'Door or window installment',
        state: 'CA',
    },
    CA5190: {
        code: 'CA5190',
        description: 'Electrical Wiring Low Wage',
        state: 'CA',
    },
    CA6504: {
        code: 'CA6504',
        description: 'Food Processing MFG',
        state: 'CA',
    },
    CA7198: {
        code: 'CA7198',
        description: 'Parcel Delivery',
        state: 'CA',
    },
    CA7219: {
        code: 'CA7219',
        description: "Trucking Firms including Terminal EE's",
        state: 'CA',
    },
    CA7360: {
        code: 'CA7360',
        description: 'Freight Forwarding',
        state: 'CA',
    },
    CA7403: {
        code: 'CA7403',
        description: 'Aircraft Operations, Not Flying Crew',
        state: 'CA',
    },
    CA7429: {
        code: 'CA7429',
        description: 'Airport Operations',
        state: 'CA',
    },
    CA8001: {
        code: 'CA8001',
        description: 'Flower Stores',
        state: 'CA',
    },
    CA8008: {
        code: 'CA8008',
        description: 'Clothing retail stores',
        state: 'CA',
    },
    CA8010: {
        code: 'CA8010',
        description: 'Hardware, electrical or plumbing supplies',
        state: 'CA',
    },
    CA8015: {
        code: 'CA8015',
        description: 'Furniture stores',
        state: 'CA',
    },
    CA8017: {
        code: 'CA8017',
        description: 'Retail Stores',
        state: 'CA',
    },
    CA8018: {
        code: 'CA8018',
        description: 'Wholesale Stores',
        state: 'CA',
    },
    CA8021: {
        code: 'CA8021',
        description: 'New Comp Code',
        state: 'CA',
    },
    CA8032: {
        code: 'CA8032',
        description: 'Clothing Wholesale',
        state: 'CA',
    },
    CA8042: {
        code: 'CA8042',
        description: 'Stores – floor covering',
        state: 'CA',
    },
    CA8046: {
        code: 'CA8046',
        description: 'Auto Parts Stores',
        state: 'CA',
    },
    CA8063: {
        code: 'CA8063',
        description: 'STORES - LIGHTING FIXTURES',
        state: 'CA',
    },
    CA8106: {
        code: 'CA8106',
        description: 'Metal Stock Dealers',
        state: 'CA',
    },
    CA8107: {
        code: 'CA8107',
        description: 'Machinery Dealers',
        state: 'CA',
    },
    CA8209: {
        code: 'CA8209',
        description: 'VEGETABLE AND TOMATO PACKING -',
        state: 'CA',
    },
    CA8232: {
        code: 'CA8232',
        description: 'Fuel Material Dealers',
        state: 'CA',
    },
    CA8291: {
        code: 'CA8291',
        description: 'Warehouses cold storage',
        state: 'CA',
    },
    CA8292: {
        code: 'CA8292',
        description: 'general warehouse',
        state: 'CA',
    },
    CA8293: {
        code: 'CA8293',
        description: 'Furniture warehouse',
        state: 'CA',
    },
    CA8389: {
        code: 'CA8389',
        description: 'Auto or Truck Repair Facilities',
        state: 'CA',
    },
    CA8800: {
        code: 'CA8800',
        description: 'Mailing or addressing companies',
        state: 'CA',
    },
    CA8810: {
        code: 'CA8810',
        description: 'CLERICAL OFFICE EMPLOYEES',
        state: 'CA',
    },
    CA8839: {
        code: 'CA8839',
        description: 'Dental or Ortho Dontia',
        state: 'CA',
    },
    CA8875: {
        code: 'CA8875',
        description: 'Public Schools',
        state: 'CA',
    },
    CA9008: {
        code: 'CA9008',
        description: 'JANITORIAL',
        state: 'CA',
    },
    CA9079: {
        code: 'CA9079',
        description: 'Restaurants',
        state: 'CA',
    },
    CA9410: {
        code: 'CA9410',
        description: 'MUNICIPAL, STATE OR OTHER PUBLIC AGENCY EMPLOYEES',
        state: 'CA',
    },
    CA9424: {
        code: 'CA9424',
        description: 'Recycling Center',
        state: 'CA',
    },
    CO8810: {
        code: 'CO8810',
        description: 'CLERICAL OFFICE EMPLOYEES NOC',
        state: 'CO',
    },
    GA8018: {
        code: 'GA8018',
        description: 'Wholesale',
        state: 'GA',
    },
    GA8810: {
        code: 'GA8810',
        description: 'Clerical',
        state: 'GA',
    },
    IL7360: {
        code: 'IL7360',
        description: 'Freight Handling',
        state: 'IL',
    },
    IL8810: {
        code: 'IL8810',
        description: 'Clerical',
        state: 'IL',
    },
    NJ4484: {
        code: 'NJ4484',
        description: 'Plastics MFG Molded products',
        state: 'NJ',
    },
    NJ4611: {
        code: 'NJ4611',
        description: 'Drug, Medicine or Pharmaceutical Preparation',
        state: 'NJ',
    },
    NJ7219: {
        code: 'NJ7219',
        description: 'General warehouse',
        state: 'NJ',
    },
    NJ8017: {
        code: 'NJ8017',
        description: 'Retail Stores',
        state: 'NJ',
    },
    NJ8018: {
        code: 'NJ8018',
        description: 'STORE: WHOLESALE NOC',
        state: 'NJ',
    },
    NJ8044: {
        code: 'NJ8044',
        description: 'Furniture Store',
        state: 'NJ',
    },
    NJ8292: {
        code: 'NJ8292',
        description: 'General Warehouse',
        state: 'NJ',
    },
    NJ8810: {
        code: 'NJ8810',
        description: 'Clerical OSI',
        state: 'NJ',
    },
    NY7367: {
        code: 'NY7367',
        description: 'Freight Forwarding',
        state: 'NY',
    },
    NY8008: {
        code: 'NY8008',
        description: 'Retail',
        state: 'NY',
    },
    NY8810: {
        code: 'NY8810',
        description: 'Clerical',
        state: 'NY',
    },
    PA2257: {
        code: 'PA2257',
        description: 'Paper Production mfg',
        state: 'PA',
    },
    PA2924: {
        code: 'PA2924',
        description: 'Wholesale stores',
        state: 'PA',
    },
    PA2953: {
        code: 'PA2953',
        description: 'Clerical',
        state: 'PA',
    },
    PA8018: {
        code: 'PA8018',
        description: 'WHSE',
        state: 'PA',
    },
    WA7105: {
        code: 'WA7105',
        description: 'New Comp Code',
        state: 'WA',
    },
    WA7117: {
        code: 'WA7117',
        description: 'Distribution Operations',
        state: 'WA',
    },
} as const;

export const WC_CODES = Object.keys(WC_CODE);

export const NOT_APPLICABLE_LAST_NAME = 'N/A';
