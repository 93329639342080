import { z } from 'zod';

export const AccessTokenObj = z
    .object({
        expiresAt: z.string(),
        expiresAtMs: z.number(),
        expiresIn: z.string(),
        expiresInMs: z.number(),
        token: z.string(),
    })
    .strict();

export const TokensObj = z
    .object({
        refreshToken: z.string(),
        accessToken: AccessTokenObj,
    })
    .strict();

export const PasswordRecoveryTokenObj = z
    .object({
        token: z.string(),
        expiresAtMs: z.number(),
        company: z
            .object({
                name: z.string(),
                logoUrl: z
                    .object({
                        original: z.string(),
                        small: z.string(),
                        medium: z.string(),
                        large: z.string(),
                    })
                    .optional(),
            })
            .optional(),
    })
    .strict();
