import { FilterFieldType, FilterQueryType } from '@temptrack/business';
import { ReactElement, JSXElementConstructor } from 'react';

export type FilterTypeToQueryValueMap = {
    [FilterFieldType.text]: string;
    [FilterFieldType.dropdown]: string | string[];
    [FilterFieldType.date]: string | { startDate: string; endDate: string };
    [FilterFieldType.datetime]: string | { startDate: string; endDate: string };
    [FilterFieldType.number]: number;
    [FilterFieldType.phone]: { masked: string; unmasked: string };
    [FilterFieldType.checkbox]: boolean;
};

export type FilterTypeToQueryTypeMap = {
    [FilterFieldType.text]:
        | FilterQueryType.contains
        | FilterQueryType.notContain
        | FilterQueryType.equals
        | FilterQueryType.notEqual;
    [FilterFieldType.dropdown]:
        | FilterQueryType.equals
        | FilterQueryType.notEqual
        | FilterQueryType.isAnyOf
        | FilterQueryType.isNoneOf
        | FilterQueryType.isEmpty
        | FilterQueryType.notEmpty;
    [FilterFieldType.date]:
        | FilterQueryType.before
        | FilterQueryType.after
        | FilterQueryType.between
        | FilterQueryType.equals
        | FilterQueryType.notEqual;
    [FilterFieldType.datetime]:
        | FilterQueryType.before
        | FilterQueryType.after
        | FilterQueryType.between
        | FilterQueryType.equals
        | FilterQueryType.notEqual;
    [FilterFieldType.number]: FilterQueryType.equals | FilterQueryType.notEqual;
    [FilterFieldType.phone]: FilterQueryType.equals | FilterQueryType.notEqual;
    [FilterFieldType.checkbox]: undefined;
};

export type FilterOption = {
    label: string;
    apiName: string;
    filterType: FilterFieldType;
    dropdownOptions?: { value: string; label: string }[];
    queryType?: FilterTypeToQueryTypeMap[FilterFieldType] | null;
    queryValue?: FilterTypeToQueryValueMap[FilterFieldType] | null;
    hide?: boolean;
};

export type FilterPanelProps = {
    renderComponent: ({
        item,
        index,
    }: any) => ReactElement<any, string | JSXElementConstructor<any>> | null;
    listData: any[];
    numberOfOptions: number;
    ListFooterComponent?: JSX.Element;
};
export enum NotFilterFields {
    isInvitePending = 'isInvitePending',
}
