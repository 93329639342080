import { z } from 'zod';
import { EmailSchema, PhoneNumberSchema, StringSchema } from '../common';

export const LoginInputSchema = z.object({
    /**
     * Email or phone number.
     *
     * For backwards compatibility, keep this field named as `email`.
     */
    email: EmailSchema.or(PhoneNumberSchema),
    password: StringSchema,
});

export const RequestSignUpCodeInputSchema = z.object({
    email: EmailSchema,
});

export const RefreshAccessTokenInputSchema = z.object({
    accessToken: StringSchema,
});

export const ChangePasswordInputSchema = z.object({
    currentPassword: StringSchema,
    newPassword: StringSchema,
});

export const ChangePasswordViaRecoveryTokenInputSchema = z.object({
    recoveryToken: StringSchema,
    newPassword: StringSchema,
});

export const ChangePasswordViaRecoveryCodeInputSchema = z.object({
    recoveryCode: StringSchema.toUpperCase(),
    phoneNumber: PhoneNumberSchema,
    newPassword: StringSchema,
});

export const UpdateCurrentUserDataInputSchema = z.object({
    firstName: StringSchema.optional(),
    lastName: StringSchema.optional(),
});

export const SendPasswordRecoveryEmailInputSchema = z.object({
    email: EmailSchema,
});

export const SendPasswordRecoverySMSInputSchema = z.object({
    phoneNumber: PhoneNumberSchema,
});

export const SetPasswordViaTokenInputSchema = z.object({
    token: StringSchema,
    password: StringSchema,
});

export const SetPasswordViaCodeInputSchema = z.object({
    code: StringSchema.toUpperCase(),
    phoneNumber: PhoneNumberSchema,
    password: StringSchema,
});

export const VerifyPhoneViaCodeInputSchema = z.object({
    code: StringSchema.toUpperCase(),
    phoneNumber: PhoneNumberSchema,
});
