import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import {UIDrawerRightContentWrapper} from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { routeParams } from '../../../../constants/urlConstants';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { GestureResponderEvent, View } from 'react-native';
import { BranchesProps } from '../../../../typesAndInterfaces/componentProps';
import {utils} from '../../../../utils/utils';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import {BranchesTable} from './BranchesTable';
import {BranchForm} from './BranchContent';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { useGetBranches } from './useGetBranches';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { FilterFieldType } from '@temptrack/business';
import { shouldShowRegionForRole } from '../../../../utils/permissionUtils';
import { useSignedInContext } from '../../../../context/SignedInContext';

const COLORS = getColors();

const Branches = ({navigation, route}: BranchesProps)=> {

    const name = 'branch';

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {currentUserData} = useSignedInContext();

    const {branchesData, PaginationComponent,
              isLoading,
              isPreviousData, fetchNextPage, hasNextPage,
        setFilters,
    } = useGetBranches({navigation, route});

    const applyFiltersCallback = useCallback(({ filters }: FilterQueryAndOtherParams)  => {
        setFilters?.(filters);
    }, [setFilters]);

    const shouldShowRegion = useMemo(()=> {
        const role = currentUserData?.role;
        return shouldShowRegionForRole(role);
    }, [currentUserData?.role]);

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);

    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);



    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'Branch Name',
                apiName: 'name',
                filterType: FilterFieldType.text,
            },
            {   label: 'Region',
                apiName: 'regionId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowRegion,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
        ];
    }, [shouldShowRegion]);

    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.branchId);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const deleteBranch = trpc.company.branch.delete.useMutation({
        onSuccess: ()=> {
            setShowMenuForRowId(undefined);
            refreshList();
            hideDeleteBranch();
            setTimeout(()=> {
                mbShowToast({ text1: `Success deleting ${name}`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteBranch, show: showDeleteBranch, component:DeleteBranch, setDeleteError} = useDeleteRecord({
        recordType: name,
        deleteAction: (id)=>{deleteBranch.mutate({branchId: id, companyId: currentCompanyId ?? ''},{
            onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: deleteBranch.isLoading,
    });

    const onRecordAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.branches, {...route.params, ...{branchId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    const addButtonText = `Add new ${name}`;

    const closeModal = useCallback(()=> {
        navigation.navigate(CompanyDashboardRoutes.branches, {...route.params, ...{branchId: '', companyId: currentCompanyId ?? '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, currentCompanyId, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            navigation.navigate(CompanyDashboardRoutes.branches, {...route.params, ...{ branchId: d, companyId: currentCompanyId ?? '', timeline: timeline}});
            setShowRecordModal(true);
        } else {
            closeModal();
        }

    }, [navigation, closeModal, currentCompanyId, route.params]);

    const modalRecordId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.branchId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.branchId, showRecordModal]);

    const {mutate: createRecord, isLoading: isLoadingRecordCreate} = trpc.company.branch.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: `Success creating ${name}`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editRecord, isLoading: isLoadingRecordEdit } = trpc.company.branch.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.branchData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: `Success editing ${name}`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });


    const refreshList = useCallback((id?: string)=> {
        if (id) {
            trpcContext.company.branch.getById.invalidate({branchId: id});
        }
        if (isMobileApp) {
            trpcContext.company.branch.list.invalidate();
        } else {
            trpcContext.company.branch.getPage.invalidate();
        }

    }, [trpcContext.company.branch.getPage, trpcContext.company.branch.getById, trpcContext.company.branch.list]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const menuItems = useMemo(()=> {
        const items: {
            title: string,
            onPress: ()=>void,
            textStyle?: any,
        }[] = [
            {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
            }},
                {title: 'Delete', onPress: ()=>{showDeleteBranch({

                    recordId: showMenuForRowId,
                    recordName: branchesData?.items?.find((item)=>item.id === showMenuForRowId)?.name,
                });}, textStyle: {color: COLORS.dangerTextColor}},
        ];
        if (isMobileApp) {
            items.unshift({title: 'Edit', onPress: ()=>{
                setShowMenuForRowId(undefined);
                onEditRecordPressed(showMenuForRowId);
                }});
        }

        return items.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [showMenuForRowId, branchesData?.items, showDeleteBranch, onEditRecordPressed]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return <ListViewHeader title={`List of ${name}es`}
                               subtitle={`View and manage our ${name}es.`}
                               buttonText={addButtonText}
                               onAddPress={onRecordAddPress}
                               buttonColor={applyTTStyles(false).primaryButtonBg}
                               filterComponent={filters.Button}

        />;
    }, [addButtonText, onRecordAddPress, filters.Button]);

   const modalHeaderProps = useMemo(()=> {
       if (showTimeline && modalRecordId) {
           const branch = branchesData?.items?.find((item)=>item.id === modalRecordId);
              return {
                title: `Timeline for ${branch?.name ?? ''}`,
              };
       }
       return {
           title: modalRecordId ? `Edit ${name}` : `Add new ${name}`,
       };
   } , [showTimeline, modalRecordId, branchesData?.items]);


    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            {DeleteBranch}
            <ModalWrapper show={showRecordModal}
                          closeAction={()=>onEditRecordPressed(undefined)}
                          {...modalHeaderProps}
                          maxHeight={undefined}
            >
                {showRecordModal ? <BranchForm isEditing={!!modalRecordId}
                                               closeAction={()=>onEditRecordPressed(undefined)}
                                               branchId={modalRecordId}
                                               action={modalRecordId ? editRecord : createRecord}
                                               submitInProgress={modalRecordId ? isLoadingRecordEdit : isLoadingRecordCreate}
                                               submitErrorMessage={errorMessage}
                                               resetSubmitError={()=>setErrorMessage('')}
                                               showTimeline={showTimeline}
                                               toggleShowTimeline={toggleShowTimeline}

                /> : null}
            </ModalWrapper>

            {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                            callerX={position.x}
                                            callerY={position.y}
                                            visible={!!showMenuForRowId}
                                            menuItems={menuItems}
                                            closeMenu={()=>setShowMenuForRowId(undefined)}
            /> : null}

            {isMobileApp ? null : HeaderComponent}
            {filters.FilterPanel}
            <BranchesTable data={branchesData?.items}
                              isLoading={branchesData ? (isLoading && !isPreviousData) : isLoading}
                              onEditIconPressed = {onEditRecordPressed}
                              openRowMenu={(e, recordId)=> {
                                   openRowMenu(e, recordId);
                               }}
                              onAddPress={onRecordAddPress}
                              addButtonText={addButtonText}
                              headerComponent={HeaderComponent}
                           fetchMore={hasNextPage ? fetchNextPage : undefined}
                           hasFilters={filters.filtersApplied}

            />
            {isMobileApp ? null : <View style={{minHeight:60}}>
                {PaginationComponent ? PaginationComponent({totalItems: branchesData?.totalItems, currentItemsLength: branchesData?.items?.length ?? 0}) : null}
            </View>}


        </UIDrawerRightContentWrapper>
    );
};

export { Branches };
