import React, { useMemo } from 'react';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { appColors, COLORS } from '../../../constants/colors';
import { Text, View } from 'react-native';
import { drawerStyles } from '../../../navigations/CustomDrawerUI';
import { MB_AnimatedChevron } from '@mightybyte/rnw.components.animated-chevron';
import { NavMenuItemProps } from './types';

const NavMenuItem = ({
    index,
    item,
    action,
    isActive,

}:NavMenuItemProps) => {
    const iconProps = {
        color: isActive ? COLORS.drawerActiveIcon : undefined,
    };
    const isSubItem = useMemo(() => item?.isSubItem, [item]);

    const Component = <View  style={{flexDirection: 'row', alignItems:'center'}} >
        {item.icon(iconProps)}
        <Text style={[isActive ? drawerStyles.activeDrawerLabelStyle : drawerStyles.drawerLabelStyle]}>{item.label}</Text>
        {item?.subTabs && <MB_AnimatedChevron ref={item?.tabRef?.chevron} chevronColor={COLORS.textPrimary} chevronSize={12}/>}
    </View>;

    if (item.subTabs) {
        return Component;
    }
    return (
        <MB_OnHoverWrapper key={index}
                           wrapperStyle={{
                               borderRadius: isSubItem ? 0 : 8,
                               paddingHorizontal: isSubItem ? 16 : 8,
                               marginVertical: isMobileApp ? 8 : 0,
                               marginRight: isMobileApp ? 0 : 16,
                               width: isSubItem ? '100%' : 'auto',
                               backgroundColor: isActive && isMobileApp ? appColors.gray100 : undefined,
                           }}
                           onHoverWrapperStyleModifier={{backgroundColor: appColors.gray100 }}
                           onPress={()=> {
                               action(item);
                           }}
        >
            {Component}
        </MB_OnHoverWrapper>
    );
};

export { NavMenuItem };
