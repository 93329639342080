import Base64 from 'react-native-base64';
import { Platform, Linking } from 'react-native';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, MB_EventObject } from '@mightybyte/rnw.utils.event-emmiter';
import { ServerRedirectNavigationProp } from '../../../typesAndInterfaces/componentProps';
import { createWebsiteBaseUrl, envs } from '../../../../env';
import { SIGNED_IN_STATUS, signedInContextGlobalFunction } from '../../../context/SignedInContext';
import { PasswordRecoveryToken, ServerError, ServerSuccess } from '../../../typesAndInterfaces/typesAndInterfaces';
import { REDIRECT_REASON, SERVER_ERROR_CODES } from '../../../constants/constants';
import { MB_accessTokenUtils, Token } from '@mightybyte/rnw.utils.access-token-utils';
import { PasswordRecoveryTokenObj } from '../../../utils/zod/zodObjects';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { currentCompanyContextGlobalFunction } from '../../../context/CurrentCompanyContext';

interface ServerRedirectPostMessageObject {
    success: boolean,
    type: WEB_POST_MESSAGE_OBJECT_TYPES,
    // subType?: WEB_POST_MESSAGE_OBJECT_SUB_TYPES,
    data?: Token,
}

export enum WEB_POST_MESSAGE_OBJECT_TYPES {
    LOGIN_COMPLETE = 'LOGIN_COMPLETE',
    GENERAL_ERROR = 'GENERAL_ERROR',
}

// enum WEB_POST_MESSAGE_OBJECT_SUB_TYPES {}

const postMessageAndClose = (message: ServerRedirectPostMessageObject) => {
    const eventObject: MB_EventObject = {
        origin: envs.BASE_URL,
        data: message,
    };

    if (Platform.OS === 'web') {
        MB_customWindowForWeb.close();
        MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, eventObject);
    }
};

const incorrectTokenHandler = async (makeRefreshTokenCall: boolean) => {
    signedInContextGlobalFunction.signOut?.({ skipSignOutRequest: true, showExpiredError: true, makeRefreshTokenCall });
    MB_customWindowForWeb.close();
};

const handleError = (errorParamBase64: string) => {
    try {
        const errorParamsString = Base64.decode(errorParamBase64);
        const errorParams = JSON.parse(errorParamsString) as ServerError;


        if (errorParams.errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN || errorParams.errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN ||
            errorParams.errorCode === SERVER_ERROR_CODES.EXPIRED_ACCESS_TOKEN) {
            const makeRefreshTokenCall = errorParams.errorCode === SERVER_ERROR_CODES.INVALID_ACCESS_TOKEN || errorParams.errorCode === SERVER_ERROR_CODES.INVALID_REFRESH_TOKEN;
            serverRedirectUtils.incorrectTokenHandler(makeRefreshTokenCall);
            return null;
        }
    } catch (error) {
        console.error('Failed to parse server redirect error', { incomingError: errorParamBase64, error });
    }

    postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR });
    return null;
};

const handlePasswordRecovery = (successParams: ServerSuccess, navigation: ServerRedirectNavigationProp, isInitialSetup: boolean, subdomain?: string) => {
    const signedInStatus = signedInContextGlobalFunction.getSignedInStatus?.();
    if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
        // Note: Was throwing a warning without the timeout
        setTimeout(() => {
            //todo: redirect to home page
            // navigation.reset({ index: 0, routes: [{ name: 'HomeNavigator' }] });
        }, 50);
        return null;
    }

    let passwordRecoveryToken: PasswordRecoveryToken | undefined = successParams.data as PasswordRecoveryToken;
    let recoveryFailed: boolean | undefined;
    if (!PasswordRecoveryTokenObj.safeParse(passwordRecoveryToken).success) {
        passwordRecoveryToken = undefined;
        recoveryFailed = true;
    }

    // Note: Was throwing a warning without the timeout
    setTimeout(async () => {
        const encodedRecoveryToken = passwordRecoveryToken ? encodeURIComponent(JSON.stringify(passwordRecoveryToken)) : undefined;
        const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
        if (isMobileApp) {
            currentCompanyContextGlobalFunction.setCurrentCustomDomain?.(subdomain ?? '');
            await Linking.openURL(`${envs.MOBILE_DEEP_LINK}forgotPassword?passwordRecoveryToken=${encodedRecoveryToken}&recoveryFailed=${recoveryFailed}${isInitialSetup ? '&initialSetup=true' : ''}`);
        } else {
            MB_customWindowForWeb.open(`${baseUrl.replace('*', subdomain ?? '')}/forgotPassword?passwordRecoveryToken=${encodedRecoveryToken}&recoveryFailed=${recoveryFailed}${isInitialSetup ? '&initialSetup=true' : ''}`, '_self');
        }
    }, 50);
    return null;
};

interface IHandleServerRedirectParams {
    errorParamBase64: string | undefined | null,
    successParamsBase64: string | undefined | null,
    navigation: ServerRedirectNavigationProp,
    subdomain?: string,
}

const handleServerRedirect = async ({ errorParamBase64, successParamsBase64, navigation, subdomain }: IHandleServerRedirectParams) => {
    if (errorParamBase64) {
        return serverRedirectUtils.handleError(errorParamBase64);
    }

    if (!successParamsBase64) {
        postMessageAndClose({ success: false, type: WEB_POST_MESSAGE_OBJECT_TYPES.GENERAL_ERROR });
        return null;
    }

    try {
        const successParamsString = Base64.decode(successParamsBase64);
        const successParams = JSON.parse(successParamsString) as ServerSuccess;
        switch (successParams.redirectReason) {

            case REDIRECT_REASON.passwordRecovery:
            case REDIRECT_REASON.setPassword:
                await MB_accessTokenUtils.removeAccessToken();
                return serverRedirectUtils.handlePasswordRecovery(successParams, navigation,
                    successParams.redirectReason === REDIRECT_REASON.setPassword,
                    subdomain
                );
            default:
                // navigation.reset({
                //     index: 0,
                //     routes: [{ name: navigation.getState().routeNames[0] }],
                // });
                return null;
        }
    } catch (error) {
        console.error('Failed to parse server redirect success params', error);
        return serverRedirectUtils.handleError('');
    }
};

export const serverRedirectUtils = {
    incorrectTokenHandler,
    handleError,
    handlePasswordRecovery,
    handleServerRedirect,
};
