import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import {mbTextStyles} from '@mightybyte/rnw.utils.style-utils';
import {textStyles} from '../constants/textStyles';
import { ModalWrapper } from '../components/helperComponents/misc/ModalWrapper';
import {getColors} from '../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../components/helperComponents/misc/OutlinedButton';
import {DotIndicator} from '@mightybyte/rnw.components.activity-indicators';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();

const useDeleteRecord = (
    {
        recordType,
        deleteAction,
        cancelAction,
        modalMaxHeight,
        actionName,
        reversable,
        isLoading,
    } :{
        recordType: string,
        modalMaxHeight?: number,
        deleteAction: (params:any) => void,
        cancelAction: () => void,
        actionName?: string,
        reversable?: boolean,
        isLoading?: boolean,
    }) => {
    const [showModal, setShowModal] = React.useState(false);
    const recordTypeRef = React.useRef(recordType);
    const recordNameRef = React.useRef('');
    const subtextRef1 = React.useRef<{
        text?: string | undefined,
        color?: string,
    }>({
        text: undefined,
        color: undefined,
    });
    const subtextRef2 = React.useRef<{
        text?: string | undefined,
        color?: string,
    }>({
        text: undefined,
        color: undefined,
    });
    const deleteActionRef = React.useRef(deleteAction);
    const cancelActionRef = React.useRef(cancelAction);
    const modalMaxHeightRef = React.useRef(modalMaxHeight ?? 300);
    const recordIdRef = React.useRef(undefined);
    const [error, setError] = React.useState('');

    const showDeleteModal = React.useCallback(({recordName, recordId, subText1, subText2}) => {
        recordIdRef.current = recordId;
        recordNameRef.current = recordName;
        if (subText1) {
            subtextRef1.current = subText1;
        }
        if (subText2) {
            subtextRef2.current = subText2;
        }
        setShowModal(true);
    }, []);

    const hideDeleteModal = React.useCallback(() => {
        recordIdRef.current = undefined;
        recordTypeRef.current = '';
        recordNameRef.current = '';
        setShowModal(false);
    }, []);

    const action = React.useCallback(() => {
        if (!recordIdRef.current) {return;}
        deleteActionRef.current(recordIdRef.current);
    }, []);

    const cancel = React.useCallback(() => {
        cancelActionRef.current();
        setError('');
        setShowModal(false);
    }, [setError]);

    const actionNameText = React.useMemo(() => {
        return actionName ?? 'Delete';
    }, [actionName]);


    const component = React.useMemo(() => {
        return (
            showModal ? <ModalWrapper show={true}
                          closeAction={cancel}
                          title={`${actionNameText} ${recordTypeRef.current}?`}
                          maxHeight={modalMaxHeightRef.current}
                                      maxWidth={515}
            >
                <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Text style={styles.text}>
                        {`You are about to ${actionNameText.toLowerCase()} the ${recordTypeRef.current.toLowerCase()} `}
                        {recordNameRef.current ? <Text style={styles.textBold}>{`"${recordNameRef.current}"`}</Text> : null}
                        <Text style={styles.text}> {'.'}</Text>
                    </Text>
                    {(subtextRef1.current?.text && subtextRef1.current?.text?.length > 0) ?
                        <Text style={[styles.subtext, {color: subtextRef1?.current?.color ?? undefined}]}>{subtextRef1.current?.text}</Text> : null}
                    {(subtextRef2.current?.text && subtextRef2.current?.text?.length > 0) ?
                        <Text style={[styles.subtext, {color: subtextRef2?.current?.color ?? undefined}]}>{subtextRef2.current?.text}</Text> : null}

                    {reversable ? null : <Text style={styles.text}> {'Note that this action cannot be undone.'}</Text>}
                    {isLoading ? <View style={{flexDirection: 'row', alignItems: 'baseline', marginTop: 12}} >
                        <Text style={styles.textPleaseWait}> {'Please wait'}</Text>
                        <DotIndicator color={COLORS.textSecondary} size={4} style={{marginLeft: 8}}/>
                        </View> : null}
                    {(error && error.length > 0) ? <Text style={[styles.subtext, {color: COLORS.dangerTextColor, marginTop: 18}]}>{error}</Text> : null}
                </View>
                    <View style={[styles.row, isMobileApp ? {marginTop: 36} : undefined]}>
                        <View style={styles.rowItem}>
                        <MB_Button
                            title={actionNameText}
                            onPress={action}
                            style={{backgroundColor: COLORS.buttonDangerBg}}
                            loading={isLoading}
                            disabled={isLoading}
                        />
                        </View>
                    <View style={styles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        action={cancel}
                        />
                    </View>

                    </View>

            </ModalWrapper> : null
        );
    }, [showModal, cancel, action, actionNameText, reversable, isLoading, error]);
    return {
        component,
        show: showDeleteModal,
        hide: hideDeleteModal,
        setDeleteError: setError,

    };
};

export {useDeleteRecord};

const styles = StyleSheet.create({
    text: mbTextStyles([textStyles.Muli_16_600, {textAlign: 'center', color: COLORS.textPrimary}]),
    subtext: mbTextStyles([textStyles.Muli_16_600, {textAlign: 'center', color: COLORS.textSecondary}]),
    textPleaseWait: mbTextStyles([textStyles.Muli_16_600, {textAlign: 'center', color: COLORS.textSecondary}]),
    textBold: mbTextStyles([textStyles.Muli_16_700, {textAlign: 'center', color: COLORS.textPrimary}]),
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 18,
    },

    rowItem: {
        width: '45%',
    },
});
