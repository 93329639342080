import React from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { Spacer } from '../Spacer';

const Badge = ({
    text,
    hideText,
    amounts,
    color,
    displayRow,
    noMargin,
}: {
    text: string,
    hideText?: boolean,
    amounts: string,
    color: {
        text: string,
        background: string
    },
    displayRow?: boolean
    noMargin?: boolean
}) => {
    const styles = getStyles(color, displayRow ?? false);
    return (
        <View style={[styles.badge, noMargin ? {marginLeft: 0} : undefined]}>
            <Text style={styles.badgeText}>{amounts}</Text>
            {(displayRow && amounts.length > 0) ? <Spacer x={6}/> : null}
            {hideText ? null : <Text style={styles.badgeAmounts}>{text}</Text>}
        </View>
    );

};

export {Badge};


const getStyles = (color: {
    text: string,
    background: string
}, displayRow: boolean) => StyleSheet.create({
    badge: {
        flexDirection: displayRow ?  'row' : 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingVertical: 4,
        paddingHorizontal: 8,
        borderRadius: 10,
        backgroundColor: color.background,
    },
    badgeText: mbTextStyles([
        textStyles.Inter_16_500, {
            fontSize: 12,
            color: color.text,
            textAlign: 'left',
        },
    ]),
    badgeAmounts: mbTextStyles([
        textStyles.Inter_16_700, {
            fontSize: 12,
            color: color.text,
            textAlign: 'left',
        },
    ]),
});
