import React, { useCallback, useMemo } from 'react';
import { ScrollView, Text, View, GestureResponderEvent } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EditIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { utilHooks } from '../../../../hooks/utilHooks';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { RegionsTableProps } from './types';

const COLORS = getColors();

const header = [
    { content: 'Region Name', conatinerStyle: {paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1,  minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const RegionsTable = ({data, isLoading, onEditIconPressed, openRowMenu, onAddPress, addButtonText, hasFilters}: RegionsTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const onEditPress = useCallback((d:string) => {
        onEditIconPressed(d);
    } , [onEditIconPressed]);

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const userData = item;
            return [
                <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.name}</Text>,
                <View style={{
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                    position: 'relative',
                }}>
                    <EditIconButton action={()=>onEditPress(userData.id)}/>
                    <IconButton icon={
                        <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                    }
                                action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                    />
                </View>,

            ];
        });
    }, [data, onEditPress, openRowMenu]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={header}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No regions yet'}
                                                          subtitle={'Start adding regions to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { RegionsTable };
