import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ClipboardListIconSvg = React.memo(({size, containerStyle, color}: {
    size?: number, containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 16}
                height={size ? size * (19 / 16) : 19}
                viewBox="0 0 16 19"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M.8 3A2.4 2.4 0 013.2.6a3.6 3.6 0 003.6 3.6h2.4A3.6 3.6 0 0012.8.6 2.4 2.4 0 0115.2 3v13.2a2.4 2.4 0 01-2.4 2.4H3.2a2.4 2.4 0 01-2.4-2.4V3zm3.6 4.8a1.2 1.2 0 000 2.4h.012a1.2 1.2 0 100-2.4H4.4zm3.6 0a1.2 1.2 0 000 2.4h3.6a1.2 1.2 0 100-2.4H8zm-3.6 4.8a1.2 1.2 0 100 2.4h.012a1.2 1.2 0 100-2.4H4.4zm3.6 0A1.2 1.2 0 108 15h3.6a1.2 1.2 0 100-2.4H8z"
                    fill={color ?? '#9CA3AF'}
                />
            </Svg>


        </View>
    );
});

export { ClipboardListIconSvg  };
