import React, { useCallback, useRef, useState } from 'react';
import { View, Text} from 'react-native';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import {recordModalContentStyles} from '../../../../constants/recordModalContentStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import {TOOLTIP_POSITION, MB_PasswordToolTip, TOOLTIP_ARROW_DIRECTION} from '@mightybyte/rnw.components.tool-tip';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { MB_PASSWORD_UTIL_ERROR_TYPES, MB_passwordUtils } from '@mightybyte/rnw.utils.password-utils';
import {BOX_SHADOW} from '../../../../utils/styleUtils';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();

enum INPUT_TYPES {
    password = 'password',
    repeatPassword = 'repeatPassword',
}

const ChangePasswordContent = ({userId, action, closeAction, submitErrorMessage, submitInProgress, resetSubmitError}: {
    userId: string
    action: (data: {userId: string, newPassword: string}) => void;
    closeAction: () => void;
    submitErrorMessage: string;
    submitInProgress: boolean;
    resetSubmitError: () => void;
}) => {
    const [password, setPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);

    const generatePasswordErrorArray = (errorArray: MB_PASSWORD_UTIL_ERROR_TYPES[], doPasswordsMatch: boolean): { message: string, isError: boolean }[] => {
        const errors: { message: string, isError: boolean }[] = [];

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.min, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.min) || errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.max) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.lowercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.lowercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.uppercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.uppercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.digits, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.digits) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.spaces, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.spaces) });
        if (errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.unknown)) {
            errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.unknown, isError: true });
        }

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.passwordMismatch, isError: !doPasswordsMatch });

        return errors;
    };
    const [mainPasswordErrorArray, setMainPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const [repeatPasswordErrorArray, setRepeatPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));

    const validateForm = useCallback(() => {
        setErrorMessage('');
        setInputError([]);
            //check password
        const validatePassword = MB_passwordUtils.validatePassword(password);
        if (validatePassword.errorMessage) {
            setErrorMessage(validatePassword.errorMessage);
            setInputError([INPUT_TYPES.password]);
            return false;
        }

        if (password !== confirmPassword) {
            setErrorMessage(STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH);
            setInputError([INPUT_TYPES.password, INPUT_TYPES.repeatPassword]);
            return false;
        }
        return true;
    },[password, confirmPassword]);


    const inputHasError = useCallback((inputType: INPUT_TYPES) => {
        return inputError.includes(inputType);
    }, [inputError]);


    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        const data = {
            userId: userId,
            newPassword: password,
        };
        action(data);
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:'password' | 'confirmPassword')=> {
        const fieldStrings = {
            password: {title: 'Password', placeholder: 'Enter password', textContentType: 'password'},
            confirmPassword: {title: 'Confirm password', placeholder: 'Confirm password', textContentType: 'password'},
        };

        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: field === 'password' ? password : confirmPassword,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
        };
    }, [password, confirmPassword]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    return (
        <View style={recordModalContentStyles.container}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1}}>
                        <WrappedInput errorMessage={''}
                                        inputComponent={<MB_TextInput {...getInputProps('password')}
                                                                      isError={inputHasError(INPUT_TYPES.password)}
                                                                      maxLength={256}
                                                                      secureTextEntry
                                                                      wrapInFormElement
                                                                      toolTipData={{
                                                                          showOnFocus: true,
                                                                          toolTipPosition: TOOLTIP_POSITION.left,
                                                                          moveBy: {
                                                                              x: isMobileApp ? 50 : 150,
                                                                              y: 50,
                                                                          },
                                                                          toolTipElement: <MB_PasswordToolTip errorArray={mainPasswordErrorArray}
                                                                                                              arrowDirection={TOOLTIP_ARROW_DIRECTION.right}
                                                                                                              containerStyle={{...BOX_SHADOW }} />,
                                                                          portalRef: portalRef,
                                                                      }}
                                                                      onChangeText={(newText: string) => {
                                                                          resetSubmitError();
                                                                          setPassword(newText);
                                                                          setErrorMessage('');
                                                                          setInputError([]);
                                                                          setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === confirmPassword));
                                                                      }}
                                                                      onFocus={() => {
                                                                          setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, confirmPassword === password));
                                                                      }}
                                                                      showRequiredAsteriks={true}
                                        />}
                        />
                <Spacer y={18}/>

                        <WrappedInput errorMessage={''}
                                        inputComponent={<MB_TextInput {...getInputProps('confirmPassword')}
                                                                      isError={inputHasError(INPUT_TYPES.repeatPassword)}
                                                                      maxLength={256}
                                                                      secureTextEntry
                                                                      wrapInFormElement
                                                                      onChangeText={(newText: string) => {
                                                                          resetSubmitError();
                                                                          setConfirmPassword(newText);
                                                                          setErrorMessage('');
                                                                          setInputError([]);
                                                                          setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === password));
                                                                      }}
                                                                      onFocus={() => {
                                                                          setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(confirmPassword).errorArray, confirmPassword === password));
                                                                      }}
                                                                      toolTipData={{
                                                                          showOnFocus: true,
                                                                          toolTipPosition: TOOLTIP_POSITION.left,
                                                                          moveBy: {
                                                                              x: isMobileApp ? 50 : 150,
                                                                              y: isMobileApp ? -170 : 50,
                                                                          },
                                                                          toolTipElement: <MB_PasswordToolTip errorArray={repeatPasswordErrorArray}
                                                                                                              arrowDirection={TOOLTIP_ARROW_DIRECTION.right}
                                                                                                              containerStyle={{ ...BOX_SHADOW }} />,
                                                                          portalRef: portalRef,
                                                                      }}
                                                                      showRequiredAsteriks={true}
                                        />}
                        />

            </View>



            <View>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{errorMessage ?? submitErrorMessage}</Text>
                </View>
                {/*<Text style={styles.errorMessage}>{errorMessage}</Text>*/}


                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Save changes'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(true).primaryButtonBg}]}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>


        </View>
    );
};

export { ChangePasswordContent };
