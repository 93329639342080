import React, { useState } from 'react';
import { StyleSheet, View, Text, LayoutChangeEvent } from 'react-native';
import { getColors } from '../../constants/colors';
import { textStyles } from '../../constants/textStyles';
import { ErrorImageFor404 } from '../../resources/svgComponents/ErrorImageFor404';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const SubDomainIsNotFound = () => {
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const viewWidthChanged = (event: LayoutChangeEvent) => {
        const width = event.nativeEvent.layout.width;
        if (width < 585 && !isSmallScreen) {
            setIsSmallScreen(true);
        } else if (width >= 585 && isSmallScreen) {
            setIsSmallScreen(false);
        }
    };

    return (
        <View style={styles.container} onLayout={viewWidthChanged}>
            <View style={styles.innerContainer}>
                <ErrorImageFor404 size={isSmallScreen ? 300 : undefined} />
                <Text style={styles.notFoundText}>
                   Company url is not valid. Please check the url and try again.
                </Text>
            </View>
        </View>
    );
};

export { SubDomainIsNotFound };

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        padding: 32,
    },
    innerContainer: {
        top: '20%',
        alignItems: 'center',
        justifyContent: 'center',
    },
    notFoundText: mbTextStyles([
        textStyles.Muli_16_600, {
            fontSize: 20,
            marginTop: 53,
        },
    ]),
});
