import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { createNavigationContainerRef, NavigationContainer, NavigatorScreenParams, getStateFromPath} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import React, { ReactNode, useEffect, useState, useCallback, useRef } from 'react';
import { Platform, Linking } from 'react-native';
import { envs } from '../../env';
// import { LOADING_PAGE_GIVE_UP_ACTION_TYPES, LoadingPage } from '../components/helperComponents/LoadingPage';
import { ServerIsDownPage } from '../components/helperComponents/ServerIsDownPage';
import { NotFound } from '../components/screens/NotFound';
import {SubDomainIsNotFound} from '../components/screens/InvalidSubDomain';
import {ServerRedirect} from '../components/screens/ServerRedirect/ServerRedirect';
import {ForgotPassword} from '../components/screens/ForgotPassword/ForgotPassword';
import { SIGNED_IN_STATUS, signedInContextGlobalFunction, useSignedInContext } from '../context/SignedInContext';
import { EnterUrl } from '../components/screens/EnterUrl/EnterUrl';
import { trpc } from '../apiCalls/trpcClient';
import { Login } from '../components/screens/Login/Login';
import { UpdateApp} from '../components/screens/UpdateApp';
import { LoadingPage } from '../components/helperComponents/LoadingPage';
import { AdminNavigator, AdminNavigatorParamList } from './AdminNavigator/AdminNavigator';
import { utils } from '../utils/utils';
import { applyTTStyles } from '../constants/colors';
import { CompanyDashboardNavigator, CompanyDashboardNavigatorParamList } from './CompanyDashboardNavigator/CompanyDashboardNavigator';
import { useCurrentCompanyContext } from '../context/CurrentCompanyContext';
import { Home } from '../components/screens/Home';
import {isMobileApp, isMobile} from '@mightybyte/rnw.utils.device-info';
import { InstallApp, extractPrefixAndPath } from '../components/screens/InstallApp';
import {Support} from '../components/screens/Support';
import {MB_FirebaseMessaging} from '../mightybyteLibraries/MB_FirebaseMessaging/MB_FirebaseMessaging';
import { MB_EVENT_EMMITER_EVENT_TYPE, MB_EventEmmiter, TMB_EventSubscription } from '@mightybyte/rnw.utils.event-emmiter/dist/MB_EventEmmiter';
import {composeNotification} from '../components/helperComponents/Notifications';
import { UserRole } from '@temptrack/business';


type PasswordRecoveryToken =  string; //todo: replace with type from backend

export type RootStackParamList = {
    EnterUrl: undefined,
    Login: { domain?: string } | undefined,
    ResetPassword: {   passwordRecoveryToken?: PasswordRecoveryToken,
                       recoveryFailed?: string | undefined,
                       initialSetup?: string | undefined
                       recoveryBySMSCode?: boolean,
                       domain?: string,
                   } | undefined,

    ForgotPassword: {
        passwordRecoveryToken?: PasswordRecoveryToken,
                        recoveryFailed?: string | undefined,
        initialSetup?: string | undefined
        domain?: string,
        recoveryBySMSCode?: boolean,
    } | undefined,
    ServerRedirect: { success?: string; error?: string, domain?: string } | undefined
    Register: undefined,
    NotFound: undefined,
    Dashboard: undefined,
    AdminNavigator: NavigatorScreenParams<AdminNavigatorParamList> & {filter?: string}
    CompanyDashboardNavigator: NavigatorScreenParams<CompanyDashboardNavigatorParamList> & {filter?: string}
    CustomerDashboardNavigator: NavigatorScreenParams<CompanyDashboardNavigatorParamList> & {filter?: string}
    Support: undefined,
};

const Stack = createNativeStackNavigator<RootStackParamList>();

const navRef = createNavigationContainerRef<RootStackParamList>();

export const getRootNavRef = () => {
    return navRef.isReady() ? navRef : undefined;
};


const config: { screens: { [key in keyof RootStackParamList]: string | { path: string, screens: Object } } } = {
    screens: {
        EnterUrl: 'enterUrl',
        NotFound: '*',
        Login: 'login',
        ForgotPassword: 'forgotPassword',
        ResetPassword: 'reset-password', //use for recovery by sms code
        ServerRedirect: 'serverRedirect',
        Register: 'register',                //page to set password for users who don't have email (use code sent to phone)

        AdminNavigator: {
            path: 'administrator/dashboard',
            screens: {
                Companies: 'companies/:companyId?',
                Users: 'users/:userId?',
            },
        },
        CompanyDashboardNavigator: {
            path: 'administrator/dashboard/:companyId',
            screens: {
                users: 'users/:userId?',
                employees: 'employees/:employeeId?',
                workorders: 'workorders/:workorderId?',
                viewChecklist: 'checklists/:checklistId',
                checklists: 'checklists',
                branches: 'branches/:branchId?',
                regions: 'regions/:regionId?',
                clients: 'clients/:clientId?',
            },
        },
        Dashboard: 'dashboard',
        CustomerDashboardNavigator: {
            path: 'dashboard/:companyId',
            screens: {
                users: 'users/:userId?',
                employees: 'employees/:employeeId?',
                workorders: 'workorders/:workorderId?',
                checklists: 'checklists',
                viewChecklist: 'checklists/:checklistId',
                branches: 'branches/:branchId?',
                regions: 'regions/:regionId?',
                clients: 'clients/:clientId?',
                jobs: 'jobs',
            },
        },
        Support: 'support',
    },
};



const linking = {
    prefixes: [...envs.WEBSITE_BASE_URL, envs.MOBILE_DEEP_LINK],
    config,
    getInitialURL: Platform.OS === 'android' ? () => undefined : undefined,
    getStateFromPath: isMobileApp ? undefined : (path: string, options: any) => path.includes('/serverRedirect') ? getStateFromPath(path, options) : utils.decodeParams(getStateFromPath(path, options)),
};

const RootNavigation = ({ onReady }: { onReady: (isReady: boolean) => void,  }) => {
    const mobileNotificationSubscription = useRef<TMB_EventSubscription>();

    const [serverIsDown, setServerIsDown] = useState<boolean | undefined>();
    const { data: minimumMobileJSVersion } = trpc.util.getMinimumMobileJSVersion.useQuery(undefined,
        { enabled: !serverIsDown, refetchOnWindowFocus: false }
    );

    const { isSignedIn, signedInStatus, setSignedInStatus, setCurrentUserData, isClient, isEmployee } = useSignedInContext();

    const {setCurrentCompanyId, currentCompanyId, setUserRole, currentCustomDomain} = useCurrentCompanyContext();

    const {
        data: serverVersion,
        failureCount: serverVersionRequestFailureCount,
        isLoading: isLoadingServerVersion,
        refetch: refetchServerVersion,
    } = trpc.util.getVersion.useQuery(undefined, { retry: 2, enabled: !serverIsDown, refetchOnWindowFocus: false });

    const {mutate: updatePushToken} = trpc.notification.addFirebaseDeviceToken.useMutation({
        onError: (error) => {
            console.log('Error updating push token', error);
        },
    });

    const { data: currentUserData, refetch: refetchCurrentUserData } = trpc.user.getCurrentUserData.useQuery(undefined, { enabled: isSignedIn && !serverIsDown });

    useEffect(() => {
        setUserRole(currentUserData?.userData?.role);
    }, [currentUserData?.userData, setUserRole]);


    useEffect(() => {
        if (currentUserData && currentUserData?.userData) {
            if (currentUserData?.userData?.companyId){
                setCurrentCompanyId(currentUserData?.userData?.companyId);
            }
            setCurrentUserData({...currentUserData.userData, ...{
                lastName: currentUserData.userData.lastName === 'N/A' ? '' : currentUserData.userData.lastName,
            }});
        }

    }, [currentUserData, setCurrentUserData, setCurrentCompanyId]);

    // Initial check for sign in status based on stored token.
    useEffect(() => {
        if (signedInStatus === SIGNED_IN_STATUS.loading && !serverIsDown) {
            MB_accessTokenUtils.getAccessToken()
                .then((accessToken) => {
                    if (accessToken) {
                        setSignedInStatus(SIGNED_IN_STATUS.signedIn);
                    } else {
                        setSignedInStatus(SIGNED_IN_STATUS.signedOut);
                    }
                });
        }
    }, [onReady, serverIsDown, setSignedInStatus, signedInStatus]);

    // Checks to see if server is down or not by pulling the backend version.
    useEffect(() => {
        if (serverVersion) {
            setServerIsDown(false);
            if (isSignedIn) {
                refetchCurrentUserData();
            }
        } else if (!serverIsDown && serverVersionRequestFailureCount >= 3) {
            setServerIsDown(true);
        }

        onReady(true);
    }, [onReady, signedInStatus, serverIsDown,
        isSignedIn, serverVersion, refetchServerVersion,
        serverVersionRequestFailureCount, refetchCurrentUserData]);

    useEffect(() => {
            if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
                const registerFirebaseToken = async () => {
                    if (!currentCustomDomain){
                        return;
                    }
                    try {
                        const token = await MB_FirebaseMessaging.getToken(currentCustomDomain);

                        updatePushToken(  {token });
                    } catch (error) {
                        console.log('Failure to register device token', error);
                    }
                };
                registerFirebaseToken();
            }
        }, [signedInStatus, updatePushToken, currentCustomDomain]);

    const trpcContext = trpc.useUtils();

    useEffect(() => {

        if (signedInStatus === SIGNED_IN_STATUS.signedIn) {
            if (mobileNotificationSubscription.current) {
                //already subscribed
                mobileNotificationSubscription.current.remove();
                mobileNotificationSubscription.current = undefined;
            }

            mobileNotificationSubscription.current = MB_EventEmmiter.addListener(MB_EVENT_EMMITER_EVENT_TYPE.message, (event) => {
                if ( event.data.message === 'NOTIFICATION_UPDATE') {
                    trpcContext.invalidate();
                    if (isMobileApp) {
                        MB_FirebaseMessaging?.onDisplayNotification(event.data.payload);

                    }
                }
            });
        } else if (signedInStatus === SIGNED_IN_STATUS.signedOut) {
            if (mobileNotificationSubscription.current) {
                mobileNotificationSubscription.current.remove();
                mobileNotificationSubscription.current = undefined;
            }
        }
        return () => {
            if (mobileNotificationSubscription.current) {
                // mobileNotificationSubscription.current.remove();
                // mobileNotificationSubscription.current = undefined;
        }};
    }, [signedInStatus, trpcContext]);

    useEffect(() => {
            const onNotificationPressed = MB_FirebaseMessaging.onNotificationPressed(event => {
                if (event?.data) {
                        const { navParams } = composeNotification(event, isClient, event?.data?.category, {
                            companyId: currentCompanyId ?? '',
                            domain: currentCustomDomain ?? '',
                        }, isEmployee);

                        if (!navParams.deepLink) {
                            return;
                        }
                        setTimeout(() => {
                             Linking.openURL(navParams.deepLink ?? '');
                        }, 2000);

                }
            });
            return onNotificationPressed;
    }, [isClient, currentCustomDomain, currentCompanyId, isEmployee]);

    const handleDeepLink = useCallback(async () => {
        if (!isMobileApp) {
            return;
        }
        const linkUrl = await Linking.getInitialURL();
        if (linkUrl) {
            const { pathAndQuery, prefix } = extractPrefixAndPath(linkUrl ?? '');
            const postfix = pathAndQuery?.includes('?') ? `${pathAndQuery}&domain=${prefix}` : `?domain=${prefix}`;
            const redirectUrl = `${envs.MOBILE_DEEP_LINK}${postfix}`;
            Linking.openURL(redirectUrl);
        }

    }, []);

    useEffect(() => {
        handleDeepLink();
    }, [handleDeepLink]);


    if ((isMobileApp && minimumMobileJSVersion && serverVersion) && utils.compareVersion(serverVersion.version, minimumMobileJSVersion.version) < 0) {
        return (
            <UpdateApp version={minimumMobileJSVersion.version} />
        );
    }

    // if ((isMobileApp && updateStatus === undefined) || signedInStatus === SIGNED_IN_STATUS.loading || isSignedIn && currentUserData === undefined || !serverVersion || (isMobileApp && !minimumMobileJSVersion)) {
    //     return <LoadingPage giveUpAction={LOADING_PAGE_GIVE_UP_ACTION_TYPES.signOut} hideGiveUpButton={serverIsDown === undefined} />;
    // }

    if (isSignedIn && !currentUserData) {
        return <LoadingPage barColor={applyTTStyles(utils.isTTEmployee(currentCustomDomain)).loaderColor}/>;
    }

    if (isMobile && !isMobileApp) {
        return <InstallApp />;
    }

    function renderGuestRoutes(): ReactNode {
        if (!isSignedIn) {
            return (
                <>
                    <Stack.Screen name="EnterUrl" component={EnterUrl}/>
                    <Stack.Screen name="Login" component={Login}/>
                    <Stack.Screen name="ForgotPassword" component={ForgotPassword}/>
                    <Stack.Screen name="ResetPassword" component={ForgotPassword} initialParams={{
                        recoveryBySMSCode: true,
                    }}/>
                    <Stack.Screen name="Register" component={ForgotPassword}/>
                </>
            );
        }
    }

    function renderSignedInRoutes(): ReactNode {
        if (isSignedIn) {
            if (currentUserData?.userData?.role === UserRole.admin) {//don't use isTTemployee check here.
                return (
                    <>
                        <Stack.Screen name={'AdminNavigator'} component={AdminNavigator}/>
                        <Stack.Screen name={'CompanyDashboardNavigator'} component={CompanyDashboardNavigator}/>

                    </>
                );
            } else if (!currentCompanyId || !currentUserData) {
               return  (
                   <Stack.Screen name={'Dashboard'} component={Home} />
               );
            }
                return (
                    <>
                        <Stack.Screen name={'CustomerDashboardNavigator'} component={CompanyDashboardNavigator}/>
                    </>
                );
        }
    }

    const domainIsValid = ()=> {
        return true;
        // if (!initialUrl) {
        //     return true;
        // }
        // const url = new URL(initialUrl);
        //
        // const {customDomain} = getDataFromUrl(url, true);
        // //remove last character if it is a dot
        // const sub = customDomain?.slice(-1) === '.' ? customDomain.slice(0, -1) : customDomain;
        // console.log('sub:', sub)
        // if (sub?.includes('.')) {
        //     return false;
        // }
        // return true;

    };

   if (!domainIsValid()){
         return <SubDomainIsNotFound />;
   }

    if (serverIsDown) {
        signedInContextGlobalFunction.signOut?.({skipSignOutRequest: true, showExpiredError: false, makeRefreshTokenCall: false});
        return <ServerIsDownPage onTryAgainPressed={refetchServerVersion} isLoading={isLoadingServerVersion} />;
    }

    if (signedInStatus === SIGNED_IN_STATUS.loading || isSignedIn && currentUserData === undefined || !serverVersion) {
        return <LoadingPage  barColor={applyTTStyles(utils.isTTEmployee(currentCustomDomain)).loaderColor}/>;
    }

    return (
        <NavigationContainer
            linking={linking}
            ref={navRef}
        >
            <Stack.Navigator
                screenOptions={{
                    headerShown: false,
                    gestureEnabled: false,
                }}
            >
                {renderGuestRoutes()}
                {renderSignedInRoutes()}
                <Stack.Screen name="NotFound" component={NotFound} />
                <Stack.Screen name="ServerRedirect" component={ServerRedirect} />
                <Stack.Screen name="Support" component={Support} />
            </Stack.Navigator>
        </NavigationContainer >
    );
};

export default RootNavigation;
export {navRef};
