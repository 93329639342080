import React, { useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Platform, Linking } from 'react-native';
import { ServerRedirectProps } from '../../../typesAndInterfaces/componentProps';
import { BarIndicator } from '@mightybyte/rnw.components.activity-indicators';
import { isMobile, isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { applyTTStyles, getColors } from '../../../constants/colors';
import * as WebBrowser from 'expo-web-browser';
import { serverRedirectUtils } from './serverRedirectUtils';
import { utilHooks } from '../../../hooks/utilHooks';
import { utils } from '../../../utils/utils';
import { envs } from '../../../../env';
import { MB_Button } from '@mightybyte/rnw.components.button';

const COLORS = getColors();

const ServerRedirect = ({ route, navigation }: ServerRedirectProps) => {
  const errorParamBase64 = route.params?.error;
  const successParamsBase64 = route.params?.success;

    const {url} = utilHooks.useInitialURL();
    const dest = useMemo(()=> {
      if (isMobileApp) {
        return route.params?.domain || envs.TEMPTRACK_SUBDOMAIN;
      }
      return utils.getSubDomainFromUrl(url);
    },[route.params?.domain, url]);



  const [redirectToMobileInitialized, setRedirectToMobileInitialized] = useState(false);
  const mobileRedirectUrl = `${envs.MOBILE_DEEP_LINK}${route.path?.charAt(0) === '/' ? route.path.slice(1) : route.path}`;


  useEffect(() => {

    if (isMobileApp || !isMobile) {
      // We execute processing on mobile and web(computers) right away.
      // For mobile browsers, it`s either handled after button press or not handled at all.

      // iOS needs to dismiss the browser window first otherwise it stays on the top
      if (Platform.OS === 'ios') {
        WebBrowser.dismissBrowser();
      }

      // Giving some time for iOS to be able to dismiss the browser window first.
      setTimeout(() => {
        serverRedirectUtils.handleServerRedirect({ errorParamBase64, successParamsBase64, navigation, subdomain:dest });
      }, Platform.OS === 'ios' ? 500 : 0);
    }
  }, [errorParamBase64, navigation, successParamsBase64, dest]);




  return (
    <View style={styles.container}>
      <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center', backgroundColor: 'transparent' }}>
        {isMobile && !isMobileApp ?
            <MB_Button
                title="Continue to TempTrack"
                style={{backgroundColor: applyTTStyles(dest === undefined).primaryButtonBg}}
                onPress={() => {
                  if (redirectToMobileInitialized) {
                    Linking.openURL(envs.MOBILE_DEEP_LINK);
                  } else {
                    Linking.openURL(mobileRedirectUrl);
                    setRedirectToMobileInitialized(true);
                  }
                }}
            />

            : <BarIndicator color={COLORS.buttonPrimaryBg} count={5} />
        }
      </View>
    </View>
  );
};

export { ServerRedirect };


const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    backgroundColor: COLORS.backgroundPrimary,
  },
});
