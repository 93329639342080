import React, { useMemo } from 'react';
import { View, StyleSheet, ViewStyle, Text } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../constants/colors';
import { MB_PageIndicator } from '@mightybyte/rnw.components.page-indicator';
import { DEFAULT_TABLE_MAX_ROWS } from '../../../constants/constants';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import Entypo from 'react-native-vector-icons/Entypo';
import { Spacer } from './Spacer';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { paginationContainerHeight } from '../../../constants/elementSizes';

const COLORS = getColors();

const PageIndicator = ({currentPage, totalItems, shouldUseTTschema, containerStyle, pageSize, setPage, setPageSize}: {
    containerStyle?: ViewStyle
    currentPage: number,
    totalItems: number,
    shouldUseTTschema?: boolean,
    pageSize: number,
    setPage: (page: number) => void,
    setPageSize: (pageSize: number) => void,
}) => {
    const pageIndicatorControlProps = useMemo(() => {
        return {
            title: '',
            buttonStyle: styles.indicatorButtonBase,
            activeColor:  appColors.white,
            selectColor: COLORS.buttonPrimaryBg,
            hoverColor: applyTTStyles(!!shouldUseTTschema).hoverPageIndicatorBackground,
        };
    }, [shouldUseTTschema]);

    const startItem = useMemo(()=> ((currentPage - 1) * pageSize) + 1,
        [currentPage, pageSize]);

    const endItem = useMemo(()=> {
       const totalPages = Math.ceil(totalItems / pageSize);
       if (totalItems < pageSize) {
              return totalItems;
       }
         if (currentPage === totalPages) {
              return totalItems;
         }
            return currentPage * pageSize;
    }, [currentPage, totalItems, pageSize]);

    const itemsPerPageOptions = [10, 20, 30, 40, 50, 100];



    return (
        <View style={[styles.container, containerStyle ?? {}]}>
            <View style={{flexDirection: 'row', marginLeft: 16, alignItems: 'center'}}>
                <Text style={styles.textSecondary}>Show per page</Text>
                <Spacer x={4}/>
                <MB_DropDownPicker
                    placeholder=""
                    items={itemsPerPageOptions.map((item) => ({label: item.toString(), value: item}))}
                    style={{ width: 100, height: 36, minHeight: 36}}
                    dropdownContainerStyle={{width: 100, maxHeight: 'auto'}}
                    multiple={false}
                    value={pageSize}
                    onValueChange={(value) => {setPageSize(value || DEFAULT_TABLE_MAX_ROWS);}}
                    arrowColor={COLORS.textSecondary}

                />
                <Spacer x={12}/>
                <Text style={styles.textPrimary}>{`${startItem}-${endItem}`}</Text>
                <Spacer x={4}/>
                <Text style={styles.textSecondary}>of</Text>
                <Spacer x={4}/>
                <Text style={styles.textPrimary}>{`${totalItems} results`}</Text>

            </View>
            <MB_PageIndicator
                current={currentPage}
                pages={Math.ceil(totalItems / pageSize)}
                style={{alignSelf: 'center'}}
                onChange={setPage}
                leftButtonProps={{...pageIndicatorControlProps, icon: <Entypo name={'chevron-small-left'} size={24}/>}}
                rightButtonProps={{...pageIndicatorControlProps, icon: <Entypo name={'chevron-small-right'} size={24}/>}}
                indicatorButtonProp={{
                    buttonStyle: {
                        ...styles.indicatorButtonBase,
                    },
                    activeColor: 'transparent',
                    selectColor: applyTTStyles(!!shouldUseTTschema).activePageIndicatorBackground,
                    hoverColor: applyTTStyles(!!shouldUseTTschema).hoverPageIndicatorBackground,
                    inactiveTextColor: COLORS.textPrimary,
                    activeTextColor: appColors.white,
                    textStyle: mbTextStyles([
                        textStyles.base, {
                            fontSize: 14,
                            fontWeight: '500',
                        },
                    ]),
                }}
            />
        </View>
    );
};

export {PageIndicator};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        width: '100%',
        height: paginationContainerHeight,
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: 8,
    },
    indicatorButtonBase: {
        width: 'auto',
        minWidth: 36,
        height: 36,
        borderRadius: 6,
    },

    textPrimary: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '500',
            color: COLORS.textPrimary,
        },
    ]),

    textSecondary: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '400',
            color: COLORS.textSecondary,
        },
    ]),
});
