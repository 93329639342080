import React, { useMemo } from 'react';
import {
    EmployeeAcceptanceStatus,
    EmployeePosition,
    GetEmployeeWorkOrderByIdResponse,
    toEmployeePositionLabel,
    toWorkOrderPositionTypeLabel,
} from '@temptrack/business';
import { StyleProp, StyleSheet, TextStyle, View, ViewStyle, Text } from 'react-native';
import { ValueRow } from '../../../helperComponents/misc/ValueRow';
import {utils} from '../../../../utils/utils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { appColors, COLORS } from '../../../../constants/colors';
import { textStyles } from '../../../../constants/textStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import {LoadingPage} from '../../../helperComponents/LoadingPage';
import Entypo from 'react-native-vector-icons/Entypo';

const JobDetails = ({
    jobData,
    isLoading,
    action,
}:{
    jobData: GetEmployeeWorkOrderByIdResponse | undefined;
    isLoading: boolean;
    action: ({accept, shortlistId}: {
        accept: boolean;
        shortlistId: string
    }) => void;
}) => {


    const showButtons = useMemo(()=> {
        return jobData?.workOrderData?.employeeAcceptanceStatus === EmployeeAcceptanceStatus.pending;
    },[jobData]);

    const rowProps: {
        containerStyles?: StyleProp<ViewStyle>
        customLabelStyle?: StyleProp<TextStyle>
        customTextStyle?: StyleProp<TextStyle>
    } = {
        containerStyles: {marginBottom: 8,
            flex: 1,
            justifyContent: 'space-between',
        },
        customLabelStyle: mbTextStyles([textStyles.Muli_16_600, {color: COLORS.textPrimary}]),
        customTextStyle: mbTextStyles([textStyles.Muli_16_400, {color: COLORS.textSecondary, textAlign: 'right'}]),
    };
    if (isLoading) {
        return <LoadingPage hideTakeLonger/>;
    }
    if (!jobData) {
        return <View style={{flex:1, justifyContent: 'center'}}>
            <View style={{alignItems: 'center'}}>
                <Entypo name={'block'} size={64} color={appColors.blue100} style={{marginBottom: 20}}/>
                <Text style={ mbTextStyles([
                    textStyles.Muli_16_600, {
                        fontSize: 24,
                    }])}>Record not found</Text>
            </View>

        </View>;
    }
    return (
            <View style={{paddingTop: 16}}>
                <ValueRow label={'Clien\'s Name'} value={jobData?.workOrderData?.clientName} {...rowProps}/>
                <ValueRow label={'Position'} value={toEmployeePositionLabel(jobData?.workOrderData?.position as EmployeePosition)} {...rowProps}/>
                <ValueRow label={'Position Type'} value={toWorkOrderPositionTypeLabel(jobData?.workOrderData?.positionType)} {...rowProps}/>
                <ValueRow label={'Pay Rate'} value={`${utils.currencyToDisplay(jobData?.workOrderData?.payRate)}`} {...rowProps}/>
                <ValueRow label={'Shift'} value={jobData?.workOrderData?.shift} {...rowProps}/>
                <ValueRow label={'Start Date'} value={utils.formatDateObjectToDisplay(jobData?.workOrderData?.startDate)}{...rowProps}/>
                <ValueRow label={'End Date'} value={utils.formatDateObjectToDisplay(jobData?.workOrderData?.endDate)} {...rowProps}/>
                <ValueRow label={'Start Time'} value={jobData?.workOrderData?.startTime} {...rowProps}/>
                <ValueRow label={'End Time'} value={jobData?.workOrderData?.endTime} {...rowProps}/>
                <ValueRow label={'Jobsite Address'} value={utils.getAddressToDisplay(jobData?.workOrderData?.jobsiteAddress)} {...rowProps}/>

                {showButtons ? <View style={[styles.row]}>
                    <View style={styles.rowItem}>
                        <MB_Button
                            title={'Accept offer'}
                            onPress={()=> action({
                                accept: true,
                                shortlistId: jobData?.workOrderData?.checklistId ?? '',
                            })}
                            loading={isLoading}
                            disabled={isLoading}
                        />
                    </View>
                    <View style={styles.rowItem}>
                        <OutlinedButton title={'Decline offer'}
                                        color={COLORS.dangerTextColor}
                                        action={()=> {
                                            action({
                                                accept: false,
                                                shortlistId: jobData?.workOrderData?.checklistId ?? '',
                                            });
                                        }}
                                        loading={isLoading}
                                        disabled={isLoading}

                        />
                    </View>

                </View> : null}


            </View>
    );
};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 18,
        marginTop: 36,
    },

    rowItem: {
        width: '45%',
    },
});


export {JobDetails};
