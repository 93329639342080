import React, { useMemo } from 'react';
import { CheckInsStatus } from '@temptrack/business';
import { appColors } from '../../../../constants/colors';
import { Badge } from './Badge';

const colorMap = {
    [CheckInsStatus.notApplicable]: {
        text: appColors.gray800,
        background: appColors.gray200,
    },
    [CheckInsStatus.none]: {
        text: appColors.red800,
        background: appColors.red100,
    },
    [CheckInsStatus.some]: {
        text: appColors.yellow800,
        background: appColors.yellow100,
    },
    [CheckInsStatus.all]: {
        text: appColors.green800,
        background: appColors.green100,
    },
};

const getBadgeColor = (status: CheckInsStatus | undefined) => {
    if (!status) {return {text: '', background: ''};}
    return colorMap[status];
};

const CheckinsStatusBadge = ({todayCheckins, checklistDate}: {todayCheckins: {
        status: CheckInsStatus;
        checkedInQty: number;
        assignedToQty: number;
    } | undefined,
    checklistDate?: string
    noMargin?: boolean
}) => {
     const color = useMemo(()=>getBadgeColor(todayCheckins?.status),[todayCheckins?.status]);

    if (!todayCheckins) {return null;}

    return (
        <Badge text={`check-ins | ${checklistDate}`}
               amounts={`${todayCheckins.checkedInQty}/${todayCheckins.assignedToQty}`}
               color={color}
               displayRow
               noMargin
        />
    );
};

export {CheckinsStatusBadge};
