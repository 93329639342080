import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { StyleProp, ViewStyle } from 'react-native';

const BranchesIconSvg = React.memo((props: { size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <Svg
            width={props.size ?? 19}
            height={props.size ? props.size * (22 / 19) : 22}
            viewBox="0 0 19 22"
            fill="none"
            {...props}
        >
            <Path
                d="M18.63 10.148a3.744 3.744 0 00-3.392-2.145 3.75 3.75 0 00-3.67 2.978c-2.822-.19-5.381-1.536-5.959-3.084a3.749 3.749 0 10-1.62.03v6.15a3.756 3.756 0 00-2.982 4.051 3.748 3.748 0 106.753-2.6 3.752 3.752 0 00-2.27-1.451v-3.875a10.483 10.483 0 006.071 2.28 3.742 3.742 0 002.766 2.91 3.748 3.748 0 004.304-5.242l-.002-.002z"
                fill={props.color ?? '#9CA3AF'}
            />
        </Svg>
    );
});

export {BranchesIconSvg};
