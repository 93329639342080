export enum NotificationCategory {
    WorkOrder = 'WorkOrder',
    Checklist = 'Checklist',
    CheckIn = 'CheckIn',
}

export enum NotificationEvent {
    EmployeeAddedToChecklist = 'EmployeeAddedToChecklist',
    EmployeeAssigned = 'EmployeeAssigned',
    NewWorkOrder = 'NewWorkOrder',
    NewChecklist = 'NewChecklist',
    EmployeeCheckedIn = 'EmployeeCheckedIn',
    ChecklistClosed = 'ChecklistClosed',
    WorkOrderClosed = 'WorkOrderClosed',
    WorkOrderReopened = 'WorkOrderReopened',
    EmployeeAcceptedRejectedOffer = 'EmployeeAcceptedRejectedOffer',
}

export enum NotificationAction {
    View = 'View',
    MarkAsRead = 'MarkAsRead',
}
