export const isValidEmail = (email:string | undefined, nullIsValid = false):boolean=> {
    if (!email || email.length === 0) {
       return nullIsValid;
    }
    const regex = new RegExp(/^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regex.test(email);
};

export const startAndEndDatesAreValid = (startDate:Date, endDate:Date):boolean => {

    return startDate < endDate;
};

export const noSpecialChars = (value:string | undefined):boolean => {
    if (!value) {
        return true;
    }
    //allowing only letters and numbers and spaces
   const regex = new RegExp(/^[a-zA-Z0-9\s]*$/);
    return regex.test(value);
};
