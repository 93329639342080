import React, { useMemo } from 'react';
import { Text, TouchableOpacity, View, StyleSheet, ViewStyle, StyleProp } from 'react-native';
import { PersonIconSvg } from '../../../../resources/svgComponents/PersonIconSvg';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { appColors } from '../../../../constants/colors';

const EmployeeBadge = ({name, colors, disabled, onPress, iconName, noVerticalSpacing, noHorizontalSpacing, containerStyle}: {
    name: string;
    colors?: {
        text: string;
        background: string;
    };
    disabled?: boolean;
    onPress?: () => void;
    iconName?: string
    noVerticalSpacing?: boolean;
    noHorizontalSpacing?: boolean;
    containerStyle?: StyleProp<ViewStyle>;
}) => {

    const itemColors = useMemo(()=> {
        if (colors) {
            return colors;
        }
        return {
            text: appColors.blue800,
            background: appColors.blue100,
        };
    }, [colors]);

    return <View style ={[styles.item, containerStyle]}>
        <TouchableOpacity style={[styles.item, noVerticalSpacing ? undefined : styles.verticalSpacing,
            noHorizontalSpacing ? undefined : styles.horizontalSpacing, {backgroundColor: itemColors.background}]}
                          onPress={onPress}
                            disabled={disabled}>
            <PersonIconSvg color={itemColors.text} containerStyle={styles.personIconContainer}/>
            <View style={{maxWidth: 150}}>
                <Text style={[styles.text, {color: itemColors.text}]} numberOfLines={1}>{name}</Text>
                {iconName ? <View style={{marginLeft: 8}}>
                    <AntDesign name={iconName} color={itemColors.text} size={16}/>
                </View> : null}
            </View>


        </TouchableOpacity>
    </View>;
};

export {EmployeeBadge};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row', alignItems: 'center',
    },
    item: {
        flexDirection: 'row',
        alignItems: 'center',
        borderRadius: 8,
        paddingVertical: 4,
        maxWidth: 200,
    },
    horizontalSpacing: {
        paddingHorizontal: 8,
    },
    verticalSpacing: {
        marginVertical: 4,
    },
    personIconContainer: {
        marginRight: 8,
    },
    text: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
        },
    ]),

});
