import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import { CreateBranchInput, EditBranchInput } from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { useAddress } from '../../../../hooks/useAddress';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { useEmail } from '../../../../hooks/useEmail';
import { useRegions } from '../../../../hooks/useRegions';
import { ConditionalFields, useConditionalFields } from '../../../../hooks/useConditionalFields';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { utils } from '../../../../utils/utils';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { envs } from '../../../../../env';
import { noSpecialChars } from '../../../../utils/validationUtils';
import {isMobileApp} from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';


const COLORS = getColors();

enum InputTypes {
    branchName = 'branchName',
    region = 'region',
}


const BranchForm = (
    {isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        branchId,
        resetSubmitError,
        showTimeline,
    }: {
    branchId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data: any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void; showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {

    const {getFieldSettings} = useConditionalFields();
    const {currentCompanyId} = useCurrentCompanyContext();

    const [initialIsDone, setInitialIsDone] = useState(false);

    const [branchName, setBranchName] = useState<string>('');
    const regionOptions = getFieldSettings(ConditionalFields.region, {
        regionId: undefined,
        regionName: undefined,
    });
    const [regionId, setRegionId] = React.useState<string | undefined>(regionOptions.allowedToEdit ? undefined : regionOptions.defaultValue?.regionId);
    const {regionDropdownOptions} = useRegions({disabled: !regionOptions.allowedToView});

    const addressForm = useAddress({isOptional: false});
    const phoneNumber = usePhoneNumber({isRequired: true});
    const email = useEmail({isRequired: true, nextToFocus: phoneNumber.ref});


    const {data: recordData, isLoading} = trpc.company.branch.getById.useQuery({branchId: branchId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isEditing && !!branchId && !!currentCompanyId,
    });

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.branchData) {
            const initialData = recordData?.branchData;
            setBranchName(initialData.name);
            setRegionId(initialData.regionId);
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: initialData.address?.line1,
                city: initialData.address?.city,
                state: initialData.address?.state,
                zipCode: initialData.address?.zipCode,
            }));
            addressForm.setCity(initialData.address?.city ?? '');
            addressForm.setState(initialData.address?.state ?? '');
            addressForm.setZipCode(initialData.address?.zipCode ?? '');
            addressForm.setAddress(initialData.address?.line1 ?? '');
            addressForm.setAddress2(initialData.address?.line2 ?? '');
            phoneNumber.setInitialValue(initialData.phoneNumber);
            email.setInitialEmail(initialData.email);
            setInitialIsDone(true);
        }

    }, [recordData, setBranchName, addressForm, phoneNumber, email, initialIsDone, setInitialIsDone]);


    const {data: timeline, isLoading: gettingTimeline} = trpc.company.branch.getTimeline.useQuery({branchId: branchId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!branchId && !!currentCompanyId,
    });

    const [formErrorMessages, setFormErrorMessages] = useState<{
        [key in InputTypes]: string;
    }>({
        [InputTypes.branchName]: '',
        [InputTypes.region]: '',
    });


    const isEmpty = (value: string | undefined) => {
        if (!value) {
            return true;
        }
        return value.trim().length === 0;
    };

    const fieldsMap = useMemo(() => {
        return {
            [InputTypes.branchName]: {value: branchName, setValue: setBranchName},
            [InputTypes.region]: {value: regionId, setValue: setRegionId},
        };

    }, [regionId, setRegionId, branchName, setBranchName]);


    const requiredFields = useMemo(() => {
        return [InputTypes.region, InputTypes.branchName];
    }, []);

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });
        if (!noSpecialChars(fieldsMap[InputTypes.branchName].value)) {
            errors[InputTypes.branchName] = STRING_CONSTANTS.NO_SPECIAL_CHARS;
            isValid = false;
        }

        setFormErrorMessages(errors);
        const addressValid = addressForm.validateAddress();
        const emailValid = email.validateEmail();
        const phoneNumberValid = phoneNumber.validate();
        return isValid && addressValid && emailValid && phoneNumberValid;
    },[formErrorMessages, setFormErrorMessages, requiredFields, fieldsMap, addressForm, email, phoneNumber]);

    const createRecord = () => {
        if (!currentCompanyId) {
            return;
        }
        const data: CreateBranchInput = {
            companyId: currentCompanyId,
            regionId: regionId as string,
            name: branchName,
            address: addressForm.values,
            phoneNumber: phoneNumber.value,
            email: email.value,

        };
        action(data);
    };

    const editRecord = () => {
        if (!currentCompanyId || !branchId) {
            return;
        }
        const data: EditBranchInput = {
            branchId: branchId as string,
            companyId: currentCompanyId,
            regionId: regionId as string,
            name: branchName,
            address: addressForm.values,
            phoneNumber: phoneNumber.value,
            email: email.value,

        };
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {

            createRecord();

        }
    };
    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);

    const cancelAction = ()=>{
        closeAction();
    };

    const setFormValue = useCallback((field: InputTypes, value: string) => {
        fieldsMap[field].setValue(value);
    }, [fieldsMap]);

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.region]: {title: 'Region Name', placeholder: 'Enter region name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.region)},
            [InputTypes.branchName]: {title: 'Branch Name', placeholder: 'Enter branch name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.branchName)},
        };

        const onChange = (newText: string) => {
            resetSubmitError();
            setFormErrorMessages({...formErrorMessages, [field]: ''});
            setFormValue(field, newText);
        };

        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            onChangeText: onChange,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
        };
    }, [fieldsMap, formErrorMessages, setFormValue, requiredFields, resetSubmitError]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1, zIndex: 4000}}>
                <View style={[recordModalContentStyles.row, {zIndex: 4000}]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.branchName)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.branchName)}
                                          nextToFocus={email.ref}
                                      />}
                        />
                    </View>
                        <WrappedInput inputComponent={
                            <MB_DropDownPicker items={regionOptions.allowedToView ? regionDropdownOptions : regionOptions.defaultValue ? [{
                                label: regionOptions.defaultValue.regionName,
                                value: regionOptions.defaultValue.regionId,
                            }] : []}
                                             value={regionId}
                                             onValueChange={setRegionId}
                                             placeholder={'Select Region'}
                                             title={'Assign Region'}
                                             containerStyle={{width: '100%'}}
                                             disabled={!regionOptions.allowedToView}
                                               disableInteractions={!regionOptions.allowedToView}
                                             showRequiredAsteriks={true}
                                               dropDownDirection={'BOTTOM'}
                                               multiple={false}
                                               listMode={'SCROLLVIEW'}

                            />
                        } errorMessage={fieldError(InputTypes.region)} containerStyle={ recordModalContentStyles.rowItem}
                                      subText={(!regionOptions.allowedToView) ?  'You can only add branches to your region' : undefined}
                        />


                </View>
                <View style={[recordModalContentStyles.row]}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>
                <View style={[recordModalContentStyles.row,  {zIndex: 3000}]}>
                    <View style={[recordModalContentStyles.rowItem, {flex: 1, zIndex: 3000}]}>
                        <WrappedInput errorMessage={addressForm.error}
                                      inputComponent={<View style={{ width: '100%'}}>
                                          <MB_LocationPicker
                                              key={initialIsDone.toString()}
                                              apiKey={envs.LOCATION_PICKER_API}
                                              title={'Address'}
                                              containerStyle={{width: '100%'}}
                                              titleStyle={{color: COLORS.textPrimary}}
                                              showRequiredAsteriks={true}
                                              value={addressForm.fullAddress}
                                              onSelect={addr => {
                                                  addressForm.setAddress(utils.getStreetFromFullAddress(addr?.text, addr?.city));
                                                  addressForm.setCity(addr?.city ?? '');
                                                  addressForm.setState(addr?.state ?? '');
                                                  addressForm.setZipCode(addr?.postalCode ?? '');
                                              }}
                                              dropDownDirection={'BOTTOM'}
                                              listMode={'SCROLLVIEW'}
                                          />
                                      </View>}
                        />
                    </View>

                    <View style={[recordModalContentStyles.rowItem, isMobileApp ? undefined : {width: 200, marginLeft: 16}]}>
                        {addressForm.addressSecondLineField}
                    </View>

                </View>
            </View>


            <View style={{zIndex:1}}>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add branch'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>


        </ScrollView>
    );
};

export {BranchForm };
