import { WorkOrderPositionType, WorkOrderStatus } from '../../entities';
import { FilterFieldType } from '../enums';
import {
    filterDateSchema,
    filterDropdownSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '../schemas';
import { createFilterMapping } from './helpers';

export const WorkOrderFilterMap = createFilterMapping({
    number: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'number',
    },
    clientId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'clientId',
    },
    startDate: {
        filterFieldType: FilterFieldType.date,
        filterFieldSchema: filterDateSchema,
        dbField: 'startDateString',
    },
    endDate: {
        filterFieldType: FilterFieldType.date,
        filterFieldSchema: filterDateSchema,
        dbField: 'endDateString',
    },
    position: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'position',
    },
    positionType: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(WorkOrderPositionType),
        dbField: 'positionType',
    },
    status: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(WorkOrderStatus),
        dbField: 'status',
    },
});
