import { EmployeePosition, EmployeePositionType } from '.';

export function toEmployeePositionTypeLabel(
    positionType: EmployeePositionType,
): string {
    return {
        [EmployeePositionType.fullTime]: 'Full Time',
        [EmployeePositionType.partTime]: 'Part Time',
    }[positionType];
}

export function toEmployeePositionLabel(position: EmployeePosition): string {
    return {
        [EmployeePosition.administrative]: 'Administrative',
        [EmployeePosition.generalLabor]: 'General Labor',
        [EmployeePosition.shippingClerk]: 'Shipping Clerk',
        [EmployeePosition.forklift]: 'Forklift',
        [EmployeePosition.customerService]: 'Customer Service',
        [EmployeePosition.server]: 'Server',
        [EmployeePosition.supervisor]: 'Supervisor',
    }[position];
}
