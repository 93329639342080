import { ImageUrls } from '@temptrack/business';

export const imageUtils = {
  images: {
      backgroundWithLogo: require('../resources/images/Background_with_logo.png'),
      mobileBackgroundWithLogo: require('../resources/images/Background_with_logo_mobile.png'),
  },
};

type ImageGetterOptions = { asUri?: boolean; preferLarge?: boolean } | undefined;
type ImageGetterReturn<T extends ImageGetterOptions> = (T extends { asUri: true } ? { uri: string } : string) | undefined

const mbGetImage = <T extends ImageGetterOptions>({ image, options, size }: { image: ImageUrls | undefined, size: 'small' | 'medium' | 'large', options?: T }): ImageGetterReturn<T> => {
    switch (size) {
        case 'small':
            return mbGetMediumImage(image, options);
        case 'medium':
            return mbGetMediumImage(image, options);
        case 'large':
            return mbGetLargestImage(image, options);
    }
};

const mbGetMediumImage = <T extends ImageGetterOptions>(image: ImageUrls | undefined, options?: T): ImageGetterReturn<T> => {
    if (!image) {
        return undefined;
    }

    function processReturn(retVal: string) {
        return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.medium) {
        return processReturn(image.medium) as ImageGetterReturn<T>;
    }

    if (options?.preferLarge) {
        if (image.large !== undefined) {
            return processReturn(image.large) as ImageGetterReturn<T>;
        } else if (image.small !== undefined) {
            return processReturn(image.small) as ImageGetterReturn<T>;
        }
    } else {
        if (image.small !== undefined) {
            return processReturn(image.small) as ImageGetterReturn<T>;
        } else if (image.large !== undefined) {
            return processReturn(image.large) as ImageGetterReturn<T>;
        }
    }

    return processReturn(image.original) as ImageGetterReturn<T>;
};

const mbGetLargestImage = <T extends ImageGetterOptions>(image: ImageUrls | undefined, options?: T): ImageGetterReturn<T> => {
    if (!image) {
        return undefined;
    }

    function processReturn(retVal: string) {
        return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.large !== undefined) {
        return processReturn(image.large) as ImageGetterReturn<T>;
    } else if (image.medium !== undefined) {
        return processReturn(image.medium) as ImageGetterReturn<T>;
    } else if (image.small !== undefined) {
        return processReturn(image.small) as ImageGetterReturn<T>;
    }

    return processReturn(image.original) as ImageGetterReturn<T>;
};

const mbGetSmallestImage = <T extends ImageGetterOptions>(image: ImageUrls | undefined, options?: T): ImageGetterReturn<T> => {
    if (!image) {
        return undefined;
    }

    function processReturn(retVal: string) {
        return !options?.asUri ? retVal : { uri: retVal };
    }

    if (image.small !== undefined) {
        return processReturn(image.small) as ImageGetterReturn<T>;
    } else if (image.medium !== undefined) {
        return processReturn(image.medium) as ImageGetterReturn<T>;
    } else if (image.large !== undefined) {
        return processReturn(image.large) as ImageGetterReturn<T>;
    }

    return processReturn(image.original) as ImageGetterReturn<T>;
};



export {mbGetImage, mbGetSmallestImage, mbGetMediumImage, mbGetLargestImage};
