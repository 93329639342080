import React, { useCallback, useMemo, useRef } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { MB_TextInput, MB_TextInputRef } from '@mightybyte/rnw.components.text-input';
import { getColors, applyTTStyles } from '../../../constants/colors';
import { textStyles } from '../../../constants/textStyles';
import { ILoginInput, LoginInputTypes, LOGIN_UI_BUTTON_PRESSED_TYPES} from './LoginUtils';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import {WrappedInput, errorInputStyles} from '../../helperComponents/misc/WrappedInput';
import {BOX_SHADOW} from '../../../utils/styleUtils';
import {sharedStyles} from '../../../utils/styleUtils';
import { ClickableText } from '../../helperComponents/misc/ClickableText';
import { Spacer } from '../../helperComponents/misc/Spacer';


const LoginInput = ({ containerStyle, onButtonPressed, email, setEmail,
                        password, setPassword, formErrorMessages, errorMessage, isTTEmployee, phoneNumberField,
}: ILoginInput) => {

    const passwordRef = useRef<MB_TextInputRef>(null);

    const shouldUseTTStyles = isTTEmployee;

    const hasError = useCallback((field:LoginInputTypes) => {
        if (formErrorMessages?.[field]) {
            return formErrorMessages[field] !== '';
        }
        return false;
    }, [formErrorMessages]);

    const emailHasError = hasError(LoginInputTypes.email);
    const passwordHasError = hasError(LoginInputTypes.password);

    const ErrorComponent = useMemo(() => {
        let errorText = errorMessage;
        return (
            <Text>
                <Text style={[textStyles.popUpErrorMessageText, styles.errorText]}>{errorText}</Text>
            </Text>
        );
    }, [errorMessage]);

    return (
        <View style={[styles.container, styles.containerPlatformStyles, containerStyle]}>
            <Text style={styles.title}>Sign in to your account</Text>
            <Spacer y={16}/>
            <View style={{marginBottom: 18, width: '100%'}}>
                <WrappedInput errorMessage={formErrorMessages?.email}
                              inputComponent={<MB_TextInput
                                  autoCapitalize={'none'}
                                  style={[sharedStyles.textInputFullWidthAndTopSpace, emailHasError && errorInputStyles.input]}
                                  placeholderTextColor={emailHasError ? getColors().inputErrorText : getColors().inputPlaceholder}
                                  textInputStyle={emailHasError ? mbTextStyles([errorInputStyles.text]) : undefined}
                                  titleStyle={emailHasError && errorInputStyles.label}
                                  value={email}
                                  onChangeText={(newText: string) => {setEmail(newText);}}
                                  title="Email address"
                                  placeholder="Enter your email address"
                                  textContentType="emailAddress"
                                  showRequiredAsteriks={true}
                                  nextToFocus={passwordRef}
                              />}

                />
                {!isTTEmployee ? <View style={{alignItems: 'center'}}>
                    <Text>or</Text>
                        {phoneNumberField}
                </View> : null}

            </View>


            <WrappedInput errorMessage={formErrorMessages?.password}
                          containerStyle={{}}
                            inputComponent={ <MB_TextInput MB_Ref={passwordRef}
                                                           autoCapitalize={'none'}
                              style={[sharedStyles.textInputFullWidthAndTopSpace, passwordHasError && errorInputStyles.input]}
                              placeholderTextColor={passwordHasError ? getColors().inputErrorText : getColors().inputPlaceholder}
                              textInputStyle={mbTextStyles([
                                  passwordHasError && errorInputStyles.text,
                              ])}
                              titleStyle={passwordHasError && errorInputStyles.label}
                              value={password}
                              onChangeText={(newText: string) => {
                                  setPassword(newText);
                              }}
                              title="Password"
                              showRequiredAsteriks={true}
                              placeholder="Enter your password"
                              secureTextEntry
                              textContentType="password"
                              wrapInFormElement
                              onSubmitEditing={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN)}
                            />
                            }
            />

            <View style={{alignSelf: 'flex-end'}}>
                <ClickableText text={'Forgot Password?'}
                               action={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD)}
                               color={applyTTStyles(shouldUseTTStyles).textButtonColor}
                               removeLeftPadding={true}
                               removeRightPadding={true}
                />
            </View>

            {ErrorComponent}
            <Spacer y={6}/>
            <MB_Button
                title="Sign In"
                onPress={() => onButtonPressed(LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN)}
                disabled={false}
                style={[styles.signInButtonStyle, {backgroundColor: applyTTStyles(shouldUseTTStyles).primaryButtonBg}]}
                preventDisabledStyleChange={true}

            />
        </View>
    );
};

export default LoginInput;

const COLORS = getColors();

const styles = StyleSheet.create({
    container: {
        width: '100%',
        paddingHorizontal: 28,
        alignItems: 'center',

    },
    containerPlatformStyles: mbPlatformStyle({
        web: {
            maxWidth: 442,
            ...BOX_SHADOW,
            alignItems: 'stretch',
            backgroundColor: COLORS.backgroundPrimary,
            paddingVertical: 31,
            paddingHorizontal: 28,
            borderRadius: 7,
        },

    }),
    title: mbTextStyles([
        textStyles.Muli_16_700, {
        fontSize: 24,
            textAlign: 'left',
        },
    ]),
    signInButtonStyle: {
        width: '100%',
        alignSelf: 'center',
    },
    errorText: {
        minHeight: 24,
        marginBottom: 12,
    },
});

