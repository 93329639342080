import { ColorSchema } from '../../../constants/colors';
import { StyleSheet } from 'react-native';
import { BOX_SHADOW } from '../../../utils/styleUtils';
import { mbPlatformStyle, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';

export const getStyles = (theme: ColorSchema)=> StyleSheet.create({

    resetPasswordContainer: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
    },
    subContainer: {
        width: '100%',
        paddingHorizontal: 18,
        alignItems: 'center',
    },
    subContainerPlatformStyles: mbPlatformStyle({
        web: {
            alignItems: 'stretch',
            backgroundColor: theme.backgroundPrimary,
            paddingVertical: 31,
            paddingHorizontal: 28,
            borderRadius: 7,
            maxWidth: 420,
            minWidth: 420,
            ...BOX_SHADOW,
        },

    }),

    changePasswordPopUp: {
        backgroundColor: 'red',
        paddingHorizontal: 61,
        paddingVertical: 50,
    },
    textInputWrapper: {
        width: '100%',
        backgroundColor: theme.backgroundPrimary,
        borderWidth: 0,
        borderRadius: 10,
        marginTop: 30,
    },

    forgotPwdText: mbTextStyles([
        textStyles.Muli_16_700, {
            fontSize: 24,
        },
    ]),
    forgotPwdPlatformText: mbPlatformStyle({
        web: {
            textAlign: 'left',
        },
    }),
    textContainer: {
        marginTop: 18,
        marginBottom: 20,
    },
    helperText: mbTextStyles([
        textStyles.Muli_16_400,
    ]),
    helperTextPlatformStyles: mbPlatformStyle({
        web: {
            textAlign: 'left',
        },
    }),

    errorMessage: {
        color: theme.inputErrorLabel,
    },
    submitButton: {
        width: '100%',
        alignSelf: 'center',
    },
});
