import React from 'react';
import { StyleProp, ViewStyle } from 'react-native';
import { ImageUrls } from '@temptrack/business';

export enum LOGIN_UI_BUTTON_PRESSED_TYPES {
    SIGN_IN,
    NAVIGATE_TO_ENTER_URL,
    NAVIGATE_TO_TT_LOGIN,
    NAVIGATE_FORGOT_PASSWORD,
}

export interface ILoginInput extends ILoginUI {
    containerStyle?: StyleProp<ViewStyle>;
}

export enum LoginInputTypes {
    email = 'email',
    password = 'password',
}

export interface ILoginUI {
    onButtonPressed: (buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => void;
    email: string;
    setEmail: React.Dispatch<React.SetStateAction<string>>;
    password: string;
    setPassword: React.Dispatch<React.SetStateAction<string>>;
    formErrorMessages: {
        [LoginInputTypes.email]: string;
        [LoginInputTypes.password]: string;
    };
    errorMessage: string;
    isTTEmployee: boolean;
    companyData?: {
        name: string;
        logoUrl?: ImageUrls | undefined;
    };
    phoneNumberField: JSX.Element;
}
