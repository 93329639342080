import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { COLORS } from '../../../constants/colors';
import {mbApplyTransparency} from '@mightybyte/rnw.utils.style-utils';
import { BOX_SHADOW } from '../../../utils/styleUtils';

export const headerStyles = StyleSheet.create({

    initials: mbTextStyles([textStyles.Muli_16_800,{
        fontSize: 14,
    }]),
    logoutText: mbTextStyles([
        textStyles.Muli_16_500,{
            fontSize: 14,
            color: COLORS.textPrimary,
            textAlign: 'left',
        },
    ]),
    profileIconContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        marginStart: 16,
        paddingHorizontal: 16,
        paddingVertical: 8,
        borderRadius: 20,
        minWidth: 200,
        maxWidth: 300,
    },
    profileIconContainerMobile: {
        alignItems: 'center',
        justifyContent: 'center',
        // padding: 16,
        borderRadius: 35,
        width: 36,
        height: 36,
    },

    menuButton: {
        borderRadius: 6,
        backgroundColor: mbApplyTransparency(COLORS.backgroundPrimary, 0.05),
        justifyContent: 'center',
        alignItems: 'center',
    },
    userMenu: {
        flexDirection: 'row',
    },
    userNameText: mbTextStyles([
        textStyles.Muli_16_800, {
        textAlign: 'left',
        },
    ]),
    roleText: mbTextStyles([
        textStyles.Muli_16_500,
        {
            fontSize: 15,
            color: COLORS.textSecondary,
        },
    ]),
    userNameBlock: {
        marginLeft: 12,
        alignItems: 'flex-start',
        justifyContent: 'center',
    },
    modalContentContainer: {
        ...BOX_SHADOW,
        padding: 16,
        borderRadius: 8,
        backgroundColor: COLORS.backgroundPrimary,
        height: 'auto',
        minWidth: 200,
        // minHeight: 200,
    },
});
