import React, { useState, useMemo} from 'react';
import { TextInput, TouchableOpacity, View, StyleSheet } from 'react-native';
import { TimePickerModal } from 'react-native-paper-dates';
import { initialStyles } from '../../hooks/useAppInitialSetup';
import { COLORS } from '../../constants/colors';
import {utils} from '../../utils/utils';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface TimePickerProps {
    value: {
               hours: number;
               minutes: number;
           } | undefined;
    placeholder?: string;
    onChange: (data: {
        hours: number;
        minutes: number;
    }) => void;
    disabled?: boolean;
}

const TimePicker = (
    { value,
        placeholder,
        onChange,
        disabled = false,
    }: TimePickerProps) => {
    const [visible, setVisible] = useState(false);

    const inputTextValue = useMemo(() => {
        if (value) {
            return utils.timeToDisplay12Hours(value);
        } else {
            return '';
        }
    }, [value]);

    return (
        <>
            <TouchableOpacity onPress={() => {
                if (disabled) {
                    return;
                }
                setVisible(true);}}
            >
                <TextInput style={initialStyles.textInputStyle}
                           placeholder={placeholder ?? ''}
                           placeholderTextColor={COLORS.inputPlaceholder}
                           value={inputTextValue}
                           editable={false}
                />
                <View style={styles.iconContainer}>
                    <MaterialCommunityIcons name={'clock-time-four'} color={COLORS.inputPlaceholder} size={20}/>
                </View>

            </TouchableOpacity>
            <View style={{justifyContent: 'center', flex: 1, alignItems: 'center'}}>
                <TimePickerModal
                    visible={visible}
                    onDismiss={()=>setVisible(false)}
                    onConfirm={(newValue: {hours: number, minutes: number}) => {
                        onChange(newValue);
                        setVisible(false);
                    }}
                    hours={value?.hours}
                    minutes={value?.minutes}
                    inputFontSize={48}
                />
            </View>
        </>
    );

};

export { TimePicker };

const styles = StyleSheet.create({
    iconContainer: {
        position: 'absolute',
        right: 0,
        top: 0,
        bottom: 0,
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 10,
    },
});
