import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import React from 'react';
import { StyleSheet, View } from 'react-native';
import {isMobileApp} from '@mightybyte/rnw.utils.device-info';
import { ToastConfigParams, ErrorToast, SuccessToast } from 'react-native-toast-message';

import { STRING_CONSTANTS } from '../../constants/constants';
import { textStyles, FONT_TYPES } from '../../constants/textStyles';
import {appColors} from '../../constants/colors';
import Feather from 'react-native-vector-icons/Feather';
import AntDesign from 'react-native-vector-icons/AntDesign';

const SuccessIcon = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <Feather name={'check-circle'} size={14} color={'#fff'} />
        </View>
    );
};

const ErrorIcon = () => {
    return (
        <View style={styles.defaultIconWrapper}>
            <AntDesign name="closecircleo" size={14} color={'#fff'} />
        </View>
    );
};
const mobilePositionStyle = isMobileApp ? { top: 20 } : undefined;
const CustomToastConfig = {

        success: (props: ToastConfigParams<any>) => {
            const { text1, text2, ...restOfProps } = props;

            return (
                <SuccessToast
                    style={[styles.themedToastBase, mobilePositionStyle]}
                    contentContainerStyle={styles.toastContentContainerStyle}
                    text1Style={[styles.text1Style]}
                    text2Style={styles.text2Style}
                    text1={text1 ?? 'Success!'}
                    text2={text2 ?? 'Updated successfully'}
                    renderLeadingIcon={SuccessIcon}

                    {...restOfProps}
                    text2NumberOfLines={2}
                />
            );
        },
        error:(props: ToastConfigParams<any>) => {
            const { text1, text2, ...restOfProps } = props;
            return (
                <ErrorToast
                    style={[styles.themedToastBase, mobilePositionStyle, styles.errorToast]}
                    contentContainerStyle={styles.toastContentContainerStyle}
                    text1Style={[styles.text1Style]}
                    text2Style={styles.text2Style}
                    text1={text1 ?? 'Error!'}
                    text2={text2 ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN}
                    renderLeadingIcon={ErrorIcon}
                    {...restOfProps}
                    text2NumberOfLines={2}

                />
            );
        },
    info: (props: ToastConfigParams<any>) => {
        const { text1, text2, ...restOfProps } = props;
        return (
            <ErrorToast
                style={[styles.themedToastBase, mobilePositionStyle, styles.infoToast]}
                contentContainerStyle={styles.toastContentContainerStyle}
                text1Style={[styles.text1Style]}
                text2Style={styles.text2Style}
                text1={text1 ?? 'Info'}
                text2={text2 ?? ''}
                {...restOfProps}
                text2NumberOfLines={2}
            />
        );
    },
};

export { CustomToastConfig };

const baseStyles = StyleSheet.create({
    toastStyle: {
        paddingHorizontal: 10,
        alignItems: 'flex-end',
        height: undefined,
        paddingVertical:0,
        backgroundColor: '#16A34A',
        borderStartWidth: 0,
    },
    textBaseStyle: {
        lineHeight: undefined,
        marginStart: 0,
        marginLeft: 0,
        margin: 0,
        marginHorizontal: 0,
        textAlign: 'left',
        color: appColors.white,
    },
});

const styles = StyleSheet.create({
    defaultIconWrapper: {
        width: 17,
        height: 17,
        alignItems: 'flex-start',
        justifyContent: 'center',
        borderRadius: 30,
    },
    errorToast: {
        backgroundColor: '#E02424',
    },
    infoToast: {
        backgroundColor: '#6B7280',
    },
    text1Style: StyleSheet.flatten([
        mbTextStyles([
        textStyles.base, {
            fontFamily: FONT_TYPES.Inter400Regular,
                fontSize: 13,
                fontWeight: '600',
        }]),
        baseStyles.textBaseStyle,
    ]),
    text2Style: StyleSheet.flatten([
        mbTextStyles([
            textStyles.base, {
                fontFamily: FONT_TYPES.Inter400Regular,
                fontSize: 12,
                fontWeight: '400',
            }]),
        baseStyles.textBaseStyle,
    ]),
    toastContentContainerStyle: {
        paddingStart: 8,
    },
    themedToastBase: {
        paddingHorizontal: 10,
        alignItems: 'center',
        height: undefined,
        paddingVertical:0,
        backgroundColor: '#16A34A',
        borderStartWidth: 0,
        minHeight: 60,
    },
    toastMobilePosition: {
        top: 20,
    },
});
