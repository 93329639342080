import { StyleSheet } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from './textStyles';
import { getColors } from './colors';
import { BOX_SHADOW } from '../utils/styleUtils';

const COLORS = getColors();

const tableStyles = StyleSheet.create({
    tableContainer: {
        ...BOX_SHADOW,
        borderRadius: 10,
        backgroundColor: COLORS.backgroundPrimary,
    },
    headerTextStyle: mbTextStyles([
        textStyles.Muli_16_600, {
            fontSize: 12,
            color: COLORS.textSecondary,
            textTransform: 'uppercase',
        },
    ]),
    rowStyle: {
        borderColor: COLORS.stroke,
    },
    tableHeader: {
        backgroundColor: COLORS.backgroundPrimary,
        borderBottomWidth:0,
    },
    tableStyle: {
        backgroundColor: COLORS.backgroundPrimary,
        // height: 'inherit',
    },
    tableRowText: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '500',
            textAlign: 'left',
        },
    ]),

    subText: mbTextStyles([
        textStyles.base, {
            fontSize: 13,
            fontWeight: '500',
            textAlign: 'left',
            color: COLORS.textSecondary,
        },
    ]),

    pageIndicatorStyle: {
        paddingVertical: 18,
    },
    tableButton: {
        height: 30,
        backgroundColor: 'transaprent',
    },
});

export { tableStyles };
