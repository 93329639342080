import React, { ReactElement, useMemo } from 'react';
import { StyleProp, StyleSheet, TouchableOpacity, ViewStyle } from 'react-native';

const IconButton = ({icon, action, removeLeftPadding, removeRightPadding, buttonStyles}: {
    icon: ReactElement,
    action: any,
    color?: string | undefined,
    removeLeftPadding?: boolean,
    removeRightPadding?: boolean,
    buttonStyles?: StyleProp<ViewStyle>

})=> {
    const styles = useMemo(()=> getStyles({
        paddingLeft: removeLeftPadding ? 0 : 10,
        paddingRight: removeRightPadding ? 0 : 10,
    }), [removeLeftPadding, removeRightPadding]);

    return (
        <TouchableOpacity style={[styles.button, buttonStyles]} onPress={action}>
            {icon}
        </TouchableOpacity>
    );
};

export {IconButton};

const getStyles = (params: {
    paddingLeft: number,
    paddingRight: number,
}) => StyleSheet.create({
    button: {
        height: 40,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        justifyContent: 'center',
    },
});
