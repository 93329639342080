import { initializeApp } from 'firebase/app';
import {
    getMessaging,
    getToken,
    onMessage,
    isSupported,
    Messaging,
} from 'firebase/messaging';
import { createWebsiteBaseUrl, envs } from '../../../env';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import {
    MB_EVENT_EMMITER_EVENT_TYPE,
    MB_EventEmmiter,
} from '@mightybyte/rnw.utils.event-emmiter';
import { currentCompanyContextGlobalFunction } from '../../context/CurrentCompanyContext';

const app = initializeApp(envs.FIREBASE_CONFIG);
let messaging: Messaging | undefined;
// const subdomain = currentCompanyContextGlobalFunction.currentCustomDomain  || envs.TEMPTRACK_SUBDOMAIN;

console.log(
    'currentCompanyContextGlobalFunction',
    currentCompanyContextGlobalFunction.currentCustomDomain,
);

// TODO: Firefox does not have the service worker object. We need to find another way of doing this in the future.

const MB_FirebaseMessaging = {
    getToken: async (subdomain: string): Promise<string | undefined> => {
        const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true).replace(
            '*',
            subdomain,
        );
        MB_customWindowForWeb.navigator.serviceWorker?.addEventListener(
            MB_EVENT_EMMITER_EVENT_TYPE.message,
            (event) => {
                const { type, payload } = event.data;

                switch (type) {
                    case 'NOTIFICATION_RECEIVED':
                        MB_EventEmmiter.emit(
                            MB_EVENT_EMMITER_EVENT_TYPE.message,
                            {
                                origin: baseUrl,
                                data: {
                                    message: 'NOTIFICATION_UPDATE',
                                    payload,
                                },
                            },
                        );
                        break;
                }
            },
        );
        const supported = await isSupported();
        messaging = supported ? getMessaging(app) : undefined;

        if (!messaging) {
            throw new Error('Unable to get permission to notify.');
        }

        const token = await getToken(messaging, {
            vapidKey: envs.GENERATED_MESSAGING_KEY,
        });

        onMessage(messaging, (payload) => {
            MB_EventEmmiter.emit(MB_EVENT_EMMITER_EVENT_TYPE.message, {
                origin: baseUrl,
                data: { message: 'NOTIFICATION_UPDATE', payload },
            });
        });

        return token;
    },
    onNotificationPressed: (callback: (notificationId: any) => void) => {
        console.log('onNotificationPressed', callback);
    },
};

export { MB_FirebaseMessaging };
