import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { NativeModules } from 'react-native';

const appFlavor = (isMobileApp ? NativeModules.RNConfig.flavor : process.env.flavor) as 'dev' | 'staging' | 'prod' | undefined;

if (!appFlavor || (appFlavor !== 'prod' && appFlavor !== 'staging' && appFlavor !== 'dev')) {
    throw 'Error: Invalid flavor specified. Must be "dev", "staging", or "prod"';
}

const serverURLs: { dev: string, staging: string, prod: string } = {
   // dev: 'https://b054-108-20-117-54.ngrok-free.app',
    dev: 'http://localhost:3000',
    staging: 'https://api.staging.temptrackapp-dev.com',
    prod: 'https://api.temptrackapp.com',
};

const urlPrefixes: { dev: string, staging: string, prod: string } = {
    dev: 'http://',
    staging: 'https://',
    prod: 'https://',
};

const urlBases: { dev: string, staging: string, prod: string } = {
    dev: 'localhost:8080',
    staging: 'temptrack.mightybyte.us',
    prod: 'temptrackapp.com',
};

const createWebsiteBaseUrl = (flavor:'dev' | 'staging' | 'prod' | undefined, subdomain: boolean) => {
    if (!flavor) {throw new Error('Invalid flavor');}
    const prefix = urlPrefixes[flavor];
    const base = urlBases[flavor];
    return subdomain ? `${prefix}*.${base}` : `${prefix}${base}`;
};

const websiteBaseUrls: { dev: string[], staging: string[], prod: string[] } = {
    dev: [createWebsiteBaseUrl('dev', false), createWebsiteBaseUrl('dev',true)],
    staging:[createWebsiteBaseUrl('staging', false), createWebsiteBaseUrl('staging',true)],
    prod: [createWebsiteBaseUrl('prod', false), createWebsiteBaseUrl('prod',true)],
};

const sharedFirebaseConfig =  {
    apiKey: 'AIzaSyD6pN4eCJLLOP7-dpG3YI2Ukff_Lg1Gy3E',
    authDomain: 'temptrackapp.firebaseapp.com',
    projectId: 'temptrackapp',
    storageBucket: 'temptrackapp.appspot.com',
    messagingSenderId: '15313714669',
};

const firebaseAppId: { dev: string, staging: string, prod: string } = {
    dev: '1:15313714669:web:b33eb21254959de2f4fbe4',
    staging: '1:15313714669:web:fba01422b6ede33ef4fbe4',
    prod: '1:15313714669:web:a90cad6e81de20e4f4fbe4',
};


const envs = {
    FLAVOR: appFlavor,
    SERVER_URL: serverURLs[appFlavor],
    BASE_URL: urlBases[appFlavor],
    WEBSITE_BASE_URL: websiteBaseUrls[appFlavor],
    LOCATION_PICKER_API: 'AIzaSyD3m8wnyJeR2T1OtxYUSa_e3jcWlPmJd6Q',
    TEMPTRACK_SUBDOMAIN: 'app',
    MOBILE_DEEP_LINK: 'temptrack://',
    MOBILE_DEEP_LINK_FOR_SERVER_REDIRECT: 'temptrack-server-redirect://',
    PLAY_STORE_DEEP_LINK: 'market://details?id=com.temptrackapp2.app',
    APP_STORE_DEEP_LINK: 'itms-apps://apps.apple.com/us/app/temptrackapp2/id6480220700',
    FIREBASE_CONFIG: {...sharedFirebaseConfig, ...{appId: firebaseAppId[appFlavor]}},
    GENERATED_MESSAGING_KEY: 'BOYz4uHiznqpiCELDatCjBdogHOI419brgpT4S_oozjOEF3rPvGcZqwfU8vdHtJWtY12g2IDm_P3T5bBBqa_lQk',
};

export { envs, createWebsiteBaseUrl, appFlavor };
