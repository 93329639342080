import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { LoginPageProps } from '../../../typesAndInterfaces/componentProps';
import { utils } from '../../../utils/utils';
import { LOGIN_UI_BUTTON_PRESSED_TYPES, LoginInputTypes } from './LoginUtils';
import { Errors } from '../../../constants/errors';
import LoginUI from './LoginUI';
import { utilHooks } from '../../../hooks/utilHooks';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { SIGNED_IN_STATUS, useSignedInContext } from '../../../context/SignedInContext';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { trpc } from '../../../apiCalls/trpcClient';
import { createWebsiteBaseUrl, envs } from '../../../../env';
import {MB_customWindowForWeb} from '@mightybyte/rnw.utils.custom-window-for-web';
import { useCurrentCompanyContext } from '../../../context/CurrentCompanyContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { usePhoneNumber } from '../../../hooks/usePhoneNumber';


const Login = ({ navigation, route }: LoginPageProps) => {
  const {currentCustomDomain, setCurrentCompanyLogoUrl,
          setCurrentCompanyName, currentCompanyLogoUrl} = useCurrentCompanyContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const prevEmail = useRef('');
  const prevPassword = useRef('');
  const [formErrorMessages, setFormErrorMessages] = useState({
    [LoginInputTypes.email]:'',
    [LoginInputTypes.password]: '',
  });
  //handles submit errors returned from the server
  const [errorMessage, setErrorMessage] = useState('');

  const domain = useMemo(() => {
    if (isMobileApp) {
        return route.params?.domain || envs.TEMPTRACK_SUBDOMAIN;
    }
    return currentCustomDomain ?? envs.TEMPTRACK_SUBDOMAIN;
  }, [currentCustomDomain, route.params?.domain]);

  const {data: companyData} = trpc.company.getBasicDetailsByDomain.useQuery({domain: domain ?? ''}, {
    enabled: domain !== undefined && domain.length !== 0 && domain !== envs.TEMPTRACK_SUBDOMAIN,
  });

  const [isTT, setIsTT] = useState(domain ? domain === envs.TEMPTRACK_SUBDOMAIN : false);

  const {url, processing} = utilHooks.useInitialURL();
  const { setSignedInStatus} = useSignedInContext();

  const { value: phoneNumberValue,
          field: phoneNumberField,
    } = usePhoneNumber({
    label: 'Cell Phone Number',
    placeholder: 'Enter your cell phone number',
    subText: 'Note: only temporary workers may use their phone number to sign in.',
  });


  useEffect(() => {
    if (companyData) {
      setCurrentCompanyLogoUrl(companyData.logoUrl);
      setCurrentCompanyName(companyData.name);
    }
  }, [companyData, setCurrentCompanyLogoUrl, setCurrentCompanyName]);

  const trpcContext = trpc.useUtils();

  const signIn = trpc.user.login.useMutation({ trpc: { context: { skipBatch: true } } });

  const validateLogin = useCallback((field?: LoginInputTypes) => {
    const errors = {...formErrorMessages};
    let isValid = true;
    if ((!field || field === LoginInputTypes.email) && (email.length === 0 && phoneNumberValue?.length === 0)) {
      setErrorMessage(STRING_CONSTANTS.EMAIL_OR_PHONE_REQUIRED);
      isValid = false;
    } else {
        setErrorMessage('');
    }
    if ((!field || field === LoginInputTypes.password) && password.length === 0) {
      errors[LoginInputTypes.password] = Errors.required;
      isValid = false;
    } else {
        errors[LoginInputTypes.password] = '';
    }
    setFormErrorMessages(errors);
    return isValid;
  }, [password, email, formErrorMessages, phoneNumberValue]);

  useEffect(() => {
    if (prevEmail.current === email) {
      return;
    }
    validateLogin(LoginInputTypes.email);
    prevEmail.current = email;
  }, [email, validateLogin]);

  useEffect(() => {
    if (prevPassword.current === password) {
      return;
    }
    validateLogin(LoginInputTypes.password);
    prevPassword.current = password;
  }, [password, validateLogin]);


  const onButtonPressedCallback = useCallback((buttonType: LOGIN_UI_BUTTON_PRESSED_TYPES) => {

    switch (buttonType) {
      case LOGIN_UI_BUTTON_PRESSED_TYPES.SIGN_IN:
        if (!validateLogin()) {
          return;
        }

        signIn.mutate(
          { email: phoneNumberValue.length === 0 ? email : phoneNumberValue, password },
          {
            onSuccess: data => {
              trpcContext.user.getCurrentUserData.invalidate();
              MB_accessTokenUtils.setAccessToken(data.tokenPair);
              setSignedInStatus?.(SIGNED_IN_STATUS.signedIn);
            },
            onError: (e) => {
              const error = utils.getParsedTrpcError(e, {
                code: STRING_CONSTANTS.FAILED_TO_LOG_IN,
                message: STRING_CONSTANTS.FAILED_TO_LOG_IN,
                statusCode: 1,
              });
              setErrorMessage(error.message);
            },
          },
        );
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_FORGOT_PASSWORD:
        if (isMobileApp) {
          navigation.navigate('ForgotPassword', {domain:domain});
        } else  {
            const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
            MB_customWindowForWeb.open(`${baseUrl.replace('*', domain)}/forgotPassword`, '_self');
        }

        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_TO_ENTER_URL:
        if (isMobileApp) {
            navigation.navigate('EnterUrl');
            return;
        } else {
          const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
          MB_customWindowForWeb.open(`${baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN)}/enterUrl`, '_self');
        }
        break;
      case LOGIN_UI_BUTTON_PRESSED_TYPES.NAVIGATE_TO_TT_LOGIN: {
        setIsTT(true);
        if (isMobileApp) {
          navigation.navigate('Login');
        } else {
          const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
          MB_customWindowForWeb.open(`${baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN)}/login`, '_self');
        }
        break;
      }
      default:
        break;
    }
  }, [validateLogin, signIn, email, password,
    trpcContext.user.getCurrentUserData, setSignedInStatus, navigation, domain, phoneNumberValue]);


  if (processing || (!isMobileApp && !url)) {
    return null;
  }

  return (
      <LoginUI
          onButtonPressed={onButtonPressedCallback}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          formErrorMessages={formErrorMessages}
          errorMessage={errorMessage}
          isTTEmployee={isTT}
          companyData={companyData ?? {name: '', logoUrl: currentCompanyLogoUrl}}
          phoneNumberField={phoneNumberField}
      />
  );
};

export { Login };
