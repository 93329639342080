import React, { useCallback, useMemo, useState } from 'react';
import { View, Text, ImageBackground, StyleSheet, ScrollView } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../constants/colors';
import {ComponentWrapper} from '../../helperComponents/componentWrapper/ComponentWrapper';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_TYPES, textStyles } from '../../../constants/textStyles';
import { TTLogoSvg } from '../../../resources/svgComponents/TTLogoSvg';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { errorInputStyles, WrappedInput } from '../../helperComponents/misc/WrappedInput';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { createWebsiteBaseUrl, envs } from '../../../../env';
import {sharedStyles} from '../../../utils/styleUtils';
import { SelectAnotherSubdomainUI } from './SelectAnotherSubdomainUI';
import { Spacer } from '../../helperComponents/misc/Spacer';
import {MB_customWindowForWeb} from '@mightybyte/rnw.utils.custom-window-for-web';
import {imageUtils} from '../../../utils/imageUtils';
import { trpc } from '../../../apiCalls/trpcClient';
import { navRef } from '../../../navigations/RootNavigator';
import { useCurrentCompanyContext } from '../../../context/CurrentCompanyContext';

const COLORS = getColors();

const EnterUrl = () => {

    const styles = isMobileApp ? mobileStyles : webStyles;

    const [domain, setDomain] = useState('');
    const [domainError, setDomainError] = useState('');
    const {setCurrentCustomDomain} = useCurrentCompanyContext();

    const domainHasError = useMemo(() => {
        return domainError !== '';
    }, [domainError]);

    const canGoNext:boolean = useMemo(() => {
        return !!domain && domain.length > 0;
    }, [domain]);

    const redirectToTTLogin = () => {
        setCurrentCustomDomain(envs.TEMPTRACK_SUBDOMAIN);
        navRef.current?.navigate('Login');
    };

    const { isError, isSuccess} = trpc.company.getBasicDetailsByDomain.useQuery({domain: domain}, {
        enabled: domain.length > 0 && domain !== envs.TEMPTRACK_SUBDOMAIN,
        retry: false,
    });

    const nextButtonPressed = useCallback(() => {
        if (isError){
            setDomainError('Invalid domain');
        } else if (isSuccess){
            if (isMobileApp) {
                setCurrentCustomDomain(domain);
                navRef.current?.navigate('Login', {domain});
            } else {
                const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
                MB_customWindowForWeb.open(`${baseUrl.replace('*', domain)}/login`, '_self' );
            }


        }
    },[isError, isSuccess, domain, setCurrentCustomDomain]);

    const onInputChanged = useCallback((newText: string) => {
        setDomain(newText);
        setDomainError('');
    }, []);

    return (
        <ComponentWrapper hideWebHeader>
            <ScrollView showsVerticalScrollIndicator={false} contentContainerStyle={styles.innerContainerStyle}>
            <View style={[styles.container, styles.leftContainer]}>
                <View style={[styles.formContainer]}>

                    <TTLogoSvg size={140} containerStyle={{marginBottom: isMobileApp ? 42 : 20}}/>
                    <Text style={styles.title}>Enter your sign-in URL</Text>
                    <Spacer y={isMobileApp ? 24 : 16}/>
                    <View style={{position: 'relative', width: '100%'}}>
                        <View style={[styles.inputSubSection, domainHasError ? styles.errorBorder : undefined]}>
                            <Text>.temptrackapp.com</Text>
                        </View>
                    <WrappedInput errorMessage={domainError}
                                  inputComponent={<MB_TextInput
                                      autoCapitalize={'none'}
                                      style={[sharedStyles.textInputFullWidthAndTopSpace, domainHasError && errorInputStyles.input]}
                                      placeholderTextColor={domainHasError ? getColors().inputErrorText : getColors().inputPlaceholder}
                                      textInputStyle={mbTextStyles([
                                          domainHasError ? errorInputStyles.text : {color: getColors().inputText},
                                      ])}
                                      titleStyle={domainHasError && errorInputStyles.label}
                                      value={domain}
                                      onChangeText={onInputChanged}
                                      placeholder="Enter company sign-in URL"
                                      onSubmitEditing={nextButtonPressed}
                                  />}
                    />
                    </View>
                    <MB_Button title="Next" onPress={nextButtonPressed}
                               disabled={!canGoNext}
                               key={`enabled ${canGoNext.toString()}`}
                               style={[styles.signInButtonStyle, {backgroundColor: applyTTStyles(true).primaryButtonBg},
                                   !canGoNext ? sharedStyles.disabledButton : undefined]}
                               preventDisabledStyleChange={true}
                    />
                    <Text style={[styles.dontKnowUrlText]}>Don’t know your sign-in URL? Contact your company for help.</Text>
                    <SelectAnotherSubdomainUI action={redirectToTTLogin} title={'TempTrack admin?'} buttonColor={applyTTStyles(true).textButtonColor}/>
                </View>
            </View>
            <View style={[styles.rightContainer]}>
                <ImageBackground source={isMobileApp ? imageUtils.images.mobileBackgroundWithLogo : imageUtils.images.backgroundWithLogo}
                                 style={styles.imageBackgroundContainer}
                                 resizeMode={'cover'}
                                 >
                    <View style={[styles.promoWrapper]}>
                        <Text style={[styles.promoText, styles.promoTextSize]}>The Ultimate Staffing Management Solution.</Text>
                    </View>

                </ImageBackground>
            </View>
            </ScrollView>
        </ComponentWrapper>
    );
};

export {EnterUrl};

const mobileStyles = StyleSheet.create({
    innerContainerStyle: {
        justifyContent: 'flex-end',
        width: '100%',
        // height: '100%',
        flexDirection: 'column-reverse',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
    },

    leftContainer: {
        width: '100%',
    },
    rightContainer: {
        width: '100%',
        minHeight: 150,
        height: '25%',
        marginBottom: 30,
        alignItems: 'flex-start',
    },
    formContainer: {
        width:'100%',
        // height: '100%',
        paddingHorizontal: 30,
        minWidth: 300,
        maxWidth: 550,
        alignItems: 'center',
        position: 'relative',
    },


    title: mbTextStyles([
        textStyles.Muli_16_700, {
            fontSize: 24,
            textAlign: 'left',
        },
    ]),

    imageBackgroundContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },

    promoWrapper: {
        marginHorizontal: 30,
        maxWidth: 300,
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    promoText: mbTextStyles([{
        fontFamily: FONT_TYPES.Inter900Black,
        color: '#FFFFFF',
        textAlign: 'left',
        paddingRight: 60,
    }]),

    promoTextSize: {
        fontSize: 24,
    },

    inputSubSection: {
        position: 'absolute',
        right: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderColor: COLORS.inputBorder,
        height: 42,
        backgroundColor: appColors.gray100,
        paddingHorizontal: 8,
        justifyContent: 'center',
        borderWidth: 1,
        top: 5,
        zIndex: 200,
    },
    errorBorder: {
        borderLeftWidth: 0,
        borderColor: appColors.red500,
    },
    signInButtonStyle: {
        marginTop: 26,
        width: '100%',
        alignSelf: 'center',
    },

    dontKnowUrlText: mbTextStyles([
        {
            fontFamily: FONT_TYPES.Inter500Medium,
            fontSize: 14,
            textAlign: 'center',
            color: appColors.gray500,
            marginTop: 20,
        },
    ]),

});

const webStyles = StyleSheet.create({
    innerContainerStyle: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        flexDirection: 'row',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
    },

    leftContainer: {
        width: '50%',
        minWidth: 300,
    },
    rightContainer: {
        width: '50%',
        position: 'relative',
        height: '100%',
    },

    formContainer: {
        width:'100%',
        paddingHorizontal: 30,
        minWidth: 300,
        maxWidth: 550,
        alignItems: 'flex-start',
        position: 'relative',
    },

    title: mbTextStyles([
        textStyles.Muli_16_700, {
            fontSize: 24,
            textAlign: 'left',
        },
    ]),

    imageBackgroundContainer: {
        width: '100%',
        height: '100%',
        justifyContent: 'center',
    },
    promoWrapper: {
        maxWidth: 550,
        marginHorizontal: 50,
    },

    promoText: mbTextStyles([{
        fontFamily: FONT_TYPES.Inter900Black,
        color: '#FFFFFF',
        textAlign: 'left',
    }]),

    promoTextSize: {
        fontSize: 60,
    },

    inputSubSection: {
        position: 'absolute',
        right: 0,
        borderTopRightRadius: 6,
        borderBottomRightRadius: 6,
        borderColor: COLORS.inputBorder,
        height: 42,
        backgroundColor: appColors.gray100,
        paddingHorizontal: 20,
        justifyContent: 'center',
        borderWidth: 1,
        top: 5,
        zIndex: 200,
    },
    errorBorder: {
        borderLeftWidth: 0,
        borderColor: appColors.red500,
    },
    signInButtonStyle: {
        marginTop: 26,
        width: '100%',
        alignSelf: 'center',
    },

    dontKnowUrlText: mbTextStyles([
        {
            fontFamily: FONT_TYPES.Inter500Medium,
            fontSize: 14,
            textAlign: 'left',
            color: appColors.gray500,
            marginTop: 20,
        },
    ]),
});
