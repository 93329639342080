import { Token } from '@mightybyte/rnw.utils.access-token-utils';
import { envs } from '../../env';
import { SERVER_ERROR_CODES } from '../constants/constants';
import { ServerError } from '../typesAndInterfaces/typesAndInterfaces';
import { utils } from '../utils/utils';
import { TokensObj } from '../utils/zod/zodObjects';
import { customAxios } from './customAxios';

const userApiCalls = {
    /**
     * Requests a new access token from a server
     * @param accessToken Access token for server validation
     * @param refreshToken Refresh token that is used to get a new access token
     * @param skipThrows If set to true, will skip all the throws, used in axiosConfig. Since we need to call this
     * when we get invalid access token or refresh token(Server needs this to potentially log some malicious activities)
     * setting this to true will prevent an infinite loop situation.
     * @returns promise containing the new access token
     */
    requestNewAccessToken: async (
        accessToken: string,
        refreshToken: string,
        skipThrows?: boolean,
    ): Promise<Token> => {
        return await customAxios
            .post(
                envs.SERVER_URL + '/api/users/token',
                {
                    accessToken,
                },
                {
                    headers: {
                        Authorization: refreshToken,
                    },
                },
            )
            .then(function (response) {
                if (skipThrows) {
                    return;
                }

                const tokenPair = response?.data?.data?.tokenPair;

                const validatorResponse = TokensObj.safeParse(tokenPair);
                if (!validatorResponse.success) {
                    console.error(
                        'tokenPair was ',
                        tokenPair,
                        validatorResponse.error.issues,
                    );
                    throw utils.createErrorObject(
                        'Data mismatch for request new access token',
                        SERVER_ERROR_CODES.CLIENT_DATA_MISMATCH_ERROR,
                    );
                }
                return tokenPair;
            })
            .catch(function (error) {
                console.error(
                    'Error while requesting access code',
                    error.response,
                );
                if (!skipThrows) {
                    if (error?.response?.data) {
                        throw error?.response?.data as ServerError;
                    }

                    const customError: ServerError = {
                        message: 'Unknown Error',
                        errorCode: SERVER_ERROR_CODES.UNKNOWN_ERROR,
                        status: 'error',
                    };

                    throw customError;
                }
            });
    },
};

export { userApiCalls };
