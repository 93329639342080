import {
    HTTPBatchLinkOptions,
    HTTPLinkOptions,
    httpBatchLink,
    httpLink,
    splitLink,
} from '@trpc/client';
import { createTRPCReact } from '@trpc/react-query';
import { envs } from '../../env';
import type { AppRouter } from '@temptrack/router';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';

const trpc = createTRPCReact<AppRouter>({});

function getConfig<T extends boolean>(
    forBatch: T,
): T extends true ? HTTPBatchLinkOptions : HTTPLinkOptions {
    if (forBatch) {
    }
    return {
        url: `${envs.SERVER_URL}/trpc`,
        headers: async () => {
            const accessToken = await MB_accessTokenUtils.getAccessToken(); // TODO: Yulia: Are we refreshing this?
            return {
                Authorization: accessToken,
            };
        },
    };
}

const reactTRPCClient = trpc.createClient({
    links: [
        splitLink({
            condition(op) {
                // check for context property `skipBatch`
                return op.context.skipBatch === true;
            },
            // when condition is true, use normal request
            true: httpLink(getConfig(false)),
            // when condition is false, use batching
            false: httpBatchLink(getConfig(true)),
        }),
    ],
});

export { trpc, reactTRPCClient };
