import React, { useCallback, useMemo } from 'react';
import { StyleSheet, View, Text, FlatList} from 'react-native';
import { appColors, COLORS } from '../../constants/colors';
import { Address, TimelineAction, TimelineDataForClient } from '@temptrack/business';
import { BOX_SHADOW } from '../../utils/styleUtils';
import { textStyles } from '../../constants/textStyles';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { Spacer } from './misc/Spacer';
import Entypo from 'react-native-vector-icons/Entypo';
import { LoadingPage } from './LoadingPage';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

function UTCGetDate(date: string){
    const dateObj = new Date(date);
    return dateObj.toLocaleDateString();
}

function UTCgetTime(date: string){
    const dateObj = new Date(date);
    return dateObj.toLocaleTimeString();

}

enum ExcludeFromDisplay {
    id = 'id',
    regionId = 'regionId',
    branchId = 'branchId',
}

const TimelineView = (
    { data,
        isLoading,
    }: {
        data: {
            id: string;
            timestamp: string;
            performedBy: {
                userId: string;
                firstName: string;
                lastName: string;
            };
            action: TimelineAction;
            data: any;
        }[];
        isLoading?: boolean
    }) => {

    type FieldChangeSummary = {
        type: TimelineAction;
        previousValue: string | boolean | Address;
        newValue?: string | boolean | string[] | Address | undefined;
    };

            const displayChange = useCallback((key: string, value: FieldChangeSummary, keyPrefix?: string) => {
                const filedName = keyPrefix ? `${keyPrefix} ${key}` : key;
                const newValueToDisplay = (value?.newValue && value.newValue !== '') ? value?.newValue : value?.newValue === false ?  'false' : 'empty';
                return (
                    <View style={{ flexDirection: 'row',  marginBottom: 8 }} key={key}>
                        <Text style={[styles.details, styles.label]}>{`${filedName}:`}</Text>
                        {value.type === TimelineAction.edited ?
                            <Text style={styles.details}> {value?.previousValue}
                                <Text style={[styles.label]}> to </Text>
                                {newValueToDisplay}</Text> :
                            <Text style={styles.details}> {newValueToDisplay}</Text>}
                    </View>
                );
            }, []);

            const RenderDetail = useCallback(({item}: {
                item: TimelineDataForClient<string, any>
            }) => {

                //capitalize first letter of action
                const actionText = item.action?.charAt(0).toUpperCase() + item.action?.slice(1);
                const text = `${actionText} by ${item.performedBy.firstName} ${item.performedBy.lastName}`;
                const dateText = UTCGetDate(item.timestamp);
                let details = null;
                if (item.data && Object.keys(item.data).length > 0){
                    details = <View>
                        {Object.entries(item.data).map(([key, value]) => {
                            if (Object.values(ExcludeFromDisplay).includes(key as ExcludeFromDisplay)) {
                                return null;
                            }
                            if (value.type === 'array') {
                                const added = value?.added && value.added.length > 0 ? `added: ${value.added.join(', ')}` : '';
                                const deleted = value?.deleted && value.deleted.length > 0 ? `deleted: ${value.deleted.join(', ')}` : '';

                                return (
                                    <View style={{ flexDirection: 'row', marginBottom: 8 }} key={key}>
                                        <Text style={[styles.details, styles.label, styles.capitalize]}>{`${key}:`}</Text>
                                        <Text style={styles.details}>{added}</Text>
                                        <Text style={styles.details}>{deleted}</Text>
                                    </View>
                                );
                            }
                            if (key === 'address' || key.toLowerCase().indexOf('address') !== -1) {
                                const addressValue = value as unknown as { [key: string]: FieldChangeSummary };
                                return Object.entries(addressValue).map(([addressKey, v]) => {
                                    const summary = {
                                        type: item.action as TimelineAction,
                                        previousValue: v?.previousValue,
                                        newValue: v?.newValue,
                                    };
                                    return displayChange(addressKey, summary, key);
                                });
                            }
                            return displayChange(key, value  as unknown as FieldChangeSummary);

                })}
            </View>;
        }


        return (
            <View style={styles.wrapper}>
                <View style={styles.line}/>
                <View style={styles.dotWrapper}>
                    <View style={styles.dot}/>
                </View>

                <View style={styles.timelineItemContainer}>
                    {/*{envs.FLAVOR === 'prod' ? null :   <Text style={styles.timeSubText}>{item.timestamp}</Text>}*/}
                    <View style={{flexDirection: 'row'}}>
                        <Text style={styles.timeSubText}>{dateText}</Text>
                        <Spacer x={10}/>
                        <Text style={styles.timeSubText}>{`${UTCgetTime(item.timestamp)}`}</Text>
                    </View>

                    <Spacer y={5}/>
                    <Text style={styles.text}>{text}</Text>
                    <Spacer y={5}/>
                    {details}


                </View>
            </View>

        );
    },[displayChange]);

  const keyExtractor = useCallback((item:  TimelineDataForClient<string, any>, index: number) => {
        return `${item.id}-${index} `;
  },[]);

    const emptyComponent = useMemo(() => {
        return (
            <View style={{flex: 1, justifyContent: 'center', alignItems: 'center',marginTop: 30}}>
                {isLoading ? <LoadingPage /> : <View style={{flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Entypo name={'block'} size={64} color={appColors.blue100} style={{marginBottom: 20}}/>
                    <Text style={styles.noTimelineText}>No timeline data available</Text>
                </View>}

            </View>

        );
    }, [isLoading]);

    if (isMobileApp) {
        return (
            <View>
                {data && data.length > 0 ? data.map((item) => {
                    return <RenderDetail item={item} key={item.id} />;
                }) : emptyComponent}
        </View>
        );
    }
    return (
        <FlatList data={data}
                  renderItem={RenderDetail}
                  keyExtractor={keyExtractor}
                  ListEmptyComponent={emptyComponent}

        />
    );
};

export {TimelineView};

const styles = StyleSheet.create({
    wrapper: {
        marginBottom: 20,
        paddingLeft: 40,
        position: 'relative',
    },
    line: {
        width: 1,
        height: '100%',
        backgroundColor: COLORS.timeLineLine,
        position: 'absolute',
        left: 18,
        top: 0,
        zIndex:1,
    },
    dotWrapper: {
        position: 'absolute',
        left: -1,
        top: '50%',
        transform: [{translateY: -20}],
        padding: 10,
        zIndex: 2,
        backgroundColor: COLORS.backgroundPrimary,
    },
    dot: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: COLORS.timeLineDot,
    },
    timelineItemContainer: {
        ...BOX_SHADOW,
        flex:1,
        padding:10,
        borderWidth: 1,
        borderRadius: 5,
        borderColor: COLORS.timelineEntryBorder,
        marginRight: 10,

    },
    text: mbTextStyles([textStyles.Inter_16_500,
        {
            color: COLORS.textPrimary,
            fontSize: 14,
            textAlign: 'left',
        },
    ]),
    timeSubText: mbTextStyles([textStyles.Inter_16_500,
        {
            color: COLORS.timelineSubText,
            fontSize: 12,
            fontWeight: '400',
            textAlign: 'left',
            textTransform: 'uppercase',
        },
    ]),
    dotStyle: {
        width: 20,
        height: 20,
        borderRadius: 10,
        backgroundColor: COLORS.timeLineDot,
        position: 'absolute',
        left: 11,
        top: '50%',
    },
    details: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '400',
            marginRight: 8,
            flex: 1,
            flexWrap: 'wrap',
            textAlign: 'left',
        },
    ]),
    label: {
        color: COLORS.textSecondary,
    },
    capitalize: {
        textTransform: 'capitalize',
    },
    noTimelineText: mbTextStyles([
        textStyles.Muli_16_600, {
            fontSize: 16,
            color: appColors.blue200,
        },
    ]),
});
