import React, { useState, useEffect, useRef, useCallback } from 'react';
import { DEFAULT_TABLE_MAX_ROWS } from '../constants/constants';
import { PageIndicator } from '../components/helperComponents/misc/PageIndicator';
import { RouteProp } from '@react-navigation/native';
import { DrawerNavigationProp } from '@react-navigation/drawer';

type PaginationRouteParams = {
    page?: number;
    pageSize?: number;
};

type PaginationComponentType = React.FC<{totalItems: number | undefined, currentItemsLength: number}>;

const usePagination = ({navigation, route}: {
    navigation: DrawerNavigationProp<any>
    route: RouteProp<any, any>;
}) => {
    const navigationRef = useRef(navigation);
    const routeRef = useRef(route);

    const [page, setPage] = useState(routeRef.current.params?.page ? Number(routeRef.current.params?.page) : 1);
    const [pageSize, setPageSize] = useState(routeRef.current.params?.pageSize ? Number(routeRef.current.params?.pageSize) : DEFAULT_TABLE_MAX_ROWS);


    useEffect(() => {
        navigationRef.current.setParams({page: page});
    }, [page]);

    useEffect(() => {
        setPage(1);
        navigationRef.current.setParams({pageSize: pageSize});
    }, [pageSize, setPage]);

    const PaginationComponent = useCallback(({totalItems}) => {
        if (!totalItems) {return null;}
        return (
            <PageIndicator currentPage={page}
                           totalItems={totalItems}
                           shouldUseTTschema={false}
                           setPage={setPage}
                           pageSize={pageSize}
                           setPageSize={setPageSize}

            />
        );
    },[page, pageSize]);

    return {
        PaginationComponent,
        page,
        pageSize,
        setPage,
    };
};

export {usePagination};
export type {PaginationRouteParams, PaginationComponentType};
