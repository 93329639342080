import React, { useCallback, useMemo, useState } from 'react';
import { Text, TouchableOpacity, View, StyleSheet } from 'react-native';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { MB_DropDownPicker, RenderListItemPropsInterface } from '@mightybyte/rnw.components.dropdownpicker';
import { appColors, applyTTStyles } from '../../../../constants/colors';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import { ValueRow, DisplayOptions} from '../../../helperComponents/misc/ValueRow';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { AssignmentStatusRenderer } from '../../../helperComponents/misc/badges/AssignmentStatusRenderer';
import { formatDateOptions, utils } from '../../../../utils/utils';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import {CheckinsStatusBadge} from '../../../helperComponents/misc/badges/CheckinsStatusBadge';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { trpc } from '../../../../apiCalls/trpcClient';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { WorkOrdersNavigationProp } from '../../../../typesAndInterfaces/componentProps';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';

const ViewWOChecklists = ({
    cancelAction,
    workOrderId,
    navigation,
}: {
    cancelAction: ()=>void
    workOrderId: string | undefined,
    navigation: WorkOrdersNavigationProp
}) => {

    const {currentCompanyId} = useCurrentCompanyContext();

    const {data: recordData} = trpc.company.checklist.getByWorkOrderId.useQuery({companyId: currentCompanyId ?? '', workOrderId: workOrderId ?? ''}, {
        enabled: !!currentCompanyId && !!workOrderId,
        retry: false,
    });

    const items = useMemo(()=> {
        if (!recordData?.checklists) {
            return [];
        }
        return recordData?.checklists.map((checklist) => {
            return {label: checklist.number, value: checklist.id};
        });
    }, [recordData?.checklists]);

    const [selectedCheckListId, setSelectedCheckListId] = React.useState<string | undefined>(undefined);

    const selectedCheckList = useMemo(()=> {
        return recordData?.checklists.find((checklist)=> checklist.id === selectedCheckListId);
    }, [selectedCheckListId, recordData?.checklists]);


    const [highlightedValue, setHighlightedValue] = useState();

    const LabelContentRenderer = useCallback(( checklist, isHighlighted)=>{
        if (!checklist) {
            return null;
        }
        return <View style={[styles.listItem, {backgroundColor: isHighlighted ? appColors.gray200 : undefined}]}>
            <Text style={initialStyles.textInputTextStyle}>{checklist.number}</Text>
            <CheckinsStatusBadge todayCheckins={checklist?.checkIns}
                                 checklistDate={utils.displayDateObj(checklist?.date)}/>
        </View>;
    },[]);

    const renderItem = useCallback((p: RenderListItemPropsInterface<any>) => {
        const { item, value: itemValue, onPress } = p;
        const isHighlighted = highlightedValue === itemValue;
        const checklist = recordData?.checklists.find((cl)=> cl.id === itemValue);
        const props = {
            onMouseMove: () => {
                if (highlightedValue !== itemValue) {
                    setHighlightedValue(itemValue);
                }
            },
            onPress: () => onPress(item),
        };
        return (
            <TouchableOpacity {...props}>
                {LabelContentRenderer(checklist, isHighlighted)}
            </TouchableOpacity>
        );
    }, [highlightedValue, recordData?.checklists, LabelContentRenderer]);

    const goToChecklist = useCallback(()=> {
        if (!currentCompanyId || !selectedCheckListId) {
            return;
        }
       cancelAction();
        setTimeout(()=> {navigation.navigate(CompanyDashboardRoutes.viewChecklist, {companyId: currentCompanyId, checklistId: selectedCheckListId});}, 400);

    }, [cancelAction, currentCompanyId, navigation, selectedCheckListId]);
    return (
        <View style={{

        }}>
            <View style={{flex:1, zIndex: 4000}}>
                <WrappedInput errorMessage={''}
                              inputComponent={<MB_DropDownPicker items={items} key={selectedCheckListId}
                                                                 value={selectedCheckListId}
                                                                 onValueChange={setSelectedCheckListId}
                                                                 listMode={'SCROLLVIEW'}
                                                                 title={'Selected checklist'}
                                                                 containerStyle={{width: '100%'}}
                                                                 dropdownContainerStyle={{
                                                                     maxHeight: 300,
                                                                 }}
                                                                 showRequiredAsteriks={true}
                                                                 dropDownDirection={'BOTTOM'}
                                                                 multiple={false}
                                                                 customListItem={(e)=>renderItem(e)}
                              />}
                />
            </View>
            {selectedCheckListId ? <>
            <View style={{zIndex: 1}}>
                <ValueRow label={'Check-ins'}
                          valueComponent={ <CheckinsStatusBadge todayCheckins={selectedCheckList?.checkIns} checklistDate={utils.displayDateObj(selectedCheckList?.date)}/>}
                            displayOption={DisplayOptions.secondary}
                          />
                <ValueRow label={'Activity Status'}
                          displayOption={DisplayOptions.secondary}
                          valueComponent={<StatusRenderer status={selectedCheckList?.activityStatus} recordType={RecordTypes.checklist}
                            />}
                />
                <ValueRow label={'Assignment Status'}
                          displayOption={DisplayOptions.secondary}
                          valueComponent={<AssignmentStatusRenderer assignmentStatus={selectedCheckList?.assignmentStatus} displayRow
                          />}
                />
                <ValueRow label={'Date created'}
                            value={utils.formatUTCDateToDisplay(selectedCheckList?.createdAt, formatDateOptions['MMM, d, yyyy'])}
                            displayOption={DisplayOptions.secondary}
                          />

            </View>
            <View style={{zIndex: 1, marginTop: 24}}>
                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Go to checklist'}
                                   onPress={goToChecklist}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}

                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Close'}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>
            </> : null}
        </View>
    );
};

export {ViewWOChecklists};

const styles = StyleSheet.create({
    listItem: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-start',
    },
});

