import React, { useRef, useMemo, useState, useCallback } from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import {
    MB_TextInput,
    MB_TextInputRef,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { Text, View } from 'react-native';
import { applyTTStyles, getColors } from '../../../constants/colors';
import { BOX_SHADOW, sharedStyles } from '../../../utils/styleUtils';
import { inputComponentStyles, WrappedInput } from '../../helperComponents/misc/WrappedInput';
import { STRING_CONSTANTS } from '../../../constants/constants';
import { MB_passwordUtils, MB_PASSWORD_UTIL_ERROR_TYPES } from '@mightybyte/rnw.utils.password-utils';
import {getStyles} from './ForgotPasswordStyles';
import { MB_PasswordToolTip, TOOLTIP_ARROW_DIRECTION, TOOLTIP_POSITION } from '@mightybyte/rnw.components.tool-tip';
import { Spacer } from '../../helperComponents/misc/Spacer';
import { utils } from '../../../utils/utils';
import {navigateToSignIn} from './utils';
import { PasswordRecoveryToken } from '../../../typesAndInterfaces/typesAndInterfaces';
import {trpc} from '../../../apiCalls/trpcClient';
import {SERVER_ERROR_CODES} from '../../../constants/constants';
import { SegmentedInput } from '../../helperComponents/misc/SegmentedInput';
import { usePhoneNumber } from '../../../hooks/usePhoneNumber';
import { OutlinedButton } from '../../helperComponents/misc/OutlinedButton';
import {isMobileApp} from '@mightybyte/rnw.utils.device-info';
import {MB_customWindowForWeb} from '@mightybyte/rnw.utils.custom-window-for-web';
import {envs, createWebsiteBaseUrl} from '../../../../env';
import {navRef} from '../../../navigations/RootNavigator';

enum INPUT_TYPES {
    password = 'password',
    repeatPassword = 'repeatPassword',
    code = 'code',
    phoneNumber = 'phoneNumber',
}

const codeReducer = (state: string[], action: { type: 'set', value: {stringValue: string, index: number} }) => {
    const newState = [...state];
    newState[action.value.index] = action.value.stringValue;
    return newState;
};
const codeLength = 6;

const ResetPassword = ({subdomain, passwordRecoveryToken, isInitialSetup, isRegisterBySMSCode}: {
    subdomain: string
    passwordRecoveryToken: PasswordRecoveryToken | undefined,
    isInitialSetup?: boolean,
    isRegisterBySMSCode?: boolean,
}) => {

    const token = passwordRecoveryToken?.token;
    const COLORS = getColors();
    const shouldUseTTStyles = utils.isTTEmployee(subdomain);
    const styles = useMemo(() => getStyles(COLORS), [COLORS]);
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [inputError, setInputError] = useState<INPUT_TYPES[]>([]);

    const [code, dispatchCode] = React.useReducer(codeReducer, new Array(codeLength).fill(''));
    const phoneNumber = usePhoneNumber({
        isRequired: true,
        label: 'Cell phone number',
    });

    const repetPasswordRef = useRef<MB_TextInputRef>(null);

    const [successReset, setSuccessReset] = useState(false);

    const {mutate: changePasswordRecovery, isLoading} = trpc.user.changePasswordViaRecoveryToken.useMutation({ trpc: { context: { skipBatch: true } } });
    const {mutate: changePasswordViaRecoveryCode, isLoading: isLoadingRecoveryCode} = trpc.user.changePasswordViaRecoveryCode.useMutation({ trpc: { context: { skipBatch: true } } });
    const generatePasswordErrorArray = (errorArray: MB_PASSWORD_UTIL_ERROR_TYPES[], doPasswordsMatch: boolean): { message: string, isError: boolean }[] => {
        const errors: { message: string, isError: boolean }[] = [];

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.min, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.min) || errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.max) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.lowercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.lowercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.uppercase, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.uppercase) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.digits, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.digits) });
        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.spaces, isError: errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.spaces) });
        if (errorArray.includes(MB_PASSWORD_UTIL_ERROR_TYPES.unknown)) {
            errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.unknown, isError: true });
        }

        errors.push({ message: MB_PASSWORD_UTIL_ERROR_TYPES.passwordMismatch, isError: !doPasswordsMatch });

        return errors;
    };
    const [mainPasswordErrorArray, setMainPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));
    const [repeatPasswordErrorArray, setRepeatPasswordErrorArray] = useState<{ message: string, isError: boolean }[]>(generatePasswordErrorArray(MB_passwordUtils.validatePassword('').errorArray, true));


    const onPasswordSubmit = () => {
        setErrorMessage('');
        setInputError([]);

        const validatePassword = MB_passwordUtils.validatePassword(password);
        if (validatePassword.errorMessage) {
            setErrorMessage(validatePassword.errorMessage);
            setInputError([INPUT_TYPES.password]);
            return;
        }

        // const validateRepeatPassword = MB_passwordUtils.validatePassword(repeatPassword);
        // if (validateRepeatPassword.errorMessage) {
        //     setErrorMessage(validateRepeatPassword.errorMessage);
        //     setInputError([INPUT_TYPES.repeatPassword]);
        //     return;
        // }

        if (password !== repeatPassword) {
            setErrorMessage(STRING_CONSTANTS.PASSWORDS_DO_NOT_MATCH);
            setInputError([INPUT_TYPES.password, INPUT_TYPES.repeatPassword]);
            return;
        }


        if (isRegisterBySMSCode) {
            const phoneNumberIsValid = phoneNumber.validate();
            if (!phoneNumberIsValid) {
                setInputError([INPUT_TYPES.phoneNumber]);
                return;
            }

            const codeString = code.join('');
            if (codeString.length !== 6) {
                setErrorMessage(STRING_CONSTANTS.INVALID_CODE);
                setInputError([INPUT_TYPES.code]);
                return;
            }
            changePasswordViaRecoveryCode({newPassword: password, recoveryCode: codeString, phoneNumber: phoneNumber.value}, {
                onSuccess: () => {
                    setSuccessReset(true);
                },
                onError: (error: any) => {
                    if (error?.errorCode === SERVER_ERROR_CODES.INVALID_PASSWORD) {
                        setErrorMessage(STRING_CONSTANTS.CURRENT_PASSWORD_WAS_INVALID);
                        setInputError([INPUT_TYPES.password]);
                    } else {
                        setErrorMessage(utils.getErrorForDisplay(error));
                    }
                },
            });

        } else {
            if (!token) {
                return console.error('No token provided');
            }
            changePasswordRecovery({newPassword: password, recoveryToken:token}, {
                    onSuccess: () => {
                        setSuccessReset(true);
                    },
                    onError: (error: any) => {
                        if (error?.errorCode === SERVER_ERROR_CODES.INVALID_PASSWORD) {
                            setErrorMessage(STRING_CONSTANTS.CURRENT_PASSWORD_WAS_INVALID);
                            setInputError([INPUT_TYPES.password]);
                        } else {
                            setErrorMessage(utils.getErrorForDisplay(error));
                        }
                    },
                }
            );
        }
    };

    const getStrings = useMemo(()=> {
        let header = successReset ? 'Password successfully changed' : 'Reset password';
        let helperText = successReset ? 'Your password has been successfully updated. You can now sign in with your new password.' : 'Enter your new password below';
        let buttonText = successReset ? 'Return to sign-in' : 'Reset password';

        if (isInitialSetup || isRegisterBySMSCode){
            return {
                header: successReset ? 'Password successfully set' : 'Set password',
                helperText: successReset ? 'Your password has been successfully set' : 'Enter your password below',
                buttonText: successReset ? 'Go to sign in' : 'Set password',
            };
        }
        return {
            header,
            helperText,
            buttonText,
        };
    }, [successReset, isInitialSetup, isRegisterBySMSCode]);

    const {header, helperText, buttonText} = getStrings;

    const inputHasError = useCallback((inputType: INPUT_TYPES) => {
        return inputError.includes(inputType);
    }, [inputError]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    const backToLogin = ()=> {
        if (subdomain === envs.TEMPTRACK_SUBDOMAIN) {
            if (isMobileApp && navRef.current?.isReady()) {
               navRef.current?.navigate('Login');
            } else {
                const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
                MB_customWindowForWeb.open(`${baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN)}/login`, '_self');
            }
        } else {
            if (isMobileApp) {
                navRef.current?.navigate('EnterUrl');
                return;
            } else {
                const baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
                MB_customWindowForWeb.open(`${baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN)}/enterUrl`, '_self');
            }
        }

    };

    const submitDisabled = useMemo(() => {
        if (successReset){
            return false;
        } else {
            return !password || !repeatPassword || (isLoading || isLoadingRecoveryCode);
        }
    } , [password, repeatPassword, isLoading, successReset, isLoadingRecoveryCode]);

    return (
        <View style={styles.resetPasswordContainer}>
            <View style={[styles.subContainer, styles.subContainerPlatformStyles]}>
                <Text style={[styles.forgotPwdText, styles.forgotPwdPlatformText]}>{header}</Text>
                <View style={styles.textContainer}>
                    <Text style={[styles.helperText, styles.helperTextPlatformStyles]}>{helperText}</Text>
                </View>

                {successReset ? null : <>
                <WrappedInput errorMessage={''}
                              showRequiredAsterisk={true}
                              title={`${isInitialSetup ? 'Password' : 'New password'}`}
                              inputComponent={
                                  <MB_TextInput
                                      autoCapitalize={'none'}
                                      isError={inputHasError(INPUT_TYPES.password)}
                                      {...inputComponentStyles(inputHasError(INPUT_TYPES.password))}
                                      value={password}
                                      onChangeText={(newText: string) => {
                                          setPassword(newText);
                                          setErrorMessage('');
                                          setInputError([]);
                                          setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === repeatPassword));
                                      }}
                                      onFocus={() => {
                                          setMainPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(password).errorArray, repeatPassword === password));
                                      }}
                                      placeholder={`Enter your ${isInitialSetup ? '' : 'new'} password`}

                                      textContentType="password"
                                      maxLength={256}
                                      secureTextEntry
                                      wrapInFormElement
                                      toolTipData={{
                                          showOnFocus: true,
                                          toolTipPosition: TOOLTIP_POSITION.left,
                                          moveBy: {
                                              x: 380,
                                              y: 150,
                                          },
                                          toolTipElement: <MB_PasswordToolTip errorArray={mainPasswordErrorArray}
                                                                              arrowDirection={TOOLTIP_ARROW_DIRECTION.right}
                                                                              containerStyle={{ marginTop: -150, ...BOX_SHADOW }} />,
                                          portalRef: portalRef,
                                      }}
                                      nextToFocus={repetPasswordRef}
                                  />
                              }
                />
                <WrappedInput errorMessage={''}
                              showRequiredAsterisk={true}
                              title={'Confirm password'}
                    inputComponent={
                        <MB_TextInput
                            autoCapitalize={'none'}
                            MB_Ref={repetPasswordRef}
                            isError={inputHasError(INPUT_TYPES.repeatPassword)}
                            {...inputComponentStyles(inputHasError(INPUT_TYPES.repeatPassword))}
                            value={repeatPassword}
                            onChangeText={(newText: string) => {
                                setRepeatPassword(newText);
                                setErrorMessage('');
                                setInputError([]);
                                setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(newText).errorArray, newText === password));
                            }}
                            onFocus={() => {
                                setRepeatPasswordErrorArray(generatePasswordErrorArray(MB_passwordUtils.validatePassword(repeatPassword).errorArray, repeatPassword === password));
                            }}
                            placeholder={'Re-enter your new password'}

                            textContentType="password"
                            maxLength={256}
                            secureTextEntry
                            wrapInFormElement
                            toolTipData={{
                                showOnFocus: true,
                                toolTipPosition: TOOLTIP_POSITION.left,
                                moveBy: {
                                    x: 380,
                                    y: 150,
                                },
                                toolTipElement: <MB_PasswordToolTip errorArray={repeatPasswordErrorArray}
                                                                    arrowDirection={TOOLTIP_ARROW_DIRECTION.right}
                                                                    containerStyle={{ marginTop: -150, ...BOX_SHADOW }} />,
                                portalRef: portalRef,
                            }}
                            onSubmitEditing={successReset ? ()=> navigateToSignIn(shouldUseTTStyles, subdomain) : onPasswordSubmit}
                        />
                    }
                />
                {isRegisterBySMSCode ? <View>
                        {phoneNumber.field}
                    <WrappedInput
                        inputComponent={
                        <SegmentedInput title={'Registration code'}
                                        onChange={(value, index) => {
                                            dispatchCode({type: 'set', value: {stringValue: value, index}});
                                        }}
                                        showRequiredAsterisk={true}
                        />}
                    />
                </View> : null}
                </>}

                <Text style={styles.errorMessage}>{errorMessage}</Text>
                <MB_TextInputToolTipPortal MB_Ref={portalRef} />

                <Spacer y={18}/>

            <MB_Button
                title={buttonText}
                onPress={successReset ? ()=> navigateToSignIn(shouldUseTTStyles, subdomain) : onPasswordSubmit}
                style={[styles.submitButton, {backgroundColor: applyTTStyles(shouldUseTTStyles).primaryButtonBg}, submitDisabled ? sharedStyles.disabledButton : null]}
                preventDisabledStyleChange={true}
                disabled={submitDisabled}
                loading={isLoading}
            />
                <Spacer y={12}/>

                {successReset ? null : <OutlinedButton title={'Back to login'}
                                action={backToLogin}
                                 extraStyles={styles.submitButton}
                />}

        </View>
        </View>

    );
};

export { ResetPassword };
