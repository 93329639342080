import React, { ReactNode, useCallback, useMemo } from 'react';
import { createDrawerNavigator, DrawerContentComponentProps } from '@react-navigation/drawer';
import { BriefcaseIconSvg } from '../../resources/svgComponents/BriefcaseIconSvg';
import { UserIconSvg } from '../../resources/svgComponents/UserIconSvg';
import { ThreePersonIconSvg } from '../../resources/svgComponents/ThreePersonIconSvg';
import { DocumentReportIconSvg } from '../../resources/svgComponents/DocumantReportIconSvg';
import { ClipboardListIconSvg } from '../../resources/svgComponents/ClipboardListIconSvg';
import { RegionsIconSvg } from '../../resources/svgComponents/RegionsIconSvg';
import {BranchesIconSvg} from '../../resources/svgComponents/BranchesIconSvg';
import {PersonIconSvg} from '../../resources/svgComponents/PersonIconSvg';
import { applyTTStyles, getColors } from '../../constants/colors';
import { Users } from '../../components/screens/CompanyManagement/Users/Users';
import { Employees } from '../../components/screens/CompanyManagement/Employees/Employees';
import { Workorders } from '../../components/screens/CompanyManagement/WorkOrders/WorkOrders';
import { Checklists } from '../../components/screens/CompanyManagement/Checklists/Checklists';
import { Clients } from '../../components/screens/CompanyManagement/Clients/Clients';
import { Branches } from '../../components/screens/CompanyManagement/Branches/Branches';
import { Regions } from '../../components/screens/CompanyManagement/Regions/Regions';
import {EmployeesJobManagement} from '../../components/screens/CompanyManagement/EmployeeJobManagement/EmployeeJobsManagement';
import { CustomDrawerUI} from '../CustomDrawerUI';
import { useCurrentCompanyContext } from '../../context/CurrentCompanyContext';
import { useSignedInContext } from '../../context/SignedInContext';
import { EmployeeWorkOrderType, UserDataForClient, UserRole } from '@temptrack/business';
import { LoadingPage } from '../../components/helperComponents/LoadingPage';
import { PaginationRouteParams } from '../../hooks/usePagination';
import { CheckOrShortListView } from '../../components/screens/CompanyManagement/ViewChecklist';
import { BOX_SHADOW } from '../../utils/styleUtils';
import {getCurrentRoute} from '../utils';
import { ChecklistViewMode, CompanyDashboardRoutes, SharedRouteParams } from '../types';
import { getVersionNumber } from '../../utils/getVersionNumber/getVersionNumber';
import { trpc } from '../../apiCalls/trpcClient';

export type CompanyDashboardNavigatorParamList = {
    dashboard: { companyId: string}
    [CompanyDashboardRoutes.users]: PaginationRouteParams & { companyId: string, userId?: string, changePassword?: boolean} & SharedRouteParams,
    [CompanyDashboardRoutes.employees]: PaginationRouteParams & { companyId: string, employeeId?: string} & {filter?: string} & SharedRouteParams,
    [CompanyDashboardRoutes.workOrders]: PaginationRouteParams & { companyId: string, workorderId?: string, viewChecklists?: boolean, duplicateId?: string} & SharedRouteParams,
    [CompanyDashboardRoutes.checkLists]: PaginationRouteParams & { companyId: string, new?: boolean, viewMode?: ChecklistViewMode} & SharedRouteParams,
    [CompanyDashboardRoutes.viewChecklist]: PaginationRouteParams & { companyId: string, checklistId: string, viewMode?: ChecklistViewMode} & SharedRouteParams,
    [CompanyDashboardRoutes.regions]: PaginationRouteParams & {companyId: string, regionId?: string} & SharedRouteParams,
    [CompanyDashboardRoutes.branches]:PaginationRouteParams &  {companyId: string, branchId?: string} & SharedRouteParams,
    [CompanyDashboardRoutes.clients]: PaginationRouteParams & {companyId: string, clientId?: string} & SharedRouteParams,
    [CompanyDashboardRoutes.employeeJobs]: PaginationRouteParams & {companyId: string, workorderId?: string, checklistId?: string, viewMode: EmployeeWorkOrderType} & SharedRouteParams,
}

const COLORS = getColors();

const Drawer = createDrawerNavigator<CompanyDashboardNavigatorParamList>();


export function GetCompanyDrawerIcon(routeName: CompanyDashboardRoutes | 'Company', focused: boolean) {
    const iconProps = {
        color: focused ? COLORS.drawerActiveIcon : undefined,
        containerStyle: {marginRight: 4},
    };
    switch (routeName) {
        case 'Company':
        case CompanyDashboardRoutes.employeeJobs:
            return <BriefcaseIconSvg {...iconProps}/>;

        case CompanyDashboardRoutes.users:
            return <UserIconSvg {...iconProps}/>;
        case CompanyDashboardRoutes.employees:
            return <ThreePersonIconSvg {...iconProps}/>;
        case CompanyDashboardRoutes.workOrders:
            return <DocumentReportIconSvg {...iconProps}/>;
        case CompanyDashboardRoutes.checkLists:
            return <ClipboardListIconSvg {...iconProps}/>;
       case CompanyDashboardRoutes.regions:
            return <RegionsIconSvg {...iconProps}/>;
        case CompanyDashboardRoutes.branches:
            return <BranchesIconSvg {...iconProps}/>;
        case CompanyDashboardRoutes.clients:
            return <PersonIconSvg {...iconProps} size={18}/>;
        default:

            return null;
    }
}

export enum navigatorTypes {
    CustomerDashboardNavigator = 'CustomerDashboardNavigator',
    CompanyDashboardNavigator = 'CompanyDashboardNavigator',
}

type DrawerProps = DrawerContentComponentProps & {
    navigatorType: navigatorTypes,
    header?: string
    currentUserData: UserDataForClient | undefined,
    serverVersion: string | undefined,
};

type Tab = {
    name: string,
    routeName:  CompanyDashboardRoutes | 'Company',
    label: string,
    subRoutes?: Tab[],
}
export const getUserRoutes = (role: UserRole):Tab[] => {
    if (role === UserRole.employee) {
        return [{
            name: 'Jobs Management', routeName: CompanyDashboardRoutes.employeeJobs,
            label: 'Jobs Management'},
        ];
    }
    if (role === UserRole.client) {
        return [
            {name: 'Work Orders Management', routeName: CompanyDashboardRoutes.workOrders, label: 'Work Orders'},
            {name: 'CheckLists Overview', routeName: CompanyDashboardRoutes.checkLists, label: 'CheckLists'},
        ];
    }
    if (role === UserRole.recruiter) {
        return [ {name: 'Clients', routeName: CompanyDashboardRoutes.clients, label: 'Clients'},
            {name: 'Employees Management', routeName: CompanyDashboardRoutes.employees, label: 'Employees'},
            {name: 'Work orders Management', routeName: CompanyDashboardRoutes.workOrders, label: 'Work Orders'},
            {name: 'Checklists Management', routeName: CompanyDashboardRoutes.checkLists, label: 'CheckLists'}];
    }
    const companySubTabs = () => {
        if (role === UserRole.regionAdmin) {
            return [
                {name: 'Branches', routeName: CompanyDashboardRoutes.branches, isSubroute: true, label: 'Branches'},
                {name: 'Clients', routeName: CompanyDashboardRoutes.clients, isSubroute: true, label: 'Clients'},
            ];
        }
        return [
            {name: 'Regions', routeName: CompanyDashboardRoutes.regions, isSubroute: true, label: 'Regions'},
            {name: 'Branches', routeName: CompanyDashboardRoutes.branches, isSubroute: true, label: 'Branches'},
            {name: 'Clients', routeName: CompanyDashboardRoutes.clients,  isSubroute: true, label: 'Clients'},
        ];
    };
    return [
        {name: 'Users Management', routeName: CompanyDashboardRoutes.users, label: 'Users'},
        {name: 'Company Management', routeName: 'Company', subRoutes: companySubTabs(), label: 'Company'},
        {name: 'Employees Management', routeName: CompanyDashboardRoutes.employees, label: 'Employees'},
        {name: 'Work orders Management', routeName: CompanyDashboardRoutes.workOrders, label: 'Work Orders'},
        {name: 'Checklists Management', routeName: CompanyDashboardRoutes.checkLists, label: 'CheckLists'},
    ];
};

const CustomDrawerContent = (props: DrawerProps)=> {

    const { navigation, navigatorType, currentUserData} = props;

    const activeRoute = getCurrentRoute(props.navigation.getState());


    const [companiesExpanded, setCompaniesExpanded] = React.useState(false);

    const tabs = useMemo<{
        name: string,
        routeName: CompanyDashboardRoutes | 'Company',
        subTabs?: {name: string, routeName: CompanyDashboardRoutes | 'Company'}[],
        isExpanded?: boolean,
        onPress?: () => void
    }[]>(() => {
        if (!currentUserData) {return [];}

        return getUserRoutes(currentUserData.role).map((route) => {
            const tabData = {
                name: route.name,
                routeName: route.routeName,
                subTabs: route.subRoutes ? route.subRoutes.map((subRoute) => {
                    return { name: subRoute.name, routeName: subRoute.routeName };
                }) : undefined,
            };
            if (route.routeName === 'Company') {
                return {...tabData, isExpanded: companiesExpanded};
            }
            return tabData;
        });
    }, [companiesExpanded, currentUserData]);


    const navigateTo = useCallback((routeName: CompanyDashboardRoutes | 'Company') => {
        if (routeName === 'Company') {
            setCompaniesExpanded(!companiesExpanded);
            return;
        }

        navigation.navigate(navigatorType, {screen: routeName});

    }, [navigation, navigatorType, setCompaniesExpanded, companiesExpanded]);

    const tabIsActive = useCallback((tab) => {
        if (!activeRoute) {return false;}
        if (tab.subTabs) {
            return tab.subTabs.some((subTab:Tab) => subTab.routeName === activeRoute);
        }
        return activeRoute === tab.routeName;
    }, [activeRoute]);

    return (
        <CustomDrawerUI header={props?.header ?? ''}
                        versions={{...versions, ...{
                            serverVersion: props.serverVersion,
                        }}}
                        regionName={currentUserData?.regionName}
                        branchName={currentUserData?.branchName}
                        tabs={
            tabs.map((tab) => {
                const isActive = tabIsActive(tab);
                return {
                    ...tab,
                    isActive: isActive,
                    icon: GetCompanyDrawerIcon(tab.routeName, isActive),
                    onPress: () => {
                        navigateTo(tab.routeName);
                    },
                    subTabs: tab.subTabs ? tab.subTabs.map((subTab) => {
                        const subIsActive = tabIsActive(subTab);
                        return {
                            ...subTab, isActive: subIsActive,
                            icon: GetCompanyDrawerIcon(subTab.routeName, subIsActive),
                            onPress: () => navigateTo(subTab.routeName),
                        };

                    }) : undefined,
                };
            })
        }/>
    );
};

const versions = getVersionNumber();
const CompanyDashboardNavigator = () => {
    const {currentCompanyName, currentCompanyId } = useCurrentCompanyContext();
    const { data: serverVersion} = trpc.util.getVersion.useQuery(undefined, { retry: 2, refetchOnWindowFocus: false });

    const {isTTEmployee, currentUserData} = useSignedInContext();
    const drawerContent = useCallback((props: DrawerContentComponentProps) =>{
        return <CustomDrawerContent {...props}
                                    serverVersion={serverVersion?.version}
                                    header={currentCompanyName}
                                    currentUserData={currentUserData}
                                    navigatorType={isTTEmployee ? navigatorTypes.CompanyDashboardNavigator : navigatorTypes.CustomerDashboardNavigator}
        />;
    }, [currentCompanyName, isTTEmployee, currentUserData, serverVersion]);
    function renderCompanyRoutes():ReactNode {
        const role = currentUserData?.role;
        if (role === UserRole.employee) {
            return (
                <>
                    <Drawer.Screen name={CompanyDashboardRoutes.employeeJobs} component={EmployeesJobManagement} initialParams={{companyId: currentCompanyId, viewMode: EmployeeWorkOrderType.active}}/>
                </>
            );
        }
        if (role === UserRole.client) {
            return (
                <>
                    <Drawer.Screen name={CompanyDashboardRoutes.workOrders} component={Workorders} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.checkLists} component={Checklists} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.viewChecklist} component={CheckOrShortListView} initialParams={{companyId: currentCompanyId}}/>
                </>
            );
        }
        if (role === UserRole.recruiter) {
            return (
                <>
                    <Drawer.Screen name={CompanyDashboardRoutes.workOrders} component={Workorders} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.clients} component={Clients} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.employees} component={Employees} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.checkLists} component={Checklists} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.viewChecklist} component={CheckOrShortListView} initialParams={{companyId: currentCompanyId}}/>
                </>
            );
        }
        if (role === UserRole.regionAdmin) {
            return (
                <>
                    <Drawer.Screen name={CompanyDashboardRoutes.users} component={Users} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.branches} component={Branches} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.clients} component={Clients} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.employees} component={Employees} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.workOrders} component={Workorders} initialParams={{companyId: currentCompanyId}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.checkLists} component={Checklists} initialParams={{companyId: currentCompanyId, timeline: undefined}}/>
                    <Drawer.Screen name={CompanyDashboardRoutes.viewChecklist} component={CheckOrShortListView} initialParams={{companyId: currentCompanyId}}/>
                </>
            );
        }
        return (
            <>
                <Drawer.Screen name={CompanyDashboardRoutes.users} component={Users} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.branches} component={Branches} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.clients} component={Clients} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.regions} component={Regions} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.employees} component={Employees} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.workOrders} component={Workorders} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.viewChecklist} component={CheckOrShortListView} initialParams={{companyId: currentCompanyId}}/>
                <Drawer.Screen name={CompanyDashboardRoutes.checkLists} component={Checklists} initialParams={{companyId: currentCompanyId}}/>

            </>
        );
    }

    // const drawer = useDrawerContext();

    if (!currentCompanyId || !currentUserData) {
        return <LoadingPage  barColor={applyTTStyles(false).loaderColor}/>;
    }

    return (
        <Drawer.Navigator
            screenOptions={{
                headerShown: false,
                drawerType: 'front',
                drawerStyle: {
                    ...BOX_SHADOW,
                    width: 340,
                },
            }}

            drawerContent={(props)=> drawerContent(props)}
            backBehavior={'history'}
        >
            {renderCompanyRoutes()}

        </Drawer.Navigator>
    );
};

export { CompanyDashboardNavigator };

