import React, { useCallback, useMemo } from 'react';
import {StyleSheet, FlatList, View, ListRenderItemInfo} from 'react-native';
import { COLORS } from '../../../../../constants/colors';
import { BOX_SHADOW } from '../../../../../utils/styleUtils';
import { utilHooks } from '../../../../../hooks/utilHooks';
import { useSignedInContext } from '../../../../../context/SignedInContext';
import { ChecklistEmployeeTableData, EmployeeUpdateCheckInData } from '../types';
import { ChecklistEmpolyeeRow } from './ChecklistEmployeeRow';
import { EmptyTableView } from '../../../../helperComponents/EmptyTableView';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const CheckListTableUI = ({data, isEditing, updateCheckinData, openComment, footerComponent, headerComponent}:{
    data: ChecklistEmployeeTableData[];
    isEditing: boolean;
    updateCheckinData: (data: EmployeeUpdateCheckInData)=> void;
    openComment: (employee: ChecklistEmployeeTableData)=> void;
    headerComponent?: JSX.Element;
    footerComponent?: JSX.Element;

}) => {
    const {isClient} = useSignedInContext();

    const tableHeight = utilHooks.useTableHeight();
    const renderItem = useCallback(({ item}: ListRenderItemInfo<ChecklistEmployeeTableData>)=> {
        return <ChecklistEmpolyeeRow employeeCheckinInfo={item}
                                     updateCheckinData={updateCheckinData}
                                     isClient={isClient}
                                     isEditing={isEditing}
                                     openComment={openComment}
                                     key={isEditing.toString()}
        />;
    },[isClient, isEditing, updateCheckinData, openComment]);

    const keyExtractor = useCallback((item:ChecklistEmployeeTableData, index)=> `${item?.id}-${index}`, []);

    const ListEmptyComponent = useMemo(()=> {
        return (
            <EmptyTableView onAddPress={()=>{}}
                            title={'No Employees assigned'}
                            hideAddButton={true}
            />
        );
    },[]);

    const separator = useMemo(()=> {
       return <View style={styles.separator}/>;
    },[]);

    const wrapperHeight = useMemo(()=> {
    const footerHeight = isMobileApp ? 0 : 90;
    return tableHeight - footerHeight;
    },[tableHeight]);


    return (
        <View style={[styles.container,{maxHeight: wrapperHeight, height: wrapperHeight}]}>
          <FlatList data={[...data]}
                    renderItem={renderItem}
                    keyExtractor={keyExtractor}
                    ListEmptyComponent={ListEmptyComponent}
                    ItemSeparatorComponent={()=>separator}
                    ListFooterComponent={isMobileApp  ? footerComponent : undefined}
                    ListHeaderComponent={isMobileApp ? headerComponent : undefined}
                    showsVerticalScrollIndicator={!isMobileApp}

          />
        </View>
    );

};

export {CheckListTableUI};

const styles = StyleSheet.create({
    container: {
        paddingHorizontal: 16,
        paddingVertical: 6,
        backgroundColor: COLORS.backgroundPrimary,
        ...BOX_SHADOW,
        width: '100%',
    },
    separator: {
        height: 1,
        backgroundColor: COLORS.inputBorder,
    },
});
