import React from 'react';
import {StyleSheet, View, Text} from 'react-native';
import {getColors} from '../../constants/colors';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';

const COLORS = getColors();

const CopyRightFooter = () => {

    return (
        <View style={[styles.container]}>
            <Text style={styles.text}>{'© 2023 TempTrack. All rights reserved.'}</Text>
        </View>
    );
};

const footerHeight = 48;

const copyRightFooterHeight = footerHeight;

export {CopyRightFooter, copyRightFooterHeight};

const styles = StyleSheet.create({
    container: {
        width: '100%',
        backgroundColor: COLORS.backgroundPrimary,
        justifyContent: 'center',
        alignItems: 'flex-start',
        paddingVertical: 24,
        paddingHorizontal: 16,
        height: footerHeight,
    },
    text: mbTextStyles([
        textStyles.base, {
            color: COLORS.textSecondary,
            fontSize: 14,
            fontWeight: '400',
        },
    ]),
});
