import {
    CheckInsStatus,
    ChecklistActivityStatus,
    ChecklistAssignmentStatus,
    ShortlistAcceptanceStatus,
} from '../../entities';
import {
    filterDateSchema,
    filterDropdownSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '..';
import { FilterFieldType } from '..';
import { createFilterMapping } from './helpers';

export const ChecklistFilterMap = createFilterMapping({
    number: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'number',
    },
    clientId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'clientId',
    },
    activityStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(ChecklistActivityStatus),
        dbField: 'activityStatus',
    },
    assignmentStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(ChecklistAssignmentStatus),
        dbField: 'assignmentStatus',
    },
    checkInsStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(CheckInsStatus),
        dbField: 'checkInsStatus',
    },
    date: {
        filterFieldType: FilterFieldType.date,
        filterFieldSchema: filterDateSchema,
        dbField: 'dateString',
    },
});

export const ShortlistFilterMap = createFilterMapping({
    number: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'number',
    },
    clientId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'clientId',
    },
    activityStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(ChecklistActivityStatus),
        dbField: 'activityStatus',
    },
    assignmentStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(ChecklistAssignmentStatus),
        dbField: 'assignmentStatus',
    },
    acceptanceStatus: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(ShortlistAcceptanceStatus),
        dbField: 'shortlistAcceptanceStatus',
    },
    date: {
        filterFieldType: FilterFieldType.date,
        filterFieldSchema: filterDateSchema,
        dbField: 'dateString',
    },
});
