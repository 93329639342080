import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ViewChecklistProps } from '../../../../../typesAndInterfaces/componentProps';
import { UIDrawerRightContentWrapper } from '../../../../helperComponents/DrawerRightContentWrapper';
import { appColors, COLORS } from '../../../../../constants/colors';
import { ListViewHeader } from '../../../../helperComponents/ListViewHeader';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { ModalWrapper } from '../../../../helperComponents/misc/ModalWrapper';
import { AddEmployees } from './AddEmployees';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../../context/CurrentCompanyContext';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { ShortlistEmployeeTableData } from '../types';
import { DisplayOptions, ValueRow } from '../../../../helperComponents/misc/ValueRow';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { ShortListTableUI } from './ShortlistTableUI';
import { ListInfoBlockUI } from '../ListInfoBlockUI';
import { EmployeeAcceptanceStatus, ShortlistAcceptanceStatus, WorkOrderPosition } from '@temptrack/business';
import { RecordTypes } from '../../../../helperComponents/misc/StatusRenderer';
import { OutlinedButton } from '../../../../helperComponents/misc/OutlinedButton';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { utils } from '../../../../../utils/utils';
import { ChecklistViewMode } from '../../../../../navigations/types';


const ViewShortlist = ({navigation, route}:ViewChecklistProps) => {
    const trpcContext = trpc.useUtils();

    const checklistId = route.params?.checklistId;

    const {currentCompanyId} = useCurrentCompanyContext();

    const [employeeAcceptanceStatusChange, setEmployeeAcceptanceStatusChange] = useState<Record<string, EmployeeAcceptanceStatus>>({});

    const toggleEmployeeAcceptanceStatus = useCallback((employeeId: string, status: EmployeeAcceptanceStatus | undefined)=> {
        if (!status) {
            setEmployeeAcceptanceStatusChange((prev)=> {
                const newChange = {...prev};
                delete newChange?.[employeeId];
                return newChange;
            });
            return;
        }
        setEmployeeAcceptanceStatusChange((prev)=> {
            return {...prev, [employeeId]: status};
        });
    },[setEmployeeAcceptanceStatusChange]);

    const [markedForDelete, setMarkedForDelete] = useState<string[]>([]);

    const toggleMarkedForDelete = useCallback((id: string)=> {
        setMarkedForDelete(m=>{
            if (m.includes(id)) {

                return m.filter((item)=> item !== id);
            }
            toggleEmployeeAcceptanceStatus(id, undefined);
            return [...m, id];
        });
    },[setMarkedForDelete, toggleEmployeeAcceptanceStatus]);

    const [showAddNewEmployeesPopup, setShowAddNewEmployeesPopup] = useState(false);


    const [submitError, setSubmitError] = useState<string>('');

    const {data: currentShortlist, isLoading: isShortlistLoading} = trpc.company.shortlist.getById.useQuery({
        shortlistId: checklistId ?? '', companyId: currentCompanyId ?? ''}, {
        enabled: !!checklistId && !!currentCompanyId,
    });


    const [manageEmployees, setManageEmployees] = useState(false);


    useEffect(() => {
        setSubmitError('');
    }, [manageEmployees, setSubmitError, showAddNewEmployeesPopup]);

    const addButtonText = useMemo(()=> {
        return manageEmployees ? 'Save changes' : 'Approve';
    },[manageEmployees]);

    const subtitleText = useMemo(()=> {
        return 'Manage employees and view accepted/declined offers';
    },[]);

    const subTitleComponent = useMemo(()=> {
        return <ValueRow label={'Client'} value={currentShortlist?.shortlistData?.clientName}
                         displayOption={DisplayOptions.secondary}
                         containerStyles={{marginLeft: 44}}
                         fontSize={16}
        />;
    },[currentShortlist?.shortlistData]);

    const tableKey = useMemo(()=> {
        const current = currentShortlist?.shortlistData?.assignedTo?.map((item)=> item.employeeId) ?? [];
        return current.join('');
    },[currentShortlist?.shortlistData]);


    const shortListEmployees = useMemo<ShortlistEmployeeTableData[]>(()=> {
        const data = currentShortlist?.shortlistData;
        if (!data) {
            return [];
        }
        const assignedTo = data.assignedTo ?? [];

        return assignedTo.map((item)=> {
            return {...item};
        });
    },[currentShortlist?.shortlistData]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const DateIteratorComponent = useMemo(()=> {
   return null;
    },[]);

    const title = useMemo(()=> {
        return `Shortlist #${currentShortlist?.shortlistData?.number ?? ''}`;

    }, [currentShortlist]);


    const extraHeaderComponent = useMemo(()=> {
       return null;
    }, []);

    const resetView = useCallback(()=> {
    },[]);

    const {mutate: createChecklist, isLoading: creatingChecklist} = trpc.company.checklist.createChecklist.useMutation({
        onSuccess: ()=> {
            trpcContext.company.shortlist.getById.invalidate({
                shortlistId: checklistId ?? '', companyId: currentCompanyId ?? '',
            });
            if (isMobileApp) {
               trpcContext.company.shortlist.list.invalidate({
                     companyId: currentCompanyId ?? '',
               });
               trpcContext.company.checklist.list.invalidate({
                     companyId: currentCompanyId ?? '',
               });
            } else {
                trpcContext.company.shortlist.getPage.invalidate({
                    companyId: currentCompanyId ?? '',
                });
                trpcContext.company.checklist.getPage.invalidate({
                    companyId: currentCompanyId ?? '',
                });
            }

            setTimeout(()=> {
                navigation.setParams({viewMode: ChecklistViewMode.checkList});
                mbShowToast({ text1: 'Checklist created successfully.',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (error)=> {
            mbShowToast({
                text1: 'Error creating checklist.',
                type: 'error',
                text2: utils.getErrorForDisplay(error),
            });
        },
    });

    const {mutate: updateShortlist, isLoading: updatingShortlist} = trpc.company.shortlist.assignEmployees.useMutation({
        onSuccess: ()=> {

            trpcContext.company.shortlist.getById.invalidate({
                shortlistId: checklistId ?? '', companyId: currentCompanyId ?? '',
            });
            setMarkedForDelete([]);
            setEmployeeAcceptanceStatusChange({});
            setTimeout(()=> {
                setManageEmployees(false);
                mbShowToast({ text1: 'Shortlist updated successfully.',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (error)=> {
            setSubmitError(utils.getErrorForDisplay(error));
        },
    });

    const headerMainButtonAction = useCallback(()=> {
        if (manageEmployees) {
           const prevEmployees = [...currentShortlist?.shortlistData?.assignedTo ?? []];

           markedForDelete.forEach((id)=> {

                const index = prevEmployees.findIndex((item)=> item.employeeId === id);
                if (index > -1) {
                     prevEmployees.splice(index, 1);
                }
           });
            Object.entries(employeeAcceptanceStatusChange).forEach(([key, value])=> {
                const index = prevEmployees.findIndex((item)=> item.employeeId === key);
                if (index > -1) {
                    prevEmployees[index].acceptanceStatus = value;
                }
            });

            updateShortlist({
                companyId: currentCompanyId ?? '',
                shortlistId: currentShortlist?.shortlistData?.id ?? '',
                assignedTo: prevEmployees,
            });

        } else {
            const employeeIds:string[] = [];

            currentShortlist?.shortlistData?.assignedTo?.forEach((item)=> {
                if (item.acceptanceStatus === EmployeeAcceptanceStatus.accepted) {
                    employeeIds.push(item.employeeId);
                }
            });
            createChecklist({
                companyId: currentCompanyId ?? '',
                shortlistId: currentShortlist?.shortlistData?.id ?? '',
                employeeIds: employeeIds,
            });
        }
    },[manageEmployees, currentShortlist, currentCompanyId, createChecklist, markedForDelete, employeeAcceptanceStatusChange, updateShortlist]);

    const {mutate: addEmployees, isLoading: isAdding} = trpc.company.shortlist.assignEmployees.useMutation({
        onSuccess: ()=> {
            trpcContext.company.shortlist.getById.invalidate({
                shortlistId: checklistId ?? '', companyId: currentCompanyId ?? '',
            });
            setTimeout(()=> {
                mbShowToast({ text1: 'New employees added to the shortlist.',
                    type: 'success',
                    text2: '',
                });
                setShowAddNewEmployeesPopup(false);
            }, 400);
        },
        onError: (error)=> {
            setSubmitError(utils.getErrorForDisplay(error));
        },
    });


    const addEmployeesAction = useCallback((employeeIds: string[])=> {
        if (!currentCompanyId || !currentShortlist) {
            return;
        }

        const existingEmployees = currentShortlist.shortlistData.assignedTo;
        const newEmployees = employeeIds.map((id)=> {
                  return {employeeId: id, acceptanceStatus: EmployeeAcceptanceStatus.pending};
              });

        addEmployees({
            companyId: currentCompanyId,
            shortlistId: currentShortlist.shortlistData.id,
            assignedTo: [...existingEmployees, ...newEmployees],
        });


    },[currentCompanyId, currentShortlist, addEmployees]);

    const footerComponent = useMemo(()=> {
        return <ListInfoBlockUI recordType={RecordTypes.shortlist} data={{

            number: currentShortlist?.shortlistData?.workOrder?.number ?? '',
            clientName: currentShortlist?.shortlistData?.clientName,
            startDate: currentShortlist?.shortlistData?.workOrder?.startDate,
            endDate: currentShortlist?.shortlistData?.workOrder?.endDate,
            position: currentShortlist?.shortlistData?.position as WorkOrderPosition,
            positionType: currentShortlist?.shortlistData?.workOrder?.positionType,
            assignmentStatus:currentShortlist?.shortlistData?.assignmentStatus,
            acceptanceStatus: {
                status: currentShortlist?.shortlistData?.shortlistAcceptanceStatus ?? ShortlistAcceptanceStatus.none,
                assignedToQty: currentShortlist?.shortlistData?.assignedTo?.length ?? 0,
                acceptedQty: 0,
            },
            activityStatus: currentShortlist?.shortlistData?.activityStatus,
            createdAt: currentShortlist?.shortlistData?.createdAt,
        }}/>;
    },[currentShortlist?.shortlistData]);

    if (!currentShortlist || isShortlistLoading) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );
    }



    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     removePaddingX={isMobileApp}
                                     wrapInScrollView={!isMobileApp}
        >
            <ModalWrapper show={showAddNewEmployeesPopup}
                          closeAction={()=> setShowAddNewEmployeesPopup(false)}
                          title={'Add employees'}
                          maxHeight={undefined}
                          minHeight={isMobileApp ? 700 : undefined}
                          >
                <AddEmployees shortlistId={currentShortlist.shortlistData.id}
                              workOrderId={currentShortlist.shortlistData.workOrder.id}
                                submitAction={addEmployeesAction}
                                submitInProgress={isAdding}
                              submitError={submitError}
                              cancelAction={()=> setShowAddNewEmployeesPopup(false)}
                />
            </ModalWrapper>
            <ListViewHeader title={title}
                            subtitle={subtitleText}
                            subtitleComponent={subTitleComponent}
                            buttonText={addButtonText}
                            onAddPress={headerMainButtonAction}
                            hideButtonLeftElement={true}
                            buttonColor={manageEmployees ? undefined : appColors.green600}
                            buttonIsLoading={updatingShortlist || creatingChecklist}
                            headerExtraComponent={<View style={isMobileApp ? styles.headerExtraContainerMobile : styles.headerExtraContainer}>
                                {extraHeaderComponent}
                            </View>}
                            showGoBack={true}
                            backButtonAction={()=> {
                                resetView();
                                navigation.goBack();
                            }}
                            cancelButton={ manageEmployees ? {
                                text: 'Cancel',
                                action: ()=> {
                                    setMarkedForDelete([]);
                                    setEmployeeAcceptanceStatusChange({});
                                    setManageEmployees(false);
                                }} : {
                                    text: 'Manage employees',
                                    icon: <AntDesign name="setting" color={COLORS.buttonPrimaryBg} size={18} />,
                                color: COLORS.buttonPrimaryBg,

                                    action: ()=> {
                                        setManageEmployees(true);
                                    },
                                }
                            }

                            leftButtonElement={manageEmployees ? <OutlinedButton title={'Add employees'}
                                                                                 extraStyles={{
                                                                                        borderColor: COLORS.buttonPrimaryBg,
                                                                                 }}
                                                                                 textStyles={mbTextStyles([{color: COLORS.buttonPrimaryBg}])}

                                                                                 leftIcon={<AntDesign name="plus" color={COLORS.buttonPrimaryBg} size={18} />}
                                                       action={()=> setShowAddNewEmployeesPopup(true)}

                                            />
                                : undefined}


            />
            {/*</View>*/}
            <View style={{width: '100%'}}>
                {isMobileApp ? null : DateIteratorComponent}

                <ShortListTableUI key={tableKey}
                                  data={shortListEmployees}
                                  isEditing={manageEmployees}
                                  updateCheckinData={()=>{}}
                    // openComment={(employee)=>setOpenComment(employee)}
                             footerComponent={footerComponent}
                                  toggleDeleteAction={toggleMarkedForDelete}
                                  markedForDelete={markedForDelete}
                                  toggleAcceptanceStatus={toggleEmployeeAcceptanceStatus}
                                  unsavedAcceptanceChanges={employeeAcceptanceStatusChange}
                />
                {isMobileApp ? null : footerComponent }

            </View>
        </UIDrawerRightContentWrapper>
    );
};

const styles = StyleSheet.create({
    headerExtraContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerExtraContainerMobile: {
        flexDirection: 'column',
    },
    dateText: mbTextStyles([
        textStyles.Muli_16_600,
    ]),
    buttonContainer: {
        paddingVertical: 8,
        backgroundColor: COLORS.backgroundPrimary,
        borderTopWidth: 1,
        borderColor: COLORS.inputBorder,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});

export {ViewShortlist};
