import { useCallback, useEffect, useMemo, useState } from 'react';
import { usePagination } from '../../../../hooks/usePagination';
import { trpc } from '../../../../apiCalls/trpcClient';
import { AdminCompaniesProps } from '../../../../typesAndInterfaces/componentProps';
import { GetDataResponseBase, ItemsPageData } from '../../../../typesAndInterfaces/typesAndInterfaces';
import {  CompanyDataForClient } from '@temptrack/business';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

type GetCompaniesReturnType = GetDataResponseBase & {
    recordData:
        | (ItemsPageData & { items: CompanyDataForClient[]  })
        | undefined;
};
const useGetCompanies = ({navigation, route}:AdminCompaniesProps):GetCompaniesReturnType => {
    const {page, pageSize, PaginationComponent, setPage} = usePagination({navigation, route});

    const [filters, setFilters] = useState<Record<string, any>>({});

    const [nextToken, setNextToken] = useState<string | undefined>(undefined);
    useEffect(() => {
        setNextToken(undefined);
    }, [pageSize, setNextToken]);


    const queryParameters = useMemo(() => {
        let params: {
            page: number;
            pageSize: number;
            lastToken?: string;
            filter?: Record<string, any>;
        } = { page, pageSize, lastToken: nextToken};
        if (Object.keys(filters).length) {
            params = {...params, filter: filters};
        }
        return params;
    }, [ page, pageSize, filters, nextToken]);

    const infiniteFetchQueryParameters = useMemo(() => {
        let params: {
            limit: number
            filter?: Record<string, any>;
        } = {limit:10};
        if (Object.keys(filters).length) {
            params = {...params, filter: filters};
        }
        return params;
    }, [filters]);

    const { data: recordData,
              isLoading,
              isPreviousData,
          } = trpc.admin.company.getPage.useQuery(queryParameters, {
        refetchOnWindowFocus: false,
        enabled: !isMobileApp,
    });

    useEffect(()=> {
     setNextToken(recordData?.nextToken ?? undefined);
    }, [recordData, setNextToken]);

    const {
              data: infiniteRecordData,
              fetchNextPage,
              hasNextPage,
              isPreviousData: infiniteFetchIsPreviousData,
              isLoading: infiniteFetchIsLoading,
          } = trpc.admin.company.list.useInfiniteQuery(infiniteFetchQueryParameters,
        {
            getNextPageParam: (lastPage) => {
                return lastPage.nextCursor;
            },
            enabled: isMobileApp,
        },
    );

    const dataToReturn = useMemo(() => {
        if (isMobileApp) {
            const pages = infiniteRecordData?.pages ?? [];
            return {
                items: pages.map((p) => p.items).flat(),
            };
        }
        return recordData;
    }, [recordData, infiniteRecordData]);

    const setFiltersAction = useCallback((newFilters: Record<string, any>) => {
        setPage(1);
        setNextToken(undefined);
        setFilters?.(newFilters);
    }, [setPage]);

    return {
        PaginationComponent: isMobileApp ? undefined : PaginationComponent,
        recordData: dataToReturn,
        isLoading: isMobileApp ? infiniteFetchIsLoading : isLoading,
        isPreviousData: isMobileApp
            ? infiniteFetchIsPreviousData
            : isPreviousData,
        fetchNextPage: isMobileApp ? fetchNextPage : undefined,
        hasNextPage: isMobileApp ? hasNextPage : undefined,
        setFilters: setFiltersAction,
    };
};

export {useGetCompanies};
