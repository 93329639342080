import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const ThreePersonIconSvg = React.memo(({size, containerStyle, color}: {
    size?: number, containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 22}
                height={size ? size * (19 / 22) : 19}
                viewBox="0 0 22 19"
                fill="none"
            >
                <Path
                    d="M14.6 4.2a3.6 3.6 0 11-7.2 0 3.6 3.6 0 017.2 0zm6 2.4a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zM15.8 15a4.8 4.8 0 00-9.6 0v3.6h9.6V15zM6.2 6.6a2.4 2.4 0 11-4.8 0 2.4 2.4 0 014.8 0zm12 12V15a7.167 7.167 0 00-.9-3.488A3.606 3.606 0 0121.8 15v3.6h-3.6zM4.7 11.512A7.168 7.168 0 003.8 15v3.6H.2V15a3.6 3.6 0 014.5-3.488z"
                    fill={color ?? '#9CA3AF'}
                />
            </Svg>


        </View>
    );
});

export { ThreePersonIconSvg };
