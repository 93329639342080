import { NOT_APPLICABLE_LAST_NAME } from '../general/constants';
import { CompanyDomainSchema } from '../inputs/common';

export function isCompanyDomainValid(domain: string): boolean {
    return CompanyDomainSchema.safeParse(domain).success;
}

/**
 * Returns the user's name as "{first name} {last name}". If the
 * last name is undefined or not applicable, it is omitted.
 *
 * @param firstName The user's first name.
 * @param lastName The user's last name.
 */
export function toFullName(firstName: string, lastName?: string): string {
    lastName = lastName === NOT_APPLICABLE_LAST_NAME ? undefined : lastName;
    return `${firstName} ${lastName ?? ''}`.trim();
}
