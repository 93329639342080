import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import {UIDrawerRightContentWrapper} from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import {useGetChecklists} from './useGetChecklists';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { utils } from '../../../../utils/utils';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { GestureResponderEvent, StyleSheet, Text, View } from 'react-native';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { ChecklistsProps } from '../../../../typesAndInterfaces/componentProps';
import { CreateShortlistForm  } from './ChecklistContent';
import {ChecklistsTable} from './ChecklistsTable';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import {
    ChecklistActivityStatus,
    ChecklistAssignmentStatus,
    FilterFieldType,
    CheckInsStatus,
    toCheckInsStatusLabel,
    toAssignmentStatusLabel,
    toActivityStatusLabel,
    ChecklistDataForClient,
    ShortlistDataForClient,
    ShortlistAcceptanceStatus,
    toShortlistAcceptanceStatusLabel,
} from '@temptrack/business';
import omit from 'lodash/omit';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { MB_ToggleSwitch } from '@mightybyte/rnw.components.toggle-switch';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import {SwitchGradient} from '../../../helperComponents/misc/SwitchGradient';
import {ChecklistViewMode} from '../../../../navigations/types';
import { ShortlistsTable } from './ShortlistsTable';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();


const Checklists = ({navigation, route}: ChecklistsProps)=> {

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {isClient} = useSignedInContext();

    const [showTimeline, setShowTimeline] = useState(false);

    const {checkListsData, PaginationComponent,
              isLoading, isPreviousData, fetchNextPage,
              hasNextPage, setFilters} = useGetChecklists({navigation, route});

    const applyFiltersCallback = useCallback(({ filters }: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
    }, [setFilters]);

    const [viewMode, setViewMode] = useState<ChecklistViewMode>(
        isClient ? ChecklistViewMode.checkList : route?.params?.viewMode || ChecklistViewMode.shortList
    );

    const name = viewMode === ChecklistViewMode.checkList ? 'Checklist' : 'Shortlist';

    useEffect(()=> {
        if (isClient) {
            setViewMode(ChecklistViewMode.checkList);
        }
    }, [isClient]);

    useEffect(() => {
        navigation.setParams({viewMode});
    }, [viewMode, navigation]);


    const initialOptions = useMemo(()=> {

        const activityStatusOptions = Object.values(ChecklistActivityStatus).map((p)=> {
            return {label: toActivityStatusLabel(p), value: p};
        });
        const assignmentStatusOptions = Object.values(ChecklistAssignmentStatus).map((p)=> {
            return {label: toAssignmentStatusLabel(p), value: p};
        });

        const checklistOptions = [
            {
                label: 'Checklist #',
                apiName: 'number',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Client',
                apiName: 'clientId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
            },
            {   label: 'Checklist Status',
                apiName: 'activityStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: activityStatusOptions,
            },
            {
                label: 'Assignment Status',
                apiName: 'assignmentStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: assignmentStatusOptions,

            },
            {
                label: 'Checked in',
                apiName: 'checkInsStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: Object.values(CheckInsStatus).map((p)=> {
                    return {label: toCheckInsStatusLabel(p), value: p};
                }),
            },
            {   label: 'Checklist Date',
                apiName: 'date',
                filterType:  FilterFieldType.date,
            },
        ];

        const shortListOptions = [
            {
                label: 'Shortlist #',
                apiName: 'number',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Client',
                apiName: 'clientId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
            },
            {   label: 'Shortlist Status',
                apiName: 'activityStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: activityStatusOptions,
            },
            {
                label: 'Assignment Status',
                apiName: 'assignmentStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: assignmentStatusOptions,

            },
            {
                label: 'Acceptance Status',
                apiName: 'acceptanceStatus',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: Object.values(ShortlistAcceptanceStatus).map((p)=> {
                    return {label: toShortlistAcceptanceStatusLabel(p), value: p};
                }),
            },
            {   label: 'Shortlist Date',
                apiName: 'date',
                filterType:  FilterFieldType.date,
            },
        ];

        if (viewMode === ChecklistViewMode.shortList) {
            return shortListOptions;
        }

        return isClient ? checklistOptions.filter((o)=>o.apiName !== 'clientId') : checklistOptions;
    }, [isClient, viewMode]);

    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.new);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });


    const {data: shortListCounter, isLoading: isLoadingShortListCounter} = trpc.company.shortlist.getPage.useQuery({
        companyId: currentCompanyId ?? '',
        page: 1,
        pageSize: 1,
    }, {
        enabled: !!currentCompanyId,
    });

    const {data: checkListCounter, isLoading: isLoadingCheckListCounter} = trpc.company.checklist.getPage.useQuery({
        companyId: currentCompanyId ?? '',
        page: 1,
        pageSize: 1,
    }, {
        enabled: !!currentCompanyId,
    });

    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const onRecordAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.checkLists, {...route.params, ...{new:true, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    const onViewTimelinePressed = useCallback(()=> {

        navigation.navigate(CompanyDashboardRoutes.checkLists, {...route.params, timeline: true});
        setShowTimeline(true);
        setShowRecordModal(true);
    }, [navigation, route.params, setShowTimeline]);

    const addButtonText = 'Add new shortlist';

    const closeModal = useCallback(()=> {
        setShowMenuForRowId(undefined);
        let updatedParams:{
            companyId: string,
            new?: boolean,
            timeline?: boolean,
        } = omit(route.params, ['new']);
        updatedParams.timeline = undefined;
        navigation.navigate(CompanyDashboardRoutes.checkLists, updatedParams);
        setShowRecordModal(false);
        setShowTimeline(false);
        setErrorMessage('');
    },[navigation, route.params]);

    const {data: checklistTimeline, isLoading: gettingChecklisTimeline} = trpc.company.checklist.getTimeline.useQuery({checklistId: showMenuForRowId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!showMenuForRowId && !!currentCompanyId,
    });

    const {mutate: createShortlist, isLoading: isSavingShortlist} = trpc.company.shortlist.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();

            setTimeout(()=> {
                mbShowToast({ text1: 'Shortlist created successfully',
                    type: 'success',
                    text2: '',
                });
            }, 400);

        },
        onError: (err)=> {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const refreshList = useCallback((id?: string)=> {
        if (id) {
            // trpcContext.company.checklist.getById.invalidate({branchId: id});
        }
        if (isMobileApp) {
            switch (viewMode) {
                case ChecklistViewMode.shortList:
                    trpcContext.company.shortlist.list.invalidate();
                    break;
                case ChecklistViewMode.checkList:
                    trpcContext.company.checklist.list.invalidate();
                    break;
                    default: break;
            }
        } else {
            switch (viewMode) {
                case ChecklistViewMode.shortList:
                    trpcContext.company.shortlist.getPage.invalidate();
                    break;
                case ChecklistViewMode.checkList:
                    trpcContext.company.checklist.getPage.invalidate();
                    break;
                    default: break;
            }
        }

    }, [trpcContext.company.checklist.getPage, trpcContext.company.checklist.list,
        trpcContext.company.shortlist.list, trpcContext.company.shortlist.getPage, viewMode]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const { mutate: closeChecklist, isLoading: isLoadingClose } = trpc.company.checklist.close.useMutation({
        onSuccess: (data)=> {
            refreshList(data.checklistData.id);
            closeModal();
            if (showMenuForRowId) {
                setShowMenuForRowId(undefined);
               hideCloseChecklist();
            }
            setTimeout(()=> {
                mbShowToast({ text1: `${name} closed`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(err.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
        },
    });

    const {hide: hideCloseChecklist, show: showCloseCheckList, component:CloseChecklist, setDeleteError: setCloseError} = useDeleteRecord({
        recordType: name,
        deleteAction: (id)=>{closeChecklist({checklistId: id, companyId: currentCompanyId ?? ''}, {
            onError: (err)=> {setCloseError(utils.getErrorForDisplay(err));},
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        actionName: 'Close',
        isLoading: isLoadingClose,
    });

    const viewChecklist = useCallback((id: string | undefined)=> {
        if (!currentCompanyId || !id) {
            return;
        }
        navigation.navigate(CompanyDashboardRoutes.viewChecklist, {companyId: currentCompanyId, checklistId: id, viewMode: viewMode});
    }, [currentCompanyId, navigation, viewMode]);

    const menuItems = useMemo(()=> {
        const rowData = checkListsData?.items?.find((item)=>item.id === showMenuForRowId);
        const base:{
            title: string,
            onPress: ()=>void,
            textStyle?: any,
        }[] = [{title: 'View', onPress: ()=>{
            viewChecklist(showMenuForRowId);
                setShowMenuForRowId(undefined);
        }},
        ];
        if (viewMode === ChecklistViewMode.checkList) {
            base.push( {title: 'View Timeline', onPress: onViewTimelinePressed});
        }
        if (rowData?.activityStatus !== ChecklistActivityStatus.closed) {
            base.push(   {title: 'Close', onPress: ()=>{
                    showCloseCheckList({
                        recordId: showMenuForRowId ?? '',
                        recordName: rowData?.number ?? '',
                    });
                }, textStyle: {color: COLORS.dangerTextColor}});
        }

        return base;
    }, [showMenuForRowId, checkListsData?.items, showCloseCheckList, viewChecklist, onViewTimelinePressed, viewMode]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);
    const headerComponent = useMemo(()=> {
        return  <ListViewHeader title={`${name}s management`}
                                subtitle={isClient ? `Manage ${name.toLowerCase()}s for existing work orders.` :
                                    'Manage shortlists and checklists for existing work orders.'
                                }
                                buttonText={addButtonText}
                                hideAddButton={isClient}
                                onAddPress={onRecordAddPress}
                                buttonColor={applyTTStyles(false).primaryButtonBg}
                                filterComponent={filters.Button}
                                toggleViewComponent={isClient ? undefined : <View style={isMobileApp ? styles.toggleWrapperMobile : styles.toggleWrapper}>
                                    <MB_ToggleSwitch isToggled={viewMode === ChecklistViewMode.checkList}
                                                                      onToggle={()=>{
                                                                          setViewMode(viewMode === ChecklistViewMode.shortList ? ChecklistViewMode.checkList : ChecklistViewMode.shortList);
                                                                      }}
                                                                      leftComponent={<Text style={[styles.toggleLabel, viewMode === ChecklistViewMode.checkList && styles.inActiveNameColor]}>{`Shortlists (${isLoadingShortListCounter ? '...' : shortListCounter?.totalItems ?? ''})`}</Text>}
                                                                      rightComponent={<Text style={[styles.toggleLabel, viewMode === ChecklistViewMode.shortList && styles.inActiveNameColor]}>{`Checklists (${isLoadingCheckListCounter ? '...' : checkListCounter?.totalItems ?? ''})`}</Text>}
                                                                      thumbInActiveComponent={<SwitchGradient />}
                                                                      thumbActiveComponent={<SwitchGradient />}
                                                                      thumbButton={{
                                                                          width: 120,
                                                                          height: 40,
                                                                          borderWidth: 10,
                                                                      }}
                                                                      thumbStyle={{ overflow: 'hidden' }}
                                                                      trackBar={{
                                                                          width: 250,
                                                                          height: 40,
                                                                          activeBackgroundColor: COLORS.buttonSecondaryBorder,
                                                                          inActiveBackgroundColor:COLORS.buttonSecondaryBorder,
                                                                          borderActiveColor: COLORS.buttonSecondaryBorder,
                                                                          borderInActiveColor: COLORS.buttonSecondaryBorder,
                                                                      }}
                                /></View>
        }


        />;
    }, [onRecordAddPress, isClient, addButtonText, filters.Button, viewMode, name, checkListCounter, isLoadingCheckListCounter, shortListCounter, isLoadingShortListCounter]);

    const baseTableProps = useMemo(()=> {
        return {
            isLoading: checkListsData ? (isLoading && !isPreviousData) : isLoading,
            openRowMenu: openRowMenu,
            onAddPress: onRecordAddPress,
            addButtonText: addButtonText,
            hideAddButton: isClient,
            headerComponent: isMobileApp ? headerComponent : undefined,
            fetchMore: hasNextPage ? fetchNextPage : undefined,
            hasFilters: filters.filtersApplied,
        };

    },[isLoading, isPreviousData, checkListsData,
        openRowMenu, onRecordAddPress, addButtonText,
        isClient, headerComponent, fetchNextPage, hasNextPage, filters.filtersApplied]);

    const modalTitle = useMemo(()=> {
        if (showTimeline) {
            return 'View Timeline';
        }
        return 'Add new shortlist';
    },[showTimeline]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            {CloseChecklist}
            <ModalWrapper show={showRecordModal}
                          closeAction={closeModal}
                          title={modalTitle}
                          maxHeight={undefined}
                          minHeight={isMobileApp ? 700 : undefined}
            >
                {showTimeline  ? <TimelineView data={checklistTimeline?.timelineData ?? []}
                                               isLoading={gettingChecklisTimeline}
                    />
                    : <CreateShortlistForm
                    closeAction={closeModal}
                                               action={createShortlist}
                                               submitInProgress={isSavingShortlist}
                                               submitErrorMessage={errorMessage}
                                               resetSubmitError={(message)=>setErrorMessage(message)}
                    cancelAction={closeModal}

                /> }
            </ModalWrapper>

            {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                            callerX={position.x}
                                            callerY={position.y}
                                            visible={!!showMenuForRowId}
                                            menuItems={menuItems}
                                            closeMenu={()=>setShowMenuForRowId(undefined)}
            /> : null}
            {isMobileApp ? null : headerComponent}
            {filters.FilterPanel}
            {viewMode === ChecklistViewMode.shortList ? <ShortlistsTable
               data={checkListsData?.items as ShortlistDataForClient[] ?? []}
                {...baseTableProps}
                />
                : <ChecklistsTable data={checkListsData?.items as ChecklistDataForClient[] ?? []}
                             {...baseTableProps}

           />}
            {isMobileApp ? null : <View style={{minHeight:60}}>
            {PaginationComponent ? PaginationComponent({totalItems: checkListsData?.totalItems, currentItemsLength: checkListsData?.items?.length ?? 0}) : null}
            </View>}
        </UIDrawerRightContentWrapper>
    );
};

export {Checklists};

const styles = StyleSheet.create({

    toggleWrapper: {
        marginRight: 24,
    },

    toggleWrapperMobile: {
        width: '100%',
        alignItems: 'center',
        marginTop: 12,
    },
    toggleLabel: mbTextStyles([
        textStyles.Muli_16_400,
        {
            fontSize: 14,
            color: COLORS.backgroundPrimary,
        },
    ]),
    inActiveNameColor: {
        color: COLORS.textPrimary,
    },
});
