import {
    FilterFieldType,
    filterDropdownSchema,
    filterPhoneSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '..';
import {
    EmployeePosition,
    EmployeePositionType,
    EmployeeStatus,
} from '../../entities';
import { createFilterMapping } from './helpers';

export const EmployeeFilterMap = createFilterMapping({
    firstName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'firstName',
    },
    lastName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'lastName',
    },
    regionId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'regionId',
    },
    branchId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'branchId',
    },
    position: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(EmployeePosition),
        dbField: 'employeePosition',
    },
    positionType: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(EmployeePositionType),
        dbField: 'employeePositionType',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
    status: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(EmployeeStatus),
        dbField: 'status',
    },
    tempWorksId: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'tempWorksId',
    },
});
