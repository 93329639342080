import { z } from 'zod';
import {
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
} from '../../../common';
import { EmployeeWorkOrderType } from '../../../../entities';

export const GetEmployeeWorkOrdersPageInputSchema =
    PaginationInputSchema.extend({
        type: z.nativeEnum(EmployeeWorkOrderType),
    });

export const ListEmployeeWorkOrdersInputSchema =
    InfiniteScrollingInputSchema.extend({
        type: z.nativeEnum(EmployeeWorkOrderType),
    });

export const GetEmployeeWorkOrderByIdInputSchema = z.object({
    workOrderId: ObjectIdSchema,
    checklistId: ObjectIdSchema,
});

export const AcceptOrRejectJobInputSchema = z.object({
    shortlistId: ObjectIdSchema,

    /**
     * If true, accepts the job.
     * If false, declines the job.
     */
    accept: z.boolean(),
});
