import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EditIconButton, ViewIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { toUserRoleLabel } from '@temptrack/business';
import { utilHooks } from '../../../../hooks/utilHooks';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { UsersTableProps } from './types';
import { shouldShowBranchForRole, shouldShowRegionForRole } from '../../../../utils/permissionUtils';

const COLORS = getColors();

const header = [
    { content: 'first name', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'last name', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'region', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'branch', conatinerStyle: {  minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'role', conatinerStyle: {  minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'status', conatinerStyle: {  minWidth: 80, maxWidth: 80, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1,  minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const UsersTable = (
    {
        data,
        isLoading,
        onEditIconPressed,
        openRowMenu,
        canManageRecords,
        onAddPress,
        hideAddButton,
        addButtonText,
        hasFilters,
    }: UsersTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const {currentUserData} = useSignedInContext();

    const shouldShowRegion = useMemo(()=> {
        return shouldShowRegionForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const shouldShowBranch = useMemo(()=> {
      return shouldShowBranchForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const headerItems = useMemo(()=> {
        if (!shouldShowBranch) {
            return header.filter((item)=> item.content !== 'branch' && item.content !== 'region');
        }
        if (!shouldShowRegion) {
            return header.filter((item)=> item.content !== 'region');
        }
        return header;
    }, [shouldShowRegion, shouldShowBranch]);

    const onEditPress = useCallback((d:string) => {
        onEditIconPressed(d);
    } , [onEditIconPressed]);

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const dataToDisplay = useMemo(()=> {

        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const userData = item;
            const columns = {
                firstName: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.firstName}</Text>,
                lastName: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.lastName}</Text>,
                region: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.regionName}</Text>,
                branch: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.branchName}</Text>,
                role: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.role ? toUserRoleLabel(item?.role) : ''}</Text>,
                status:  <StatusRenderer status={item?.status} recordType={RecordTypes.user} isInvitePending={item.isInvitePending}/>,
            };

            const action = <View style={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                position: 'relative',
            }}>
                {canManageRecords ? <EditIconButton action={()=>onEditPress(userData.id)}/> : <ViewIconButton action={()=>onEditPress(userData.id)}/>}
                {canManageRecords ? <IconButton icon={
                    <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                }
                                                action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                /> : null}
            </View>;

            if (!shouldShowBranch) {
                return [
                    columns.firstName,
                    columns.lastName,
                    columns.role,
                    columns.status,
                    action,
                ];
            }
            if (!shouldShowRegion) {
                return [
                    columns.firstName,
                    columns.lastName,
                    columns.branch,
                    columns.role,
                    columns.status,
                    action,
                ];
            }

            return [
                columns.firstName,
                columns.lastName,
                columns.region,
                columns.branch,
                columns.role,
                columns.status,
                action,
            ];
        });
    }, [data, onEditPress, openRowMenu, canManageRecords, shouldShowRegion, shouldShowBranch]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={headerItems}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No users yet'}
                                                          subtitle={canManageRecords ? 'Start adding users to populate the directory.' : undefined}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          hideAddButton={hideAddButton}
                                                                            height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { UsersTable };
