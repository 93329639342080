import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import {
    CreateRegionInput,
    EditRegionInput,
} from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import {recordModalContentStyles} from '../../../../constants/recordModalContentStyles';
import {noSpecialChars} from '../../../../utils/validationUtils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    region = 'region',
}


const RegionForm = (
    {
        isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        regionId,
        resetSubmitError,
        showTimeline,
    }: {
    regionId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data: any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {

    const {currentCompanyId} = useCurrentCompanyContext();

    const [initialIsDone, setInitialIsDone] = useState(false);
    const [regionName, setRegionName] = useState('');


    const {data: recordData, isLoading} = trpc.company.region.getById.useQuery({regionId: regionId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isEditing && !!regionId && !!currentCompanyId,
    });

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.regionData) {
            setRegionName(recordData.regionData.name);
            setInitialIsDone(true);
        }

    }, [recordData, initialIsDone, setInitialIsDone]);


    const {data: timeline, isLoading: gettingTimeline} = trpc.company.region.getTimeline.useQuery({regionId: regionId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!regionId && !!currentCompanyId,
    });


    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
       [InputTypes.region]: '',
    });


    const isEmpty = (value: string | undefined) => {
        if (!value) {
            return true;
        }
        return value?.trim().length === 0;
    };

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        if (isEmpty(regionName)) {
            errors[InputTypes.region] = STRING_CONSTANTS.REQUIRED;
            isValid = false;
        } else {
            if (!noSpecialChars(regionName)) {
                errors[InputTypes.region] = STRING_CONSTANTS.NO_SPECIAL_CHARS;
                isValid = false;
            }
        }

        setFormErrorMessages(errors);
        return isValid;
    },[formErrorMessages, setFormErrorMessages, regionName ]);

    const createRecord = () => {
        if (!currentCompanyId) {
            return;
        }
        const data: CreateRegionInput = {
            companyId: currentCompanyId,
            name: regionName,
        };
        action(data);
    };

    const editRecord = () => {
        if (!currentCompanyId || isEmpty(regionName) || !regionId) {
            //just for TS. We already validate this in validateForm
            return;
        }
        const data: EditRegionInput = {
            regionId: regionId as string,
            name: regionName ?? '',
            companyId: currentCompanyId as string,
        };
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {

            createRecord();

        }
    };
    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);

    const cancelAction = ()=>{
        closeAction();
    };

    const fieldsMap = useMemo(() => {
        return {
            [InputTypes.region]: {value: regionName, setValue: setRegionName},
        };

    }, [regionName, setRegionName]);

    const setFormValue = useCallback((field: InputTypes, value: string) => {
        fieldsMap[field].setValue(value);
    }, [fieldsMap]);

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.region]: {title: 'Region Name', placeholder: 'Enter region name', textContentType: 'name', showRequiredAsteriks: true},
        };

        const onChange = (newText: string) => {
            resetSubmitError();
            setFormErrorMessages((prevErrors)=> {
                return {...prevErrors, [field]: ''};
            });
            setFormValue(field, newText);
        };

        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            onChangeText: onChange,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
            inputMode: 'text',
        };
    }, [fieldsMap, setFormValue, resetSubmitError]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1}}>
                <View style={recordModalContentStyles.row}>
                        <WrappedInput errorMessage={fieldError(InputTypes.region)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.region)}/>}
                        />
                </View>
            </View>


            <View>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add region'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                        />
                    </View>
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>
            </View>


        </ScrollView>
    );
};

export { RegionForm };
