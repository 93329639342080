export enum ImageFileExtension {
    PNG = 'png',
    JPG = 'jpg',
    JPEG = 'jpeg',
    WEBP = 'webp',
}

export enum RedirectReason {
    passwordRecovery = 'passwordRecovery',
    setPassword = 'setPassword',
}

export enum DiffType {
    added = 'added',
    edited = 'edited',
    deleted = 'deleted',
    array = 'array',
}
