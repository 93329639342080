export enum CompanyDashboardRoutes {
    users = 'users',
    employees = 'employees',
    workOrders = 'workorders',
    checkLists = 'checklists',
    viewChecklist = 'viewChecklist',
    regions = 'regions',
    branches = 'branches',
    clients = 'clients',
    employeeJobs = 'jobs',
}

export type CompanyNavigatorTab = {
    name: string;
    routeName: CompanyDashboardRoutes | 'Company';
    label: string;
    subTabs?: CompanyNavigatorTab[];
};

export enum AdminViewRoutes {
    companies = 'Companies',
    users = 'Users',
}
export type AdminNavigatorTab = {
    name: string;
    routeName: AdminViewRoutes;
    label: string;
    subTabs?: AdminNavigatorTab[];
};

export type SharedRouteParams = {
    filter?: string;
    timeline?: boolean;
    update?: boolean;
};

export enum ChecklistViewMode {
    shortList = 'shortlist',
    checkList = 'checklist',
}
