import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import { UIDrawerRightContentWrapper } from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { routeParams } from '../../../../constants/urlConstants';
import { utils } from '../../../../utils/utils';
import { GestureResponderEvent, Text, View } from 'react-native';
import { WorkOrdersProps } from '../../../../typesAndInterfaces/componentProps';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import { WorkOrdersTable } from './WorkOrdersTable';
import { WorkOrderForm } from './WorkOrdersContent';
import {
    FilterFieldType,
    toWorkOrderPositionLabel,
    toWorkOrderPositionTypeLabel,
    WorkOrderPosition,
    WorkOrderPositionType,
    WorkOrderStatus,
} from '@temptrack/business';
import { useGetWorkOrders } from './useGetWorkOrders';
import { utilHooks } from '../../../../hooks/utilHooks';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { ViewWOChecklists } from './viewWOChecklists';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';

const COLORS = getColors();

const Workorders = ({navigation, route}: WorkOrdersProps)=> {
    const name = 'Work Order';

    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {isClient} = useSignedInContext();

    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.workorderId);
    const [idToDuplicate, setIdToDuplicate] = useState<string | undefined>(route.params?.duplicateId);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(route.params?.workorderId);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [errorMessage, setErrorMessage] = useState('');

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);


    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    const modalContentMaxHeight = utilHooks.useMaxModalHeight();

    const {mutate: createRecord, isLoading: isLoadingRecordCreate} = trpc.company.workOrder.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: `Success creating ${name}`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: closeWO, isLoading: isLoadingCloseWO } = trpc.company.workOrder.close.useMutation({
        onSuccess: (data)=> {
            refreshList(data.workOrderData.id);
            closeModal();
            if (showMenuForRowId) {
                setShowMenuForRowId(undefined);
                hideCloseWorkOrder();
            }
            const woNumber = data?.workOrderData?.number ?? '';
            setTimeout(()=> {
                mbShowToast({ text1: `${name} ${woNumber} is closed.`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(err.message ?? STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN);
        },
    });

    const {mutate: reopenWO} = trpc.company.workOrder.reopen.useMutation({
        onSuccess: (data)=> {
            const woNumber = data?.workOrderData?.number ?? '';
            refreshList();
            if (showMenuForRowId) {
                setShowMenuForRowId(undefined);
            }
            setTimeout(()=> {
                mbShowToast({ text1: `${name} ${woNumber} has been re-opened.`,
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideCloseWorkOrder, show: showCloseWorkOrder, component:CloseWorkOrder} = useDeleteRecord({
        recordType: name,
        deleteAction: (id)=>{closeWO({
            workOrderId: id,
            companyId: currentCompanyId ?? '',
        });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        reversable: true,
        actionName: 'Cancel',
        isLoading: isLoadingCloseWO,
    });

    const {workOrdersData, PaginationComponent,
              isPreviousData, isLoading, hasNextPage, fetchNextPage, setFilters} = useGetWorkOrders({navigation, route});

    const applyFiltersCallback = useCallback(({ filters }: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
    }, [setFilters]);

    const initialOptions = useMemo(()=> {
        const positionOptions = Object.values(WorkOrderPosition).map((p)=> {
            return {label: toWorkOrderPositionLabel(p), value: p};
        });
        const positionTypeOptions = Object.values(WorkOrderPositionType).map((p)=> {
            return {label: toWorkOrderPositionTypeLabel(p), value: p};
        });
        const options = [
            {
                label: 'Work order #',
                apiName: 'number',
                filterType: FilterFieldType.text,
            },
            {
                label: 'Client',
                apiName: 'clientId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
            },
            {   label: 'Start Date',
                apiName: 'startDate',
                filterType:  FilterFieldType.date,
            },
            {   label: 'End Date',
                apiName: 'endDate',
                filterType:  FilterFieldType.date,
            },
            {
                label: 'Position',
                apiName: 'position',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: positionOptions,
            },
            {
                label: 'Position Type',
                apiName: 'positionType',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: positionTypeOptions,
            },
            {
                label: 'Status',
                apiName: 'status',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [
                    {label: 'Open', value: WorkOrderStatus.open},
                    {label: 'In Progress', value: WorkOrderStatus.inProgress},
                    {label: 'Completed', value: WorkOrderStatus.completed},
                ],
            },
        ];
        return isClient ? options.filter((o)=>o.apiName !== 'clientId') : options;
    }, [isClient]);


    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    const onRecordAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.workOrders, {...route.params, ...{workorderId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    const addButtonText = `Add new ${name.toLowerCase()}`;

    const closeModal = useCallback(()=> {
        let params = {...route.params};
        if (params.viewChecklists) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const {viewChecklists, ...rest} = params;
            params = {...rest};
        }
        navigation.navigate(CompanyDashboardRoutes.workOrders, {...params, ...{workorderId: '', companyId: currentCompanyId ?? '', duplicateId: undefined, timeline: undefined}});
        setShowRecordModal(false);
        setShowTimeline(false);
        setErrorMessage('');
    },[navigation, currentCompanyId, route.params]);

    const onViewRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            setShowMenuForRowId(d);
            navigation.navigate(CompanyDashboardRoutes.workOrders, {...route.params, ...{workorderId: d, companyId: currentCompanyId ?? '',  timeline: timeline}});
            setShowRecordModal(true);
        } else {
            setShowMenuForRowId(undefined);
            closeModal();
        }

    }, [navigation, closeModal, currentCompanyId, route.params]);

    const onViewChecklistsPressed = useCallback((d: string|undefined) => {
        if (d) {
            navigation.navigate(CompanyDashboardRoutes.workOrders, {...route.params, ...{workorderId: d, companyId: currentCompanyId ?? '', viewChecklists: true}});
            setShowRecordModal(true);
    } else {
            setShowMenuForRowId(undefined);
            closeModal();
        }
    }, [navigation, closeModal, currentCompanyId, route.params]);

    const modalRecordId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.workorderId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.workorderId, showRecordModal]);


    const refreshList = useCallback((id?: string)=> {
        if (id) {
            trpcContext.company.workOrder.getById.invalidate({workOrderId: id, companyId: currentCompanyId ?? ''});
        }
        if (isMobileApp) {
            trpcContext.company.workOrder.list.invalidate();
        } else {
            trpcContext.company.workOrder.getPage.invalidate();
        }

    }, [trpcContext.company.workOrder.getPage, trpcContext.company.workOrder.list, trpcContext.company.workOrder.getById, currentCompanyId]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const menuItems = useMemo(()=> {

        const cancelOption = {
            title: 'Cancel order', onPress: ()=>{
                const woData = workOrdersData?.items.find((item)=>item.id === showMenuForRowId);
                showCloseWorkOrder({

                recordId: showMenuForRowId,
                recordName: woData?.number,
                    subText1: {
                    text: woData?.status === WorkOrderStatus.inProgress ? 'All checklists would be closed.' : undefined,
                        color: COLORS.dangerTextColor,
                },
                subText2: {
                    text: isClient ? undefined : `Client: ${woData?.clientName}.`,
                },
            });}, textStyle: {color: COLORS.dangerTextColor}};

        const openWorkOrder = {
            title: 'Re-open order', onPress: ()=> {
                reopenWO({
                    workOrderId: showMenuForRowId ?? '',
                    companyId: currentCompanyId ?? '',
                });

            },
        };
        const duplicateWorkOrder = {
            title: 'Duplicate order', onPress: ()=> {
                const originalId = showMenuForRowId;
                setIdToDuplicate(originalId);
                navigation.navigate(CompanyDashboardRoutes.workOrders, {...route.params, ...{workorderId: routeParams.newRecord, companyId: currentCompanyId ?? '', duplicateId: originalId}});
                setShowRecordModal(true);
                setShowMenuForRowId(undefined);
            },
        };

        const base = [
            {title: 'Checklists summary', onPress: ()=> {onViewChecklistsPressed(showMenuForRowId);}},
            {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onViewRecordPressed(showMenuForRowId, true);
                }},
        ];
        if (isMobileApp) {
            base.unshift({title: 'View', onPress: ()=> {
                    setShowMenuForRowId(undefined);
                    onViewRecordPressed(showMenuForRowId);
            }});
        }
        const wo = workOrdersData?.items.find((item)=>item.id === showMenuForRowId);

            if (wo?.status === WorkOrderStatus.open || wo?.status === WorkOrderStatus.inProgress) {
                base.push(cancelOption);
            } else {
                base.push(openWorkOrder);// base.push(openWorkOrder);
            }
            base.push(duplicateWorkOrder);
        return base.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [workOrdersData?.items, showCloseWorkOrder, showMenuForRowId, isClient, reopenWO, currentCompanyId, navigation, route.params, onViewChecklistsPressed, onViewRecordPressed]);

    const viewWorkOrderTitle = useMemo(()=> {
        const woData = workOrdersData?.items.find((item)=>item.id === showMenuForRowId);
        if (showTimeline) {
            return (<View style={{flexDirection: 'row'}}>
                    <Text style={initialStyles.modalTitle}>{`Timeline for ${woData?.number}`}</Text>
            </View>
            );
        }
        return (
            <View style={{flexDirection: 'row'}}>
            <Text style={initialStyles.modalTitle}>{woData?.number}</Text>
                <Spacer x={10}/>
                <StatusRenderer status={woData?.status} recordType={RecordTypes.workOrder}/>
        </View>
        );
    }, [workOrdersData?.items, showMenuForRowId, showTimeline]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const HeaderComponent = useMemo(()=> {
        return   <ListViewHeader title={`${name}s`}
                                 subtitle={`View and manage our ${name.toLowerCase()}s.`}
                                 buttonText={addButtonText}
                                 onAddPress={onRecordAddPress}
                                 buttonColor={applyTTStyles(false).primaryButtonBg}
                                 filterComponent={filters.Button}

        />;
    }, [onRecordAddPress, addButtonText, filters.Button]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            {CloseWorkOrder}
            <ModalWrapper show={showRecordModal && !!route.params.viewChecklists }
                          closeAction={()=>onViewChecklistsPressed(undefined)}
                          title={`View checklists(${workOrdersData?.items.find((wo)=> wo.id === modalRecordId)?.checklistIds?.length ?? 0}) for ${workOrdersData?.items.find((wo)=> wo.id === modalRecordId)?.number}`}
                          maxHeight={undefined}
                          minHeight={500}

            >
                {null}
                <ViewWOChecklists cancelAction={()=>onViewChecklistsPressed(undefined)}
                                  workOrderId={modalRecordId}
                                  navigation={navigation}
                />

            </ModalWrapper>
            <ModalWrapper show={showRecordModal && !route.params.viewChecklists}
                          closeAction={()=>onViewRecordPressed(undefined)}
                          title={modalRecordId ? undefined : `Add new ${name.toLowerCase()}`}
                          titleCustomComponent={modalRecordId ?  viewWorkOrderTitle : undefined}
                          maxHeight={modalContentMaxHeight}
            >
                {(showRecordModal && !route.params.viewChecklists) ? <WorkOrderForm isViewing={!!modalRecordId}
                                               closeAction={()=>onViewRecordPressed(undefined)}
                                               workOrderId={modalRecordId}
                                               action={createRecord}
                                               submitInProgress={isLoadingRecordCreate}
                                               submitErrorMessage={errorMessage}
                                               resetSubmitError={()=>setErrorMessage('')}
                                                 idToDuplicate={idToDuplicate}
                                                                                    showTimeline={showTimeline}

                /> : null}
            </ModalWrapper>
            {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                            callerX={position.x}
                                            callerY={position.y}
                                            visible={!!showMenuForRowId}
                                            menuItems={menuItems}
                                            closeMenu={()=>setShowMenuForRowId(undefined)}
            /> : null}

            {isMobileApp ? null : HeaderComponent}
            {filters.FilterPanel}
            <WorkOrdersTable data={workOrdersData?.items}
                             isLoading={workOrdersData ? (isLoading && !isPreviousData) : isLoading}
                             openRowMenu={(e, recordId)=> {
                                 openRowMenu(e, recordId);
                             }}
                             onAddPress={onRecordAddPress}
                             addButtonText={addButtonText}
                             onViewPressed={onViewRecordPressed}
                             headerComponent={HeaderComponent}
                             fetchMore={hasNextPage ? fetchNextPage : undefined}
                             hasFilters={filters.filtersApplied}
            />
            {isMobileApp ? null : <View style={{minHeight:60}}>
            {PaginationComponent ? PaginationComponent({totalItems: workOrdersData?.totalItems, currentItemsLength: workOrdersData?.items?.length ?? 0}) : null}
            </View>}
            </UIDrawerRightContentWrapper>
    );
};

export {Workorders};
