import React, { useContext, useMemo, useState } from 'react';
import { deleteResponseInterceptors, setResponseInterceptors } from '../apiCalls/customAxios';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { useQueryClient } from '@tanstack/react-query';
import { mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils, Token } from '@mightybyte/rnw.utils.access-token-utils';
import { getRootNavRef } from '../navigations/RootNavigator';
import { trpc } from '../apiCalls/trpcClient';
import { UserDataForClient, UserRole } from '@temptrack/business';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

export const signedInContextGlobalFunction: {
    signOut?: (params: ISignOut) => void,
    getSignedInStatus?: () => SIGNED_IN_STATUS,
} = {};

export interface ISignOut { skipSignOutRequest?: boolean; showExpiredError?: boolean; makeRefreshTokenCall?: boolean }

export const enum SIGNED_IN_STATUS {
    loading = 'loading',
    signedIn = 'signedIn',
    signedOut = 'signedOut',
}

export interface ISignedInContextType {
    isSignedIn: boolean,
    signedInStatus: SIGNED_IN_STATUS,
    currentUserData: UserDataForClient | undefined,
    setSignedInStatus: (isSignedIn: SIGNED_IN_STATUS) => void,
    signOut: (params?: ISignOut) => Promise<void>,
    setCurrentUserData: (userData: UserDataForClient) => void,
    isTTEmployee: boolean,
    isClient: boolean,
    isEmployee: boolean,
}

const SignedInContext = React.createContext<ISignedInContextType | undefined>(undefined);

const SignedInStatusContextProvider = ({ children }: { children?: React.ReactNode }) => {
    const [signedInStatus, setSignedInStatus] = useState<SIGNED_IN_STATUS>(SIGNED_IN_STATUS.loading);
    const [currentUserData, setCurrentUserData] = useState<UserDataForClient | undefined>();
    const queryClient = useQueryClient();

    const signOutMutation = trpc.user.signOut.useMutation();

    const signOut = async (params?: ISignOut) => {
        if (signedInStatus !== SIGNED_IN_STATUS.signedOut) {
            setSignedInStatus(SIGNED_IN_STATUS.signedOut);
            await signOutHelper(params);
            if (isMobileApp) {
                return;
            } else {
                getRootNavRef()?.navigate('Login');
            }
        } else {
            getRootNavRef()?.navigate('Login');
        }
    };
    /**
     *
     * @param params object of config params
     * SkipSignOutRequest - Will skip the request to sign out - Intended to be set to true when signing out due to invalid access or refresh token
     * showExpiredError - Will show a pop-up saying that session expired - Intended to be set to true when signing out due to expired access token
     * makeRefreshTokenCall - Will make a new access token request - Intended to be set to true when signing out due to invalid access or refresh token.
     */
    const signOutHelper = async (params?: ISignOut) => {
        if (params?.makeRefreshTokenCall) {
            console.error('Signing out because of invalid token');
        }

        let tokens: Token | undefined;
        if (params?.makeRefreshTokenCall) {
            const tokensString = await MB_accessTokenUtils.getAccessToken();
            if (tokensString) {
                try {
                    tokens = JSON.parse(tokensString) as Token;
                }
                catch (e){}
            }
        }

        // NOTE: We need this on the server side for detecting a potential malicious activity.
        // NOTE: We are deleting the interceptor before making the requestNewAccessToken to prevent loops where this call
        // will throw an invalid access token error and will be caught by the interceptor again.
        // Make sure to always add it back afterwards.
        if (params?.makeRefreshTokenCall && tokens) {
            try {
                deleteResponseInterceptors();
                await userApiCalls.requestNewAccessToken(tokens.accessToken.token, tokens.refreshToken, true);
                setResponseInterceptors();
            } catch (error) {
                setResponseInterceptors();
            }
        }

        if (!params?.skipSignOutRequest) {
            try {
               signOutMutation.mutate(undefined, {onSettled: () => {
                    postSignOut();
                    },
                });
            } catch (error) { }
        } else {
            await postSignOut(params);
        }
    };

    const postSignOut = async (params?: ISignOut) => {
        try {
            await MB_accessTokenUtils.removeAccessToken();
            setCurrentUserData(undefined);
            await queryClient.cancelQueries();
            queryClient.removeQueries();
            queryClient.clear();
        } catch (error) {
            console.error('Error when signing out');
        }

        if (params?.showExpiredError) {
            setTimeout(() => {
                mbShowPopUp({ message: 'Login Session expired. Please try logging in' });
            }, 500);
        }
    };

    signedInContextGlobalFunction.signOut = signOut;
    signedInContextGlobalFunction.getSignedInStatus = () => signedInStatus;

    const isTTEmployee = useMemo(()=> {
        return currentUserData?.role === UserRole.admin;

    }, [currentUserData]);

    const isClient = useMemo(()=> {
        return currentUserData?.role === UserRole.client;
    }, [currentUserData]);

    const isEmployee = useMemo(()=> {
        return currentUserData?.role === UserRole.employee;
    },[currentUserData]);

    return (
        <SignedInContext.Provider value={{
            isSignedIn: signedInStatus === SIGNED_IN_STATUS.signedIn,
            signedInStatus: signedInStatus,
            currentUserData,
            setCurrentUserData,
            setSignedInStatus,
            signOut,
            isTTEmployee: isTTEmployee,
            isClient: isClient,
            isEmployee: isEmployee,
        }}>
            {children}
        </SignedInContext.Provider>
    );
};

function useSignedInContext() {
    const context = useContext(SignedInContext);
    if (context === undefined) {
        throw new Error('useSignedInContext must be used within a SignedInStatusContextProvider');
    }

    return context;
}

export { SignedInStatusContextProvider, useSignedInContext };
