import React, { useMemo } from 'react';
import { Text, View, StyleSheet } from 'react-native';
import { appColors, getColors } from '../../../constants/colors';
import {textStyles} from '../../../constants/textStyles';
import {
    ChecklistActivityStatus,
    EmployeeStatus,
    toWorkOrderStatusLabel,
    UserStatus,
    WorkOrderStatus,
} from '@temptrack/business';
import {Spacer } from './Spacer';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';

const COLORS = getColors();

const checklistStatusMapping = {
   [ChecklistActivityStatus.active]: COLORS.statusActive,
    [ChecklistActivityStatus.inactive]: COLORS.statusInactiveGray,
    [ChecklistActivityStatus.closed]: COLORS.statusInactive,
};

const employeeStatusMapping = {
    [EmployeeStatus.active]: COLORS.statusActive,
    [EmployeeStatus.inactive]: COLORS.statusInactive,
};

const userStatusMapping = {
    [UserStatus.active]: COLORS.statusActive,
    [UserStatus.inactive]: COLORS.statusInactive,
};

const workOrderStatusMapping = {
    [WorkOrderStatus.open]: COLORS.statusActive,
    [WorkOrderStatus.completed]: COLORS.statusInactive,
    [WorkOrderStatus.inProgress]: COLORS.statusInProgress,
};

enum RecordTypes {
    checklist = 'checklist',
    shortlist = 'shortlist',
    employee = 'employee',
    user = 'user',
    workOrder = 'workOrder',
}


const StatusRenderer = ({status, isInvitePending, recordType}: {
    status: UserStatus | EmployeeStatus | ChecklistActivityStatus | WorkOrderStatus | undefined
    isInvitePending?: boolean,
    recordType: RecordTypes,
})=> {

    const dotColor = useMemo(()=> {
        if (isInvitePending) {
            return appColors.blue200;
        }
        if (!status) {return '';}

        switch (recordType) {
            case RecordTypes.checklist:
                case RecordTypes.shortlist:
                return checklistStatusMapping[status as ChecklistActivityStatus];
            case RecordTypes.employee:
                return employeeStatusMapping[status as EmployeeStatus];
            case RecordTypes.user:
                return userStatusMapping[status as UserStatus];
            case RecordTypes.workOrder:
                return workOrderStatusMapping[status as WorkOrderStatus];
            default:
                return '';
        }
    }, [status, isInvitePending, recordType]);

    const statusText = useMemo(()=> {
        if (isInvitePending) {
            return 'invited';
        }
        switch (recordType) {
            case RecordTypes.workOrder:
                return toWorkOrderStatusLabel(status as WorkOrderStatus);
            default:
                return status;
        }
    }, [status, isInvitePending, recordType]);
    if (!status) {
        return null;
    }
    return (
        <View style={styles.container}>
            <View style={[styles.statusDot, {backgroundColor: dotColor}]}/>
            <Spacer x={6}/>
            <Text style={[styles.text, styles.capitalized]}>{statusText}</Text>
        </View>
    );
};

export {StatusRenderer, RecordTypes};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    statusDot: {
        width: 10,
        height: 10,
        borderRadius: 5,
    },

    text: mbTextStyles([
        textStyles.base, {
            fontSize: 14,
            fontWeight: '500',
        },
    ]),
    capitalized: {
        textTransform: 'capitalize',
    },
});
