import { z } from 'zod';
import {
    AddressSchema,
    DateInputSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    StringSchema,
    TimeInputSchema,
    filterMappingToSchema,
    isStartDateBeforeEndDate,
    startDateNotBeforeEndDateError,
} from '../../common';
import {
    WorkOrderPayFrequency,
    WorkOrderPositionType,
    WorkOrderShift,
} from '../../../entities';
import { WorkOrderFilterMap } from '../../../filter';

export const CreateWorkOrderInputSchema = z
    .object({
        companyId: StringSchema,
        clientId: ObjectIdSchema,
        startDate: DateInputSchema,
        startTime: TimeInputSchema,
        endDate: DateInputSchema.optional(),
        endTime: TimeInputSchema,
        shift: z.nativeEnum(WorkOrderShift),
        position: StringSchema,
        positionType: z.nativeEnum(WorkOrderPositionType),
        employeesNeededQty: z.number().int().positive(),
        payRate: z.number().positive(),
        payFrequency: z.nativeEnum(WorkOrderPayFrequency),
        jobsiteAddress: AddressSchema,
        experience: StringSchema,
        license: StringSchema,
        certification: StringSchema,
        ppe: StringSchema.array().optional(),
        jobsiteEnvironment: StringSchema,
        isResumeRequired: z.boolean(),
        isInterviewRequired: z.boolean(),
        isEVerifyRequired: z.boolean(),
        language: StringSchema.array(),
        notes: StringSchema.optional(),
    })
    .refine(isStartDateBeforeEndDate, startDateNotBeforeEndDateError);

export const GetWorkOrdersPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(WorkOrderFilterMap),
});

export const ListWorkOrdersPageInputSchema =
    InfiniteScrollingInputSchema.extend({
        companyId: ObjectIdSchema,
        filter: filterMappingToSchema(WorkOrderFilterMap),
    });

export const GetWorkOrderByIdInputSchema = z.object({
    workOrderId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const CloseWorkOrderInputSchema = z.object({
    companyId: ObjectIdSchema,
    workOrderId: ObjectIdSchema,
});

export const ReopenWorkOrderInputSchema = z.object({
    companyId: ObjectIdSchema,
    workOrderId: ObjectIdSchema,
});

export const GetWorkOrdersForDropdownInputSchema = z.object({
    companyId: ObjectIdSchema,
});

export const GetWorkOrderTimelineInputSchema = z.object({
    workOrderId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
