import { FilterFieldType } from '../enums';
import {
    filterPhoneSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '../schemas';
import { createFilterMapping } from './helpers';

export const BranchFilterMap = createFilterMapping({
    name: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'name',
    },
    regionId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'regionId',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
});
