import React from 'react';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { COLORS } from '../../../constants/colors';

const NoText = () => {
    return (
        <AntDesign name="minus" color={COLORS.textPrimary} size={18} />
    );
};

export {NoText};
