import React, { ComponentProps, FC, ReactNode } from 'react';
import { SERVER_ERROR_CODES, STRING_CONSTANTS } from '../constants/constants';
import { DateObj, ServerError, TrpcError } from '../typesAndInterfaces/typesAndInterfaces';
import { mbHidePopUp, mbShowPopUp } from '@mightybyte/rnw.components.pop-up';
import { envs } from '../../env';
import { createNumberMask, formatWithMask, Masks } from 'react-native-mask-input';
import {compareAsc} from 'date-fns';
import {isMobileApp} from '@mightybyte/rnw.utils.device-info';

export const formatDateOptions = {
    'MMM, d, yyyy': {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    },
};

const monthNumberToMonthString =  (monthNumber: number | undefined) => {
    if (!monthNumber) {
    return '';
}
const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
return monthNames[monthNumber - 1];
};

const longToShortStateMapping:Record<string, string> = {
    'Alabama': 'AL',
    'Alaska': 'AK',
    'American Samoa': 'AS',
    'Arizona': 'AZ',
    'Arkansas': 'AR',
    'California': 'CA',
    'Colorado': 'CO',
    'Connecticut': 'CT',
    'Delaware': 'DE',
    'District Of Columbia': 'DC',
    'Federated States Of Micronesia': 'FM',
    'Florida': 'FL',
    'Georgia': 'GA',
    'Guam': 'GU',
    'Hawaii': 'HI',
    'Idaho': 'ID',
    'Illinois': 'IL',
    'Indiana': 'IN',
    'Iowa': 'IA',
    'Kansas': 'KS',
    'Kentucky': 'KY',
    'Louisiana': 'LA',
    'Maine': 'ME',
    'Marshall Islands': 'MH',
    'Maryland': 'MD',
    'Massachusetts': 'MA',
    'Michigan': 'MI',
    'Minnesota': 'MN',
    'Mississippi': 'MS',
    'Missouri': 'MO',
    'Montana': 'MT',
    'Nebraska': 'NE',
    'Nevada': 'NV',
    'New Hampshire': 'NH',
    'New Jersey': 'NJ',
    'New Mexico': 'NM',
    'New York': 'NY',
    'North Carolina': 'NC',
    'North Dakota': 'ND',
    'Northern Mariana Islands': 'MP',
    'Ohio': 'OH',
    'Oklahoma': 'OK',
    'Oregon': 'OR',
    'Palau': 'PW',
    'Pennsylvania': 'PA',
    'Puerto Rico': 'PR',
    'Rhode Island': 'RI',
    'South Carolina': 'SC',
    'South Dakota': 'SD',
    'Tennessee': 'TN',
    'Texas': 'TX',
    'Utah': 'UT',
    'Vermont': 'VT',
    'Virgin Islands': 'VI',
    'Virginia': 'VA',
    'Washington': 'WA',
    'West Virginia': 'WV',
    'Wisconsin': 'WI',
    'Wyoming': 'WY',
};

export const dollarMask = createNumberMask({
    prefix: ['$', ' '],
    separator: '.',
    precision: 2,
    delimiter: ',',
});
export const utils = {
    createErrorObject: (message: string, errorCode: SERVER_ERROR_CODES | string): ServerError => {
        return {
            message,
            errorCode,
            status: 'error',
        };
    },
    /**
     *
     * @param version string version 1
     * @param version2 string version 2
     * @returns -1 if version 1 < version 2, 0 if version 1 === version 2, 1 if version 1 > version 2
     */
    compareVersion: (version: string, version2: string) => {
        return version.localeCompare(version2, undefined, { numeric: true, sensitivity: 'base' });
    },

    combineComponents: (components: FC[]): FC => {
        const reducerFunction = (
            AccumulatedComponents: FC<ReactNode>,
            CurrentComponent: FC<ReactNode>,
        ): FC<ReactNode> => {
            return ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => {
                const Component = AccumulatedComponents as FC<ReactNode>;
                return (
                    <Component>
                        <CurrentComponent>{children}</CurrentComponent>
                    </Component>
                );
            };
        };

        return components.reduce(reducerFunction, ({ children }: ComponentProps<FC<ReactNode>>): JSX.Element => (
            <>{children}</>
        )) as FC;
    },
    showLogOutPopUp: (onSignOut?: () => void, onPopUpClosed?: () => void) => {
        mbShowPopUp({
            title: 'Are you sure you want to log out ?',
            buttonText: 'Yes',
            buttonAction: () => {
                // TODO: This is kind of ugly. We needed to do this because of some issues with iOS pop-ups and also on Android it was trying to perform an action on a component that was unmounted.
                mbHidePopUp();
                setTimeout(() => onPopUpClosed?.(), 0);
                setTimeout(() => onSignOut?.(), 400);
            },
            secondaryButtonText: 'No',
        });
    },
    getRelativeValue: (minValue: number, minRelativeValue: number, maxValue: number, maxRelativeValue: number, value: number) => {
        if (value <= minValue) {
            return minRelativeValue;
        } else if (value >= maxValue) {
            return maxRelativeValue;
        } else {
            if (minRelativeValue < maxRelativeValue) {
                return minRelativeValue + ((value - minValue) * ((maxRelativeValue - minRelativeValue) / (maxValue - minValue)));
            } else {
                return minRelativeValue - ((value - minValue) * ((minRelativeValue - maxRelativeValue) / (maxValue - minValue)));
            }
        }
    },
    enumFromStringValue<T>(enm: { [s: string]: T }, value: string): T | undefined {
        return (Object.values(enm) as unknown as string[]).includes(value)
            ? value as unknown as T
            : undefined;
    },
    isTTEmployee: (subdomain: string | undefined) => subdomain === envs.TEMPTRACK_SUBDOMAIN,

    getSubDomainFromUrl: (path: string | undefined | null): string => {
        if (!path) {
            return envs.TEMPTRACK_SUBDOMAIN;
        }

       //if mobile app and contain envs.deeplinkurl
        if (isMobileApp && path.includes(envs.MOBILE_DEEP_LINK) && path.indexOf('domain=') !== -1) {
           return path.split('domain=')[1];
        }

        const pathUrl = new URL(path);
        const trimmedPath = pathUrl.host.split(envs.BASE_URL);
        return trimmedPath?.[0].replace('.','') ?? undefined;
    },

    getErrorForDisplay: (err: any): string => {
        if (err?.message) {
            try {
                const parsedError = JSON.parse(err.message);
                if (parsedError?.length > 0) {
                    return parsedError[0].message;
                }
            } catch (e) {
            }
        }
        return err?.message || STRING_CONSTANTS.SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN;
    },
    getAddressToDisplay: (address: {
                                       line1: string;
                                       line2?: string;
                                       state: string;
                                       city: string;
                                       zipCode: string;
                                   } | undefined) => {
        if (!address) {
            return '';
        }
        const { line1, line2, state, city, zipCode } = address;
        return `${line1}${line2 ? `, ${line2}` : ''}, ${city}, ${state} ${zipCode}`;
    },
    formatUTCDateToDisplay: (dateStr: string | undefined, extraOptions?: Record<string, any>) => {
        if (!dateStr) {
            return '';
        }
        const date = new Date(dateStr);
        return date.toLocaleDateString('en-US', {
            timeZone: 'UTC',
            ...extraOptions,
        });
    },

    formatDateObjectToDisplay: (date: DateObj | undefined) => {
        if (!date) {
            return '\u2014';  //long dash
        }
        if (date.day === null) {
            return 'unset';  //long dash
        }
       return `${date.month}/${date.day}/${date.year}`;
    },
    phoneNumberToDisplay: (phoneNumber: string | undefined) => {
        if (!phoneNumber) {
            return '';
        }
        return formatWithMask({
            text: phoneNumber,
            mask: Masks.USA_PHONE,
        }).masked;
    },
    currencyToDisplay: (currency: number | undefined) => {
        if (!currency) {
            return '';
        }
        return formatWithMask({
            text: currency.toString(),
            mask: dollarMask,
        }).masked;
    },
    isToday: (date: string | undefined) => {
        if (!date) {
            return false;
        }
        const today = new Date();
        const dateToCheck = new Date(date);
        return today.getFullYear() === dateToCheck.getFullYear() &&
            today.getMonth() === dateToCheck.getMonth() &&
            today.getDate() === dateToCheck.getDate();
    },
    isBeforeToday: (date: string | undefined) => {
        if (!date) {
            return false;
        }
        const today = new Date();
        const dateToCheck = new Date(date);
        return today > dateToCheck;
    },
    // isAfterToday: (date: string | undefined) => {
    //     if (!date) {
    //         return false;
    //     }
    //     const today = new Date();
    //     const dateToCheck = new Date(date);
    //     return today < dateToCheck;
    // },

    isEndDateBeforeStartDate: (startDate: string | undefined, endDate: string | undefined) => {
        if (!startDate || !endDate) {
            return false;
        }
        const startDateObj = new Date(startDate);
        const endDateObj = new Date(endDate);
        return compareAsc(startDateObj, endDateObj) === 1;
    },

    stateToShort: (state: string | undefined) => {
        if (!state) {
            return '';
        }
        return longToShortStateMapping[state] || state;
    },

    getStreetFromFullAddress: (address: string | undefined, city: string | undefined) => {
        if (!address) {
            return '';
        }
        if (!city || address.indexOf(city) === -1) {
            return address;
        }

        return address.split(city)[0]?.trim()?.replace(/,/g, '') ?? '';
    },
    getIsoDateStringFromObject: (date: DateObj | undefined):string | undefined=> {
        if (!date) {
            new Date().toISOString();
        }
        if (date?.day === null){
            return undefined;
        }
        if (!date?.year || !date?.month || !date?.day){
            return new Date().toISOString();
        }
        try {
            return new Date(date.year, date.month - 1, date.day).toISOString();
        } catch (e) {
            return new Date().toISOString();
        }
    },

    displayDateObj: (date: DateObj | undefined) => {
        if (!date) {
            return '';
        }
        return `${monthNumberToMonthString(date.month)} ${date.day}, ${date.year}`;
    },

    timeToDisplay12Hours: (time: {
      hours: number;
        minutes: number;
    } | undefined) => {
        if (!time) {
            return '';
        }
        const { hours, minutes } = time;
        const hours12 = hours % 12 || 12;
        const ampm = hours >= 12 ? 'pm' : 'am';
        return `${hours12}:${minutes.toString().padStart(2, '0')} ${ampm}`;
    },
    timeTo12HoursDBString: (time: {
                                      hours: number;
                                      minutes: number;
                                  } | undefined) => {
        if (!time) {
            return '';
        }
        const { hours, minutes } = time;
        const hours12 = hours % 12 || 12;
        const ampm = hours >= 12 ? 'pm' : 'am';
        return `${hours12}:${minutes.toString().padStart(2, '0')}${ampm}`;
    },
    timeStringToTimeObject: (time: string | undefined) => {
        if (!time) {
            return undefined;
        }

        const timeParts = time.split(':');
        if (timeParts.length !== 2) {
            return undefined;
        }

        let hours = parseInt(timeParts[0]);
        const minutes = parseInt(timeParts[1]);

        const isPM = time.toLowerCase().indexOf('pm') !== -1;

        if (isPM && hours < 12) {
            hours += 12;
        }

        if (isNaN(hours) || isNaN(minutes)) {
            return undefined;
        }
        return {
            hours,
            minutes,
        };
    },
    arraysOfStringsAreEqual: (newArr: string[] | undefined, oldArr: string[] | undefined) => {
        if (!newArr || !oldArr) {
            return false;
        }
        if (newArr.length !== oldArr.length) {
            return false;
        }
        return newArr.every((item) => oldArr.includes(item));
    },

    getParsedTrpcError: (error: any, fallbackReturn:TrpcError):TrpcError => {
        try {
            const parsedError = JSON.parse(JSON.stringify(error));
            return parsedError?.data?.error ?? fallbackReturn;

        } catch (e) {
            return fallbackReturn;
        }
    },

    decodeParams: (data: any) => {
        Object.keys(data ?? {}).forEach(key => {
            if (typeof data[key] === 'object') {
                utils.decodeParams(data[key]);
            } else if (typeof data[key] === 'string') {
                if (data[key] === 'n/a') {
                    delete data[key];
                } else {
                    try { data[key] = JSON.parse(Buffer.from(data[key], 'base64').toString('ascii')); } catch { }
                }
            }
        });
        return data;
    },
};

