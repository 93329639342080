import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ListViewHeader } from '../../../helperComponents/ListViewHeader';
import {UIDrawerRightContentWrapper} from '../../../helperComponents/DrawerRightContentWrapper';
import { applyTTStyles, getColors } from '../../../../constants/colors';
import { ClientDataForClient, FilterFieldType, UserRole } from '@temptrack/business';
import { trpc } from '../../../../apiCalls/trpcClient';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { CompanyDashboardRoutes } from '../../../../navigations/types';
import { routeParams } from '../../../../constants/urlConstants';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { GestureResponderEvent, View } from 'react-native';
import { ModalWrapper } from '../../../helperComponents/misc/ModalWrapper';
import {ClientForm} from './ClientsContent';
import { ActionMenu } from '../../../helperComponents/ActionMenu';
import {ClientsTable} from './TableView';
import { ClientsProps } from '../../../../typesAndInterfaces/componentProps';
import { useDeleteRecord } from '../../../../hooks/useDeleteRecord';
import { utils } from '../../../../utils/utils';
import { useGetClients } from './useGetClients';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { FilterQueryAndOtherParams, useFilter } from '../../../../hooks/filters/useFilter';
import { shouldShowBranchForRole, shouldShowRegionForRole } from '../../../../utils/permissionUtils';
import { NotFilterFields } from '../../../../hooks/filters/types';

const COLORS = getColors();

const Clients = ({navigation, route}: ClientsProps)=> {
    const rolesThatCanManageClients = useRef([
        UserRole.admin,
        UserRole.companyAdmin,
        UserRole.regionAdmin,
    ]);
    const trpcContext = trpc.useUtils();
    const {currentCompanyId} = useCurrentCompanyContext();
    const {currentUserData} = useSignedInContext();

    const {clientsData, PaginationComponent,
              isLoading, isPreviousData, fetchNextPage,
              hasNextPage, setFilters, setExtraParams} = useGetClients({navigation, route});
    const [showRecordModal, setShowRecordModal] = useState(!!route.params?.clientId);
    const [showMenuForRowId, setShowMenuForRowId] = useState<string | undefined>(undefined);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const [showTimeline, setShowTimeline] = useState(route.params?.timeline ?? false);

    const toggleShowTimeline = useCallback(()=> {
        setShowTimeline((prev)=>!prev);
    }, [setShowTimeline]);

    useEffect(() => {
        if (!showTimeline && route.params?.timeline) {
            navigation.setParams({timeline: undefined});
        }
    }, [showTimeline, navigation, route.params?.timeline]);

    const [currentRowData, setCurrentRowData] = useState<ClientDataForClient | undefined>(undefined);

    const [errorMessage, setErrorMessage] = useState('');

    const applyFiltersCallback = useCallback(({ filters, otherParams}: FilterQueryAndOtherParams) => {
        setFilters?.(filters);
        if (otherParams) {
            setExtraParams?.(otherParams);
        }
    }, [setFilters, setExtraParams]);

    const shouldShowRegion = useMemo(()=> {
        return shouldShowRegionForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const shouldShowBranch = useMemo(()=> {
        return shouldShowBranchForRole(currentUserData?.role);
    }, [currentUserData?.role]);

    const initialOptions = useMemo(()=> {
        return [
            {
                label: 'Client Name',
                apiName: 'name',
                filterType: FilterFieldType.text,
            },
            {   label: 'Region',
                apiName: 'regionId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowRegion,
            },
            {
                label:'Branch',
                apiName: 'branchId',
                filterType:  FilterFieldType.dropdown,
                dropdownOptions: [],
                hide: !shouldShowBranch,
            },
            {
                label: 'Email',
                apiName: 'email',
                filterType:  FilterFieldType.text,
            },
            {
                label: 'Phone number',
                apiName: 'phoneNumber',
                filterType:  FilterFieldType.phone,
            },
            // {
            //     label: 'Status',
            //     apiName: 'status',
            //     filterType:  FilterFieldType.dropdown,
            //     dropdownOptions: [
            //         {label: 'Active', value: UserStatus.active},
            //         {label: 'Inactive', value: UserStatus.inactive},
            //     ],
            // },
            {
                label: 'Invite Pending',
                apiName: NotFilterFields.isInvitePending,
                filterType:  FilterFieldType.checkbox,
            },
        ];
    }, [shouldShowRegion, shouldShowBranch]);

    const filters = useFilter({
        isTempTrack: false,
        applyFiltersCallback,
        options: initialOptions,
        initialParams: route?.params?.filter,
        userCompanyId: currentCompanyId,
    });

    useEffect(() => {
        setErrorMessage('');
    }, [showRecordModal]);

    useEffect(() => {
        if (showMenuForRowId) {
            setCurrentRowData(clientsData?.items.find((item)=>item.id === showMenuForRowId));
        } else {
            setCurrentRowData(undefined);
        }

    }, [showMenuForRowId, clientsData?.items, setCurrentRowData]);

    const onClientAddPress = useCallback(() => {
        navigation.navigate(CompanyDashboardRoutes.clients, {...route.params, ...{clientId: routeParams.newRecord, companyId: currentCompanyId ?? ''}});
        setShowRecordModal(true);
    } , [navigation, currentCompanyId, route.params]);

    // const onDeletePress = useCallback(() => {} , []);

    const addButtonText = 'Add new client';

    const closeModal = useCallback(()=> {
        navigation.navigate(CompanyDashboardRoutes.clients, {...route.params, ...{companyId: currentCompanyId ?? '', timeline: undefined}});
        setShowTimeline(false);
        setShowRecordModal(false);
        setErrorMessage('');
    },[navigation, currentCompanyId, route.params]);

    const onEditRecordPressed = useCallback((d: string|undefined, timeline?: boolean) => {
        if (d) {
            navigation.navigate(CompanyDashboardRoutes.clients, {...route.params, ...{clientId: d, companyId: currentCompanyId ?? '', timeline: timeline}});
            setShowRecordModal(true);
        } else {
            closeModal();
        }

    }, [navigation, closeModal, currentCompanyId, route.params]);

    const modalClientId = useMemo(()=> {
        if (!showRecordModal) {
            return undefined;
        }
        const routeRecordId = route.params?.clientId;
        return routeRecordId === routeParams.newRecord ? undefined : routeRecordId;
    } , [route.params?.clientId, showRecordModal]);

    const {mutate: createClient, isLoading: isLoadingClientCreate} = trpc.company.client.create.useMutation({
        onSuccess: ()=> {
            refreshList();
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Adding  Client',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const { mutate: editClient, isLoading: isLoadingClientEdit } = trpc.company.client.edit.useMutation({
        onSuccess: (data)=> {
            refreshList(data.clientData.id);
            closeModal();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Editing Client',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {mutate: resendInvite} = trpc.company.client.resendInvite.useMutation({
        onSuccess: ()=> {
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Resending Invite',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err:any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const refreshList = useCallback((id?: string)=> {
        if (id) {
            trpcContext.company.client.getById.invalidate({clientId: id, companyId: currentCompanyId ?? ''});
        }
        if (isMobileApp) {
            trpcContext.company.client.list.invalidate();
        } else {
            trpcContext.company.client.getPage.invalidate();
        }

    }, [trpcContext.company.client.getPage, trpcContext.company.client.list, trpcContext.company.client.getById, currentCompanyId]);

    const openRowMenu = useCallback((e: GestureResponderEvent, rowId:string) => {

        if (showMenuForRowId && showMenuForRowId === rowId) {
            setShowMenuForRowId(undefined);
            return;
        }
        setPosition({ x: e.nativeEvent?.pageX, y: e.nativeEvent.pageY });
        setShowMenuForRowId(rowId);
    }, [showMenuForRowId]);

    const deleteClient = trpc.company.client.delete.useMutation({
        onSuccess: ()=> {
            setShowMenuForRowId(undefined);
            refreshList();
            hideDeleteClient();
            setTimeout(()=> {
                mbShowToast({ text1: 'Success Deleting Client',
                    type: 'success',
                    text2: '',
                });
            }, 400);
        },
        onError: (err: any) => {
            setErrorMessage(utils.getErrorForDisplay(err));
        },
    });

    const {hide: hideDeleteClient, show: showDeleteClient, component:DeleteClient, setDeleteError} = useDeleteRecord({
        recordType: 'client',
        deleteAction: (id)=>{
            deleteClient.mutate({clientId: id, companyId: currentCompanyId ?? ''}, {
                onError: (err)=>{setDeleteError(utils.getErrorForDisplay(err));},
            });},
        cancelAction: ()=>{
            setShowMenuForRowId(undefined);
        },
        isLoading: deleteClient.isLoading,
    });

    const editNotAllowed = useMemo(()=> {
        return !currentUserData?.role || !rolesThatCanManageClients.current.includes(currentUserData?.role);
    } , [currentUserData?.role]);

    const menuItems = useMemo(()=> {
        const options = {
            viewTimeline: {title: 'View Timeline', onPress: ()=> {
                    setShowTimeline(true);
                    onEditRecordPressed(showMenuForRowId, true);
                    setShowMenuForRowId(undefined);
                }},
            resendInvite: currentRowData?.isInvitePending ? {
                title: 'Resend Invite', onPress: () => {
                    if (!currentCompanyId) {
                        return;
                    }
                    resendInvite({ clientId: showMenuForRowId ?? '', companyId: currentCompanyId });
                    setShowMenuForRowId(undefined);
                },
            } : undefined,
            delete:   {title: 'Delete', onPress: ()=>{showDeleteClient({

                    recordId: showMenuForRowId,
                    recordName: (()=> {
                        const client = clientsData?.items.find((item)=>item.id === showMenuForRowId);
                        return client?.name ?? '';
                    })(),
                });}, textStyle: {color: COLORS.dangerTextColor}},
            editOrView: {
                title: editNotAllowed ? 'View' : 'Edit', onPress: () => {
                    onEditRecordPressed(showMenuForRowId);
                       setShowMenuForRowId(undefined);
                },
            },
        };
        const base = [options.viewTimeline, options.resendInvite];
        if (isMobileApp) {
            base.unshift(options.editOrView);
        }
        if (!editNotAllowed) {
            base.push(options.delete);
        }
        return base.filter((item)=>item !== undefined) as {title: string, onPress: ()=>void, textStyle?: any}[];
    }, [showMenuForRowId, resendInvite,currentRowData, currentCompanyId, clientsData, showDeleteClient,
        onEditRecordPressed, editNotAllowed]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const recordModalTitleProps = useMemo(()=> {
        if (showTimeline) {
            const name = clientsData?.items.find((item)=>item.id === modalClientId)?.name;
            return {title: `Timeline for ${name}`};
        }
        return {title: modalClientId ? editNotAllowed ? 'View client' : 'Edit client' : 'Add new client'};
    }, [showTimeline, modalClientId, editNotAllowed, clientsData?.items]);

    const HeaderComponent = useMemo(()=> {
        return  <ListViewHeader title={'List of clients'}
                                hideAddButton={editNotAllowed}
                                subtitle={'View and manage our clients.'}
                                buttonText={addButtonText}
                                onAddPress={onClientAddPress}
                                buttonColor={applyTTStyles(false).primaryButtonBg}
                                filterComponent={filters.Button}

        />;
    }, [onClientAddPress, editNotAllowed, filters.Button]);

    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction,
        }}
                                     wrapInScrollView={!isMobileApp}
                                     filterIsOpen={filters.filterIsOpen}
        >
            <>
                {DeleteClient}
                <ModalWrapper show={showRecordModal}
                              closeAction={()=>onEditRecordPressed(undefined)}
                              {...recordModalTitleProps}
                              maxHeight={undefined}
                >
                    {showRecordModal ? <ClientForm isEditing={!!modalClientId}
                                                        closeAction={()=>onEditRecordPressed(undefined)}
                                                        clientId={modalClientId}
                                                        action={modalClientId ? editClient : createClient}
                                                        submitInProgress={modalClientId ? isLoadingClientEdit : isLoadingClientCreate}
                                                        submitErrorMessage={errorMessage}
                                                        resetSubmitError={()=>setErrorMessage('')}
                                                        editNotAllowed={editNotAllowed}
                                                   showTimeline={showTimeline}
                                                   toggleShowTimeline={toggleShowTimeline}

                    /> : null}
                </ModalWrapper>

                {showMenuForRowId ? <ActionMenu rowId={showMenuForRowId}
                                                callerX={position.x}
                                                callerY={position.y}
                                                visible={!!showMenuForRowId}
                                                menuItems={menuItems}
                                                closeMenu={()=>setShowMenuForRowId(undefined)}
                /> : null}

                {isMobileApp ? null : HeaderComponent}
                {filters.FilterPanel}
                <ClientsTable data={clientsData?.items}
                              canManageRecords={!editNotAllowed}
                              isLoading={clientsData ? (isLoading && !isPreviousData) : isLoading}
                              onEditIconPressed = {onEditRecordPressed}
                              openRowMenu={(e, recordId)=>{openRowMenu(e, recordId);}}
                              onAddPress={onClientAddPress}
                              addButtonText={addButtonText}
                              fetchMore={hasNextPage ? fetchNextPage : undefined}
                              headerComponent={HeaderComponent}
                              hasFilters={filters.filtersApplied}
                />
                {isMobileApp ? null : <View style={{minHeight:60}}>
                {PaginationComponent ? PaginationComponent({totalItems: clientsData?.totalItems, currentItemsLength: clientsData?.items?.length ?? 0}) : null}
                </View>}
                </>
        </UIDrawerRightContentWrapper>
    );
};

export { Clients };
