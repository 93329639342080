import React from 'react';
import { View } from 'react-native';

interface SpacerProps {
    x?: number;
    y?: number;
}

const Spacer: React.FC<SpacerProps> = ({ x, y }) => {
    const defaultValue = 'auto';

    return (
        <View
            style={{
                width: x || defaultValue,
                height: y || defaultValue,
            }}
        />
    );
};

export {Spacer};






