import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { StyleSheet, Text, TouchableOpacity, View, ScrollView} from 'react-native';
import { CreateClientInput, EditClientInput, UserRole } from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import {recordModalContentStyles} from '../../../../constants/recordModalContentStyles';
import { useEmail } from '../../../../hooks/useEmail';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { useAddress } from '../../../../hooks/useAddress';
import { MB_DropDownPicker, RenderListItemPropsInterface } from '@mightybyte/rnw.components.dropdownpicker';
import { ConditionalFields, useConditionalFields } from '../../../../hooks/useConditionalFields';
import { useRegions } from '../../../../hooks/useRegions';
import { getCodeOptionsForState } from './workersCompCodesList';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import Entypo from 'react-native-vector-icons/Entypo';
import {getDescriptionForCode} from './workersCompCodesList';
import {utils } from '../../../../utils/utils';
import { envs } from '../../../../../env';
import { MB_LocationPicker } from '@mightybyte/rnw.components.location-picker';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import { TimelineView } from '../../../helperComponents/timelineView';

const COLORS = getColors();
enum InputTypes {
    name = 'name',
    branch = 'branch',
    region = 'region',
    workersCompensationCodes = 'workersCompensationCodes',
}

const getEmptyMessage = (state:string | undefined)=> {
    if (!state || state.trim().length === 0){
        return 'Please enter address first';
    }
    return `No codes found for state of ${state}`;
};

const ClientForm = (
    {
        isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        clientId,
        resetSubmitError,
        editNotAllowed,
        showTimeline,
    }: {
    clientId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data:any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    editNotAllowed: boolean;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {

    const {currentCompanyId} = useCurrentCompanyContext();
    const {getFieldSettings} = useConditionalFields();
    const {currentUserData} = useSignedInContext();


    const addressForm = useAddress({isOptional: false, disabled: editNotAllowed});
    const phoneNumber = usePhoneNumber({isRequired: true, disabled: editNotAllowed});
    const email = useEmail({isRequired: true, disabled: editNotAllowed, nextToFocus: phoneNumber.ref});


    const [initialIsDone, setInitialIsDone] = useState(false);

    const [name, setName] = useState<string>('');

    const [workersCompensationCodes, setWorkersCompensationCodes] = useState<string[]>([]);
    const [codeOptions, setCodeOptions] = useState<{label: string, value: string}[]>([]);
    const [emptyWCListMessage, setEmptyWCListMessage] = useState<string>('');

    useEffect(() => {
        setCodeOptions(getCodeOptionsForState(utils.stateToShort(addressForm.currentState)));
        setEmptyWCListMessage(getEmptyMessage(addressForm.currentState));
    }, [addressForm.currentState]);

    const regionOptions = getFieldSettings(ConditionalFields.region, {
        regionId: undefined,
        regionName: undefined,
    });
    const [regionId, setRegionId] = React.useState<string | undefined>(regionOptions.allowedToEdit ? undefined : regionOptions.defaultValue?.regionId);
    const {regionDropdownOptions} = useRegions({disabled: !regionOptions.allowedToView});

    const [branchId, setBranchId] = React.useState<string | undefined>(undefined);

    const {data: recordData, isLoading} = trpc.company.client.getById.useQuery({clientId: clientId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isEditing && !!clientId && !!currentCompanyId,
    });

    const {data: branchOptions, isLoading: gettingBranches} = trpc.company.branch.getDropdown.useQuery({companyId: currentCompanyId ?? '', regionId: regionId ?? ''}, {
        enabled: !!currentCompanyId && !!regionId,
    });

    const branchDropdownOptions = useMemo(() => {
        if (!branchOptions || !branchOptions?.items || gettingBranches) {
            if (currentUserData?.role === UserRole.recruiter) {
                return [{label: currentUserData?.branchName, value: currentUserData?.branchId}];
            }
            return [];
        }
        return branchOptions.items.map((branch)=> {
            return {label: branch.name, value: branch.id};
        });
    }, [branchOptions, gettingBranches, currentUserData?.branchId, currentUserData?.branchName, currentUserData?.role]);



    useEffect(() => {
        if (initialIsDone) {
            return;
        }

        if (recordData?.clientData) {
            const initialData = recordData.clientData;
            setName(initialData.name);
            setWorkersCompensationCodes(initialData.workersCompensationCode);
            email.setInitialEmail(initialData.email);
            phoneNumber.setInitialValue(initialData.phoneNumber);
            addressForm.setFullAddress(utils.getAddressToDisplay({
                line1: initialData.address?.line1,
                city: initialData.address?.city,
                state: initialData.address?.state,
                zipCode: initialData.address?.zipCode,
            }));
            addressForm.setAddress(initialData?.address?.line1 ?? '');
            addressForm.setCity(initialData.address?.city ?? '');
            addressForm.setState(initialData.address?.state ?? '');
            addressForm.setZipCode(initialData.address?.zipCode ?? '');
            addressForm.setAddress2(initialData.address?.line2 ?? '');
            setBranchId(initialData.branchId);
            setRegionId(initialData.regionId);
            setInitialIsDone(true);
        }

    }, [recordData, phoneNumber, email, initialIsDone, addressForm]);

    const fieldsMap = useMemo<{
        [key in InputTypes]: {
            value: any;
        };
    }>(()=> {
        const base = {
            [InputTypes.name]: {value: name},
            [InputTypes.workersCompensationCodes]: {value: workersCompensationCodes},
            [InputTypes.branch]: {value: branchId},
            [InputTypes.region]: {value: regionId},
        };
        return base;
    }, [ name, workersCompensationCodes, branchId, regionId]);


    const {data: timeline, isLoading: gettingTimeline} = trpc.company.client.getTimeline.useQuery({clientId: clientId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!clientId && !!currentCompanyId,
    });


    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
        [InputTypes.name]: '',
        [InputTypes.workersCompensationCodes]: '',
        [InputTypes.branch]: '',
        [InputTypes.region]: '',
    });


    const isEmpty = (value: string | string[]| undefined ) => {
        if (!value) {
            return true;
        }
        if (Array.isArray(value)) {
            return value.filter((v)=> v.trim().length > 0).length === 0;
        }

        return value.trim().length === 0;
    };

    const requiredFields = useMemo(()=> {
        const required = [InputTypes.name, InputTypes.branch, InputTypes.region];
        return required;
    }, []);

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        //check required fields
        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });

        setFormErrorMessages(errors);
        const emailIsValid = email.validateEmail();
        const phoneNumberIsValid = phoneNumber.validate();
        const addressIsValid = addressForm.validateAddress();

        return isValid && emailIsValid && phoneNumberIsValid && addressIsValid;
    },[fieldsMap, formErrorMessages, setFormErrorMessages, requiredFields, email, phoneNumber, addressForm]);

    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);


    const createRecord = () => {
        if (!branchId || !currentCompanyId) {
            return;
        }

        const data: CreateClientInput = {
            companyId: currentCompanyId,
            name,
            workersCompensationCode: workersCompensationCodes,
            email: email.value,
            phoneNumber: phoneNumber.value,
            address: addressForm.values,
            branchId: branchId,
        };
        action(data);
    };

    const editRecord = () => {
        if (!clientId || !branchId || !currentCompanyId) {
            //just for TS. We already validate this in validateForm
            return;
        }
        const data: EditClientInput = {
            clientId: clientId,
            companyId: currentCompanyId,
            name,
            workersCompensationCode: workersCompensationCodes,
            email: email.value,
            phoneNumber: phoneNumber.value,
            address: addressForm.values,
            branchId: branchId,
        };
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {
            createRecord();

        }
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.name]: {title: 'Client Name', placeholder: 'Enter client name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.name)},
            [InputTypes.workersCompensationCodes]: {title: 'Assign Workers’ Compensation Code(s)', placeholder: 'Select WC code(s)', textContentType: 'name', showRequiredAsteriks: false},
            [InputTypes.branch]: {title: 'Assign Branch', placeholder: 'Select branch', textContentType: 'none' as MB_TextInputProps['textContentType'], showRequiredAsteriks: requiredFields.includes(InputTypes.branch)},
            [InputTypes.region]: {title: 'Assign Region', placeholder: 'Select region', textContentType: 'none' as MB_TextInputProps['textContentType'], showRequiredAsteriks: requiredFields.includes(InputTypes.region)},
        };


        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
            disable: editNotAllowed,
            preventDisabledStyleChange: true,
        };
    }, [fieldsMap, requiredFields, editNotAllowed]);

    const cleanErrorsOnFieldChange = useCallback((field: InputTypes) => {
        resetSubmitError();
        setFormErrorMessages((prevErrors)=> {
            return {...prevErrors, ...{[field]: ''}};
        });
    }, [resetSubmitError, setFormErrorMessages]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);


    const [highlightedValue, setHighlightedValue] = useState();

    const LabelContentRenderer = useCallback(( code, isHighlighted, isSelected)=>{
        const description = getDescriptionForCode(code);
        return <View style={[styles.listItem, {backgroundColor: isHighlighted ? appColors.gray200 : undefined}]}>
            <View style={styles.listItemValue}>
                <Text style={initialStyles.dropdownBadgeStyle}>{code}</Text>
                <Text style={initialStyles.textInputTextStyle}>{`${description}`}</Text>
            </View>

            {isSelected ? <Entypo name={'check'} color={appColors.green600} size={16}/> : null}
        </View>;
    },[]);

    const renderWorkOrderListItem = useCallback((p: RenderListItemPropsInterface<any>) => {
        const { item, value: itemValue, onPress, isSelected } = p;
        const isHighlighted = highlightedValue === itemValue;
        const props = {
            onMouseMove: () => {
                if (highlightedValue !== itemValue) {
                    setHighlightedValue(itemValue);
                }
            },
            onPress: () => onPress(item),
        };
        return (
            <TouchableOpacity {...props}>
                {LabelContentRenderer(item.value, isHighlighted, isSelected)}
            </TouchableOpacity>
        );
    }, [highlightedValue, LabelContentRenderer]);

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    if (isEditing && (isLoading || !recordData || !initialIsDone)) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );

    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1}}>
                <View style={recordModalContentStyles.row}>
                    {/*<View style={recordModalContentStyles.rowItem}>*/}
                        <WrappedInput errorMessage={fieldError(InputTypes.name)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.name)}
                                                                   onChangeText={(newValue)=> {
                                                                       cleanErrorsOnFieldChange(InputTypes.name);
                                                                       setName(newValue);
                                                                   }}
                                                                   nextToFocus={email.ref}
                                      />}
                        />
                </View>
                <View style={[recordModalContentStyles.row,  {zIndex: 3000}]}>
                    <View style={[recordModalContentStyles.rowItem, {flex: 1, zIndex: 3000}]}>
                        <WrappedInput errorMessage={addressForm.error}
                                      inputComponent={<View style={{ width: '100%'}}>
                                          <MB_LocationPicker
                                              key={initialIsDone.toString()}
                                              apiKey={envs.LOCATION_PICKER_API}
                                              title={'Address'}
                                              containerStyle={{width: '100%'}}
                                              titleStyle={{color: COLORS.textPrimary}}
                                              disabled={editNotAllowed}
                                              disableInteractions={editNotAllowed}
                                              showRequiredAsteriks={true}
                                              value={addressForm.fullAddress}
                                              onSelect={addr => {
                                                  addressForm.setAddress(utils.getStreetFromFullAddress(addr?.text, addr?.city));
                                                  addressForm.setCity(addr?.city ?? '');
                                                  addressForm.setState(addr?.state ?? '');
                                                  addressForm.setZipCode(addr?.postalCode ?? '');
                                              }}
                                              dropDownDirection={'BOTTOM'}
                                              listMode={'SCROLLVIEW'}
                                          />
                                      </View>}
                        />
                    </View>

                    <View style={[recordModalContentStyles.rowItem, isMobileApp ? undefined : {width: 200, marginLeft: 16}]}>
                        {addressForm.addressSecondLineField}
                    </View>

                </View>
                <View style={[recordModalContentStyles.row,  {zIndex: 2000}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={codeOptions}
                                           multiple={true}
                                           value={workersCompensationCodes}
                                           onValueChange={(newValue)=> {
                                               setWorkersCompensationCodes(newValue ? newValue.map((v)=> v as string) : []);
                                           }}
                                           placeholder={getInputProps(InputTypes.workersCompensationCodes).placeholder}
                                           title={getInputProps(InputTypes.workersCompensationCodes).title}
                                           containerStyle={{width: '100%'}}
                                           dropdownContainerStyle={initialStyles.dropdownContainerStyle}
                                           disableInteractions={editNotAllowed}
                                           disabled={editNotAllowed}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           customListItem={(e)=>renderWorkOrderListItem(e)}
                                           extendableBadgeContainer={true}
                                           badgeStyle={initialStyles.dropdownBadgeStyle}
                                           listEmptyMessage={emptyWCListMessage}
                        />}
                                  containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 1500, width: '100%'}}}
                                  errorMessage={fieldError(InputTypes.workersCompensationCodes)}/>
                </View>
                <View style={[recordModalContentStyles.row, {zIndex: 1000}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={regionOptions.allowedToView ? regionDropdownOptions : regionOptions.defaultValue ? [{
                            label: regionOptions.defaultValue.regionName,
                            value: regionOptions.defaultValue.regionId,
                        }] : []}
                                           value={regionId}
                                           onValueChange={setRegionId}
                                           placeholder={'Select Region'}
                                           title={'Assign Region'}
                                           zIndex={2}
                                           containerStyle={{width: '100%'}}
                                           disabled={!regionOptions.allowedToView || editNotAllowed}
                                           disableInteractions={!regionOptions.allowedToView || editNotAllowed}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}

                        />
                    } errorMessage={fieldError(InputTypes.region)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 1000}}}
                                  subText={regionOptions.allowedToView || editNotAllowed ? undefined : 'You can only invite clients to your region'}
                    />

                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={branchDropdownOptions}
                                           value={branchId}
                                           onValueChange={setBranchId}
                                           placeholder={'Select Branch'}
                                           title={'Assign Branch'}
                                           containerStyle={{width: '100%'}}
                                           disabled={editNotAllowed}
                                           disableInteractions={editNotAllowed}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           multiple={false}
                                           listMode={'SCROLLVIEW'}
                        />
                    } errorMessage={fieldError(InputTypes.branch)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 950}}}/>


                </View>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>
                <View style={{zIndex: 1}}>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                {editNotAllowed ? <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow, {justifyContent: 'center'}]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Close'}
                                   onPress={cancelAction}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                            // preventDisabledStyleChange={true}
                        />
                    </View>
                    </View> :
                    <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={[recordModalContentStyles.rowItem]}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add Client'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                            // preventDisabledStyleChange={true}
                        />
                    </View>
                        {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={editNotAllowed ? 'Close' : 'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>}
            </View>

            </View>
        </ScrollView>
    );
};

export { ClientForm };


const styles = StyleSheet.create({
    listItem: {
        paddingHorizontal: 16,
        paddingVertical: 8,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    listItemValue: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});
