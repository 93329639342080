import { z } from 'zod';
import {
    AddressSchema,
    CompanyDomainSchema,
    EmailSchema,
    InfiniteScrollingInputSchema,
    LogoUploadInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';
import { CompanyFilterMap } from '../../../filter';

export const AdminCreateCompanyInputSchema = z.object({
    name: StringSchema,
    logoFilename: StringSchema.optional(),
    customDomain: CompanyDomainSchema,
    email: EmailSchema,
    phoneNumber: StringSchema,
    address: AddressSchema,
});

export const AdminGetCompanyLogoUploadUrlInputSchema = LogoUploadInputSchema;

export const AdminEditCompanyInputSchema =
    AdminCreateCompanyInputSchema.partial().extend({
        id: ObjectIdSchema,
    });

export const AdminGetCompaniesPageInputSchema = PaginationInputSchema.extend({
    filter: filterMappingToSchema(CompanyFilterMap),
});

export const AdminListCompaniesInputSchema =
    InfiniteScrollingInputSchema.extend({
        filter: filterMappingToSchema(CompanyFilterMap),
    });

export const AdminGetCompanyByIdInputSchema = z.object({ id: ObjectIdSchema });

export const AdminDeleteCompanyInputSchema = z.object({ id: ObjectIdSchema });

export const AdminGetCompanyTimelineInputSchema = z.object({
    id: ObjectIdSchema,
});
