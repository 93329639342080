import React, { useEffect, useState } from 'react';
import { StyleSheet } from 'react-native';
import { MB_PopUp, MB_PopUpSetDefaultStyles } from '@mightybyte/rnw.components.pop-up';
import { MB_accessTokenUtils } from '@mightybyte/rnw.utils.access-token-utils';
import { isMobile, MB_deviceInfoInitialize } from '@mightybyte/rnw.utils.device-info';
import { MB_orientationLocker, MB_ORIENTATION_LOCK } from '@mightybyte/rnw.utils.orientation-locker';
import { mbTextStyles, mbTextStylesSetDefaultFontFamily } from '@mightybyte/rnw.utils.style-utils';
import { userApiCalls } from '../apiCalls/userApiCalls';
import { appColors, getColors } from '../constants/colors';
import { FONT_TYPES, textStyles } from '../constants/textStyles';
import { fontLoader } from '../utils/fontLoader';
import { MB_Toast } from '@mightybyte/rnw.components.toast';
import { MB_ModalAttachGlobalChildren, MB_ModalSetDefaultProps } from '@mightybyte/rnw.components.modal';
import { MB_ResourceUploaderSetDefaultStyles } from '@mightybyte/rnw.components.resource-uploader';
import { TextProps, TextInputProps, Text, TextInput } from 'react-native';
import { MB_TextInputSetDefaultStyles } from '@mightybyte/rnw.components.text-input';
import { MB_ButtonSetDefaultStyles } from '@mightybyte/rnw.components.button';
import {MB_DropDownPickerSetDefaultStyles } from '@mightybyte/rnw.components.dropdownpicker/dist/src/MB_DropDownPicker';
import { BOX_SHADOW } from '../utils/styleUtils';

const COLORS = getColors();

type WithDefaultProps<T> = T & {
    defaultProps?: Object
}

function disableFontScaling() {
    (Text as WithDefaultProps<TextProps>).defaultProps = { allowFontScaling: false };
    (TextInput as WithDefaultProps<TextInputProps>).defaultProps = { allowFontScaling: false };
}

function useAppInitialSetup() {
    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        async function performInitialSetup() {

            disableFontScaling();

            await MB_deviceInfoInitialize();

            MB_orientationLocker.setDefaultOrientation(MB_ORIENTATION_LOCK.PORTRAIT);

            mbTextStylesSetDefaultFontFamily(FONT_TYPES.MuliBase);

            //todo: ASK Hovo about errors in userApiCalls.requestNewAccessToken (return void??? and "as any" here)
            MB_accessTokenUtils.setAccessTokenRefreshEndpointFunction(userApiCalls.requestNewAccessToken as any);

            MB_TextInputSetDefaultStyles({
                titleStyle: styles.textInputTitle,
                style: styles.textInputStyle,
                placeholderTextColor: COLORS.inputPlaceholder,
                textInputStyle: styles.textInputTextStyle,
            });

            MB_ButtonSetDefaultStyles({
                style: styles.button,
                textStyle: styles.buttonText,
            });

            MB_PopUpSetDefaultStyles({
                defaultContainerStyle: styles.modalPopUpDefaultContainer,
                defaultTitleStyle: styles.modalPopUpDefaultTitle,
                defaultMessageStyle: styles.modalPopUpDefaultMessage,
                defaultPrimaryButtonStyle: styles.modalPopUpDefaultPrimaryButton,
                defaultPrimaryButtonTextStyle: styles.modalPopUpDefaultPrimaryButtonText,
                defaultSeconaryButtonStyle: styles.modalPopUpDefaultSecondaryButton,
                defaultSecondaryButtonTextStyle: styles.modalPopUpDefaultSecondaryButtonText,
                defaultButtonsContainerStyleModifier: styles.modalPopUpDefaultButtonsContainerModifier,
            });

            MB_ModalSetDefaultProps({
                // childrenWrapperStyle: styles.modalChildrenWrapper,
                titleStyle: styles.modalTitle,
                closeButtonXProps: {
                    size: 12,
                    color:COLORS.closeIconColor,
                },
            });

            MB_ResourceUploaderSetDefaultStyles({
                wrapperStyle: styles.resourceUploaderDefaultContainer,
                uploadButtonStyle: styles.resourceUploaderButton,
                previewContainerStyle: styles.resourceUploaderPreviewContainer,
                previewImageBackgroundStyle: styles.resourceUploaderPreviewImageBackground,
                dragAndDropAndProcessingResourceTextStyle: styles.resourceUploaderDragAndDropText,
                modalTitleStyle: styles.modalTitle,
                cameraOrGalleryModalCloseButtonXProps: {color: COLORS.textPrimary},
                dragAndDropAndProcessingResourceButtonColor: appColors.blue600,
            });

            MB_DropDownPickerSetDefaultStyles({
                //containerStyle: { backgroundColor: 'blue' },
                style: {minHeight: 42, paddingVertical: 0},
                dropdownContainerStyle: { backgroundColor: COLORS.backgroundSecondary,
                    borderColor:COLORS.inputBorder, borderWidth: 1, borderTopWidth: 0, ...BOX_SHADOW},
                dropdownStyle: styles.dropdownStyle,
                searchTextInput: styles.searchTextInputStyle,
                placeholderStyle: mbTextStyles([textStyles.Muli_16_600, {
                    fontSize: 14,
                    color: COLORS.textSecondary,
                    textAlign: 'left',
                }]),
                dropdownLabelStyle: styles.textInputTextStyle,
                labelStyle: styles.textInputTextStyle,
                titleStyle: styles.textInputTitle,
                disableInteractions: false,
                highlightColor: appColors.gray200,
                arrowColor: COLORS.textSecondary,
                itemSeparatorStyle: { backgroundColor: COLORS.backgroundSecondary, height: 1 },
            });

            MB_ModalAttachGlobalChildren([
                <MB_PopUp key={'popUp'} />,
                <MB_Toast key={'toast'} />,
            ]);

            try {
                await fontLoader();
            } catch (error) {
                console.error('Prepare stage failed', error);
            } finally {
                setIsDone(true);
            }
        }



        if (!isDone) {
            performInitialSetup();
        }
    }, [isDone]);

    return isDone;
}


const styles = StyleSheet.create({
    modalPopUpDefaultContainer: {
        backgroundColor: COLORS.buttonPrimaryBg,
        borderRadius: 8,
        borderColor: COLORS.modalBorder,
        padding: 20,
    },
    modalPopUpDefaultTitle: mbTextStyles([textStyles.Muli_16_600]),
    modalPopUpDefaultMessage: mbTextStyles([
        [textStyles.Muli_16_600],
    ]),
    modalPopUpDefaultPrimaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        alignSelf: 'center',
        backgroundColor: COLORS.buttonPrimaryBg,
        borderWidth: 1,
        borderColor: COLORS.buttonPrimaryBorder,

    },

    modalPopUpDefaultPrimaryButtonText: mbTextStyles([
        textStyles.Muli_16_500, {
            color: COLORS.buttonPrimaryText,
            fontSize: 14,
        },
    ]),

    modalPopUpDefaultSecondaryButton: {
        height: isMobile ? 38 : 38,
        minWidth: 115,
        alignSelf: 'center',
        backgroundColor: COLORS.buttonSecondaryBg,
        borderWidth: 1,
        borderColor: COLORS.buttonSecondaryBorder,
    },

    modalPopUpDefaultSecondaryButtonText: mbTextStyles([
        textStyles.Muli_16_500, {
            color: COLORS.buttonSecondaryText,
            fontSize: 14,

        },
    ]),

    modalPopUpDefaultButtonsContainerModifier: {
        marginTop: 33,
    },
    resourceUploaderDefaultContainer: {
        backgroundColor: COLORS.backgroundPrimary,
        paddingHorizontal: 68.5,
    },
    resourceUploaderPreviewContainer: {
        backgroundColor: appColors.gray300,
        borderWidth: 1,
        borderColor: COLORS.inputBorder,
        borderStyle: 'dashed',
    },
    resourceUploaderDragAndDropText: mbTextStyles([
        textStyles.Muli_16_600, {},
    ]),
    resourceUploaderButton: {
        minWidth: 228,
    },
    resourceUploaderPreviewImageBackground: {
        borderRadius: 300,
        width: 82,
        height: 82,
        backgroundColor: COLORS.backgroundPrimary,
        alignItems: 'center',
        justifyContent: 'center',
    },
    textInputTitle: mbTextStyles([
        textStyles.Muli_16_700, {
            color: COLORS.inputLabel,
            fontSize: 14,
            textAlign: 'left',
            marginBottom: 0,
            lineHeight: 28,
        },
    ]),
    textInputStyle: {
        borderRadius: 6,
        borderWidth: 1,
        borderColor: COLORS.inputBorder,
        backgroundColor: COLORS.inputBackground,
        height: 42,
        paddingHorizontal: 10,
    },
    textInputTextStyle: mbTextStyles([
        textStyles.Muli_16_600, {
            color: COLORS.inputText,
            fontSize: 14,
            textAlign: 'left',
        },
    ]),

    button: {
        backgroundColor: COLORS.buttonPrimaryBg,
        borderRadius: 8,
        height: 41,
    },
    buttonText: mbTextStyles([
        textStyles.Muli_16_600, {
            color: COLORS.buttonPrimaryText,
            fontSize: 14,
        },
    ]),
    modalChildrenWrapper: {
        backgroundColor: COLORS.backgroundPrimary,
    },
    modalTitle: mbTextStyles([
        textStyles.Muli_16_600, {
            fontSize: 18,
        },
    ]),
    dropdownStyle: {
        backgroundColor: COLORS.inputBackground,
    },
    dropdownBadgeStyle: {
        borderRadius: 16,
        backgroundColor: appColors.blue100,
        paddingHorizontal: 8,
        paddingVertical: 4,
        marginRight: 8,
    },
    searchTextInputStyle: mbTextStyles([
        textStyles.Muli_16_600, {
            color: COLORS.textSecondary,
            fontSize: 14,
            textAlign: 'left',
        },
    ]),
    dropdownContainerStyle: {
        maxHeight: 300,
        borderRadius: 4,
        borderColor: COLORS.inputBorder,
        borderTopWidth: 1,
    },
    requiredAsteriksColor: {
        color: '#DD3636',
    },
});

export { useAppInitialSetup, styles as initialStyles };
