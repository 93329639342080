import React, {useMemo} from 'react';
import { StyleSheet, Text, TouchableOpacity } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { FONT_TYPES } from '../../../constants/textStyles';
import { appColors } from '../../../constants/colors';

const ClickableText = ({text, action, color, removeLeftPadding, removeRightPadding, outline}: {
    text: string,
    action: () => void,
    color?: string | undefined,
    removeLeftPadding?: boolean,
    removeRightPadding?: boolean,
    outline?: boolean,
})=> {
    const styles = useMemo(()=> getStyles({
            textColor: color ?? appColors.primary700,
            paddingLeft: removeLeftPadding ? 0 : 10,
            paddingRight: removeRightPadding ? 0 : 10,
    }), [color, removeLeftPadding, removeRightPadding]);

    return (
        <TouchableOpacity style={[styles.button, {}]} onPress={action}>
            <Text style={[styles.text, outline ? {
                borderBottomWidth: 2,
                borderColor: color ?? appColors.primary700,
            } : null]}>{text}</Text>
        </TouchableOpacity>
    );
};

export {ClickableText};

const getStyles = (params: {
    textColor: string
    paddingLeft: number,
    paddingRight: number,
}) => StyleSheet.create({
    button: {
        height: 40,
        paddingLeft: params.paddingLeft,
        paddingRight: params.paddingRight,
        justifyContent: 'center',
    },

    text: mbTextStyles([
        {
            fontFamily: FONT_TYPES.Inter500Medium,
            fontSize: 14,
            textAlign: 'left',
            color: params.textColor,
        },
    ]),
});
