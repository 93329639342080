import React, { useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import {ComponentWrapper} from './componentWrapper/ComponentWrapper';
import { CopyRightFooter } from './CopyrightFooter';
import { drawerWidth, rightViewPadding, rightViewPaddingMobile } from '../../constants/elementSizes';
import {getColors} from '../../constants/colors';
import { HeaderProps } from './Header/types';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
const COLORS = getColors();

const UIDrawerRightContentWrapper = ({
    headerProps,
    removePaddingX,
    wrapInScrollView,
                                         children,
    filterIsOpen,
}: {
    headerProps: HeaderProps,
    removePaddingX?: boolean,
    wrapInScrollView?: boolean,
    filterIsOpen?: boolean,
    children: React.ReactNode}) => {

    const horizontalPadding = useMemo(()=> {
        const padding = removePaddingX ? 0 : isMobileApp ? rightViewPaddingMobile : rightViewPadding;
        return {
            paddingRight: isMobileApp ? rightViewPaddingMobile : rightViewPadding,
            paddingLeft: (!isMobileApp && filterIsOpen) ? drawerWidth + padding  : padding,
        };
    }, [removePaddingX, filterIsOpen]);
    return (
        <ComponentWrapper innerContainerStyle={[isMobileApp ? styles.containerMobile : styles.container]}
                          headerProps={headerProps}
                          wrapInScrollView={wrapInScrollView}

        >
            <View style={[styles.childrenContainer, horizontalPadding]}>
                {children}
                {isMobileApp ? null : <CopyRightFooter/>}
            </View>


        </ComponentWrapper>
    );
};

export {UIDrawerRightContentWrapper};


const styles = StyleSheet.create({
    container: {
        backgroundColor: COLORS.backgroundSecondary,
        // justifyContent: 'space-between',
        paddingBottom: 0,
    },
    containerMobile: {
        backgroundColor: COLORS.backgroundSecondary,
        justifyContent: 'flex-start',
        paddingBottom: 0,

    },
    childrenContainer: {
        paddingTop: 12,
        paddingBottom: 0,
    },
});
