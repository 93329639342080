import { z } from 'zod';
import {
    AddressSchema,
    AlphanumericSchema,
    EmailSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';
import { BranchFilterMap } from '../../../filter';

export const CreateBranchInputSchema = z.object({
    regionId: ObjectIdSchema,
    companyId: ObjectIdSchema,
    name: AlphanumericSchema,
    phoneNumber: StringSchema,
    email: EmailSchema,
    address: AddressSchema,
});

export const GetBranchByIdInputSchema = z.object({
    branchId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetBranchesPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(BranchFilterMap),
});

export const ListBranchesInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(BranchFilterMap),
});

export const GetBranchesDropdownInputSchema = z.object({
    companyId: ObjectIdSchema,
    regionId: ObjectIdSchema.optional(),
});

export const EditBranchInputSchema = CreateBranchInputSchema.partial().extend({
    branchId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const DeleteBranchInputSchema = z.object({
    branchId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const GetBranchTimelineInputSchema = z.object({
    branchId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});
