import { z } from 'zod';
import {
    DateInputSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    filterMappingToSchema,
} from '../../common';
import { EmployeeAcceptanceStatus } from '../../../entities';
import { ShortlistFilterMap } from '../../../filter';

export const CreateShortlistInputSchema = z.object({
    companyId: ObjectIdSchema,
    workOrderId: ObjectIdSchema,
    date: DateInputSchema,
    assignedTo: z
        .object({
            employeeId: ObjectIdSchema,
            acceptanceStatus: z.nativeEnum(EmployeeAcceptanceStatus),
        })
        .array()
        .optional(),
});

export const GetShortlistsPageInputSchema = PaginationInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ShortlistFilterMap),
});

export const ListShortlistsInputSchema = InfiniteScrollingInputSchema.extend({
    companyId: ObjectIdSchema,
    filter: filterMappingToSchema(ShortlistFilterMap),
});

export const GetShortlistByIdInputSchema = z.object({
    companyId: ObjectIdSchema,
    shortlistId: ObjectIdSchema,
});

export const AssignShortlistEmployeesInputSchema = z.object({
    shortlistId: ObjectIdSchema,
    companyId: ObjectIdSchema,

    /**
     * Note: this will overwrite the currently assigned employees.
     */
    assignedTo: z
        .object({
            employeeId: ObjectIdSchema,
            acceptanceStatus: z.nativeEnum(EmployeeAcceptanceStatus),
        })
        .array(),
});

export const GetAssignedShortlistEmployeesInputSchema = z.object({
    shortlistId: ObjectIdSchema,
    companyId: ObjectIdSchema,
});

export const DeleteShortlistInputSchema = z.object({
    companyId: ObjectIdSchema,
    shortlistId: ObjectIdSchema,
});

export const GetAvailableShortlistEmployeesInputSchema = z.object({
    companyId: ObjectIdSchema,
    workOrderId: ObjectIdSchema,
    shortlistId: ObjectIdSchema.optional(),
});
