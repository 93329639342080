import React, { useCallback } from 'react';
import {View, Text } from 'react-native';
import {ChecklistEmployeeTableData} from '../types';
import { EmployeeBadge } from '../EmployeeBadge';
import { WrappedInput } from '../../../../helperComponents/misc/WrappedInput';
import { MB_TextInput } from '@mightybyte/rnw.components.text-input';
import { recordModalContentStyles } from '../../../../../constants/recordModalContentStyles';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { applyTTStyles } from '../../../../../constants/colors';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { OutlinedButton } from '../../../../helperComponents/misc/OutlinedButton';
import {COLORS} from '../../../../../constants/colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../../helperComponents/misc/Spacer';

const CommentModalContent = ({employee, action, cancelAction, comment, submitInProgress, deleteComment, submitError, isClient}: {
    employee: ChecklistEmployeeTableData;
    action: (comment: string)=>void;
    cancelAction: ()=>void;
    deleteComment: ()=>void;
    comment?: string;
    submitInProgress?: boolean;
    submitError?: string;
    isClient?: boolean;
}) => {

    const isEditing = comment && comment?.length > 0;

    const [commentText, setCommentText] = React.useState<string>(comment ?? '');
    const [errorMessage, setErrorMessage] = React.useState<string>('');

    const SubmitAction = useCallback(()=> {
        if (!commentText || commentText.length === 0) {
            setErrorMessage('Please enter a comment');
            return;
        }
        action(commentText);
    }, [commentText, action]);

    return (
        <View>
            <EmployeeBadge name={`${employee.firstName} ${employee.lastName}`} disabled={true}/>
            <WrappedInput errorMessage={errorMessage}
                inputComponent={<MB_TextInput
                            title={'Comment'}
                            value={commentText}
                            onChangeText={setCommentText}
                            placeholder={'Add comment'}
                            multiline={true}
                            style={{width: '100%', height: 100}}
                            autoCorrect={true}
                            autoFocus={true}
                            returnKeyType={'done'}
                            blurOnSubmit={true}
                            disable={submitInProgress || !isClient}
            />}

            />
            <View style={recordModalContentStyles.errorContainer}>
                <Text style={recordModalContentStyles.errorMessage}>{submitError ?? ''}</Text>
            </View>
            <View style={[recordModalContentStyles.row,  recordModalContentStyles.buttonRow, !isClient ? {justifyContent: 'center'} : undefined]}>
                {isClient ? <View style={recordModalContentStyles.rowItem}>
                    <MB_Button title={isEditing ? 'Save changes' : 'Add Comment'}
                               onPress={SubmitAction}
                               disabled={submitInProgress}
                               style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                               leftElement={isEditing ? undefined : <AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                    />
                    {isMobileApp ? <Spacer y={24}/> : undefined}
                </View> : null }
                <View style={recordModalContentStyles.rowItem}>
                    {isEditing && isClient ? <OutlinedButton title={'Delete comment'} action={deleteComment}/>
                        : <OutlinedButton title={isClient ? 'Discard' : 'Close'}
                                    disabled={submitInProgress}
                                    action={cancelAction}
                    />}
                </View>

            </View>
        </View>
    );
};

export {CommentModalContent};
