import React from 'react';
import {Text, View, StyleSheet} from 'react-native';
import {EmployeeAcceptanceStatus} from '@temptrack/business';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../constants/textStyles';
import { COLORS } from '../../../../constants/colors';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';

const AcceptOrDeclineJob = ({
    actionType,
    woNumber,
    action,
    cancelAction,
    isLoading,
}: {
    actionType: EmployeeAcceptanceStatus.declined | EmployeeAcceptanceStatus.accepted
    woNumber: string,
    action: () => void,
    cancelAction: () => void
    isLoading: boolean
}) => {

    const isAccept = actionType === EmployeeAcceptanceStatus.accepted;
    return (
        <View style={{justifyContent: 'space-between', flex: 1}}>
            <Text style={styles.text}>{`You are about to ${isAccept ? 'accept' : 'decline'}  the offer for the job`}
                <Text style={styles.textBold}>{`\u00A0 ${woNumber}.\u00A0`}</Text>
                <Text style={styles.text}>{'Note that this action cannot be undone.'}</Text>
            </Text>

            <View style={[styles.row, isMobileApp ? {marginTop: 36} : undefined]}>
                <View style={styles.rowItem}>
                    <MB_Button
                        title={isAccept ? 'Accept' : 'Decline'}
                        onPress={action}
                        style={{backgroundColor: isAccept ? COLORS.buttonPrimaryBg : COLORS.buttonDangerBg}}
                        loading={isLoading}
                        disabled={isLoading}
                    />
                </View>
                <View style={styles.rowItem}>
                    <OutlinedButton title={'Discard'}
                                    action={cancelAction}
                    />
                </View>

            </View>

        </View>
    );
};

export {AcceptOrDeclineJob};

const styles = StyleSheet.create({
    text: mbTextStyles([textStyles.Muli_16_400, {textAlign: 'center', color: COLORS.textPrimary}]),
    subtext: mbTextStyles([textStyles.Muli_16_400, {textAlign: 'center', color: COLORS.textSecondary}]),
    textPleaseWait: mbTextStyles([textStyles.Muli_16_600, {textAlign: 'center', color: COLORS.textSecondary}]),
    textBold: mbTextStyles([textStyles.Muli_16_700, {textAlign: 'center', color: COLORS.textPrimary}]),
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 18,
    },

    rowItem: {
        width: '45%',
    },
});
