import { mbShadow, mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { StyleSheet } from 'react-native';
import { textStyles } from '../constants/textStyles';
import { COLORS } from '../constants/colors';

export const BOX_SHADOW = mbShadow({
    offsetWidth: 0,
    offsetHeight: 3,
    color:  '#000000',
    opacity: 0.1,
    radius: 5,
    elevation: 5,
});

export const sharedStyles = StyleSheet.create({
    textInputFullWidthAndTopSpace: {
        width: '100%',
        marginTop: 5,
    },

    disabledButton: {
        opacity: 0.5,
    },

    touchebleTextButton: {
        height: 40,
        paddingHorizontal: 10,
        justifyContent: 'center',
    },
    inputSubText: mbTextStyles([
        textStyles.Muli_16_400, {
            color: COLORS.textSecondary,
            fontSize: 14,
            textAlign: 'left',
            marginTop: 5,
        },
    ]),

});
