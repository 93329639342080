import React from 'react';
import { View, Text, StyleSheet, ViewStyle } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import { getColors } from '../../../constants/colors';
import { sharedStyles } from '../../../utils/styleUtils';
import { initialStyles } from '../../../hooks/useAppInitialSetup';

const COLORS = getColors();

const WrappedInput = ({
    inputComponent,
    errorMessage,
    containerStyle,
    subText,
    title,
                          showRequiredAsterisk,
    errorContainerStyle,
}: {
    inputComponent: JSX.Element,
    errorMessage?: string | undefined,
    containerStyle?: ViewStyle
    subText?: string | undefined,
    title?: string | undefined,
    showRequiredAsterisk?: boolean,
    errorContainerStyle?: ViewStyle,

}) => {

    return (
        <View style={[styles.container, containerStyle]}>
            {title ? <View>
                <Text style={initialStyles.textInputTitle}>{title}{showRequiredAsterisk ? <Text style={initialStyles.requiredAsteriksColor}>*</Text> : null}</Text>
            </View> : null}
            {inputComponent}
            <View style={[{minHeight: 18, zIndex: -1, flexDirection: 'column'}, errorContainerStyle]}>
                {errorMessage && errorMessage.length > 0 ? <Text style={styles.errorMessage}>{errorMessage}</Text> : null}
                {subText && subText.length > 0 ? <Text style={sharedStyles.inputSubText}>{subText}</Text> : null}
        </View>
        </View>
    );
};

const styles = StyleSheet.create({
    container: {
        width: '100%',
    },
    errorMessage: mbTextStyles([
        textStyles.Muli_16_400, {
            color: COLORS.inputErrorLabel,
            fontSize: 14,
            textAlign: 'left',
            marginTop: 5,
            marginRight: 10,
        },
    ]),
});

const errorInputStyles = {
    text: {
        color: COLORS.inputErrorText,
    },
    input: {
        borderColor: COLORS.inputErrorBorder,
    },
    label: {
        color: COLORS.inputErrorLabel,
    },
};

const inputComponentStyles = (isError:boolean)=> {
    return {
        style: [sharedStyles.textInputFullWidthAndTopSpace, isError && errorInputStyles.input],
        placeholderTextColor: isError ? getColors().inputErrorText : getColors().inputPlaceholder,
        textInputStyle: mbTextStyles([isError && errorInputStyles.text]),
        titleStyle: isError && errorInputStyles.label,
    };
};



export { WrappedInput, errorInputStyles, inputComponentStyles };
