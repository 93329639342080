import React, { useMemo } from 'react';
import { StyleSheet, Text, View } from 'react-native';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../constants/textStyles';
import { appColors, getColors } from '../../constants/colors';
import { BOX_SHADOW } from '../../utils/styleUtils';
import { MB_Pressable } from '@mightybyte/rnw.components.pressable';
import { MB_OnHoverWrapper } from '@mightybyte/rnw.components.on-hover-wrapper';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();

type MenuItem = {
    title: string;
    onPress: () => void;
    textStyle?: any;
    disabled?: boolean;
}

const ActionMenu = ({menuItems, closeMenu, rowId, callerY, callerX, visible}:{
    menuItems: MenuItem[];
    closeMenu: () => void;
    rowId: string;
    callerX: number;
    callerY: number;
    visible: boolean;

}) => {
    const action = (item:MenuItem) => {
        if (item.disabled) {
            return;
        } else {
            item.onPress();
        }
    };
    const position = useMemo(() => {
        if (isMobileApp) {
            return {top: callerY - 110, right: -30};
        }
        return {top: callerY - 50, left: callerX - 180};
    }, [callerX, callerY]);
    return visible ? <MB_Pressable style={styles.actionsMenuModal}
                                   onPress={closeMenu}
    >
        <View key={rowId}
              style={[styles.actionsMenuContainer, position]}>
            {menuItems.map((item, index) => {
                return (
                    <MB_OnHoverWrapper
                        key={index}
                        wrapperStyle={styles.action}
                        onHoverWrapperStyleModifier={item.disabled ? styles.actionHoveDisabled : styles.actionHover}
                        onPress={() => action(item)}
                    >
                        {() => (<Text style={[styles.actionText, item.textStyle, item.disabled ? styles.disabledText : undefined]}>{item.title}</Text>)}
                    </MB_OnHoverWrapper>
                );
            })}
        </View>
    </MB_Pressable> : null;
};

export { ActionMenu };

const styles = StyleSheet.create({
    actionsMenuModal: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
        backgroundColor: 'transparent',
        elevation: 200,
    },
    actionsMenuContainer: {
        ...BOX_SHADOW,
        width: 200,
        height: 'auto',
        backgroundColor: COLORS.backgroundPrimary,
        borderRadius: 8,
        borderWidth: 1,
        borderColor: COLORS.inputBorder,
        position: 'absolute',
        zIndex: 100,
        margin: 6,
        marginBottom: 8,
        padding: 8,
    },
    action: {
        height: 32,
        borderRadius: 4,
        alignItems: 'flex-start',
        justifyContent: 'center',
        paddingStart: 8,
    },
    actionHover: {
        backgroundColor: appColors.gray100,
    },
    actionHoveDisabled: {
         backgroundColor: COLORS.backgroundPrimary,
    },

    actionText: mbTextStyles([
        textStyles.base, {
            color: COLORS.textPrimary,
            textAlign: 'left',
            fontSize: 14,
            fontWeight: '400',
        },
    ]),
    disabledText: {
        color: appColors.gray400,
    },
});

