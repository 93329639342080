import React, { ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { trpc, reactTRPCClient } from '../apiCalls/trpcClient';
import { signedInContextGlobalFunction } from './SignedInContext';
import {APIError} from '@temptrack/business';


const handleTrpcError = async (error: any) => {
    const parsedError = JSON.parse(JSON.stringify(error));
    const err = parsedError?.data?.error;
    if (err?.code === APIError.invalidAccessToken.code ||
        err?.code === APIError.invalidRefreshToken.code ||
        err?.code === APIError.expiredAccessToken.code)
    {
        const makeRefreshTokenCall = err?.code === APIError.invalidAccessToken.code ||
            err?.code === APIError.invalidRefreshToken.code;
        console.log('Sign out from Query context');
        signedInContextGlobalFunction?.signOut?.({
            skipSignOutRequest: true,
            showExpiredError: true,
            makeRefreshTokenCall,
        });
    }
    if (err?.code === APIError.inactiveUser.code) {
        signedInContextGlobalFunction?.signOut?.({
            skipSignOutRequest: true,
            showExpiredError: false,
        });
    }
};

const queryClient = new QueryClient({
    defaultOptions: {
        mutations: {
            onError: (error:any) => {
                handleTrpcError(error);
            },
        },
        queries: {
            onError: (error:any) => {
                handleTrpcError(error);
            },
        },
    },
});

const QueryContextProvider = ({ children }: { children?: ReactNode }) => {
    return (
        <trpc.Provider client={reactTRPCClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
                {children}
            </QueryClientProvider>
        </trpc.Provider>
    );
};

export { QueryContextProvider };
