import React, { useCallback, useMemo} from 'react';
import { Text, View, GestureResponderEvent, ScrollView } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EditIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { AdminCompaniesTableProps } from './types';

const COLORS = getColors();

const header = [
    { content: 'company name', conatinerStyle: { minWidth: 300, maxWidth: 300, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'address', conatinerStyle: { minWidth: 300, maxWidth: 300, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'email', conatinerStyle: {  minWidth: 250, maxWidth: 250, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'phone number', conatinerStyle: { minWidth: 170, maxWidth: 170, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1,  minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const CompaniesTable = ({data, isLoading, openRowMenu, onEditRecordPressed, onAddPress, addButtonText, hasFilters}: AdminCompaniesTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }

        return data.map((item) => {
            const companyData = item;
            return [
                <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.name}</Text>,
                <Text style={tableStyles.tableRowText} numberOfLines={2}>
                    {utils.getAddressToDisplay(item?.address)}
                </Text>,
                <Text style={[tableStyles.tableRowText, {color: COLORS.textSecondary}]} numberOfLines={1}>{item?.email}</Text>,
                <Text style={[tableStyles.tableRowText, {color: COLORS.textSecondary}]} numberOfLines={1}>{utils.phoneNumberToDisplay(item.phoneNumber)}</Text>,
                <View style={{
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                }}>
                    <EditIconButton action={()=>onEditRecordPressed(companyData.id)}/>
                    {/*{rowButton}*/}
                    <IconButton icon={
                        <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                    }
                                action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                    />
                    {/*<RowMenu items={menuItems(item)}/>*/}
                </View>,

            ];
        });
    }, [data, onEditRecordPressed, openRowMenu]);

    return (
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={header}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No companies yet'}
                                                          subtitle={'Start adding companies to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
    );
};

export { CompaniesTable };
