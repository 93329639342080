import * as React from 'react';
import Svg, { Path } from 'react-native-svg';
import { StyleProp, ViewStyle } from 'react-native';

const RegionsIconSvg = React.memo((props: { size?: number,
    containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <Svg
            width={props.size ?? 24}
            height={props.size ?? 24}
            viewBox="0 0 24 24"
            fill="none"
        >
            <Path
                d="M21.89 7.172C22 8.432 22 10.006 22 12c0 4.134 0 6.455-.987 7.95L15.06 14l6.83-6.828zm-1.938 13.84L14 15.06l-6.828 6.83C8.433 22 10.006 22 12 22c4.134 0 6.456 0 7.952-.988z"
                fill={props.color ?? '#9CA3AF'}
            />
            <Path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 2c4.714 0 7.071 0 8.535 1.464.504.504.835 1.114 1.052 1.889L5.353 21.587c-.775-.217-1.385-.548-1.889-1.052C2 19.072 2 16.714 2 12s0-7.071 1.464-8.536C4.93 2 7.286 2 12 2zM5.5 8.757c0 1.785 1.117 3.868 2.86 4.613.406.173.874.173 1.28 0 1.743-.745 2.86-2.828 2.86-4.613C12.5 6.958 10.933 5.5 9 5.5S5.5 6.958 5.5 8.757z"
                fill={props.color ?? '#9CA3AF'}
            />
            <Path d="M10.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" fill={props.color ?? '#9CA3AF'} />
        </Svg>
    );
});

export {RegionsIconSvg};
