import React from 'react';
import { Text, View } from 'react-native';
import { ChecklistAssignmentStatus, toAssignmentStatusLabel } from '@temptrack/business';
import { tableStyles } from '../../../../constants/tableStyles';
import { Spacer } from '../Spacer';

export type AssignmentStatusRendererProps = {
    assignmentStatus: {
        status: ChecklistAssignmentStatus | undefined,
        assignedToQty: number | undefined,
        employeesNeededQty: number | undefined,
    } | undefined,
    displayRow?: boolean

};

const AssignmentStatusRenderer = ({assignmentStatus, displayRow}: AssignmentStatusRendererProps) => {
    if (!assignmentStatus) {return null;}

    return (
        <View style={{flexDirection: displayRow ? 'row' : 'column', alignItems: displayRow ? 'center' : 'flex-start'}}>
            {assignmentStatus.status ? <Text style={tableStyles.tableRowText}>{toAssignmentStatusLabel(assignmentStatus.status)}</Text> : null}
            {displayRow ? <Spacer x={6}/> : null}
            <Text style={tableStyles.subText}>{assignmentStatus.assignedToQty}/{assignmentStatus.employeesNeededQty}</Text>
        </View>
    );

};

export {AssignmentStatusRenderer};
