import { z } from 'zod';
import { UserStatus } from '../../..';
import { TempTrackUserFilterMap } from '../../../filter';
import {
    EmailSchema,
    InfiniteScrollingInputSchema,
    ObjectIdSchema,
    PaginationInputSchema,
    StringSchema,
    filterMappingToSchema,
} from '../../common';

export const AdminCreateUserInputSchema = z.object({
    firstName: StringSchema,
    lastName: StringSchema,
    email: EmailSchema,
    phoneNumber: StringSchema,
    status: z.nativeEnum(UserStatus),
});

export const AdminGetUsersPageInputSchema = PaginationInputSchema.extend({
    filter: filterMappingToSchema(TempTrackUserFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const AdminListUsersInputSchema = InfiniteScrollingInputSchema.extend({
    filter: filterMappingToSchema(TempTrackUserFilterMap),
    isInvitePending: z.boolean().optional(),
});

export const AdminGetUserByIdInputSchema = z.object({
    userId: ObjectIdSchema,
});

export const AdminEditUserInputSchema =
    AdminCreateUserInputSchema.partial().extend({
        id: ObjectIdSchema,
    });

export const AdminChangeUserPasswordInputSchema = z.object({
    /**
     * The user to change the password for.
     */
    userId: ObjectIdSchema,

    newPassword: StringSchema,
});

export const AdminResendInviteInputSchema = z.object({
    /**
     * The user to resend the invite to.
     */
    userId: ObjectIdSchema,
});

export const AdminDeleteUserInputSchema = z.object({
    userId: ObjectIdSchema,
});

export const AdminGetUserTimelineInputSchema = z.object({
    userId: ObjectIdSchema,
});
