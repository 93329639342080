import { MB_BLUR_TINT } from '@mightybyte/rnw.components.blur';

const tableReusableProps:{
    hideHeaderWhenEmpty: boolean,
    loadingScreenProps: {
        blurTint?: MB_BLUR_TINT,
        androidBlurViewOverlayColor?: string,
        textColor?: string,
    }
} = {
    hideHeaderWhenEmpty: true,
    loadingScreenProps: {
        blurTint: MB_BLUR_TINT.dark,
        androidBlurViewOverlayColor: 'rgba(0,0,0,0.5)',
        textColor: '#fff',
    },
};

export { tableReusableProps };
