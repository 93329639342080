import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import {getColors} from '../../constants/colors';
import {textStyles} from '../../constants/textStyles';
import {mbTextStyles} from '@mightybyte/rnw.utils.style-utils';
import {AddButton} from './misc/AddButton';
import { listViewHeaderHeight, rightViewPaddingMobile, listViewHeaderHeighNoFilters } from '../../constants/elementSizes';
import { Spacer } from './misc/Spacer';
import Entypo from 'react-native-vector-icons/Entypo';
import { IconButton } from './misc/IconButton';
import { OutlinedButton } from './misc/OutlinedButton';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const COLORS = getColors();

const ListViewHeader = ({title, subtitle, subtitleComponent, buttonText, onAddPress, buttonColor, hideAddButton, hideButtonLeftElement, headerExtraComponent,
                            showGoBack, backButtonAction, buttonDisabled, buttonIsLoading, buttonIcon, cancelButton, leftButtonElement, filterComponent, toggleViewComponent}: {
    title: string,
    subtitle?: string,
    subtitleComponent?: JSX.Element | null,
    buttonText: string,
    onAddPress: () => void,
    buttonColor?: string,
    hideAddButton?: boolean,
    hideButtonLeftElement?: boolean,
    headerExtraComponent?: JSX.Element
    showGoBack?: boolean
    backButtonAction?: () => void
    buttonDisabled?: boolean
    buttonIsLoading?: boolean
    buttonIcon?: JSX.Element
    cancelButton?: {
        action: () => void
        text: string
        color?: string;
        icon?: JSX.Element
    } | undefined
    leftButtonElement?: JSX.Element
    filterComponent?: JSX.Element
    toggleViewComponent?: JSX.Element,
})=> {

    return (
        <View style={{
            height: isMobileApp ? undefined : filterComponent ? listViewHeaderHeight : listViewHeaderHeighNoFilters,
            width: '100%',
            marginBottom: isMobileApp ? 10 : 16,
            backgroundColor: COLORS.backgroundSecondary,
        }}>
            <View style={isMobileApp ? styles.mobileContainer : styles.container}>
                <View style={[styles.pageHeaderContainer,
                    isMobileApp ? styles.pageHeaderContainerMobile : null,
                ]}>
                    <View>
                        <View style={{flexDirection: 'row', alignItems:'center'}}>
                            {showGoBack ? <IconButton icon={<Entypo name="chevron-left" size={24} color={COLORS.textPrimary}/>}
                                                      action={backButtonAction ?? null}/>
                                : null}
                            <Text style={isMobileApp ? styles.pageHeaderMobileStyles : styles.pageHeader}>{title}</Text>
                            {(headerExtraComponent && !isMobileApp) ? <>
                                <Spacer x={8}/>
                                {headerExtraComponent}
                            </> : null}
                        </View>
                        {(subtitle && !isMobileApp) ? <Text style={[styles.pageSubheader, {marginLeft: showGoBack ? 44 : undefined}]}>{subtitle}</Text> : null}
                        {subtitleComponent ? subtitleComponent : null}
                        {headerExtraComponent && isMobileApp ? <View style={{width: '100%'}}>
                            <Spacer y={8}/>
                            {headerExtraComponent}
                        </View> : null}
                    </View>
                    {isMobileApp ? <>{filterComponent}</> : null}

                </View>
                <View style={{flexDirection: isMobileApp ? 'column' : 'row-reverse'}}>
                    {hideAddButton ? null : <View style={isMobileApp ? styles.buttonContainerMobile : styles.buttonContainer}>

                        {cancelButton ?  <OutlinedButton title={cancelButton?.text ?? 'Cancel'} action={cancelButton?.action ?? null} extraStyles={{
                            marginRight: isMobileApp ? undefined : 16,
                            marginBottom: isMobileApp ? 16 : undefined,
                            width: isMobileApp ? '100%' : undefined,
                            borderColor: cancelButton.color ?? undefined,
                        }} textStyles={mbTextStyles({
                            color: cancelButton.color ?? undefined,
                        })}
                        leftIcon={cancelButton.icon}
                        /> : null}
                        <View style={{flexDirection: 'row'}}>
                            {(leftButtonElement) ? <View style = {{
                                marginRight: 16,
                                width: isMobileApp ? '50%' : undefined,
                            }}>
                                { leftButtonElement}
                            </View> : null}
                            <AddButton buttonText={buttonText}
                                       onAddPress={onAddPress}
                                       style={{backgroundColor: buttonColor ? buttonColor : COLORS.buttonPrimaryBg,
                                           width: isMobileApp ? leftButtonElement ? '50%' : '100%' : undefined,
                                       }}
                                       hideLeftElement={hideButtonLeftElement}
                                       disabled={buttonDisabled}
                                       isLoading={buttonIsLoading}
                                       buttonIcon={buttonIcon}
                            />
                        </View>

                    </View>}
                    {toggleViewComponent ? toggleViewComponent : null}
                </View>

            </View>
            {isMobileApp ? null : filterComponent ? <>
                <Spacer y={8}/>
                {filterComponent}
            </> : null}

        </View>
    );
};


export {ListViewHeader};

const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    mobileContainer: {
        marginBottom: 16,
    },
    pageHeaderContainer: {
        alignItems: 'flex-start',
    },
    pageHeaderContainerMobile: {
        marginBottom: 16,
        flexDirection: 'row',
        justifyContent: 'space-between',
        overflow: 'visible',
    },
    pageHeader: mbTextStyles([
        textStyles.Muli_16_700, {
            fontSize: 30,
            lineHeight: 45,
        },
    ]),
    pageHeaderMobileStyles: mbTextStyles([
        textStyles.Muli_16_700, {
            fontSize: 24,
            textAlign: 'left',
            marginBottom: 4,
        },
    ]),
    pageSubheader: mbTextStyles([
        textStyles.Muli_16_400,{
        textAlign: 'left',
        },
    ]),
    tableHeaderContainer: {

        alignItems: 'center',
        paddingHorizontal: 16,
        backgroundColor: COLORS.backgroundPrimary,
    },
    tableTitle: mbTextStyles([
        textStyles.Muli_16_600,
    ]),
    buttonContainer: {
       flexDirection: 'row',
    },
    buttonContainerMobile: {
        flexDirection: 'column',
        paddingHorizontal: rightViewPaddingMobile,
    },


});

