import React, {useCallback} from 'react';
import {View, FlatList } from 'react-native';
import { FilterOption, FilterPanelProps } from './types';

const FilterPanelUI = (props:FilterPanelProps)=> {
    const keyExtractor = useCallback((item: FilterOption, index) => `${index}-${item.apiName}`, []);

    const RenderCell = useCallback(({ index, style, ...otherProps })=> {
        const zIndex = (props.numberOfOptions - index) * 1000;
        const cellStyle = [
            style,
            {zIndex: zIndex},
        ];
        return <View index={index} style={cellStyle} {...otherProps} />;
    },[props.numberOfOptions]);
    return (
        <>
            <FlatList
                renderItem={({item, index})=>props.renderComponent({
                    item: item,
                    index: index,
                })}
                CellRendererComponent={RenderCell}
                keyExtractor={keyExtractor}

                data={props.listData}
                ListFooterComponent={props.ListFooterComponent}
                nestedScrollEnabled={true}
            />
        </>

    );
};

export {FilterPanelUI};
