import { useCallback, useMemo } from 'react';
import { useSignedInContext } from '../context/SignedInContext';
import { UserDataForClient, UserRole } from '@temptrack/business';

enum ConditionalFields {
    region = 'region',
    branch = 'branch',
    clientName = 'clientName',
    workOrderStatus = 'workOrderStatus',
}

type FieldSettings = {
    allowedToView: boolean;
    allowedToEdit: boolean;
    getDefaultValue: (userDate: UserDataForClient)=> any;
}

const permissions: {
    [key in UserRole]: {
        [k in ConditionalFields]?: FieldSettings;
    } | undefined;
} = {
    [UserRole.admin]: undefined,
    [UserRole.companyAdmin]: undefined,
    [UserRole.regionAdmin]: {
        [ConditionalFields.region]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userDate: UserDataForClient)=> {
                return {
                    regionId: userDate.regionId,
                    regionName: userDate.regionName,
                };
            },
        },
    },
    [UserRole.client]: {
        [ConditionalFields.clientName]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userData: UserDataForClient)=> {
                return userData.companyId;
            },
        },
        [ConditionalFields.region]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userData: UserDataForClient)=> {
                return {
                    regionId: userData.regionId,
                    regionName: userData.regionName,
                };
            },
        },
        [ConditionalFields.branch]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userData: UserDataForClient)=> {
                return {
                    branchId: userData.branchId,
                    branchName: userData.branchName,
                };
            },
        },
    },
    [UserRole.recruiter]: {
        [ConditionalFields.region]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userDate: UserDataForClient)=> {
                return {
                    regionId: userDate.regionId,
                    regionName: userDate.regionName,
                };
            },
        },
        [ConditionalFields.branch]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userData: UserDataForClient)=> {
                return {
                    branchId: userData.branchId,
                    branchName: userData.branchName,
                };
            },
        },
    },
    [UserRole.employee]: {
        [ConditionalFields.region]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userDate: UserDataForClient)=> {
                return {
                    regionId: userDate.regionId,
                    regionName: userDate.regionName,
                };
            },
        },
        [ConditionalFields.branch]: {
            allowedToView: false,
            allowedToEdit: false,
            getDefaultValue: (userData: UserDataForClient)=> {
                return {
                    branchId: userData.branchId,
                    branchName: userData.branchName,
                };
            },
        },
    },
};

const useConditionalFields = () => {
    const {currentUserData} = useSignedInContext();

    const role = useMemo(() => {
        return currentUserData?.role;
    }, [currentUserData?.role]);

    const fieldSettings = useCallback((field: ConditionalFields, defaultValueFallback: any) => {
        if (!role || !permissions?.[role] || !permissions?.[role]?.[field] || !currentUserData) {
            return {
                allowedToView: true,
                allowedToEdit: true,
                defaultValue: undefined,
            };
        } else {
            return {
                allowedToView: permissions?.[role]?.[field]?.allowedToView ?? true,
                allowedToEdit: permissions?.[role]?.[field]?.allowedToEdit ?? true,
                defaultValue: permissions?.[role]?.[field]?.getDefaultValue(currentUserData) ?? defaultValueFallback ?? undefined,
            };
        }
    }, [role, currentUserData]);

    return {
        getFieldSettings: fieldSettings,
    };
};

export { useConditionalFields, ConditionalFields };
