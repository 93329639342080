import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Text, View, ScrollView } from 'react-native';
import { CreateUserInput, EditUserInput, UserRole, UserStatus } from '@temptrack/business';
import { WrappedInput } from '../../../helperComponents/misc/WrappedInput';
import {
    MB_TextInput,
    MB_TextInputProps,
    MB_TextInputRef,
    MB_TextInputToolTipPortal,
    MB_TextInputToolTipPortalRef,
} from '@mightybyte/rnw.components.text-input';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { DefaultSwitch } from '../../../helperComponents/misc/DefaultSwitch';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { OutlinedButton } from '../../../helperComponents/misc/OutlinedButton';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { STRING_CONSTANTS } from '../../../../constants/constants';
import { trpc } from '../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../context/CurrentCompanyContext';
import { recordModalContentStyles } from '../../../../constants/recordModalContentStyles';
import { useEmail } from '../../../../hooks/useEmail';
import { usePhoneNumber } from '../../../../hooks/usePhoneNumber';
import { ConditionalFields, useConditionalFields } from '../../../../hooks/useConditionalFields';
import { useRegions } from '../../../../hooks/useRegions';
import { useRoles } from '../../../../hooks/useRoles';
import { initialStyles } from '../../../../hooks/useAppInitialSetup';
import { MB_DropDownPicker } from '@mightybyte/rnw.components.dropdownpicker';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../helperComponents/misc/Spacer';
import {TimelineView} from '../../../helperComponents/timelineView';

const COLORS = getColors();

enum InputTypes {
    firstName = 'firstName',
    lastName = 'lastName',
    role = 'role',
    branch = 'branch',
    region = 'region',
}

type CompanyRoleTypes = UserRole.companyAdmin | UserRole.regionAdmin | UserRole.recruiter
const rolesWithoutBranch:CompanyRoleTypes[] = [UserRole.companyAdmin, UserRole.regionAdmin];
const rolesWithoutRegion:CompanyRoleTypes[] = [UserRole.companyAdmin];

const CompanyUserForm = (
    {
        isEditing,
        closeAction,
        action,
        submitInProgress,
        submitErrorMessage,
        userId,
        resetSubmitError,
        editNotAllowed,
        showTimeline,
}: {
    userId: string | undefined;
    isEditing: boolean;
    closeAction: () => void;
    action: (data:any)=> void;
    submitInProgress: boolean;
    submitErrorMessage: string;
    resetSubmitError: () => void;
    editNotAllowed: boolean;
    showTimeline: boolean;
    toggleShowTimeline: () => void;
}) => {
    const {currentCompanyId} = useCurrentCompanyContext();
    const {getFieldSettings} = useConditionalFields();

    const phoneNumber = usePhoneNumber({isRequired: true, disabled: editNotAllowed});
    const email = useEmail({isRequired: true, disabled: editNotAllowed, nextToFocus: phoneNumber.ref});

    const [initialIsDone, setInitialIsDone] = useState(false);

    const [firstName, setFirstName] = React.useState<string>('');
    const [lastName, setLastName] = React.useState<string>('');

    const lastNameRef = useRef<MB_TextInputRef>(null);

    const {roleOptions} = useRoles();
    const [role, setRole] = React.useState<CompanyRoleTypes | undefined>( roleOptions?.length === 1 ? roleOptions[0].value as CompanyRoleTypes : undefined);

    const [branchId, setBranchId] = React.useState<string | undefined>(undefined);

    const regionOptions = getFieldSettings(ConditionalFields.region, {
        regionId: undefined,
        regionName: undefined,
    });
    const [regionId, setRegionId] = React.useState<string | undefined>(regionOptions.allowedToEdit ? undefined : regionOptions.defaultValue?.regionId);
    const {regionDropdownOptions} = useRegions({disabled: !regionOptions.allowedToView});

    const [status, setStatus] = React.useState<UserStatus>(UserStatus.active);

    const {data: branchOptions, isLoading: gettingBranches} = trpc.company.branch.getDropdown.useQuery({companyId: currentCompanyId ?? '', regionId: regionId ?? ''}, {
        enabled: !!currentCompanyId && !!regionId,
    });

    const branchDropdownOptions = useMemo(() => {
        if (!branchOptions || !branchOptions?.items || gettingBranches) {
            return [];
        }
        return branchOptions.items.map((branch)=> {
            return {label: branch.name, value: branch.id};
        });
    }, [branchOptions, gettingBranches]);

    const needBranch = useMemo(()=> {
        if (!role) {return false;}
        return !rolesWithoutBranch.includes(role);
    }, [role]);

    const needRegion = useMemo(()=> {
        if (!role) {return false;}
        return !rolesWithoutRegion.includes(role);
    }, [role]);

    const {data: recordData, isLoading} = trpc.company.user.getById.useQuery({userId: userId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: isEditing && !!userId && !!currentCompanyId,
    });

    const {data: timeline, isLoading: gettingTimeline} = trpc.company.user.getTimeline.useQuery({userId: userId ?? '', companyId: currentCompanyId ?? '' }, {
        enabled: showTimeline && !!userId && !!currentCompanyId,
    });

    useEffect(() => {
        if (initialIsDone) {
            return;
        }
        if (recordData?.userData) {
            const initialData = recordData.userData;
            setFirstName(initialData.firstName);
            setLastName(initialData.lastName);
            setStatus(initialData.status);
            setRole(initialData.role as CompanyRoleTypes);
            setRegionId(initialData.regionId);
            setBranchId(initialData.branchId);
            email.setInitialEmail(initialData.email);
            phoneNumber.setInitialValue(initialData.phoneNumber);
            setInitialIsDone(true);

        }

    }, [recordData, phoneNumber, email, initialIsDone]);

    const fieldsMap = useMemo<{
        [key in InputTypes]: {
            value: any;
        };
    }>(()=> {
        const base = {
            [InputTypes.firstName]: {value: firstName},
            [InputTypes.lastName]: {value: lastName},
            [InputTypes.role]: {value: role},
            [InputTypes.branch]: {value: branchId},
            [InputTypes.region]: {value: regionId},
        };
        return base;
    }, [ firstName, lastName, role, branchId, regionId]);

    const [formErrorMessages, setFormErrorMessages] = useState<
        {
            [key in InputTypes]: string;
        }
    >({
        [InputTypes.firstName]: '',
        [InputTypes.lastName]: '',
        [InputTypes.role]: '',
        [InputTypes.branch]: '',
        [InputTypes.region]: '',
    });


    useEffect(() => {
        if (role === UserRole.companyAdmin) {
            setBranchId('');
            setRegionId('');

        }
        setFormErrorMessages(prevErrors=>{
            return {...prevErrors, ...{[InputTypes.branch]: '', [InputTypes.region]: ''}};});
    }, [role, setFormErrorMessages]);


    const isEmpty = (value: string | undefined) => {
        if (!value) {
            return true;
        }
        return value.trim().length === 0;
    };

    const requiredFields = useMemo(()=> {
        const required = [InputTypes.firstName, InputTypes.lastName, InputTypes.role];
        if (needRegion) {
            required.push(InputTypes.region);
        }
        if (needBranch) {
            required.push(InputTypes.branch);
        }
        return required;
    }, [needBranch, needRegion]);

    const validateForm = useCallback(() => {
        const errors = {...formErrorMessages};
        let isValid = true;

        //check required fields
        requiredFields.forEach((key) => {
            const value = fieldsMap[key as keyof typeof fieldsMap].value;
            if (isEmpty(value)) {
                errors[key as keyof typeof errors] = STRING_CONSTANTS.REQUIRED;
                isValid = false;
            } else {
                errors[key as keyof typeof errors] = '';
            }
        });

        setFormErrorMessages(errors);
        const emailIsValid = email.validateEmail();
        const phoneNumberIsValid = phoneNumber.validate();
        return isValid && emailIsValid && phoneNumberIsValid;
    },[fieldsMap, formErrorMessages, setFormErrorMessages, requiredFields, email, phoneNumber]);

    const fieldError = useCallback((field: InputTypes) => {
        return formErrorMessages[field];
    }, [formErrorMessages]);


    const createRecord = () => {
        if (!currentCompanyId) {
            return;
        }
        if (!role || !currentCompanyId) {
            //just for TS. We already validate this in validateForm
            return;
        }
        const data: CreateUserInput = {
            companyId: currentCompanyId,
            firstName,
            lastName,
            email: email.value,
            phoneNumber: phoneNumber.value,
            status,
            role,
        };
        if (needRegion) {
            data.regionId = regionId ?? undefined;
        }
        if (needBranch) {
            data.branchId = branchId;
        }
        action(data);
    };

    const editRecord = () => {
        if (!role || !currentCompanyId) {
            //just for TS. We already validate this in validateForm
            return;
        }
        const data: EditUserInput = {
            userId: userId as string,
            firstName,
            lastName,
            email: email.value,
            phoneNumber: phoneNumber.value,
            status,
            role,
            companyId: currentCompanyId as string,
        };
        if (needRegion) {
            data.regionId = regionId ?? undefined;
        }
        if (needBranch) {
            data.branchId = branchId;
        }
        action(data);
    };

    const submitAction =  () => {
        if (!validateForm()) {
            return;
        }
        if (isEditing) {
            editRecord();
        } else {
            createRecord();

        }
    };

    const cancelAction = ()=>{
        closeAction();
    };

    const getInputProps = useCallback((field:InputTypes)=> {
        const fieldStrings = {
            [InputTypes.firstName]: {title: 'First Name', placeholder: 'Enter first name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.firstName)},
            [InputTypes.lastName]: {title: 'Last Name', placeholder: 'Enter last name', textContentType: 'name', showRequiredAsteriks: requiredFields.includes(InputTypes.lastName)},
            [InputTypes.role]: {title: 'Assign a Role', placeholder: 'Select a role', textContentType: 'none' as MB_TextInputProps['textContentType'], showRequiredAsteriks: requiredFields.includes(InputTypes.role)},
            [InputTypes.branch]: {title: 'Assign Branch', placeholder: 'Select branch', textContentType: 'none' as MB_TextInputProps['textContentType'], showRequiredAsteriks: requiredFields.includes(InputTypes.branch)},
            [InputTypes.region]: {title: 'Assign Region', placeholder: 'Select region', textContentType: 'none' as MB_TextInputProps['textContentType'], showRequiredAsteriks: requiredFields.includes(InputTypes.region)},
        };


        return {
            style: {width: '100%'},
            placeholderTextColor: COLORS.inputPlaceholder,
            value: fieldsMap[field].value,
            title: fieldStrings[field].title,
            placeholder: fieldStrings[field].placeholder,
            textContentType: fieldStrings[field].textContentType as MB_TextInputProps['textContentType'],
            showRequiredAsteriks: fieldStrings[field].showRequiredAsteriks,
            disable: editNotAllowed,
            preventDisabledStyleChange: true,
        };
    }, [fieldsMap, requiredFields, editNotAllowed]);

    const cleanErrorsOnFieldChange = useCallback((field: InputTypes) => {
        resetSubmitError();
        setFormErrorMessages((prevErrors)=> {
            return {...prevErrors, ...{[field]: ''}};
        });
    }, [resetSubmitError, setFormErrorMessages]);

    const portalRef = useRef<MB_TextInputToolTipPortalRef>(null);

    if (isEditing && (isLoading || !recordData)) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );
    }

    if (showTimeline) {
        return (
            <TimelineView data={timeline?.timelineData ?? []}
                          isLoading={gettingTimeline}
            />
        );
    }

    return (
        <ScrollView contentContainerStyle={recordModalContentStyles.container} nestedScrollEnabled={true}>
            <MB_TextInputToolTipPortal MB_Ref={portalRef} />
            <View style={{flex:1}}>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.firstName)}
                                      inputComponent={<MB_TextInput{...getInputProps(InputTypes.firstName)}
                                                                   onChangeText={(newValue)=> {
                                                                       cleanErrorsOnFieldChange(InputTypes.lastName);
                                                                       setFirstName(newValue);
                                                                   }}
                                                                   nextToFocus={lastNameRef}
                                      />}
                        />
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        <WrappedInput errorMessage={fieldError(InputTypes.lastName)}
                                      inputComponent={<MB_TextInput {...getInputProps(InputTypes.lastName)}
                                          onChangeText={(newValue)=> {
                                                cleanErrorsOnFieldChange(InputTypes.lastName);
                                                setLastName(newValue);
                                          }}
                                                                    nextToFocus={email.ref}
                                                                    MB_Ref={lastNameRef}
                                      />}
                        />
                    </View>
                </View>
                <View style={recordModalContentStyles.row}>
                    <View style={recordModalContentStyles.rowItem}>
                        {email.field}
                    </View>
                    <View style={recordModalContentStyles.rowItem}>
                        {phoneNumber.field}
                    </View>
                </View>
                <View style={[recordModalContentStyles.row, {zIndex: 3000}]}>
                    <WrappedInput inputComponent={
                        <MB_DropDownPicker items={roleOptions}
                                           value={role}
                                           onValueChange={(newValue: string| undefined)=> {
                                               if (!newValue || newValue === role) {return;}
                                               setRole(newValue ? newValue as CompanyRoleTypes : undefined);
                                           }}
                                           placeholder={'Select a role'}
                                           title={'Assign a Role'}
                                           containerStyle={{width: '100%'}}
                                           dropdownContainerStyle={initialStyles.dropdownContainerStyle}
                                           disabled={editNotAllowed}
                                           disableInteractions={editNotAllowed}
                                           showRequiredAsteriks={true}
                                           dropDownDirection={'BOTTOM'}
                                           listMode={'SCROLLVIEW'}
                                           multiple={false}


                        />
                    } errorMessage={fieldError(InputTypes.role)}/>

                </View>
                {needRegion ? <View style={[recordModalContentStyles.row, {zIndex: 2000}]}>
                  <WrappedInput inputComponent={
                      <MB_DropDownPicker items={regionOptions.allowedToView ? regionDropdownOptions : regionOptions.defaultValue ? [{
                            label: regionOptions.defaultValue.regionName,
                            value: regionOptions.defaultValue.regionId,
                      }] : []}
                                         value={regionId}
                                         onValueChange={setRegionId}
                                         placeholder={'Select Region'}
                                         title={'Assign Region'}
                                         containerStyle={{width: '100%'}}
                                         disabled={!regionOptions.allowedToView || editNotAllowed}
                                         disableInteractions={!regionOptions.allowedToView || editNotAllowed}
                                         showRequiredAsteriks={true}
                                         dropDownDirection={'BOTTOM'}
                                         listMode={'SCROLLVIEW'}
                                         multiple={false}



                      />
                  } errorMessage={fieldError(InputTypes.region)}
                                containerStyle={needBranch ? {...recordModalContentStyles.rowItem, ...{zIndex: 2000}} : {zIndex: 2000}}
                                subText={regionOptions.allowedToView || editNotAllowed ? undefined : 'You can only invite users to your region'}
                  />

                    {needBranch ? <WrappedInput inputComponent={
                      <MB_DropDownPicker items={branchDropdownOptions}
                                         value={branchId}
                                         onValueChange={setBranchId}
                                         placeholder={'Select Branch'}
                                         title={'Assign Branch'}
                                         containerStyle={{width: '100%'}}
                                         disabled={editNotAllowed}
                                         disableInteractions={editNotAllowed}
                                         showRequiredAsteriks={true}
                                         dropDownDirection={'BOTTOM'}
                                         listMode={'SCROLLVIEW'}
                                         multiple={false}
                      />
                  } errorMessage={fieldError(InputTypes.branch)} containerStyle={{...recordModalContentStyles.rowItem, ...{zIndex: 1500}}}/> : null}


                </View> : null}
                <View style={recordModalContentStyles.row}>
                    {editNotAllowed ? null
                        : <View style={[{justifyContent: 'flex-start'}]}>
                    <Text style={recordModalContentStyles.inputLabel}>Status</Text>

                    <View style={{flexDirection: 'row', alignItems: 'center'}}>
                        <DefaultSwitch isToggled={status === UserStatus.active}
                                       onToggle={(newValue)=> {
                                           setStatus(newValue ? UserStatus.active : UserStatus.inactive);}}
                                       shouldUseTTStyles={false}
                                       label={status}
                                       disabled={editNotAllowed}

                        />
                    </View>

                </View>}
                </View>
            </View>


            <View style={{zIndex: -1}}>
                <View style={recordModalContentStyles.errorContainer}>
                    <Text style={recordModalContentStyles.errorMessage}>{submitErrorMessage}</Text>
                </View>


                {editNotAllowed ? <View style={[recordModalContentStyles.row, {justifyContent: 'center'},  recordModalContentStyles.buttonRow]}>
                    <View style={recordModalContentStyles.rowItem}>
                        <MB_Button title={'Close'}
                                   onPress={cancelAction}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                            // preventDisabledStyleChange={true}
                        />
                    </View>
                    </View> :
                    <View style={[recordModalContentStyles.row, recordModalContentStyles.buttonRow]}>
                    <View style={[recordModalContentStyles.rowItem]}>
                        <MB_Button title={isEditing ? 'Save changes' : 'Add user'}
                                   onPress={submitAction}
                                   disabled={submitInProgress}
                                   loading={submitInProgress}
                                   style={[{backgroundColor: applyTTStyles(false).primaryButtonBg}]}
                                   leftElement={<AntDesign name="plus" color={COLORS.buttonPrimaryText} size={18} />}
                        />
                    </View>
                        {isMobileApp ? <Spacer y={24}/> : undefined}
                    <View style={recordModalContentStyles.rowItem}>
                        <OutlinedButton title={editNotAllowed ? 'Close' : 'Discard'}
                                        disabled={submitInProgress}
                                        action={cancelAction}
                        />
                    </View>

                </View>}
            </View>


        </ScrollView>
    );
};

export { CompanyUserForm };
