import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

function CommentsIconSvg({ size }: {
    size?: number
}) {
    return (
        <Svg
            width={size ?? 14}
            height={size ? size * (13 / 14) : 13}
            viewBox="0 0 14 13"
            fill="none"
        >
            <Path
                d="M.6 2.5A1.6 1.6 0 012.2.9h5.6a1.6 1.6 0 011.6 1.6v3.2a1.6 1.6 0 01-1.6 1.6H6.2L3.8 9.7V7.3H2.2A1.6 1.6 0 01.6 5.7V2.5z"
                fill="#FACA15"
            />
            <Path
                d="M11 4.1v1.6a3.2 3.2 0 01-3.2 3.2h-.937L5.45 10.314c.224.119.479.186.75.186h1.6l2.4 2.4v-2.4h1.6a1.6 1.6 0 001.6-1.6V5.7a1.6 1.6 0 00-1.6-1.6H11z"
                fill="#633112"
            />
        </Svg>
    );
}

export default CommentsIconSvg;
