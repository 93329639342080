import React, { useMemo } from 'react';
import { TTLogoSvg } from '../../../resources/svgComponents/TTLogoSvg';
import { Image, StyleSheet, Text, View } from 'react-native';
import { mbGetMediumImage } from '../../../utils/imageUtils';
import { ImageUrls } from '@temptrack/business';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../constants/textStyles';
import {getColors} from '../../../constants/colors';
const COLORS = getColors();

const DisplayLogo = ({isTempTrack, logoUrl, companyName, width, ignoreName, svgSize}: {
    isTempTrack: boolean,
    logoUrl?: ImageUrls | undefined | string,
    companyName?: string,
    width?:  number | string | undefined,
    svgSize?: number | undefined,
    ignoreName?: boolean
}) => {

    const logo = useMemo(()=> {
        if (isTempTrack) {
            return <TTLogoSvg size={svgSize ?? 146}/>;
        } else if (logoUrl && typeof logoUrl !== 'string') {
            const logoSource = mbGetMediumImage(logoUrl, {asUri: true});
            return (<View style={{height: 66, minWidth: 66, width: 'auto'}}>
                   {logoSource ? <Image source={logoSource}
                           resizeMode="contain"
                           style={{width: width ?? '100%', height: '100%', left: 0}}
                    /> : null}
            </View>);
        } else if (companyName && !ignoreName) {
            return (
                <View style={{width: width ?? '100%'}}>
                    <Text style={styles.companyName}>{companyName}</Text>
                </View>

        );

        }
    }, [logoUrl, width, companyName, ignoreName, svgSize, isTempTrack]);


    return (
        <>
            {logo}
        </>

    );
};
const styles = StyleSheet.create({
    companyName: mbTextStyles([
        textStyles.Muli_16_700, {
            color: COLORS.textPrimary,
            marginBottom: 12,
            fontSize: 28,
        },
    ]),
});

export { DisplayLogo };
