import React from 'react';
import { MB_Button } from '@mightybyte/rnw.components.button';
import { StyleProp, ViewStyle, StyleSheet } from 'react-native';
import { getColors } from '../../../constants/colors';

const OutlinedButton = ({title, action, extraStyles, textStyles, disabled, leftIcon, color, loading}:{
    title: string,
    action: () => void,
    extraStyles?: StyleProp<ViewStyle>;
    textStyles?: StyleProp<ViewStyle>;
    disabled?: boolean;
    leftIcon?: JSX.Element;
    color?: string;
    loading?: boolean;
}) => {

    return (
        <MB_Button
            title={title}
            onPress={action}
            style={[defaultStyles.button, extraStyles ? extraStyles : {}, color ? {borderColor: color} : {}]}
            textStyle={[defaultStyles.text, textStyles ? textStyles : {}, color ? {color: color} : {}]}
            disabled={disabled}
            leftElement={leftIcon}
            loading={loading}
        />
    );
};

export {OutlinedButton};

const COLORS = getColors();

const defaultStyles = StyleSheet.create({
    button: {
        backgroundColor: 'transparent',
        borderWidth: 1,
        borderColor: COLORS.buttonSecondaryBorder,
    },
    text: {
        color: COLORS.buttonSecondaryText,
    },
});
