import React from 'react';
import { MB_LinearGradient } from '@mightybyte/rnw.components.linear-gradient';
import { COLORS } from '../../../constants/colors';

const SwitchGradient = ({children, style}:{
    children?: React.ReactNode
    style?: {[key: string]: any}
}) => {

    let elementStyle = {
        height: '100%',
    };

    if (style) {
        elementStyle = {
            ...elementStyle,
            ...style,
        };
    }

    if (children) {
        return (
            <MB_LinearGradient
                colors={[COLORS.buttonPrimaryBg, COLORS.buttonPrimaryBg]}
                useAngle
                angle={90}
                style={elementStyle}
            >
                {children}
            </MB_LinearGradient>
        );
    }
    return (
        <MB_LinearGradient
            colors={[COLORS.buttonPrimaryBg, COLORS.buttonPrimaryBg]}
            useAngle
            angle={90}
            style={elementStyle}
        />
    );
};

export { SwitchGradient };
