import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EditIconButton, ViewIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { toEmployeePositionLabel, toEmployeePositionTypeLabel, UserRole } from '@temptrack/business';
import { utilHooks } from '../../../../hooks/utilHooks';
import { utils } from '../../../../utils/utils';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { useSignedInContext } from '../../../../context/SignedInContext';
import { EmployeesTableProps } from './types';
import { NoText } from '../../../helperComponents/misc/NoText';

const COLORS = getColors();

const header = [
    { content: 'employee name', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: 'region', conatinerStyle: {  minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    {content: 'branch', conatinerStyle: {  minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'position', conatinerStyle: {  minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'position type', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'email', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'phone number', conatinerStyle: { minWidth: 150, maxWidth: 150, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'status', conatinerStyle: {  minWidth: 80, maxWidth: 80, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1, minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const EmployeesTable = ({data, isLoading, onEditIconPressed, openRowMenu, canManageRecords, onAddPress, addButtonText, hasFilters}:EmployeesTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const {currentUserData} = useSignedInContext();

    const shouldShowRegion = useMemo(()=> {
        return currentUserData?.role === UserRole.admin || currentUserData?.role === UserRole.companyAdmin;
    }, [currentUserData?.role]);

    const shouldShowBranch = useMemo(()=> {
        return shouldShowRegion || currentUserData?.role === UserRole.regionAdmin;
    }, [currentUserData?.role, shouldShowRegion]);

    const onEditPress = useCallback((d:string) => {
        onEditIconPressed(d);
    } , [onEditIconPressed]);

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const headerItems = useMemo(()=> {
        if (shouldShowRegion && shouldShowBranch) {
            return header;
        }
        if (!shouldShowBranch) {
            return header.filter((item)=> item.content !== 'region' && item.content !== 'branch');
        }

        if (!shouldShowRegion) {
            return header.filter((item)=> item.content !== 'region');
        }

        return header;
    }, [shouldShowRegion, shouldShowBranch]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const userData = item;
            const columns = {
                name: <View style={{maxWidth: 200}}> <Text style={tableStyles.tableRowText} numberOfLines={2}>{item?.firstName} {item?.lastName}</Text>
                    {item?.tempWorksId ? <Text style={tableStyles.subText} numberOfLines={1}>{`TempWorks ID:${item?.tempWorksId}`}</Text> : null}
                </View>,
                region: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.regionName}</Text>,
                branch: <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.branchName}</Text>,
                position: <Text style={tableStyles.tableRowText} numberOfLines={2}>{toEmployeePositionLabel(item?.position)}</Text>,
                positionType: <Text style={tableStyles.tableRowText} numberOfLines={1}>{toEmployeePositionTypeLabel(item?.positionType)}</Text>,
                email: <Text style={tableStyles.tableRowText} numberOfLines={1}>{!item?.email ? <NoText/> : item?.email}</Text>,
                phoneNumber: <Text style={tableStyles.tableRowText} numberOfLines={1}>{utils.phoneNumberToDisplay(item.phoneNumber)}</Text>,
                status:  <StatusRenderer status={item?.status} recordType={RecordTypes.employee} isInvitePending={item.isInvitePending}/>,
            };

            const action = <View style={{
                flexDirection: 'row',
                alignSelf: 'flex-end',
                position: 'relative',
            }}>
                {canManageRecords ? <EditIconButton action={()=>onEditPress(userData.id)}/> : <ViewIconButton action={()=>onEditPress(userData.id)}/>}
                {canManageRecords ? <IconButton icon={
                    <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                }
                                                action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                /> : null}
            </View>;
            if (!shouldShowBranch) {
                return [
                    columns.name,
                    columns.position,
                    columns.positionType,
                    columns.email,
                    columns.phoneNumber,
                    columns.status,
                    action,
                ];
            }
            if (!shouldShowRegion) {
                return [
                    columns.name,
                    columns.branch,
                    columns.position,
                    columns.positionType,
                    columns.email,
                    columns.phoneNumber,
                    columns.status,
                    action,
                ];
            }
            return [
                columns.name,
                columns.region,
                columns.branch,
                columns.position,
                columns.positionType,
                columns.email,
                columns.phoneNumber,
                columns.status,
                action,
            ];
        });
    }, [data, onEditPress, openRowMenu, canManageRecords, shouldShowRegion, shouldShowBranch]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={headerItems}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No employees yet'}
                                                          subtitle={canManageRecords ? 'Start adding employees to populate the directory.' : undefined}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          hideAddButton={!canManageRecords}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { EmployeesTable };
