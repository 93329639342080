export enum ChecklistActivityStatus {
    /**
     * At least 1 employee has been assigned.
     */
    active = 'active',

    /**
     * The work order has ended or 0 employees have been assigned.
     */
    inactive = 'inactive',

    /**
     * The checklist has been closed.
     */
    closed = 'closed',
}

export enum ChecklistAssignmentStatus {
    none = 'none',
    some = 'some',
    all = 'all',
}

export enum CheckInsStatus {
    /**
     * The checklist is inactive or closed.
     */
    notApplicable = 'notApplicable',

    /**
     * No one has checked in.
     */
    none = 'none',

    /**
     * Some have checked in.
     */
    some = 'some',

    /**
     * All have checked in.
     */
    all = 'all',
}

export enum CheckInRejectionReason {
    noShow = 'noShow',
    late = 'late',
    performance = 'performance',
    missingEquipment = 'missingEquipment',
}

export enum EmployeeAcceptanceStatus {
    pending = 'pending',
    accepted = 'accepted',
    declined = 'declined',
}

export enum ShortlistAcceptanceStatus {
    none = 'none',
    some = 'some',
    all = 'all',
}
