import { FilterFieldType, filterTextSchema } from '..';
import { createFilterMapping } from './helpers';

export const RegionFilterMap = createFilterMapping({
    name: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'name',
    },
});
