import React, {  useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { getColors } from '../../../constants/colors';
import {ColorSchema} from '../../../constants/colors';
import { DisplayLogo } from '../../helperComponents/misc/DisplayLogo';
import {ResetPassword} from './ResetPassword';
import { utilHooks } from '../../../hooks/utilHooks';
import { ForgotPasswordProps } from '../../../typesAndInterfaces/componentProps';
import {RequestReset} from './RequestReset';
import { useCurrentCompanyContext } from '../../../context/CurrentCompanyContext';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { envs } from '../../../../env';


const ForgotPassword = ({route}: ForgotPasswordProps) => {
    const COLORS = getColors();
    const {url} = utilHooks.useInitialURL();

    const styles = useMemo(() => getStyles(COLORS), [COLORS]);
    const passwordRecoveryToken = route.params?.passwordRecoveryToken;
    const recoveryFailed = route.params?.recoveryFailed ? route.params.recoveryFailed === 'undefined' : false;
    const isInitialSetup = route.params?.initialSetup ? route.params.initialSetup === 'true' : false;
    const isRegisterBySMSCode = route.params?.recoveryBySMSCode ?? false;
    const {currentCompanyName, currentCompanyLogoUrl, currentCustomDomain} = useCurrentCompanyContext();

    const domain = useMemo(() => {
        if (isMobileApp) {
            return route.params?.domain ?? envs.TEMPTRACK_SUBDOMAIN;
        }
        return currentCustomDomain ?? envs.TEMPTRACK_SUBDOMAIN;
    }, [currentCustomDomain, route.params?.domain]);

    if (!isMobileApp && !url) {
        return null;
    }

    const companyData = (()=> {
        if (!passwordRecoveryToken) {return {
            name: currentCompanyName ?? undefined,
            logoUrl: currentCompanyLogoUrl ?? undefined,
        };}
        try {
            const parsedToken = JSON.parse(passwordRecoveryToken);
            return {
                name: parsedToken?.company?.name,
                logoUrl: parsedToken?.company?.logoUrl,
            };
        } catch (e) {
            return {
                name: undefined,
                logoUrl: undefined,
            };
        }
    })();

    return (
        <View style={styles.container}>
            <View style={styles.logoContainer}>
                <DisplayLogo isTempTrack={domain === envs.TEMPTRACK_SUBDOMAIN}
                    logoUrl={companyData.logoUrl}
                             companyName={companyData.name}
                             svgSize={146}
                             width={200}
                />
            </View>
            {((passwordRecoveryToken && !!recoveryFailed) || isRegisterBySMSCode) ? <ResetPassword subdomain={domain}
                                                    passwordRecoveryToken={passwordRecoveryToken ? JSON.parse(passwordRecoveryToken) : undefined}
                                                                          isInitialSetup={isInitialSetup}
                                                                          isRegisterBySMSCode={isRegisterBySMSCode}

            /> : <RequestReset subdomain={domain} recoveryFailed={recoveryFailed}/>}
        </View>
    );
};

export { ForgotPassword };

const getStyles = (theme: ColorSchema)=> StyleSheet.create({
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        backgroundColor: theme.backgroundSecondary,
    },
    logoContainer: {
        marginBottom: 16,
        maxWidth: 420,
    },

});
