import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View } from 'react-native';
import { ViewChecklistProps } from '../../../../../typesAndInterfaces/componentProps';
import { UIDrawerRightContentWrapper } from '../../../../helperComponents/DrawerRightContentWrapper';
import { appColors, applyTTStyles, COLORS } from '../../../../../constants/colors';
import { ListViewHeader } from '../../../../helperComponents/ListViewHeader';
import { useSignedInContext } from '../../../../../context/SignedInContext';
import { CheckinsStatusBadge } from '../../../../helperComponents/misc/badges/CheckinsStatusBadge';
import { utils } from '../../../../../utils/utils';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { ModalWrapper } from '../../../../helperComponents/misc/ModalWrapper';
import { trpc } from '../../../../../apiCalls/trpcClient';
import { LoadingPage } from '../../../../helperComponents/LoadingPage';
import { useCurrentCompanyContext } from '../../../../../context/CurrentCompanyContext';
import { CheckListTableUI } from './CheckListTableUI';
import { ListInfoBlockUI } from '../ListInfoBlockUI';
import AntDesign from 'react-native-vector-icons/AntDesign';
import { mbShowToast } from '@mightybyte/rnw.components.toast';
import { ChecklistEmployeeTableData, EmployeeUpdateCheckInData } from '../types';
import { CommentModalContent } from './CommentModalContent';
import {
    WorkOrderPosition,
} from '@temptrack/business';
import { DisplayOptions, ValueRow } from '../../../../helperComponents/misc/ValueRow';
import { DateIterator } from '../../../../helperComponents/DateIterator';
import { ChecklistViewMode, CompanyDashboardRoutes } from '../../../../../navigations/types';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';
import { Spacer } from '../../../../helperComponents/misc/Spacer';
import { RecordTypes } from '../../../../helperComponents/misc/StatusRenderer';


const ViewChecklist = ({navigation, route}:ViewChecklistProps) => {
    const trpcContext = trpc.useUtils();

    const {isClient} = useSignedInContext();
    const checklistId = route.params?.checklistId;

    const [viewMode] = useState<ChecklistViewMode>(route.params?.viewMode ?? ChecklistViewMode.checkList);

    const {currentCompanyId} = useCurrentCompanyContext();

    const [editCheckins, setEditCheckins] = useState(false);

    const [submitError, setSubmitError] = useState<string | undefined>(undefined);

    const [checkingChangesToBeSaved, setCheckingChangesToBeSaved] = useState<Record<string, EmployeeUpdateCheckInData>>({});

    const updateCheckinData = useCallback((data: EmployeeUpdateCheckInData)=> {
        setCheckingChangesToBeSaved(prevState => {
            const newState = {...prevState};
            newState[data.id] = data;
            return newState;
        });
    },[]);

    const {data: currentChecklist, isLoading: isChecklistLoading} = trpc.company.checklist.getById.useQuery({
        checklistId: checklistId ?? '', companyId: currentCompanyId ?? ''}, {
        enabled: !!checklistId && !!currentCompanyId && viewMode === ChecklistViewMode.checkList,
    });

    const {data: allChecklists} = trpc.company.checklist.getByWorkOrderId.useQuery({
        companyId: currentCompanyId ?? '', workOrderId: currentChecklist?.checklistData?.workOrder?.id ?? ''}, {
        enabled: !!currentCompanyId && !!currentChecklist?.checklistData?.workOrder?.id,
    });

    const dates = useMemo(()=> {
        if (!allChecklists?.checklists) {
            return [];
        }
        //reverse the order of the dates
        return allChecklists.checklists?.map((item)=> {
            return {date: item.date, id: item.id};
        }).reverse();
    }, [allChecklists?.checklists]);

    const addButtonText = useMemo(()=> {
        if (isClient) {
            return editCheckins ? 'Save changes' : 'Check-in employees';
        } else {
            return '';
        }
    },[isClient, editCheckins]);

    const subtitleText = useMemo(()=> {
        if (isClient) {
            return 'Check-in employees for specific dates or add comments.';
        } else {
            return 'View employees check-ins and added comments.';
        }
    },[isClient]);

    const subTitleComponent = useMemo(()=> {
        if (isClient) {
            return null;
        } else {
            return <ValueRow label={'Client'} value={currentChecklist?.checklistData?.clientName}
                             displayOption={DisplayOptions.secondary}
                             containerStyles={{marginLeft: 44}}
                             fontSize={16}
            />;
        }
    },[isClient, currentChecklist?.checklistData]);


    const {mutate: checkinEmployees, isLoading: isCheckinEmployeesLoading} = trpc.company.checklist.checkIn.useMutation({
        onSuccess: ()=> {
            mbShowToast({ text1: 'Check-ins saved',
                type: 'success',
                text2: '',
            });
            setEditCheckins(false);

            trpcContext.company.checklist.getById.invalidate({
                checklistId: checklistId ?? '', companyId: currentCompanyId ?? ''});
        },
        onError: (err)=> {
            mbShowToast({ text1: utils.getErrorForDisplay(err),
                type: 'error',
                text2: '',
            });
        },
    });

    const onCheckinSubmit = useCallback(()=> {
        if (!currentCompanyId || !checklistId) {
            return;
        }
        if (!editCheckins) {
            setEditCheckins(true);
            return;
        }
        const updatedCheckins = Object.values(checkingChangesToBeSaved);
        const filtered = updatedCheckins.filter((item)=> item.isApproved !== -1);

        checkinEmployees({
            companyId: currentCompanyId ?? '',
            checklistId: checklistId ?? '',
            employees: filtered.map((item:EmployeeUpdateCheckInData)=> {
                return {...item, ...{isApproved: item?.isApproved === 1}};
            }),
        });
    } ,[currentCompanyId, checklistId, editCheckins, checkinEmployees, checkingChangesToBeSaved]);

    const tableKey = useMemo(()=> {
        const current = currentChecklist?.checklistData?.checkIns?.checkIns?.map((item)=> item.employee.id) ?? [];
        return current.join('');
    },[currentChecklist?.checklistData]);


    /*Comments handling*/

    const commentOnSuccessAction = (actyionType: 'add'|'edit'|'delete')=> {
        setOpenComment(undefined);
        const actionText = actyionType === 'add' ? 'added' : actyionType === 'edit' ? 'edited' : 'deleted';
        setTimeout(()=> {
            mbShowToast({ text1: `Comment ${actionText}`,
                type: 'success',
                text2: '',
            });
        }, 400);
        trpcContext.company.checklist.getById.invalidate({
            checklistId: checklistId ?? '', companyId: currentCompanyId ?? ''});
    };

    const resetView = useCallback(()=> {
        setOpenComment(undefined);
        setEditCheckins(false);
        setCheckingChangesToBeSaved({});
    },[]);


    useEffect(() => {
        resetView();
    }, [checklistId, resetView]);

    const [openComment, setOpenComment] = useState<ChecklistEmployeeTableData| undefined>(undefined);

    useEffect(() => {
        if (openComment === undefined) {
            setSubmitError(undefined);
        }
    }, [openComment, setSubmitError]);

    const {mutate: addComment, isLoading: addingComment} = trpc.company.checklist.comment.create.useMutation({
        onSuccess: ()=> commentOnSuccessAction('add'),
        onError: (err)=> {
            setSubmitError(utils.getErrorForDisplay(err));
        },

    });

    const {mutate: deleteComment, isLoading: deletingComment} = trpc.company.checklist.comment.delete.useMutation({
        onSuccess: ()=> commentOnSuccessAction('delete'),
        onError: (err)=> {
            setSubmitError(utils.getErrorForDisplay(err));
        },
    });

    const deleteCommentAction = useCallback(()=> {
        if (!currentCompanyId || !checklistId || !openComment) {
            return;
        }

        deleteComment({
            companyId: currentCompanyId ?? '',
            checklistId: checklistId ?? '',
            employeeId: openComment.id,
        });
    },[openComment, currentCompanyId, checklistId, deleteComment]);

    const {mutate: editComment, isLoading: editingComment} = trpc.company.checklist.comment.edit.useMutation({
        onSuccess: ()=> commentOnSuccessAction('edit'),
        onError: (err)=> {
            setSubmitError(utils.getErrorForDisplay(err));
        },
    });

    const getEmployeeComment = useCallback((employeeId: string)=> {
        const comment = currentChecklist?.checklistData?.comments?.find((item)=> item.employee.id === employeeId);
        return comment?.comment ?? '';
    },[currentChecklist?.checklistData?.comments]);

    const commentAction = useCallback((comment: string)=> {
        if (!currentCompanyId || !checklistId || !openComment) {
            return;
        }

        const existingComment = getEmployeeComment(openComment.id);
        const payload = {
            companyId: currentCompanyId ?? '',
            checklistId: checklistId ?? '',
            employeeId: openComment.id,
            comment: comment,
        };
        if (existingComment && existingComment?.length > 0) {
            editComment(payload);
        } else {
            addComment(payload);
        }

    },[openComment, currentCompanyId, checklistId, getEmployeeComment, addComment, editComment]);

    const commentModalTitle = useMemo(()=> {
        if (!isClient || !openComment) {
            return 'View comment';
        }
        const comment = getEmployeeComment(openComment.id);
        return comment.length > 0  ? 'Edit comment' : 'Add comment';
    },[isClient, openComment, getEmployeeComment]);

    /* End of comments handling*/

    const checklistEmployees = useMemo<ChecklistEmployeeTableData[]>(()=> {
        const data = currentChecklist?.checklistData;
        if (!data) {
            return [];
        }
        const assignedTo = data.assignmentStatus.assignedTo ?? [];
        const checkIns = data.checkIns?.checkIns ?? [];
        const comments = data.comments ?? [];

        const employeeData = assignedTo.map((item)=> {
            const checkedInfo = checkIns.find((checkIn)=> checkIn.employee.id === item.id);
            const comment = comments.find((c)=> c.employee.id === item.id);
            const base: ChecklistEmployeeTableData = {...item};
            const currentChanges = checkingChangesToBeSaved?.[item.id];

            if (checkedInfo || currentChanges) {
                base.isApproved = currentChanges ? currentChanges?.isApproved === 1 : checkedInfo?.isApproved  ?? false;
                base.rejectionReasons = checkedInfo?.rejectionReasons;
            }
            if (comment) {
                base.comment = comment.comment;
            }
            return base;
        });
        return employeeData;
    },[currentChecklist, checkingChangesToBeSaved]);

    const dateIteratorOnPress = useCallback((id)=> {
        if (!currentCompanyId) {
            return;
        }
        navigation.navigate(CompanyDashboardRoutes.viewChecklist, {companyId: currentCompanyId, checklistId:id});
    },[currentCompanyId, navigation]);

    const headerMenuAction = useCallback(()=> {
        navigation.toggleDrawer();
    }, [navigation]);

    const DateIteratorComponent = useMemo(()=> {
        return (
            <View style={{marginBottom: 4}}>
                <DateIterator dates={dates} currentId={checklistId} onPress={dateIteratorOnPress}/>
            </View>
        );
    },[dates, checklistId, dateIteratorOnPress]);

    const extraHeaderComponent = useMemo(()=> {
                if (isMobileApp){
                    return (
                    <View style={{alignItems: 'center'}}>
                            <Spacer y={12}/>
                            <CheckinsStatusBadge todayCheckins={currentChecklist?.checklistData?.checkIns}
                                                 checklistDate={utils.displayDateObj(currentChecklist?.checklistData?.date)}
                                                 noMargin
                            />
                            <Spacer y={6}/>
                            {DateIteratorComponent}
                    </View>
                    );}
                return (

                    <CheckinsStatusBadge todayCheckins={currentChecklist?.checklistData?.checkIns} checklistDate={utils.displayDateObj(currentChecklist?.checklistData?.date)}/>
                );
    }, [currentChecklist, DateIteratorComponent]);

    const footerComponent = useMemo(()=> {
        return <ListInfoBlockUI recordType={RecordTypes.checklist} data={{

            number: currentChecklist?.checklistData?.workOrder?.number ?? '',
            clientName: currentChecklist?.checklistData?.clientName,
            startDate: currentChecklist?.checklistData?.workOrder?.startDate,
            endDate: currentChecklist?.checklistData?.workOrder?.endDate,
            position: currentChecklist?.checklistData?.workOrder?.position as WorkOrderPosition,
            positionType:  currentChecklist?.checklistData?.workOrder.positionType,
            assignmentStatus: {
                status: currentChecklist?.checklistData?.assignmentStatus?.status,
                assignedToQty: currentChecklist?.checklistData?.assignmentStatus?.assignedToQty,
                employeesNeededQty: currentChecklist?.checklistData?.assignmentStatus?.employeesNeededQty,
            },
            activityStatus: currentChecklist?.checklistData?.activityStatus,
            createdAt: currentChecklist?.checklistData?.createdAt,
            acceptanceStatus: undefined,
        }}/>;
    },[currentChecklist?.checklistData]);

    if (!currentChecklist || isChecklistLoading) {
        return (
            <LoadingPage barColor={appColors.blue700}/>
        );
    }


    return (
        <UIDrawerRightContentWrapper headerProps={{
            routeName: route.name,
            headerMenuAction: headerMenuAction,
        }}
                                     removePaddingX={isMobileApp}
                                     wrapInScrollView={!isMobileApp}
        >
            <ModalWrapper show={openComment !== undefined}
                          closeAction={()=> setOpenComment(undefined)}
                          title={commentModalTitle}
                          height={400}
                          maxHeight={400}>
                {openComment !== undefined ? <CommentModalContent employee={openComment}
                                                                  action={commentAction}
                                                                  cancelAction={()=> setOpenComment(undefined)}
                                                                  deleteComment={deleteCommentAction}
                                                                  submitInProgress={addingComment || deletingComment || editingComment}
                                                                  comment={getEmployeeComment(openComment.id)}
                                                                  submitError={submitError}
                                                                  isClient={isClient}
                /> : null}

            </ModalWrapper>
            {/*<View style={isMobileApp ? {paddingHorizontal: rightViewPadding} : undefined}>*/}
                <ListViewHeader title={ `Checklist #${currentChecklist?.checklistData?.number ?? ''}`}
                                subtitle={subtitleText}
                                subtitleComponent={subTitleComponent}
                                hideAddButton={!isClient}
                                buttonText={addButtonText}
                                onAddPress={onCheckinSubmit}
                                hideButtonLeftElement={true}
                                buttonColor={applyTTStyles(false).primaryButtonBg}
                    // hideAddButton={isClient}
                    // buttonDisabled={isClient && editCheckins && checkingChangesToBeSaved.length === 0}
                                buttonIsLoading={isCheckinEmployeesLoading}
                                buttonIcon={isClient ? undefined : <AntDesign name={'setting'} size={20} color={COLORS.buttonPrimaryText}/>}
                                headerExtraComponent={<View style={isMobileApp ? styles.headerExtraContainerMobile : styles.headerExtraContainer}>
                                    {extraHeaderComponent}
                                </View>}
                                showGoBack={true}
                                backButtonAction={()=> {
                                    resetView();
                                    navigation.goBack();
                                }}
                                cancelButton={isClient && editCheckins ? {
                                    action: ()=> {
                                        setCheckingChangesToBeSaved({});
                                        setEditCheckins(false);
                                    },
                                    text: 'Cancel',
                                } : undefined}

                />
            {/*</View>*/}

            { currentChecklist?.checklistData ?
                <View style={{width: '100%'}}>
                    {isMobileApp ? null : DateIteratorComponent}

                    <CheckListTableUI key={tableKey}
                                      data={checklistEmployees}
                                      isEditing={editCheckins}
                                      updateCheckinData={updateCheckinData}
                                      openComment={(employee)=>setOpenComment(employee)}
                                      footerComponent={ footerComponent }
                    />
                    {isMobileApp ? null : footerComponent }

            </View> : null }
        </UIDrawerRightContentWrapper>
    );
};

const styles = StyleSheet.create({
    headerExtraContainer: {
        flexDirection: 'row',
        alignItems: 'center',
    },
    headerExtraContainerMobile: {
        flexDirection: 'column',
    },
    dateText: mbTextStyles([
        textStyles.Muli_16_600,
    ]),
    buttonContainer: {
        paddingVertical: 8,
        backgroundColor: COLORS.backgroundPrimary,
        borderTopWidth: 1,
        borderColor: COLORS.inputBorder,
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
});

export {ViewChecklist};
