import React from 'react';
import { StyleProp, TextStyle, TouchableOpacity, ViewStyle, StyleSheet } from 'react-native';
import { COLORS } from '../../../constants/colors';
import MaterialCommunityIcons from 'react-native-vector-icons/MaterialCommunityIcons';

interface ICheckbox {
  isChecked: boolean,
  onPress: () => void,
  containerStyle?: StyleProp<ViewStyle>,
  checkboxStyleChecked?: StyleProp<TextStyle>,
  checkboxStyleUnchecked?: StyleProp<TextStyle>,
  disabled?: boolean,
}

const Checkbox = ({ isChecked, onPress, containerStyle, checkboxStyleChecked, checkboxStyleUnchecked, disabled }: ICheckbox) => {
  return (
    <TouchableOpacity style={[styles.container, containerStyle]} onPress={onPress} disabled={disabled}>
      {isChecked ?
        <MaterialCommunityIcons name="checkbox-marked" size={20} color={COLORS.buttonPrimaryBg} style={checkboxStyleChecked} />
        :
        <MaterialCommunityIcons name="checkbox-blank-outline" size={20} color={COLORS.inputBorder} style={checkboxStyleUnchecked} />
      }
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    width: 40,
    height: 40,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export { Checkbox };
