import React, { useCallback, useMemo } from 'react';
import { GestureResponderEvent, ScrollView, Text, View } from 'react-native';
import { appColors, applyTTStyles, getColors } from '../../../../constants/colors';
import { tableStyles } from '../../../../constants/tableStyles';
import { RecordTypes, StatusRenderer } from '../../../helperComponents/misc/StatusRenderer';
import { MB_Table } from '@mightybyte/rnw.components.table';
import { EditIconButton } from '../../../helperComponents/misc/TableRowActions';
import { IconButton } from '../../../helperComponents/misc/IconButton';
import Entypo from 'react-native-vector-icons/Entypo';
import { utilHooks } from '../../../../hooks/utilHooks';
import { EmptyTableView } from '../../../helperComponents/EmptyTableView';
import { tableReusableProps } from '../../../helperComponents/tableReusableProps';
import { AdminUsersTableProps } from './types';
import { utils } from '../../../../utils/utils';

const COLORS = getColors();

const header = [
    { content: 'first name', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'last name', conatinerStyle: { minWidth: 200, maxWidth: 200, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'email', conatinerStyle: { minWidth: 300, maxWidth: 300, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    { content: 'phone number', conatinerStyle: {  minWidth: 170, maxWidth: 170, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle},
    { content: 'status', conatinerStyle: {  minWidth: 80, maxWidth: 80, paddingVertical: 0 }, textStyle: tableStyles.headerTextStyle },
    {content: '', conatinerStyle: { flex: 1,  minWidth: 120}, textStyle: tableStyles.headerTextStyle },
];

const UsersTable = ({data, isLoading, onEditIconPressed, openRowMenu, onAddPress, addButtonText, hasFilters}: AdminUsersTableProps) => {
    const tableHeight = utilHooks.useTableHeight();

    const onEditPress = useCallback((d:string) => {
        onEditIconPressed(d);
    } , [onEditIconPressed]);

    const keyExtractor = useCallback((index) =>data?.[index]?.id ?? index, [data]);

    const dataToDisplay = useMemo(()=> {
        if (!data || data.length === 0) {
            return [];
        }
        return data.map((item) => {
            const userData = item;
            return [
                <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.firstName}</Text>,
                <Text style={tableStyles.tableRowText} numberOfLines={1}>{item?.lastName}</Text>,
                <Text style={[tableStyles.tableRowText, {color: COLORS.textSecondary}]} numberOfLines={1}>{item?.email}</Text>,
                <Text style={[tableStyles.tableRowText, {color: COLORS.textSecondary}]} numberOfLines={1}>{utils.phoneNumberToDisplay(item?.phoneNumber)}</Text>,
                <StatusRenderer status={item?.status} isInvitePending={item.isInvitePending} recordType={RecordTypes.user}/>,
                <View style={{
                    flexDirection: 'row',
                    alignSelf: 'flex-end',
                    position: 'relative',
                }}>
                    <EditIconButton action={()=>onEditPress(userData.id)}/>
                    <IconButton icon={
                        <Entypo name={'dots-three-horizontal'} color={COLORS.textSecondary} size={20}/>
                    }
                                action={(e:GestureResponderEvent)=>openRowMenu(e, item.id)}
                    />
                </View>,

            ];
        });
    }, [data, onEditPress, openRowMenu]);

    return (
        <>
            <ScrollView style={[tableStyles.tableContainer, {height: tableHeight}]}>
                <MB_Table {...tableReusableProps}
                          header={header}
                          headerStyle={tableStyles.tableHeader}
                          data={[...dataToDisplay]}
                          style={[tableStyles.tableStyle]}
                          rowStyle={tableStyles.rowStyle}
                          keyExtractor={keyExtractor}
                          rowTextStyle={tableStyles.tableRowText}
                          loading={isLoading}
                          doNotRenderEmptyData={isLoading}
                          EmptyComponent={<EmptyTableView onAddPress={onAddPress}
                                                          title={'No users yet'}
                                                          subtitle={'Start adding users to populate the directory.'}
                                                          buttonText={addButtonText}
                                                          buttonColor={applyTTStyles(false).primaryButtonBg}
                                                          iconColor={appColors.blue100}
                                                          height={tableHeight}
                                                          hasFilters={hasFilters}
                          />}

                />
            </ScrollView>
        </>
    );
};

export { UsersTable };
