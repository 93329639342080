import * as React from 'react';
import { StyleProp, View, ViewStyle } from 'react-native';
import Svg, { Path } from 'react-native-svg';

const DocumentReportIconSvg = React.memo(({size, containerStyle, color}: {
    size?: number, containerStyle?: StyleProp<ViewStyle>
    color?: string
}) => {
    return (
        <View style={containerStyle}>
            <Svg
                width={size ?? 16}
                height={size ? size * (20 / 16) : 20}
                viewBox="0 0 16 20"
                fill="none"
            >
                <Path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M3.2.4A2.4 2.4 0 00.8 2.8v14.4a2.4 2.4 0 002.4 2.4h9.6a2.4 2.4 0 002.4-2.4V6.897a2.4 2.4 0 00-.703-1.697L10.4 1.104A2.4 2.4 0 008.703.4H3.2zm2.4 12a1.2 1.2 0 10-2.4 0V16a1.2 1.2 0 102.4 0v-3.6zM8 8.8A1.2 1.2 0 019.2 10v6a1.2 1.2 0 11-2.4 0v-6A1.2 1.2 0 018 8.8zm4.8-1.2a1.2 1.2 0 10-2.4 0V16a1.2 1.2 0 102.4 0V7.6z"
                    fill={color ?? '#9CA3AF'}
                />
            </Svg>


        </View>
    );
});

export { DocumentReportIconSvg  };
