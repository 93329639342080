import { createWebsiteBaseUrl, envs } from '../../../../env';
import { MB_customWindowForWeb } from '@mightybyte/rnw.utils.custom-window-for-web';
import {isMobileApp} from '@mightybyte/rnw.utils.device-info';
import {getRootNavRef} from '../../../navigations/RootNavigator';

const navigation = getRootNavRef?.();

export const navigateToSignIn = (isTTEmployee:boolean, customDomain?: string | undefined)=> {
    let baseUrl = createWebsiteBaseUrl(envs.FLAVOR, true);
    //if custom domain is passed that means we want to skip enter url page
    if (customDomain) {
        baseUrl = baseUrl.replace('*', customDomain);
    } else {
        baseUrl = baseUrl.replace('*', envs.TEMPTRACK_SUBDOMAIN);
    }
    if (!isMobileApp){
        MB_customWindowForWeb.open(`${baseUrl}/login`, '_self');
    } else {
        navigation?.navigate('EnterUrl');
    }

};
