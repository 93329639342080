import { CompanyUserRoles, UserStatus } from '../../entities';
import {
    filterDropdownSchema,
    filterPhoneSchema,
    filterStringDropdownSchema,
    filterTextSchema,
} from '..';
import { FilterFieldType } from '..';
import { createFilterMapping } from './helpers';

export const TempTrackUserFilterMap = createFilterMapping({
    firstName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'firstName',
    },
    lastName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'lastName',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
    status: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(UserStatus),
        dbField: 'status',
    },
});

export const CompanyUserFilterMap = createFilterMapping({
    firstName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'firstName',
    },
    lastName: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'lastName',
    },
    email: {
        filterFieldType: FilterFieldType.text,
        filterFieldSchema: filterTextSchema,
        dbField: 'email',
    },
    phoneNumber: {
        filterFieldType: FilterFieldType.phone,
        filterFieldSchema: filterPhoneSchema,
        dbField: 'phoneNumber',
    },
    regionId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'regionId',
    },
    branchId: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterStringDropdownSchema,
        dbField: 'branchId',
    },
    role: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(
            CompanyUserRoles.reduce(
                (acc, role) => {
                    acc[role] = role;
                    return acc;
                },
                {} as Record<
                    (typeof CompanyUserRoles)[number],
                    (typeof CompanyUserRoles)[number]
                >,
            ),
        ),
        dbField: 'role',
    },
    status: {
        filterFieldType: FilterFieldType.dropdown,
        filterFieldSchema: filterDropdownSchema(UserStatus),
        dbField: 'status',
    },
});
