import { version } from '../../version';

const getVersionNumber = (): {
    jsVersion: string;
    appVersion?: string;
    buildVersion?: string;
} => {
    return {
        jsVersion: version,
    };
};

export { getVersionNumber };
