import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CheckInRejectionReason, toCheckInRejectionReasonLabel } from '@temptrack/business';
import { appColors, COLORS } from '../../../../../constants/colors';
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import Entypo from 'react-native-vector-icons/Entypo';
import { EmployeeBadge } from '../EmployeeBadge';
import CommentsIconSvg from '../../../../../resources/svgComponents/CommentsIconSvg';
import { mbTextStyles } from '@mightybyte/rnw.utils.style-utils';
import { textStyles } from '../../../../../constants/textStyles';
import { ChecklistEmployeeTableData, EmployeeUpdateCheckInData } from '../types';
import { useCheckboxGroup } from '../../../../../hooks/useCheckboxGroup';
import { initialStyles } from '../../../../../hooks/useAppInitialSetup';
import { Spacer } from '../../../../helperComponents/misc/Spacer';
import {utils} from '../../../../../utils/utils';
import { isMobileApp } from '@mightybyte/rnw.utils.device-info';

const ChecklistEmpolyeeRow = ({
    employeeCheckinInfo,
    updateCheckinData,
    isClient,
    isEditing,
    openComment,
}:{
    employeeCheckinInfo: ChecklistEmployeeTableData,
    updateCheckinData: (data: EmployeeUpdateCheckInData)=> void;
    isClient: boolean,
    isEditing: boolean,
    openComment: (employee: ChecklistEmployeeTableData)=> void;
}) => {
    const getInitialState = useCallback((employeeData)=> {
        if (employeeData?.isApproved !== undefined) {
            return employeeData.isApproved ? 1 : 0;
        }
        return -1;
    }, []);

    const initialReasons = useRef(employeeCheckinInfo?.rejectionReasons ?? []);
    const initialIsApproved = useRef(employeeCheckinInfo?.isApproved !== undefined ? employeeCheckinInfo.isApproved : undefined);

    const [approvalStatus, setApprovalStatus] = useState<-1 | 0 | 1>(getInitialState(employeeCheckinInfo));

    useEffect(() => {
        setApprovalStatus(getInitialState(employeeCheckinInfo));
    }, [employeeCheckinInfo, setApprovalStatus, getInitialState]);

    const hasComment = employeeCheckinInfo?.comment !== undefined && employeeCheckinInfo?.comment !== '';

    const checkboxGroup = useCheckboxGroup({
        options: Object.values(CheckInRejectionReason).map((reason) => {
            return {
                label: toCheckInRejectionReasonLabel(reason),
                value: reason,
            };
        }),
        initialValues: employeeCheckinInfo?.rejectionReasons ?? [],

    });


    const checkBoxClickHandler = useCallback(() => {
        setApprovalStatus(1);

    }, [setApprovalStatus]);

    const rejectClickHandler = useCallback(() => {
           setApprovalStatus(0);


    }, [setApprovalStatus]);

    const hasUnsavedChanges = useMemo(()=> {
        if (!isEditing){ return false;}
        if (approvalStatus === 0 && !utils.arraysOfStringsAreEqual(checkboxGroup.values, initialReasons.current)) {
            return true;
        }

        if (initialIsApproved.current === undefined){
            return approvalStatus !== -1;
        } else if (!initialIsApproved.current) {
            return approvalStatus !== 0;
        }
        return approvalStatus !== 1;
    }, [approvalStatus, checkboxGroup.values, isEditing]);

    useEffect(() => {
        if (approvalStatus === -1){
            return;
        }
        const data:EmployeeUpdateCheckInData = {
            id: employeeCheckinInfo.id,
            isApproved: approvalStatus,
        };
        if (data.isApproved === 0) {
            data.rejectionReasons = checkboxGroup.values.map((reason) => reason as CheckInRejectionReason);
        }

            updateCheckinData(data);

    }, [checkboxGroup.values, approvalStatus, employeeCheckinInfo.id, updateCheckinData]);

    const checkboxColor = useMemo(()=> {
        return approvalStatus === 1 ? appColors.green600 : COLORS.inputBackground;
    }, [approvalStatus]);


    const declineColor = useMemo(()=> {
       if (approvalStatus === -1 || approvalStatus === 1){
           return COLORS.inputBorder;
       }
        return appColors.red600;
    }, [approvalStatus]);

    const CheckboxComponent = useCallback(()=> {
        return (
            <View style={[styles.checkBox, {backgroundColor: checkboxColor,
                borderColor: approvalStatus === 1 ? checkboxColor : appColors.gray300},
            ]}>
                {approvalStatus === 1 ? <Entypo name={'check'} color={approvalStatus === 1 ? COLORS.backgroundPrimary : appColors.gray300} size={12}/> : null}

            </View>
        );
    }, [approvalStatus, checkboxColor]);


    const iconsRenderer = ()=> {
        if (!isClient || !isEditing) {
            return (

                <View style={{width: 30, justifyContent: 'center', alignItems: 'center'}}>
                    {approvalStatus === 0 ? <Entypo name={'block'} color={appColors.red600} size={16}/> : CheckboxComponent()}
                </View>
            );

        }
        else {
            return (
                <>
                    <TouchableOpacity onPress={()=> checkBoxClickHandler()}
                                      disabled={!isClient}
                                      style={{justifyContent: 'center', alignItems: 'center'}}
                    >
                        {CheckboxComponent()}
                    </TouchableOpacity>
                    <Spacer x={8}/>
                    <TouchableOpacity onPress={()=> rejectClickHandler()}
                                      disabled={!isClient}
                                      style={{justifyContent: 'center', alignItems: 'center'}}
                    >
                        <Entypo name={'block'} color={declineColor} size={16}/>

                    </TouchableOpacity>
                </>
            );
        }
    };


    return (
        <View>
            <View style={[styles.rowItem, {}]}>

                <View style={[styles.row, {width: isMobileApp ? undefined : isEditing ? 390 : 300, borderRightWidth: isMobileApp ? undefined : 1, borderColor: COLORS.inputBorder}]}>
                    {iconsRenderer()}
                    <Spacer x={8}/>
                    <View style={isMobileApp ? undefined : {flexDirection: 'row', alignItems: 'center'}}>
                        <EmployeeBadge name={`${employeeCheckinInfo?.firstName} ${employeeCheckinInfo?.lastName}`}
                                       disabled={true} noVerticalSpacing={true}
                                       containerStyle={{marginRight: 8}}
                        />
                        {hasUnsavedChanges ?
                            <Text style={[styles.checkedText, {color: appColors.red500}]}>Changes not saved</Text> : null}
                    </View>

                </View>

                {isMobileApp ? null : <View style={[styles.row, {flex: 1, paddingHorizontal: 16}]}>
                    {hasComment ? <Text style={styles.commentText} numberOfLines={1}>{employeeCheckinInfo.comment}</Text> : null}
                </View>}

                <View style={[styles.row, isMobileApp ? undefined : {width: 150, justifyContent: 'flex-end'}]}>


                    {isClient && !hasComment ? <TouchableOpacity onPress={()=>openComment(employeeCheckinInfo)} style={styles.row}>
                        {isMobileApp ? null :  <Text style={styles.addCommentText}>Add comment</Text>}
                        <Entypo name={'plus'} color={appColors.blue600} size={16} />
                    </TouchableOpacity> : null}
                    {hasComment ? <TouchableOpacity onPress={()=>openComment(employeeCheckinInfo)} style={styles.row}>
                        {isMobileApp ? null : <Text style={styles.addCommentText}>{isClient ? 'Edit comment' : 'View comment'}</Text>}
                        <CommentsIconSvg size={16}/>
                    </TouchableOpacity> : null}

                </View>

            </View>
            {(isEditing && approvalStatus === 0 ) ? <View style={isMobileApp ? undefined : {paddingLeft: 50, marginBottom: 6}} >
                <View style={{flexDirection: 'row', alignItems: 'center'}}>
                <Text style={styles.declineReasonText}>Reason for declining:<Text style={initialStyles.requiredAsteriksColor}>*</Text></Text>
                {checkboxGroup.CheckBoxGroup}
                </View>

            </View> : null}
            {(!isEditing && approvalStatus === 0 ) ? <View style={{paddingLeft: 40, marginBottom: 6}} >
                <Text style={styles.declineReasonText}>{`Reason for declining: ${employeeCheckinInfo?.rejectionReasons?.map((r)=> {
                    return toCheckInRejectionReasonLabel(r);
                })?.join(', ')}`}</Text>
            </View>

            : null}
        </View>
    );

};

export { ChecklistEmpolyeeRow};

const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        alignItems: 'center',
        marginVertical: 2,
    },
    rowItem: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingVertical: 4,
    },
    checkBox: {
        width: 16,
        height: 16,
        borderRadius: 4,
        // marginRight: 8,
        justifyContent: 'center',
        alignItems: 'center',
        borderWidth: 1,
    },
    checkedText:  mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
            marginLeft: 8,
        },
    ]),
    addCommentText: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
            marginRight: 4,
            color: appColors.blue600,
        },
    ]),
    commentText: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
            color: COLORS.textSecondary,
        },
    ]),
    declineReasonText: mbTextStyles([
        textStyles.Muli_16_500, {
            textAlign: 'left',
            fontSize: 14,
            color: COLORS.textPrimary,
            marginRight: 16,
        },
    ]),
});

